import React, { useState, useEffect } from "react";
import { ResizableBox } from "react-resizable";
import * as Icon from "react-feather";
import { Form, Button, Accordion } from "react-bootstrap";
import { openGitHubPullRequestService } from "../services/editor";
import ByPassTestsModal from "./Modals/ByPassTests";
import { CircularProgress } from "@mui/material"

export default function PullRequestModal({
  setIsShown,
  selectedBranch,
  dbtCommand,
  activeTenant,
  test_dbt_model,
  dbt_test_status,
  dbt_logs,
  dbt_url
}) {
  const [showBypassModal, setShowBypassModal] = useState(false);
  const [localDbtTestStatus, setLocalDbtTestStatus] = useState(dbt_test_status);
  const [isTestsOpened, setIsTestsOpened] = useState(true);

  useEffect(() => {
    // Call test_dbt_model when the modal is opened
    if(!localDbtTestStatus && !dbt_logs) {
        test_dbt_model(dbtCommand);
    }
  }, []); // Empty dependency array ensures this runs only once when the modal is opened

  useEffect(() => {
    setLocalDbtTestStatus(dbt_test_status);
  }, [dbt_test_status]);

  const handleCreatePullRequest = () => {
    if (localDbtTestStatus === "failed" || !localDbtTestStatus) {
      setShowBypassModal(true);
    } else {
      openGitHubPullRequest();
    }
  };

  const openGitHubPullRequest = async () => {
    if (!localDbtTestStatus) await openGitHubPullRequestService(dbt_url);
    const tenantName = typeof activeTenant === 'string' ? activeTenant : activeTenant.name || 'default';
    const pullRequestUrl = `https://github.com/datajolt/${tenantName}/compare/main...${selectedBranch}`;
    window.open(pullRequestUrl, "_blank");
  };    

  const handleTestClick = () => {
    setLocalDbtTestStatus(null);
    test_dbt_model(dbtCommand);
  };

  const getStatusIcon = (isButton = false) => {
    if (!localDbtTestStatus) {
      return (
        <CircularProgress size={16} color="#fff" />
      );
    } else if (localDbtTestStatus === "success") {
      return <Icon.Check color={isButton ? "white" : "green"} />;
    } else if (localDbtTestStatus === "failed") {
      return <Icon.X color={isButton ? "white" : "red"} />;
    }
    return null;
  };

  return (
    <>
      <ResizableBox
        className={`dragged-box react-resizable sidebar-modal-wrapper pull-request-modal`}
        height={window.innerHeight - 50}
        width={window.innerWidth / 3}
        maxConstraints={[window.innerWidth - 65, window.innerHeight - 50]}
        minConstraints={[400, 400]}
        resizeHandles={["w"]}
        id="active-modal-sidebar"
        style={{
          zIndex: 1050, // Higher z-index to ensure it appears above Robin ChatSidebar
          position: 'relative',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'
        }}
      >  
        <section className="schedule-sidebar-content" style={{ gap: "11px" }}>
          <h2 className="heading-schedule-model" style={{ marginBottom: 0 }}>
            <small
              style={{
                fontWeight: 500,
                fontSize: 12,
                border: "2px solid #D0D5DD",
                borderRadius: "7px",
                display: "flex",
                alignItems: "center",
                padding: "12px 15px",
                height: "48px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >            
              <Icon.ChevronsRight
                color="#438593"
                onClick={() => setIsShown(false)}
                role="button"
              />{" "}
              Pull Request for {selectedBranch}
            </small>
          </h2>
          <Form
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            <Form.Group controlId="dependencies">
              <Form.Label>Testing your branch with the following command</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  value={dbtCommand}
                  type="text"
                  disabled
                />
                <Button
                  variant="secondary"
                  className="ml-2 test-button"
                  onClick={handleTestClick}
                  disabled={!localDbtTestStatus}
                >
                  <Icon.Code width={15} className="button-icon" />
                  {localDbtTestStatus ? "Test" : "Testing..."}
                </Button>
              </div>
            </Form.Group>
            <br />
            <Accordion
              defaultActiveKey="0"
              className="pl-0"
              style={{ flex: 1 }}
              activeKey="0"
            >
              <Accordion.Item eventKey="0" className="bg-white" style={{height: "100%"}}>
                <Accordion.Header>
                  <div style={{ 
                    width: '100%',
                    pointerEvents: 'none'
                  }}>
                    <span style={{ 
                      display: 'inline-block',
                      width: 'calc(100% - 30px)'  // Adjust if needed to prevent text wrapping
                    }}>
                      DBT Test Logs &nbsp;&nbsp;&nbsp; {getStatusIcon()}
                    </span>
                    <style>{`
                      .accordion-button::after {
                        display: none !important;
                      }
                    `}</style>
                  </div>
                </Accordion.Header>
                {dbt_logs && (
                  <Accordion.Body className="p-0 m-2 h-100">
                    <div
                      className="log-display"
                      dangerouslySetInnerHTML={{
                        __html: dbt_logs,
                      }}
                    />
                  </Accordion.Body>
                )}
              </Accordion.Item>
            </Accordion>
          </Form>

          <div className="d-flex justify-content-between gap-2 mt-auto schedule-model-btns-wrapper">
            <Button
              variant={localDbtTestStatus === "failed" ? "danger" : "primary"}
              className={`w-100 sc-btn ${localDbtTestStatus === "failed" || !localDbtTestStatus ? "btn-danger" : ""}`}
              style={localDbtTestStatus === "failed" || !localDbtTestStatus ? { backgroundColor: "#dc3545", borderColor: "#dc3545" } : {}}
              onClick={handleCreatePullRequest}
            >
              {localDbtTestStatus === "failed" || !localDbtTestStatus ? (
                <>Bypass test & Create pull request</>
              ) : (
                <>Create Pull Request <Icon.GitPullRequest width={24} className="button-icon" /></>
              )}
            </Button>
          </div>
        </section>
      </ResizableBox>

      <ByPassTestsModal
        isShown={showBypassModal}
        onHide={() => setShowBypassModal(false)}
        onAction={() => {
          setShowBypassModal(false);
          setLocalDbtTestStatus("failed");
          openGitHubPullRequest();
        }}
      />
    </>
  );
}