import React from "react";
import { DialogContentText, Typography } from "@mui/material";
import { Delete, X } from "react-feather";
import Layout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";

/**
 * @param {{
 *  isShown: boolean,
 *  onHide: () => void,
 *  onAction: () => void,
 *  closeableDarwer: string,
 *  children?: JSX.Element
 * }}} data
 */
const CloseDrawerModal = ({
  isShown,
  onHide,
  onAction,
  closeableDrawer,
  children,
}) => {
  return (
    <Layout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>Warning!</ModalLayoutHeader>
      <ModalLayoutContent>
        <DialogContentText
          textAlign={"center"}
          fontSize={11}
          fontFamily={"Poppins"}
          color="#6c757d"
        >
          You are about to close {closeableDrawer} without saving your progress,
          all the progress will be lost.
          <Typography
            component={"span"}
            display={"block"}
            fontWeight={600}
            fontSize={11}
            mt={1}
          >
            Are you sure ?
          </Typography>
        </DialogContentText>
      </ModalLayoutContent>
      <ModalLayoutFooter>
        {children || (
          <>
            <ModalLayoutBtn onClick={onHide} color="secondary">
              <X size={16} /> Cancel
            </ModalLayoutBtn>
            <ModalLayoutBtn color="error" onClick={onAction}>
              <Delete size={16} /> Close
            </ModalLayoutBtn>
          </>
        )}
      </ModalLayoutFooter>
    </Layout>
  );
};

export default CloseDrawerModal;
