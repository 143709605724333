import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Controls,
  ReactFlowProvider
} from "react-flow-renderer";
import WorkflowDAG from "./workflowDAG";
import { debounce } from "lodash";
import { CSSTransition } from "react-transition-group";

import DagHistory from "../DagHistory";

import "./workflows.css";
import DatajoltSpinner from "../../../components/global/DatajoltSpinner";
import { handleRefreshingService, updateNodeStatusesService } from "../../../services/overview";

const nodeTypes = { workflowDAG: WorkflowDAG };

export default function WorkflowManger({
  editorURL,
  DAGList,
  editWorkflow = false,
  updateWorkflows,
  setCurrentWorkflow,
  listDags
}) {
  const [isShowingDagHistoryTable, setIsShowingDagHistoryTable] =
    useState(false);
  const [selectedDagId, setSelectedDagId] = useState(null);
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const prevNodesRef = useRef([]);
  const prevEdgesRef = useRef([]);

  const handleDagHistoryOpen = (dagId) => {
    setSelectedDagId(dagId);
    setIsShowingDagHistoryTable(true);
  };

  const setInitialNodes = (statusesList) => {
    updateNodeStatuses();
    if (editWorkflow.initial_workflow_chain) {
      const starterEdges = [];
      const starterData = [...editWorkflow.initial_workflow_chain].map(
        (node) => {
          if (node.dependencies.length) {
            node.dependencies.forEach((dep) => {
              starterEdges.push({
                target: node.dag,
                source: dep,
                id: `${node.dag}-${dep}`,
              });
            });
          }
          const dagData = DAGList.find((target) => target.id === node.dag);
          return {
            id: node.dag,
            type: "workflowDAG",
            position: {
              x: node?.position?.x ? parseFloat(node.position.x) : 0,
              y: node?.position?.y ? parseFloat(node.position.y) : 0,
            },
            data: {
              label: node.dag,
              logo: node.logo,
              nodeData: {
                ...dagData,
                id: node.dag,
                datajolt_task_id: dagData?.datajolt_task_id || node.dag,
                task_name: node.dag,
                ...statusesList?.[node.dag],
              },
              _setIsShowingDagHistoryTable: handleDagHistoryOpen,
              _handleDelete: undefined,
              editorURL: editorURL,
            },
          };
        }
      );
      setNodes(starterData);
      setEdges(starterEdges);
    }
  };

  useEffect(() => {
    setInitialNodes();
  }, [JSON.stringify(editWorkflow)]);

  const handleCanvasUpdate = useCallback(
    debounce((nodesData, edgesData) => {
      const updatedWorkflowChain = combineNodesAndEdges(nodesData, edgesData);
      updateWorkflows(updatedWorkflowChain);
    }, 1000),
    []
  );

  function combineNodesAndEdges(nodes, edges) {
    const uniqueIds = new Set();
    const filteredNodes = [];

    nodes.forEach((node) => {
      if (!uniqueIds.has(node.id)) {
        uniqueIds.add(node.id);
        filteredNodes.push(node);
      }
    });

    const combinedArray = filteredNodes.map((node) => {
      const dependencies = edges
        .filter((edge) => edge.target === node.id)
        .map((edge) => edge.source);

      return {
        dag: node.id,
        dependencies,
        position: node.position,
      };
    });

    return combinedArray;
  }

  useEffect(() => {
    if (
      JSON.stringify(nodes) !== JSON.stringify(prevNodesRef.current) ||
      JSON.stringify(edges) !== JSON.stringify(prevEdgesRef.current)
    ) {
      handleCanvasUpdate(nodes, edges);
      prevNodesRef.current = nodes;
      prevEdgesRef.current = edges;
    }
  }, [nodes, edges]);

  const updateNodeStatuses = async () => {
    const response = await updateNodeStatusesService(editWorkflow.run_id, editWorkflow.workflow_name);

    if(response) {
      const workflow_dag_status = response?.data;
      setNodes((nds) =>
        nds.map((node) => {
          const updatedNodeData = workflow_dag_status[node.id];
          return {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...updatedNodeData,
              },
            },
          };
        })
      );
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateNodeStatuses();
    }, 7000); // Fetch node statuses every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []); // Empty dependency array ensures this runs once on mount

  const handleRefreshing = async () => {
    const dag = DAGList.find(dag => dag.workflowname === editWorkflow.workflow_name);
    var advanced_settings = dag.advanced_settings;
    setIsLoading(true);
    
    const newLineage = await handleRefreshingService(editorURL, advanced_settings);

    if(newLineage) {
      setCurrentWorkflow(newLineage);
      listDags();
    }

    setIsLoading(false);
  };

  return (
    <>
      <CSSTransition
        in={isShowingDagHistoryTable}
        classNames="form-tab"
        timeout={300}
        unmountOnExit
      >
        <DagHistory
          dagList={DAGList}
          historyModalId={selectedDagId}
          onClose={() => {
            setIsShowingDagHistoryTable(false);
            setSelectedDagId(null);
          }}
          show_logs={true}
          isWorkflowView={true}
        />
      </CSSTransition>
      <section id="workflowManager">
        <ReactFlowProvider>
          <div style={{ height: "calc(100vh - 140px)" }}>
            {Boolean(nodes.length && !isLoading) ? (
              <div className="dndflow canvas-wrapper canvas-wrapper--full-width">
                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                  <ReactFlow
                    deleteKeyCode="192"
                    onNodesChange={onNodesChange}
                    nodes={nodes}
                    edges={edges}
                    onInit={setReactFlowInstance}
                    nodeTypes={nodeTypes}
                    minZoom={0.05}
                    maxZoom={0.8}
                    fitView
                  >
                    <Controls />
                  </ReactFlow>
                </div>
              </div>
            ) : (
              <div className="row loading-workflow-visuals">
                <br></br>
                <center>
                  <DatajoltSpinner />
                </center>
              </div>
            )}
          </div>
        </ReactFlowProvider>

        <button
          style={{
            position: "absolute",
            right: "16px",
            bottom: "16px",
            color: "white",
            border: "1px solid #D0D5DD",
            borderRadius: "5px",
            background: "#818589",
            padding: "10px 15px",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "25px",
            zIndex: 10,
          }}
          onClick={handleRefreshing}
        >
          Refresh Visualisation
        </button>
      </section>
    </>
  );
}
