import React, { useState, useEffect } from "react";
import {
  useAuth,
  useTenantsState,
  AdminPortal,
  ContextHolder,
  useAuthActions,
} from "@frontegg/react";
import { Settings, User, LogOut, ChevronDown } from "react-feather";
import { setSession } from "../../utils/jwt";
import {
  MenuItem,
  Button,
  Menu,
  Stack,
  Typography,
  CircularProgress,
  menuClasses,
} from "@mui/material";
import { fetchCustomerParametersService } from "../../services/global";

const NavbarComponent = () => {
  const { switchTenant } = useAuthActions();
  const [loading, setLoading] = useState(true);
  const [tenant, setTenant] = useState(localStorage.getItem("activeTenantId"));
  const [profileImage, setProfileImage] = useState();
  const { user } = useAuth();
  const { tenants } = useTenantsState();
  const [sortedTenants, setSortedTenants] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (tenants.length > 0) {
      const sorted = [...tenants].sort((a, b) => a.name.localeCompare(b.name));
      setSortedTenants(sorted);
    } else {
      setLoading(false);
    }
  }, [tenants]);

  useEffect(() => {
    fetchCustomerParameters();
  }, []);

  useEffect(() => {
    if (user?.profilePictureUrl) {
      setProfileImage(user.profilePictureUrl);
    }
  }, [user?.profilePictureUrl]);

  const handleTenantChange = async (newTenantId) => {
    try {
      console.log(`Switching to tenant ID: ${newTenantId}`);
      localStorage.removeItem("timezone");
      localStorage.removeItem("logo");
      localStorage.removeItem("dwh_schemas");
      localStorage.removeItem("last_successful_query");
      setSession(null);
      console.log("Setting selected_branch to main");
      localStorage.setItem("selected_branch", "main");
      await switchTenant({ tenantId: newTenantId });
    } catch (error) {
      console.error("Error switching tenant:", error);
    }
  };

  const fetchCustomerParameters = async () => {
    const logo = localStorage.getItem("logo");
    const timezone = localStorage.getItem("timezone");

    if (
      logo &&
      logo !== "undefined" &&
      timezone &&
      timezone !== "undefined"
    ) {
      setLoading(false);
    } else {
      const customer_name = await fetchCustomerParametersService();

      if(customer_name) {
        setLoading(false);
        setTenant(customer_name);
      }
    }
  };

  const adminPortal = () => {
    AdminPortal.show();
  };

  const signOut = () => {
    const baseUrl = ContextHolder.getContext().baseUrl;
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
  };

  return (
    <Stack
      component={"nav"}
      direction={"row"}
      bgcolor={"#fff"}
      boxShadow={"0 0 2rem 0 rgba(41, 48, 66, 0.1)"}
      paddingBlock={1}
      paddingInline={2.5}
      justifyContent={"space-between"}
    >
      {loading ? (
        <CircularProgress size={16} />
      ) : (
        <img
          src={localStorage.getItem("logo")}
          alt="Logo"
          style={{
            width:
              localStorage.getItem("logo") ===
              "https://assets-global.website-files.com/64cc5a89d4d4629cd9a9c005/64cc5a89d4d4629cd9a9c180_Pear-Logo-Green-dark-2021%2520(1)-p-500.png"
                ? "90px"
                : "135px",
            height: "auto",
          }}
        />
      )}
      <div>
        <Button onClick={handleMenu} sx={{ color: "#293042" }}>
          <Typography
            variant="caption"
            sx={{ display: { xs: "inline-block", sm: "none" } }}
          >
            <Settings size={18} />
          </Typography>
          <Typography
            variant="caption"
            sx={{ display: { xs: "none", sm: "inline-block" } }}
          >
            <img
              src={profileImage}
              alt={user ? user.name : "Avatar"}
              style={{
                marginBlock: -16,
                borderRadius: "50%",
              }}
              width={40}
              height={40}
            />{" "}
            <Typography
              variant="caption"
              fontSize={11}
              textTransform={"initial"}
              fontFamily={"Poppins"}
            >
              {user?.name}
            </Typography>
          </Typography>
          <ChevronDown size={12} />
        </Button>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{
            [`& .${menuClasses.root}`]: {
              boxShadow: "0 0.1rem 0.2rem rgba(0, 0, 0, 0.05)",
              paddingBlock: 1,
              border: "1px solid rgba(0, 0, 0, 0.15)",
              borderRadius: "0.2rem",
            },
          }}
        >
          <MenuItem
            onClick={adminPortal}
            sx={{
              paddingInline: 3,
              paddingBlock: 0.5,
              color: "#495057",
              gap: 1,
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <User size={18} />
            Profile
          </MenuItem>
          <MenuItem
            onClick={signOut}
            sx={{
              paddingInline: 3,
              paddingBlock: 0.5,
              color: "#495057",
              gap: 1,
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <LogOut size={18} />
            Sign out
          </MenuItem>
          <hr />
          {sortedTenants.map((tenantItem) => (
            <MenuItem
              key={tenantItem.name}
              active={tenantItem.tenantId === tenant}
              onClick={() => handleTenantChange(tenantItem.tenantId)}
              sx={{
                paddingInline: 3,
                paddingBlock: 0.5,
                color: "#495057",
                gap: 1,
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              {tenantItem.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </Stack>
  );
};

export default NavbarComponent;
