import React from "react";
import { Dialog, dialogClasses } from "@mui/material";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  children: JSX.Element
 *  styles: SxProps<Theme> | undefined;
 * }} data
 */
const ModalLayout = ({ isShown, onHide, children, styles = {} }) => {
  return (
    <Dialog
      open={isShown}
      onClose={onHide}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          maxWidth: "600px",
          width: "100%",
        },
        ...styles
      }}
    >
      {children}
    </Dialog>
  );
};

export default ModalLayout;
