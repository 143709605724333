import React, { useState, useEffect, useRef } from "react";
import DatajoltSpinner from "../../components/global/DatajoltSpinner";
import { ChevronsRight, Minimize, Maximize, AlertCircle } from "react-feather";
import _ from "lodash";
import { fetchDbtDocsService } from "../../services/editor";

const DbtDocsModal = ({ show, handleClose, dbtUrl, activeBranch }) => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [width, setWidth] = useState(50); // Width as percentage
  const [isResizing, setIsResizing] = useState(false);
  const modalRef = useRef(null);
  const resizeHandleRef = useRef(null);

  useEffect(() => {
    if (show) {
      const fetchDbtDocs = async () => {
        setContent(await fetchDbtDocsService(dbtUrl, activeBranch));
        setLoading(false);
      };
      fetchDbtDocs();
    }
  }, [show, activeBranch]);

  // Handle mouse events for resizing
  const handleMouseDown = (e) => {
    e.preventDefault(); // Prevent text selection during drag
    e.stopPropagation(); // Stop event from propagating to other elements
    
    const startX = e.clientX;
    const startWidth = width;
    
    function onMouseMove(e) {
      // Calculate how far the mouse has moved
      const dx = startX - e.clientX;
      
      // Convert to percentage of window width
      const percentageDelta = (dx / window.innerWidth) * 100;
      
      // Update width (moving left increases width, moving right decreases width)
      const newWidth = startWidth + percentageDelta;
      
      // Clamp between 20% and 80%
      setWidth(Math.min(Math.max(newWidth, 20), 80));
    }
    
    function onMouseUp(e) {
      // Clean up event listeners
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      
      // Reset resizing state
      setIsResizing(false);
      
      // Reset handle appearance
      if (resizeHandleRef.current) {
        resizeHandleRef.current.style.backgroundColor = "transparent";
      }
    }
    
    // Set resizing state
    setIsResizing(true);
    
    // Add event listeners to document to capture all mouse events
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  // Check if content is empty or contains the "not found" message
  const isDocsNotFound = !loading && (!content || content.includes("Could not find DBT Docs"));

  return (
    <div
      ref={modalRef}
      className={`sidebar-modal-wrapper ${isFullScreen && "fullscreen"}`}
      style={{
        height: window.innerHeight - 50,
        width: isFullScreen ? "100%" : `${width}%`,
        position: "relative",
        transition: isResizing ? "none" : "width 0.1s ease",
        userSelect: isResizing ? "none" : "auto"
      }}
    >
      {/* Resizable handle with improved visibility and pointer-events handling */}
      <div 
        ref={resizeHandleRef}
        onMouseDown={handleMouseDown}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "8px",
          height: "100%",
          cursor: "ew-resize",
          zIndex: 10,
          backgroundColor: isResizing ? "rgba(67, 133, 147, 0.6)" : "transparent",
          borderRight: "1px solid rgba(67, 133, 147, 0.3)",
          // This ensures the handle captures all mouse events
          pointerEvents: "all"
        }}
        onMouseEnter={(e) => {
          if (!isResizing) {
            e.currentTarget.style.backgroundColor = "rgba(67, 133, 147, 0.3)";
          }
        }}
        onMouseLeave={(e) => {
          if (!isResizing) {
            e.currentTarget.style.backgroundColor = "transparent";
          }
        }}
      />

      {/* Content container with pointer-events handling */}
      <div style={{
        height: "100%",
        // Disable pointer events on content when resizing
        pointerEvents: isResizing ? "none" : "auto",
        // Add padding to ensure content doesn't overlap with resize handle
        paddingLeft: "10px"
      }}>
        {loading ? (
          <center className="connector-spinner" style={{ minHeight: "600px" }}>
            <br />
            <br />
            <DatajoltSpinner />
          </center>
        ) : (
          <section className="schedule-sidebar-content" style={{ height: "100%" }}>
            <header
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2>
                <ChevronsRight
                  color="#438593"
                  onClick={handleClose}
                  role="button"
                />{" "}
                DBT Docs
              </h2>

              {isFullScreen ? (
                <Minimize role="button" onClick={() => setIsFullScreen(false)} />
              ) : (
                <Maximize role="button" onClick={() => setIsFullScreen(true)} />
              )}
            </header>
            
            {isDocsNotFound ? (
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "calc(100% - 60px)",
                padding: "20px",
                textAlign: "center",
                color: "#555",
                backgroundColor: "#f9f9f9",
                borderRadius: "8px",
                margin: "20px 10px"
              }}>
                <AlertCircle 
                  size={48} 
                  color="#438593" 
                  style={{ marginBottom: "20px", opacity: 0.8 }} 
                />
                <h3 style={{ 
                  color: "#438593", 
                  marginBottom: "15px",
                  fontWeight: "500"
                }}>
                  DBT Docs Not Found
                </h3>
                <p style={{ 
                  fontSize: "16px", 
                  lineHeight: "1.6",
                  maxWidth: "500px",
                  margin: "0 auto 20px"
                }}>
                  Documentation for this project hasn't been generated yet.
                </p>
                <div style={{
                  backgroundColor: "#edf7f9",
                  padding: "15px 20px",
                  borderRadius: "6px",
                  border: "1px solid #d0e6ea",
                  fontFamily: "monospace",
                  fontSize: "14px",
                  color: "#2a5158",
                  width: "100%",
                  maxWidth: "450px",
                  textAlign: "left"
                }}>
                  $ dbt docs generate --static
                </div>
                <p style={{ 
                  fontSize: "14px", 
                  marginTop: "20px",
                  color: "#777"
                }}>
                  Run this command in the DBT CLI to generate documentation.
                </p>
                
                {/* DBT Logo */}
                <div style={{
                  marginTop: "40px",
                  opacity: 0.7
                }}>
                  <img 
                    src="https://images.seeklogo.com/logo-png/43/2/dbt-logo-png_seeklogo-431111.png" 
                    alt="dbt Logo" 
                    style={{
                      width: "120px",
                      height: "auto"
                    }}
                  />
                </div>
              </div>
            ) : (
              <div style={{ height: "calc(100% - 60px)" }}>
                <iframe 
                  srcDoc={content} 
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    display: "block"
                  }}
                />
              </div>
            )}
          </section>
        )}
      </div>
    </div>
  );
};

export default DbtDocsModal;
