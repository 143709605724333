import { Box } from "@mui/material";
import React from "react";

const Content = ({ children }) => (
  <Box
    sx={{
      paddingTop: { xs: 3, md: 1 },
      paddingBottom: { xs: 3, md: 0 },
      paddingInline: { xs: 3, md: 2 },
      flex: 1,
    }}
  >
    {children}
  </Box>
);

export default Content;
