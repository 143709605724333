/* eslint-disable */
import React, { Component } from "react";
import {
  Tab,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  InputGroup,
  Alert,
  Tooltip,
  Button,
  Card
} from "react-bootstrap";
import axios from "../../utils/axios";
import * as Icon from "react-feather";
import { Helmet } from "react-helmet-async";
import "./SchemaList.css";
import "./Editor.css";
import { config } from "../../constants";
import { loader } from "@monaco-editor/react";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";
import { ResizableBox } from "react-resizable";
import * as Reducer from "../../redux/slices/editorSlice";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import DropdownMenu from "../../components/Dropdown";
import AutocompleteInput from "../../components/Autocomplete";
import debounce from "lodash.debounce";
import {
  Stack,
  TableContainer,
  IconButton,
  tableClasses,
  tableCellClasses,
  tableRowClasses,
  tableHeadClasses,
  CircularProgress,
  Box
} from "@mui/material";
import SchemaBox from "./SchemaBox";
import DatajoltSpinner from "../../components/global/DatajoltSpinner";
import QueriesBox from "./QueriesBox";
import EditorHistoryBox from "./EditorHistoryBox";
import ScheduleModal from "../../components/ScheduleModal";
import { useAuth, useTenantsState } from "@frontegg/react";
import DbtDocsModal from "./DbtDocsModal";
import { ReactComponent as FailedIcon } from "../../assets/img/icons/failed.svg";
import { ReactComponent as SuccessIcon } from "../../assets/img/icons/success.svg";
import EditorTabs from "./EditorTabs";
import { searchForFolder } from "../../utils/searchForFolder";
import DataQualityTestsCRUD from '../../components/DataQualityTestsSidebar/DataQualityTestsCRUD';
import LeftBoxes from "./LeftBoxes";
import LineageVisulaizer from "../jobs/workflows/lineageVisulaizer";
import CustomTable from "../../components/global/Table";
import _ from "lodash";
import { cancelSQLService, createFileService, createFolderService, deleteFileService, deleteFolderService, duplicateFileService, executeDBTCommandService, fetchDbtRunsService, fetchGitRepoService, fetchRepoFromPgService, fetchRunLogsService, generateDBTDocsService, getFileHistoryService, getLineageService, openFileService, openLineageAfterSavingSQLService, runSQLService, saveAllUnsavedFilesService, saveSQLService } from "../../services/editor";
import CreateFileModal from "../../components/Modals/CreateFile";
import DuplicateFileModal from "../../components/Modals/DuplicateFile";
import UnsavedFilesModal from "../../components/Modals/UnsavedFiles";
import EditingNotAllowedModal from "../../components/Modals/EditingNotallowed";
import SwitchBranchModal from "../../components/Modals/SwitchBranch";
import DeleteFileModal from "../../components/Modals/DeleteFile";
import CloseWithoutSavingModal from "../../components/Modals/CloseWithoutSaving";
import SaveForLineageModal from "../../components/Modals/SaveForLineage";
import RunDBTProdModal from "../../components/Modals/RunDBTProd";
import TestWithoutSavingModal from "../../components/Modals/TestWithoutSaving";
import HammerWithoutSavingModal from "../../components/Modals/HammerWithoutSaving";
import OverwriteFileModal from "../../components/Modals/OverwriteFile";
import ChatBot from "../../components/chatbot/ChatBot";
import ChatBtnIcon from "../../components/chatbot/ChatBtnIcon";
import MonacoEditor from "./MonacoEditor";

Sentry.init({
  dsn: "https://53a82b56123b43348a6579efda968a77@o4504469493645312.ingest.sentry.io/4504469494497280",
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 1.0
});

const TenantContextComponent = (props) => {
  const { user } = useAuth();
  const { tenants } = useTenantsState();
  const activeTenant = tenants.find((t) => t.tenantId === user.tenantId);

  return <SQLEditor {...props} activeTenant={activeTenant} />;
};

// Redux
const mapStateToProps = (state) => {
  return {
    activeTab: state.activeTab,
    files_history: state.files_history,
    files: state.files,
    unsaved_files: state.unsaved_files,
    savedCommands: state.saved_commands,
  };
};

const mapDispatchToProps = (dispatch) => ({
  reset: () => dispatch(Reducer.reset()),
  updateActiveTab: (activeTab) => dispatch(Reducer.updateActiveTab(activeTab)),
  updateSelectedShas: (selectedShas) =>
    dispatch(Reducer.updateSelectedShas(selectedShas)),
  removeSelectedShas: (selectedShas) =>
    dispatch(Reducer.removeSelectedShas(selectedShas)),
  updateFiles: (files) => dispatch(Reducer.updateFiles(files)),
  removeFiles: (files) => dispatch(Reducer.removeFiles(files)),
  updateFilesHistory: (filesHistory) =>
    dispatch(Reducer.updateFilesHistory(filesHistory)),
  insertFilesHistory: (filesHistory) =>
    dispatch(Reducer.insertFilesHistory(filesHistory)),
  removeFilesHistory: (filesHistory) =>
    dispatch(Reducer.removeFilesHistory(filesHistory)),
  updateUnsavedFiles: (unsavedFiles) =>
    dispatch(Reducer.updateUnsavedFiles(unsavedFiles)),
  removeUnsavedFiles: (unsavedFiles) =>
    dispatch(Reducer.removeUnsavedFiles(unsavedFiles)),
  addSavedCommand: (savedCommand) =>
    dispatch(Reducer.addSavedCommand(savedCommand)),
});

let completions;

loader.config({ paths: { vs: "/vs" } });

class SQLEditor extends Component {
  constructor(props) {
    super(props);

    this.queryResultBoxRef = React.createRef(null);
    this.tabsRef = React.createRef(null);
    this.handleEditorChange = debounce(this.handleEditorCodeChange, 100);
    this.debouncedOnCursorPositionChange = debounce(
      this.onCursorPositionChange,
      100
    );
    this.debouncedOnCursorSelection = debounce(this.onCursorSelection, 100);
    this.queriesSearchRef = React.createRef(null);

    this.state = {
      showDataQualityTestsSidebar: false,
      showSaveBeforeTestsModal: false,
      showSaveBeforeHammer: false,
      existingTests: [],
      availableTests: [],
      currentModelName: '',
      progress: 40,
      loading: false,
      show_file_history: false,
      pull_request_dbt_test_status:false,
      showDbtDocsModal: false,
      git_files: [],
      logs_variant: "danger",
      modal_new_file: false,
      modal_duplicate_file: false,
      add_table_to: 0,
      dbt_command: "",
      dbt_cli_env: "Dev",
      activefolder: "Dev/",
      pull_request_dbt_logs:false,
      foldername: "Dev/models/",
      dbt_logs_test: false,
      selected_text: false,
      modal_save_file: false,
      create_file_log: false,
      running: false,
      models_subfolder: false,
      schedule_frequency: "Hourly",
      show_metadata: false,
      show_model_config: false,
      materialisation: "Table",
      changedFiles: [],
      full_refresh: false,
      model_part: "Only this model",
      showUnsavedFilesModal: false,
      showEditingNotAllowedModal: false,
      text: "00:00:00",
      schemasData: null,
      miliseconds: 0,
      seconds: 0,
      loading_git: true,
      schedule_details: false,
      deleting: false,
      loading_file_history: false,
      loading_table_details: false,
      creating: false,
      table_details_columns: [],
      table_details_results: [],
      schedule_dow: [],
      sql_results: [],
      started_query: false,
      logs: "",
      metadatatable: false,
      filename: false,
      saving: false,
      cancelling: false,
      columns: [],
      running_queries: [],
      running_dbt: false,
      dbt_status: false,
      modal_schedule: false,
      results_tab: false,
      creating_transformation: false,
      opening: false,
      skips:[],
      queryResultBoxMargin: 250,
      isSearchingQueries: false,
      queriesSearchKeywords: "",
      maxEditorSize: false,
      queriesBoxHeight: 0,
      saveForLineageModal: false,
      activeResultTab: "query",
      isShowingCLI: false,
      isScheduleModalShown: false,
      tested_command_lineage: false,
      selectedRun: null,
      loadingLogs: false,
      showBranchSwitchWarningModal: false,
      activeBranch: localStorage.getItem("selected_branch") || "main",
      showProdConfirmationModal: false,
      cli_runs: [],
      sidebarConstrains: {
        min: 0,
        max: 0,
        leftBoxes: 0,
        chatBox: 0
      },
      showPullRequestModal: false,
      testdbtCommand:"dbt build --select state:modified",
      sortedColumn: {
        column: "",
        isAsc: true,
      },
      isUpdatingBranch: false,
      editorSuggestedCode: {}
    };
    this.timer = null;
    this.chatSidebarRef = React.createRef();
    this.chatBtnRef = React.createRef();

  }

  // Define as arrow function to auto-bind
  setChangedFiles = (files) => {
    this.setState({ changedFiles: files });
  };

  createNewUntitledFile = (value = "") => {
    // Extract all existing untitled file numbers
    const untitledNumbers = Object.keys({ ...this.props.files })
      .filter((fileName) => fileName.startsWith("untitled-"))
      .map((fileName) => parseInt(fileName.replace("untitled-", ""), 10))
      .filter((num) => !isNaN(num));

    // Determine the highest untitled file number
    const maxUntitledNumber =
      untitledNumbers.length > 0 ? Math.max(...untitledNumbers) : 0;
    const newUntitledFileName = `untitled-${maxUntitledNumber + 1}`;
    this.setState({ closing_file: newUntitledFileName });
    this.props.updateActiveTab(newUntitledFileName);
    this.props.updateFiles({
      [newUntitledFileName]: value || 
      "/* Write your SQL Here! */",
    });
    
    setTimeout(() => {
      const container = this.tabsRef.current.querySelector(".editor-tab-container");
      const parentBounds = container.getBoundingClientRect();
      const children = container.children;
      const childBounds = children[children.length - 1].getBoundingClientRect();
      const isScrollable   = container.scrollWidth - container.clientWidth > 60;
      const relativeBounds = {
        top:
          childBounds.top - parentBounds.top + container.scrollTop,
        left:
          childBounds.left -
          parentBounds.left +
          container.scrollLeft,
      };
  
      if(isScrollable) {  
        container.scrollTo({
          top: relativeBounds.top,
          left: relativeBounds.left,
          behavior: "smooth",
        });
      }
    }, 0)
  };

  triggerHammer = (dbt_command) => {
    const activeTab = this.props.activeTab;
    const hasUnsavedChanges = Object.keys(this.props.unsaved_files).includes(activeTab);

    if (hasUnsavedChanges) {
      this.setState({ dbt_command: dbt_command,showSaveBeforeHammer: true });
    } else {
      this.setState({ dbt_command: dbt_command }, () => {
        this.submitDBTCommand();
      });
    }
  };

  

  toggleDataQualityTestsSidebar = () => {
    const activeTab = this.props.activeTab;
    const hasUnsavedChanges = Object.keys(this.props.unsaved_files).includes(activeTab);

    if (hasUnsavedChanges) {
      this.setState({ showSaveBeforeTestsModal: true });
    } else {
      this.setState(
        (prevState) => ({
          showDataQualityTestsSidebar: true,
          currentModelName: this.props.activeTab, // Store the current model name
        }),
        this.resetTestSelection // Reset test selection
      );  
    }
  };

  resetTestSelection = () => {
    this.setState({
      selectedColumnTest: '',
      selectedColumnTestConfig: {},
      columnTestInputValues: {},
      selectedTableTest: '',
      selectedTableTestConfig: {},
      tableTestInputValues: {},
    });
  };

  fetchDbtRuns = async () => {
    const response = await fetchDbtRunsService(this.state.dbt_url);

    if(response) {
      const runs = response.data;
      this.setState({
        cli_runs: runs,
      });
      if (runs.length > 0) {
        this.selectRun(runs[0]);
      }
    }
  };


  setdbturl = () => {
    const localUrl = "http://127.0.0.1:5000";
    const sqlEditorUrl = config.url.SQL_EDITOR_URL;

    const waitForTenant = setInterval(() => {
      if (this.props.activeTenant?.name) {
        clearInterval(waitForTenant);
        const dbtUrl =
          sqlEditorUrl === localUrl
            ? localUrl
            : `${sqlEditorUrl}/${this.props.activeTenant.name}-dbt`;
        this.setState({ dbt_url: dbtUrl });
        this.fetchDbtRuns();
      }
    }, 500); // Check every 100ms
  };

  selectRun(run) {
    if (run.status === "running") {
      this.setState({ selectedRun: run });
    } else {
      this.fetchRunLogs(run);
    }
  }

  toggleBranchSwitchWarningModal = () => {
    this.setState({
      showBranchSwitchWarningModal: !this.state.showBranchSwitchWarningModal,
    });
  };

  async fetchRunLogs(run) {
    this.setState({ loadingLogs: true, selectedRun: run });

    const response = await fetchRunLogsService(run.id, this.state.dbt_url);

    if(response) {
      this.setState({
        dbt_command_logs: response.data,
        loadingLogs: false,
      });
    } else{
      this.setState({ loadingLogs: false });
    }
  }

  toggleEditingNotAllowedModal = () => {
    this.setState({
      showEditingNotAllowedModal: !this.state.showEditingNotAllowedModal,
    });
  };

  getStatusIcon(status) {
    switch (status) {
      case "success":
        return <SuccessIcon className="run-status-icon" style={{ width: 16, height: 16 }} />;
      case "running":
        return (
          <CircularProgress size={16} color="#fff" />
        );
      case "failed":
        return <FailedIcon className="run-status-icon" style={{ width: 16, height: 16 }} />;
      default:
        return null;
    }
  }

  // SERIES OF FUNCTION USED FOR THE LIVE TIMER : TO DO : FIX THE FACT IT DOESNT RUN WHEN TAB IS NOT FOCUSED
  step = () => {
    if (!this.state.running) return;
    this.calculate();
    this.print();
  };

  print = () => {
    this.setState({ text: this.format() });
  };

  format = () => {
    return `${this.state.seconds}.${this.state.miliseconds}s`;
  };

  reset = () => {
    this.setState({
      seconds: 0,
      miliseconds: 0,
      text: "00:00:00",
    });
  };

  stop = () => {
    this.setState({ running: false });
    clearInterval(this.watch);
  };

  calculate = () => {
    this.state.miliseconds += 50;
    if (this.state.miliseconds >= 1000) {
      this.state.seconds += 1;
      this.state.miliseconds = 0;
    }
  };
  // END OF TIMER FUNCTIONS

  // EDITOR LOADING
  // AFTER LOADING EDITOR, LOADS AUTO COMPLETE

  onCursorPositionChange = (editor) => {
    var position = editor.getPosition();
    var model = editor.getModel();
    var offset = model.getOffsetAt(position);
    this.setState({ add_table_to: offset });
  };

  onCursorSelection = (editor) => {
    this.setState({
      selected_text: editor.getModel().getValueInRange(editor.getSelection()),
    });
  };

  handleEditorDidMount = (editor, monaco) => {
    editor.focus();

    editor.onDidChangeCursorPosition(() =>
      this.debouncedOnCursorPositionChange(editor)
    );
    editor.onDidChangeCursorSelection(() =>
      this.debouncedOnCursorSelection(editor)
    );

    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyR, () => {
      this.runSQL(false);
    });

    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, () => {
      this.checkFileStatus(this.props.activeTab, 0);
    });
    
    // Add Command+L shortcut to focus the AI sidebar input
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyL, () => {
      // Find the AI sidebar input element and focus it
      const aiInputElement = document.querySelector('.message-input-form .datajolt-input');
      if (aiInputElement) {
        aiInputElement.focus();
      }
    });

    // // TO DO: API CALL TO MAKE THIS BETTER
    if (!completions) {
      completions = monaco.languages.registerCompletionItemProvider("sql", {
        triggerCharacters: [' ','.'],
        provideCompletionItems: () => {
          var suggestions = [
            { label: "SELECT * FROM", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "SELECT * FROM" },
            { label: "SELECT", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "SELECT" },
            { label: "FROM", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "FROM" },
            { label: "JOIN", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "JOIN" },
            { label: "LEFT JOIN", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "LEFT JOIN" },
            { label: "RIGHT JOIN", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "RIGHT JOIN" },
            { label: "FULL JOIN", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "FULL JOIN" },
            { label: "WHERE", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "WHERE" },
            { label: "GROUP BY", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "GROUP BY" },
            { label: "ON", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "ON" },
            { label: "WITH", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "WITH" },
            { label: "CREATE", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "CREATE" },
            { label: "TABLE", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "TABLE" },
            { label: "DROP", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "DROP" },
            { label: "ADD", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "ADD" },
            { label: "INSERT", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "INSERT" },
            { label: "DELETE", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "DELETE" },
            { label: "ALTER", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "ALTER" },
            { label: "SCHEMA", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "SCHEMA" },
            { label: "ORDER BY", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "ORDER BY" },
            { label: "DESC", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "DESC" },
            { label: "ASC", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "ASC" },
            { label: "HAVING", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "HAVING" },
            { label: "COUNT", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "COUNT" },
            { label: "CASE", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "CASE" },
            { label: "CASE WHEN", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "CASE WHEN" },
            { label: "THEN", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "THEN" },
            { label: "ELSE", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "ELSE" },
            { label: "END", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "END" },
            { label: "DISTINCT", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "DISTINCT" },
            { label: "SELECT DISTINCT", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "SELECT DISTINCT" },
            { label: "TRUNCATE", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "TRUNCATE" },
            { label: "UNION", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "UNION" },
            { label: "UNION ALL", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "UNION ALL" },
            { label: "IS NULL", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "IS NULL" },
            { label: "IS NOT NULL", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "IS NOT NULL" },
            { label: "IN", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "IN" },
            { label: "BETWEEN", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "BETWEEN" },
            { label: "LIKE", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "LIKE" },
            { label: "NOT LIKE", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "NOT LIKE" },
            { label: "AND", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "AND" },
            { label: "OR", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "OR" },
            { label: "NOT", kind: monaco.languages.CompletionItemKind.Keyword, insertText: "NOT" },
            { label: "SUM", kind: monaco.languages.CompletionItemKind.Function, insertText: "SUM" },
            { label: "AVG", kind: monaco.languages.CompletionItemKind.Function, insertText: "AVG" },
            { label: "MIN", kind: monaco.languages.CompletionItemKind.Function, insertText: "MIN" },
            { label: "MAX", kind: monaco.languages.CompletionItemKind.Function, insertText: "MAX" },
            { label: "COALESCE", kind: monaco.languages.CompletionItemKind.Function, insertText: "COALESCE" },
            { label: "IFNULL", kind: monaco.languages.CompletionItemKind.Function, insertText: "IFNULL" },
            { label: "NULLIF", kind: monaco.languages.CompletionItemKind.Function, insertText: "NULLIF" },
            { label: "DATEADD", kind: monaco.languages.CompletionItemKind.Function, insertText: "DATEADD" },
            { label: "DATEDIFF", kind: monaco.languages.CompletionItemKind.Function, insertText: "DATEDIFF" },
            { label: "CAST", kind: monaco.languages.CompletionItemKind.Function, insertText: "CAST" },
            { label: "CONVERT", kind: monaco.languages.CompletionItemKind.Function, insertText: "CONVERT" },
            { label: "ROUND", kind: monaco.languages.CompletionItemKind.Function, insertText: "ROUND" },
            { label: "ABS", kind: monaco.languages.CompletionItemKind.Function, insertText: "ABS" },
            { label: "FLOOR", kind: monaco.languages.CompletionItemKind.Function, insertText: "FLOOR" },
            { label: "CEIL", kind: monaco.languages.CompletionItemKind.Function, insertText: "CEIL" },
            { label: "SUBSTRING", kind: monaco.languages.CompletionItemKind.Function, insertText: "SUBSTRING" },
            { label: "CONCAT", kind: monaco.languages.CompletionItemKind.Function, insertText: "CONCAT" },
            { label: "UPPER", kind: monaco.languages.CompletionItemKind.Function, insertText: "UPPER" },
            { label: "LOWER", kind: monaco.languages.CompletionItemKind.Function, insertText: "LOWER" },
            { label: "TRIM", kind: monaco.languages.CompletionItemKind.Function, insertText: "TRIM" },
            { label: "LTRIM", kind: monaco.languages.CompletionItemKind.Function, insertText: "LTRIM" },
            { label: "RTRIM", kind: monaco.languages.CompletionItemKind.Function, insertText: "RTRIM" },
            { label: "REPLACE", kind: monaco.languages.CompletionItemKind.Function, insertText: "REPLACE" },
            { label: "SPLIT", kind: monaco.languages.CompletionItemKind.Function, insertText: "SPLIT" },
            { label: "ARRAY_AGG", kind: monaco.languages.CompletionItemKind.Function, insertText: "ARRAY_AGG" },
            { label: "LISTAGG", kind: monaco.languages.CompletionItemKind.Function, insertText: "LISTAGG" },
            { label: "FIRST_VALUE", kind: monaco.languages.CompletionItemKind.Function, insertText: "FIRST_VALUE" },
            { label: "LAST_VALUE", kind: monaco.languages.CompletionItemKind.Function, insertText: "LAST_VALUE" },
            { label: "LEAD", kind: monaco.languages.CompletionItemKind.Function, insertText: "LEAD" },
            { label: "LAG", kind: monaco.languages.CompletionItemKind.Function, insertText: "LAG" },
            { label: "RANK", kind: monaco.languages.CompletionItemKind.Function, insertText: "RANK" },
            { label: "DENSE_RANK", kind: monaco.languages.CompletionItemKind.Function, insertText: "DENSE_RANK" },
            { label: "ROW_NUMBER", kind: monaco.languages.CompletionItemKind.Function, insertText: "ROW_NUMBER" },
            { label: "NTILE", kind: monaco.languages.CompletionItemKind.Function, insertText: "NTILE" },
            { label: "PERCENT_RANK", kind: monaco.languages.CompletionItemKind.Function, insertText: "PERCENT_RANK" },
            { label: "STDDEV", kind: monaco.languages.CompletionItemKind.Function, insertText: "STDDEV" },
            { label: "VARIANCE", kind: monaco.languages.CompletionItemKind.Function, insertText: "VARIANCE" },
            { label: "MEDIAN", kind: monaco.languages.CompletionItemKind.Function, insertText: "MEDIAN" },
            { label: "MODE", kind: monaco.languages.CompletionItemKind.Function, insertText: "MODE" },
            { label: "CORR", kind: monaco.languages.CompletionItemKind.Function, insertText: "CORR" },
            { label: "COVAR_POP", kind: monaco.languages.CompletionItemKind.Function, insertText: "COVAR_POP" },
            { label: "COVAR_SAMP", kind: monaco.languages.CompletionItemKind.Function, insertText: "COVAR_SAMP" },
            { label: "IFF", kind: monaco.languages.CompletionItemKind.Function, insertText: "IFF" },
            { label: "GREATEST", kind: monaco.languages.CompletionItemKind.Function, insertText: "GREATEST" },
            { label: "LEAST", kind: monaco.languages.CompletionItemKind.Function, insertText: "LEAST" },
            { label: "DECODE", kind: monaco.languages.CompletionItemKind.Function, insertText: "DECODE" },
            { label: "ASCII", kind: monaco.languages.CompletionItemKind.Function, insertText: "ASCII" },
            { label: "CHAR", kind: monaco.languages.CompletionItemKind.Function, insertText: "CHAR" },
            { label: "PARSE_JSON", kind: monaco.languages.CompletionItemKind.Function, insertText: "PARSE_JSON" },
            { label: "FLATTEN", kind: monaco.languages.CompletionItemKind.Function, insertText: "FLATTEN" },
            { label: "HASH", kind: monaco.languages.CompletionItemKind.Function, insertText: "HASH" },
            { label: "MD5", kind: monaco.languages.CompletionItemKind.Function, insertText: "MD5" },
            { label: "SHA1", kind: monaco.languages.CompletionItemKind.Function, insertText: "SHA1" },
            { label: "SHA2", kind: monaco.languages.CompletionItemKind.Function, insertText: "SHA2" },
            { label: "TRY_CAST", kind: monaco.languages.CompletionItemKind.Function, insertText: "TRY_CAST" },
            { label: "REGEXP_COUNT", kind: monaco.languages.CompletionItemKind.Function, insertText: "REGEXP_COUNT" },
            { label: "REGEXP_INSTR", kind: monaco.languages.CompletionItemKind.Function, insertText: "REGEXP_INSTR" },
            { label: "REGEXP_SUBSTR", kind: monaco.languages.CompletionItemKind.Function, insertText: "REGEXP_SUBSTR" },
            { label: "REGEXP_LIKE", kind: monaco.languages.CompletionItemKind.Function, insertText: "REGEXP_LIKE" },
            { label: "REGEXP_REPLACE", kind: monaco.languages.CompletionItemKind.Function, insertText: "REGEXP_REPLACE" },
            { label: "STRTOK_TO_ARRAY", kind: monaco.languages.CompletionItemKind.Function, insertText: "STRTOK_TO_ARRAY" },
            { label: "XMLGET", kind: monaco.languages.CompletionItemKind.Function, insertText: "XMLGET" },
            { label: "ARRAY_CONSTRUCT", kind: monaco.languages.CompletionItemKind.Function, insertText: "ARRAY_CONSTRUCT" },
            { label: "ARRAY_APPEND", kind: monaco.languages.CompletionItemKind.Function, insertText: "ARRAY_APPEND" },
            { label: "ARRAY_PREPEND", kind: monaco.languages.CompletionItemKind.Function, insertText: "ARRAY_PREPEND" },
            { label: "ARRAY_SIZE", kind: monaco.languages.CompletionItemKind.Function, insertText: "ARRAY_SIZE" },
            { label: "ARRAY_TO_STRING", kind: monaco.languages.CompletionItemKind.Function, insertText: "ARRAY_TO_STRING" },
            { label: "OBJECT_CONSTRUCT", kind: monaco.languages.CompletionItemKind.Function, insertText: "OBJECT_CONSTRUCT" },
            { label: "OBJECT_INSERT", kind: monaco.languages.CompletionItemKind.Function, insertText: "OBJECT_INSERT" },
            { label: "OBJECT_DELETE", kind: monaco.languages.CompletionItemKind.Function, insertText: "OBJECT_DELETE" },
            { label: "OBJECT_KEYS", kind: monaco.languages.CompletionItemKind.Function, insertText: "OBJECT_KEYS" },
            { label: "PI", kind: monaco.languages.CompletionItemKind.Function, insertText: "PI" },
            { label: "DEGREES", kind: monaco.languages.CompletionItemKind.Function, insertText: "DEGREES" },
            { label: "LN", kind: monaco.languages.CompletionItemKind.Function, insertText: "LN" },
            { label: "EXP", kind: monaco.languages.CompletionItemKind.Function, insertText: "EXP" },
            { label: "MOD", kind: monaco.languages.CompletionItemKind.Function, insertText: "MOD" },
            { label: "SQRT", kind: monaco.languages.CompletionItemKind.Function, insertText: "SQRT" },
            { label: "RANDOM", kind: monaco.languages.CompletionItemKind.Function, insertText: "RANDOM" },
            { label: "TO_ARRAY", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_ARRAY" },
            { label: "TO_BINARY", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_BINARY" },
            { label: "TO_BOOLEAN", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_BOOLEAN" },
            { label: "TO_CHAR", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_CHAR" },
            { label: "TO_VARCHAR", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_VARCHAR" },
            { label: "TO_DATE", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_DATE" },
            { label: "TO_DECIMAL", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_DECIMAL" },
            { label: "TO_NUMBER", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_NUMBER" },
            { label: "TO_NUMERIC", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_NUMERIC" },
            { label: "TO_DOUBLE", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_DOUBLE" },
            { label: "TO_GEOGRAPHY", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_GEOGRAPHY" },
            { label: "TO_GEOMETRY", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_GEOMETRY" },
            { label: "TO_JSON", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_JSON" },
            { label: "TO_OBJECT", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_OBJECT" },
            { label: "TO_QUERY", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_QUERY" },
            { label: "TO_TIME", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_TIME" },
            { label: "TO_TIMESTAMP", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_TIMESTAMP" },
            { label: "TO_TIMESTAMP_NTZ", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_TIMESTAMP_NTZ" },
            { label: "TO_TIMESTAMP_LTZ", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_TIMESTAMP_LTZ" },
            { label: "TO_TIMESTAMP_TZ", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_TIMESTAMP_TZ" },
            { label: "TO_VARIANT", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_VARIANT" },
            { label: "TO_XML", kind: monaco.languages.CompletionItemKind.Function, insertText: "TO_XML" },
            { label: "DIV0", kind: monaco.languages.CompletionItemKind.Function, insertText: "DIV0" },
          ];
          
          try {

            var model = editor.getModel();
            if (!model) {
              console.warn('Editor model is not available');
              return { suggestions };
            }
          
            if(this.state.autocomplete?.dwh_structure) {
              // Get text before cursor
              var position = editor.getPosition();
              const textBeforeCursor = model.getValueInRange({
                startLineNumber: 1,
                startColumn: 1,
                endLineNumber: position.lineNumber,
                endColumn: position.column
              });
              const keywordRegex = /\b(select|from|join|on|where|group by)\b|;/gi;
              let lastKeyword = { word: null, index: -1 };
              let match;            
              keywordRegex.lastIndex = 0;            
              while ((match = keywordRegex.exec(textBeforeCursor)) !== null) {
                lastKeyword = { word: match[0].toLowerCase(), index: match.index };
              }

              if(lastKeyword.word == "from" || lastKeyword.word == "join") {
                const textAfterKeyword = textBeforeCursor.slice(lastKeyword.index + lastKeyword.word.length).replace(/\n/g, ' ');
                if (/\w+\.\w+\s/.test(textAfterKeyword) || textAfterKeyword.endsWith('}} ')) {
                  return { suggestions };
                }
                const schemaMatch = textAfterKeyword.trim().match(/^(\w+)\./);
                if (schemaMatch) {
                  const schema = schemaMatch[1];
                  if (this.state.autocomplete?.dwh_structure?.[schema]) {
                    suggestions = []
                    const tables = Object.keys(this.state.autocomplete.dwh_structure[schema]);
                    suggestions = tables.map(table => ({ label: table + ' (table)', kind: monaco.languages.CompletionItemKind.Keyword, insertText: table }));
                  }
                } 
                else {
                  suggestions = []
                  suggestions = this.state.schemasData.map(schema => ({ label: schema + ' (schema)', kind: monaco.languages.CompletionItemKind.Keyword, insertText: schema }));
                  suggestions.push(
                    { label: "{{ ref('') }}", kind: monaco.languages.CompletionItemKind.Snippet, insertText: "{{ ref('') }}" },
                    { label: "{{ source('', '') }}", kind: monaco.languages.CompletionItemKind.Snippet, insertText: "{{ source('', '') }}" }
                  );
                }
              }
              else if (lastKeyword.word === "on" || lastKeyword.word === "where" || lastKeyword.word === "group by") {
                console.log("ON/WHERE/GROUP BY autocomplete: Columns + Functions");
                const tableRegex = /(?:FROM|JOIN)\s+((?:\w+\.)?[\w_]+)(?:\s+(?:AS\s+)?(?!WHERE|JOIN|ON|GROUP|ORDER|LIMIT)(\w+))?(?=\s+(?:JOIN|WHERE|GROUP|ORDER|LIMIT|ON|$)|\s*$)/gi;

                let match;
                const tables = [];
                // Find all tables
                while ((match = tableRegex.exec(textBeforeCursor)) !== null) {
                  const [, fullTableName, alias] = match;
                  const [schema, table] = fullTableName.split('.');
                  // Use the table name as alias if no alias is provided
                  const safeAlias = alias || table;
                  tables.push({ 
                    schema, 
                    table,
                    alias: safeAlias.toLowerCase()
                  });
                }
                console.log("Tables found:", tables);

                // Check if we're after a dot without a space
                const dotMatch = textBeforeCursor.match(/(\w+)\.\S*$/);
                if (dotMatch) {
                  console.log("Suggesting columns for specific table/alias");
                  const lastWord = dotMatch[1];
                  console.log("Last word before dot:", lastWord);
                  
                  let tableToSuggest = tables.find(t => (t.alias && t.alias.toLowerCase() === lastWord.toLowerCase()) || (t.table && t.table.toLowerCase() === lastWord.toLowerCase()));                
                  if (tableToSuggest) {
                    console.log("Found table to suggest:", tableToSuggest);
                    suggestions = []
                    if (this.state.autocomplete?.dwh_structure?.[tableToSuggest.schema]?.[tableToSuggest.table]) {
                      const columns = this.state.autocomplete.dwh_structure[tableToSuggest.schema][tableToSuggest.table];
                      suggestions = columns.map(column => {
                        const columnName = column.split(' ')[0]; // Extract column name without type
                        return {
                          label: `${columnName} (column)`,
                          kind: monaco.languages.CompletionItemKind.Field,
                          insertText: columnName,
                          sortText: "001" // This will appear first
                        };
                      });
                    }
                  } else {
                    console.log("No matching table found for", lastWord);
                  }
                } else {
                  console.log("Suggesting columns for all tables");
                  tables.forEach(({ schema, table, alias }) => {
                    if (this.state.autocomplete?.dwh_structure?.[schema]?.[table]) {
                      const columns = this.state.autocomplete.dwh_structure[schema][table];
                      columns.forEach(column => {
                        const columnName = column.split(' ')[0]; // Extract column name without type
                        suggestions = [
                          { 
                            label: `${alias}.${columnName} (column)`, 
                            kind: monaco.languages.CompletionItemKind.Field, 
                            insertText: `${alias}.${columnName}`,
                            sortText: "001"  // This will appear first
                          },
                          ...suggestions
                        ];
                      });
                    }
                  }); 
                }
              }
              else if (lastKeyword.word == "select") {
                console.log("Column autocomplete: Columns + Functions");
                let endLineNumber = position.lineNumber;
                console.log("End line number before:", endLineNumber);
                let overwrittenEndLineNumber = false;
                if (endLineNumber < model.getLineCount()) {
                  for (let i = position.lineNumber + 1; i <= model.getLineCount(); i++) {
                    const lineContent = model.getLineContent(i);
                    if (lineContent.toUpperCase().includes('SELECT') || lineContent.includes(';')) {
                      endLineNumber = i - 1;
                      overwrittenEndLineNumber = true;
                      break;
                    }
                  }
                }
                if (!overwrittenEndLineNumber) {
                  endLineNumber = model.getLineCount();
                }
                console.log("End line number after:", endLineNumber);
                const value = model.getValueInRange({
                  startLineNumber: position.lineNumber,
                  startColumn: 1,
                  endLineNumber: endLineNumber,
                  endColumn: model.getLineMaxColumn(endLineNumber)
                });

                // Updated regex to capture all table references with schemas, making the trailing space optional
                const tableRegex = /(?:FROM|JOIN)\s+((?:\w+\.)?[\w_]+)(?:\s+(?:AS\s+)?(\w+))?(?=\s+(?:JOIN|WHERE|GROUP|ORDER|LIMIT|ON|$)|\s*$)/gi;
                let match;
                const tables = [];
                console.log("Identifying tables in:", value);
                // Find all tables
                while ((match = tableRegex.exec(value)) !== null) {
                  const [, fullTableName, alias] = match;
                  const [schema, table] = fullTableName.split('.');
                  // Use the table name as alias if no alias is provided
                  const safeAlias = alias || table;
                  tables.push({ 
                    schema, 
                    table,
                    alias: safeAlias.toLowerCase()
                  });
                }

                console.log("Tables found:", tables);

                // If tables are found, fetch columns for each
                if (tables.length > 0) {
                  const dotMatch = textBeforeCursor.match(/(\w+)\.\S*$/);
                  if (dotMatch) {
                    console.log("Suggesting columns for specific table/alias");
                    const lastWord = dotMatch[1];
                    console.log("Last word before dot:", lastWord);
                    
                    let tableToSuggest = tables.find(t => (t.alias && t.alias.toLowerCase() === lastWord.toLowerCase()) || (t.table && t.table.toLowerCase() === lastWord.toLowerCase()));
                    if (tableToSuggest) {
                      console.log("Found table to suggest:", tableToSuggest);
                      suggestions = []
                      if (this.state.autocomplete?.dwh_structure?.[tableToSuggest.schema]?.[tableToSuggest.table]) {
                        const columns = this.state.autocomplete.dwh_structure[tableToSuggest.schema][tableToSuggest.table];
                        suggestions = columns.map(column => {
                          const columnName = column.split(' ')[0]; // Extract column name without type
                          return {
                            label: `${columnName} (column)`,
                            kind: monaco.languages.CompletionItemKind.Field,
                            insertText: columnName,
                            sortText: "001" // This will appear first
                          };
                        });
                      }
                    } else {
                      console.log("No matching table found for", lastWord);
                    }
                  } else {
                  let allColumnsInsertText = '';
                  tables.forEach(({ schema, table, alias }) => {
                    if (this.state.autocomplete?.dwh_structure?.[schema]?.[table]) {
                      const columns = this.state.autocomplete.dwh_structure[schema][table];
                      
                      // Add to the all columns insert text
                      allColumnsInsertText += columns.map(column => `${alias}.${column.split(' ')[0]}`).join(',\n') + ',\n';

                      // Add individual column suggestions
                      columns.forEach(column => {
                        const columnName = column.split(' ')[0]; // Extract column name without type
                        suggestions.push({ 
                          label: `${alias}.${columnName} (column)`, 
                          kind: monaco.languages.CompletionItemKind.Field, 
                          insertText: `${alias}.${columnName}`,
                          sortText: "001"  // This will appear first 
                        });
                      });
                    }
                  });
                  // Add a single suggestion for all columns from all tables
                  if (allColumnsInsertText) {
                    suggestions.unshift({ 
                      label: `|| ALL COLUMNS ||`, 
                      kind: monaco.languages.CompletionItemKind.Snippet, 
                      insertText: allColumnsInsertText.replace(/,\n$/, '\n'),
                      sortText: "001"  // This will appear first
                    });
                  }
                  }
                }
              }
            }
          }
          catch (error) {
            console.log("Error in autocomplete:", error);
            return { suggestions };
          }
          return { suggestions };
        },
      });
    }
  };

  handleResizing = (leftBoxesWidth, rightBoxesWidth) => {
    if (window.innerWidth >= 768) {
      this.setState({
        sidebarConstrains: {
          max: window.innerWidth - 300,
          min: Math.max(window.innerWidth / 6 - 20, 160),
          leftBoxes:
            typeof leftBoxesWidth === "number"
              ? leftBoxesWidth
              : window.innerWidth / 6 - 20,
          chatBox:
            typeof rightBoxesWidth === "number"
              ? rightBoxesWidth
              : window.innerWidth / 6 - 20,
            },
          });
    } else {
      this.setState({
        sidebarConstrains: {
          min: window.innerWidth - 300,
          max: window.innerWidth - 300,
          leftBoxes:
            typeof leftBoxesWidth === "number"
              ? leftBoxesWidth
              : window.innerWidth - 300,
          chatBox:
            typeof rightBoxesWidth === "number"
              ? rightBoxesWidth
              : window.innerWidth - 300,
        },
      });
    }
  };

  // WHEN PAGE IS FIRST RENDERED, TRIGGER FECTHING FILES FROM GIT
  componentDidMount() {
    // Add the beforeunload event listener when this component mounts
    this.beforeUnloadListener = (e) => {
      const message = 'Are you sure you want to leave?';
      e.preventDefault();
      e.returnValue = message; // This is needed for Chrome
      return message;
    };
    
    window.addEventListener('beforeunload', this.beforeUnloadListener);

    const items = document.getElementsByClassName("content");
    for (const item of items) {
      item.style.padding = "0";
    }
    let height = document.documentElement.clientHeight;
    const queryParams = new URLSearchParams(window.location.search);
    const filePath = queryParams.get("file");
    const lastQueryData = localStorage.getItem("last_successful_query");
    this.setState({ editor_height: height - 400, progress: 70 });

    if (lastQueryData) {
      this.setState(JSON.parse(lastQueryData));
    }

    if (filePath) {
      setTimeout(() => {
        this.openFile("main", filePath, "latest");
      }, 50);
    }

    // Fetch Git repo only once on component mount
    this.setState({ progress: 100 });
    this.setdbturl();

    const chatWidth = localStorage.getItem("chat-width");
    const editorWidth = localStorage.getItem("editor-width");

    this.handleResizing(+editorWidth || undefined, chatWidth === null ? undefined : +chatWidth);
    window.addEventListener("resize", this.handleResizing);

    // Add simple event listener to prevent browser navigation on table edges
    document.addEventListener('wheel', this.handleWheelNavigation, { passive: false });
  }

  componentWillUnmount() {
    const items = document.getElementsByClassName("content");
    for (const item of items) {
      item.style.padding = "0.5rem 1rem 0.1rem";
    }

    window.removeEventListener("resize", this.handleResizing);
    window.removeEventListener('beforeunload', this.beforeUnloadListener);

    // Remove the event listener
    document.removeEventListener('wheel', this.handleWheelNavigation);
  }

  handleWheelNavigation = (e) => {
    // If this is a horizontal scroll event (more X than Y movement)
    if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
      // Find the closest scrollable parent element
      let scrollableParent = e.target;
      let foundScrollable = false;
      
      while (scrollableParent && scrollableParent !== document.body) {
        const style = window.getComputedStyle(scrollableParent);
        const overflowX = style.getPropertyValue('overflow-x');
        const overflow = style.getPropertyValue('overflow');
        
        // If this element can scroll horizontally
        if (overflowX === 'auto' || overflowX === 'scroll' || 
            overflow === 'auto' || overflow === 'scroll') {
          // Only if element is actually scrollable (has content wider than container)
          if (scrollableParent.scrollWidth > scrollableParent.clientWidth) {
            foundScrollable = true;
            const atLeftEdge = scrollableParent.scrollLeft <= 0 && e.deltaX < 0;
            const atRightEdge = scrollableParent.scrollLeft + scrollableParent.clientWidth >= 
                               scrollableParent.scrollWidth - 1 && e.deltaX > 0;
            
            // Prevent browser navigation when at the edges
            if (atLeftEdge || atRightEdge) {
              e.preventDefault();
              return;
            }
            break;
          }
        }
        scrollableParent = scrollableParent.parentNode;
      }
      
      // If we couldn't find any scrollable parent with content,
      // prevent the browser navigation completely
      if (!foundScrollable) {
        e.preventDefault();
      }
    }
  }

  setSchemasData = (data) => this.setState({ schemasData: data });

  scrollSelectedFile = (parentRef, id) => {
    if (parentRef && parentRef.current) {
      const container = parentRef.current.querySelector(".editor-tab-container");
      const parentBounds = container.getBoundingClientRect();
      const children = container.children;
      const isScrollable   = container.scrollWidth - container.clientWidth > 60;

      if(isScrollable) {
        for (let i = 0; i < children.length; i++) {
          if (children[i].id === id) {
            const childBounds = children[i].getBoundingClientRect();
            const relativeBounds = {
              top:
                childBounds.top - parentBounds.top + container.scrollTop,
              left:
                childBounds.left -
                parentBounds.left +
                container.scrollLeft,
            };
            
            container.scrollTo({
              top: relativeBounds.top,
              left: relativeBounds.left - 60,
              behavior: "smooth",
            });
            break;
          }
        }
      }
    }
  };

  submitDBTCommand = () => {
    if (this.state.dbt_cli_env === "Prod") {
      this.setState({ showProdConfirmationModal: true });
    } else {
      this.executeDBTCommand();
    }
  };

  executeDBTCommand = async () => {
    const DBT_Command = {
      dbt_command: this.state.dbt_command,
      dbt_branch: this.state.activeBranch,
      dbt_cli_env: this.state.dbt_cli_env,
    };

    if (this.state.dbt_command === "") {
      toast.error("Please provide a DBT command.");
      return;
    }

    this.props.addSavedCommand({ command: this.state.dbt_command });
    const maxId = Math.max(0, ...this.state.cli_runs.map((run) => run.id || 0));
    const newRun = {
      id: maxId + 1,
      dbt_command: this.state.dbt_command,
      status: "running",
    };
    this.setState(() => ({
      dbt_command_status: false,
      logs: false,
      running_dbt_command: true,
      dbt_command_logs: `Running ${DBT_Command["dbt_command"]} ...`,
      activeResultTab: "dbt",
      cli_runs: [newRun, ...(this.state.cli_runs || [])],
      selectedRun: newRun, // Highlight the new run immediately
    }));

    const handleRunUpdate = (api_logs, status) => {
      const updatedRun = { ...newRun, status };
      this.setState((prevState) => ({
        running_dbt_command: false,
        dbt_command_logs: api_logs,
        dbt_command_status: status,
        cli_runs: prevState.cli_runs.map((run) =>
          run.id === newRun.id ? updatedRun : run
        ),
        selectedRun: updatedRun,
      }));
    };

    const isSuccess = await executeDBTCommandService(
      this.state.dbt_url, 
      DBT_Command,
      (dbt_command_logs) => this.setState({ dbt_command_logs }),
      handleRunUpdate
    )

    if(!isSuccess) {
      handleRunUpdate(this.state.dbt_command_logs, "failed");
    }
  };

  addToFileHistory = (value) => {
    // ADD STEP TO FILE HISTORY IF IT IS NOT THERE
    if (
      this.props.files_history[this.props.activeTab] &&
      this.props.files_history[this.props.activeTab].length > 0 &&
      this.props.files_history[this.props.activeTab][0]["date"] !== "RIGHT NOW"
    ) {
      this.props.updateUnsavedFiles({ [this.props.activeTab]: value.trim() });
      this.props.insertFilesHistory({
        [this.props.activeTab]: {
          date: "RIGHT NOW",
          author: "Unsaved Changes",
          sha: "unsaved",
        },
      });
      this.props.updateSelectedShas({ [this.props.activeTab]: "unsaved" });
    } else {
      this.props.updateUnsavedFiles({ [this.props.activeTab]: value.trim() });
    }
  };

  handleEditorCodeChange = (value) => {
    if (this.state.activeBranch === "main") {
      this.toggleEditingNotAllowedModal();
      return;
    }

    if (!this.state.opening && !this.state.deleting) {
      const activeTab = this.props.activeTab;

      if (activeTab === "" || activeTab === "+" || activeTab === undefined) {
        this.setState(
          {
            untitledFileContent: value,
            untitledFileOpen: true,
          },
          () => {
            this.createNewUntitledFile(value);
          }
        );
      } else if(!this.state.isUpdatingBranch && this.props.files[this.props.activeTab] !== value) {
        this.addToFileHistory(value);
        this.props.updateFiles({ [activeTab]: value });
      } else {
        this.setState({ isUpdatingBranch: false });
      }
    }
  };

  setAutocomplete = (data) => this.setState({ autocomplete: data });

  handleSelectChange = (e, state_variable) => {
    this.setState({
      [state_variable]: e.value.trim(),
    });
  };

  toggleSaveForLineageModal = () =>
    this.setState({ saveForLineageModal: !this.state.saveForLineageModal });

  get_lineage = async (skipUnsavedModal = false, file_level = true) => {
    if (
      !skipUnsavedModal &&
      Object.keys(this.props.unsaved_files).includes(this.props.activeTab)
    ) {
      this.toggleSaveForLineageModal();
      return;
    }
    var DBT_Command;
    if (file_level) {
      this.setState({ show_lineage_modal: true, getting_file_lineage: true });
      DBT_Command = {
        model: this.props.activeTab,
        dbt_branch: this.state.activeBranch,
      };
    } else {
      this.setState({
        show_lineage_modal: true,
        getting_command_lineage: true,
      });
      DBT_Command = {
        model: "test",
        dbt_branch: this.state.activeBranch,
      };
    }

    const response = await getLineageService(this.state.dbt_url, DBT_Command);

    if(response) {
      this.setState({ lineage: response.data });
    } else {
      this.setState({ show_lineage_modal: false, lineage: [] });
    }

    this.setState({
      getting_file_lineage: false,
      getting_command_lineage: false,
    });
  };

  fetchRepoFromPg = async (branch) => {
    this.setState({ loading_git: true });

    const response = await fetchRepoFromPgService(branch);
    
    if(response)
      this.setState({ git_files: response.data, loading_git: false });
    else
      this.setState({ git_files: false, loading_git: false });
  };

  fetchGitRepo = async () => {
    this.setState({ loading_git: true, logs: false });

    const response = await fetchGitRepoService(this.state.activeBranch);

    if(response) {
      this.setState({ git_files: response.data });
      if (!this.state.generate_dbt_docs) {
        this.generate_dbt_docs();
      }
    } else {
      this.setState({ git_files: false });
    }
    
    this.setState({ loading_git: false });
  };
  // SQL FUNCTIONS
  // TRIGGERS WHEN USERS CLICKS CREATE A NEW FILE, OR SAVE - TO KNOW WHETHER MODAL NEEDS TO BE SHOWN TO ASK FOR FILENAME
  checkFileStatus = (file, closing) => {
    if (file === "+") {
      this.toggleNewFileModal();
    } else if (file.startsWith("untitled-")) {
      this.toggleNewFileModal();
    } else if (this.state.activeBranch == "main") {
      this.toggleOverwriteInProdModal();
    } else {
      this.saveSQL(closing, file, 0);
    }
  };

  saveSQL = async (
    close,
    file,
    sharing,
    fileContent = null,
    branch = this.state.activeBranch,
    trigger_hammer = null
  ) => {
    this.setState({ saving: true, modal_save_file: false });

    await new Promise(resolve => setTimeout(resolve, 300));

    const isSuccess = await saveSQLService(file, branch, fileContent || this.props.files[file]);
    console.log('Save SQL: ', isSuccess)
    if(isSuccess) {
      // Update git_files.changes
      const newGitFiles = { ...this.state.git_files };
      if (!newGitFiles.changes) {
        newGitFiles.changes = [];
      }
      if (!newGitFiles.changes.some(f => f.path === file)) {
        newGitFiles.changes.push({ path: file, status: 'modified' });
        this.setState({ git_files: newGitFiles });
      }

      if (this.state.modal_overwrite_file) {
        this.setState({ modal_overwrite_file: false });
      }
      this.props.removeUnsavedFiles(file);
      this.generate_dbt_docs();
      if (!close) {
        await this.getFileHistory(file);
      }
      if (this.state.showDataQualityTestsSidebar) {
        this.setState(prevState => ({
          dataQualityTestsReloadTrigger: prevState.dataQualityTestsReloadTrigger + 1
        }));
      }
    }

    this.setState({ saving: false });
    if (trigger_hammer) {
      this.setState({ showSaveBeforeHammer:false});
      this.submitDBTCommand();
    }
    if (close === 1) {
      this.closeFile(this.state.closing_file);
      this.setState({ modal_save_file: false });
    }
  };

  OpenLineageAfterSavingSQL = (close, file = this.props.activeTab) => {
    this.setState({ saving: true, logs: false });
    setTimeout(async () => {
      const error = await openLineageAfterSavingSQLService(file, branch, this.props.files[file]);

      if(!error) {
        if (this.state.modal_overwrite_file) {
          this.setState({ modal_overwrite_file: false });
        }
        this.props.removeUnsavedFiles(file);
        if (!close) {
          this.getFileHistory(file);
        }
      } else {
        this.setState({
          logs_variant: "danger",
          logs: error.response.data["error"],
        });
      }

      this.setState({ saving: false, saveForLineageModal: false });
      this.get_lineage();
    }, 300);
  };

  runSQL = async (gptGenerated = false) => {
    this.reset();
    this.setState({
      activeResultTab: "query",
      running: true,
      results_tab: this.props.activeTab,
    });
    this.watch = setInterval(() => this.step(), 50);
    let file = this.props.activeTab;
    var running_queries = this.state.running_queries;
    running_queries[this.props.activeTab] =
      this.props.files[this.props.activeTab];
    const start = performance.now();
    const today = new Date();
    const start_time = `${String(today.getHours()).padStart(2, "0")}:${String(
      today.getMinutes()
    ).padStart(2, "0")}:${String(today.getSeconds()).padStart(2, "0")}`;
    this.setState({
      cancelling: false,
      query_time: false,
      started_query: start_time,
      logs: false,
      loading: true,
      running_queries: running_queries,
      sql_results: [],
      columns: [],
    });
    if (this.state.selected_text == false) {
      var sql_to_run = this.props.files[this.props.activeTab];
    } else {
      var sql_to_run = this.state.selected_text;
    }
    var tabname = this.props.activeTab.slice(0, -4);
    const SQL = {
      sql: sql_to_run,
      tabname: tabname,
      dbt_branch: this.state.activeBranch,
    };


    const { isSuccess, response } = await runSQLService(this.state.dbt_url, SQL, this.props.activeTab);
    
    if (isSuccess) {
      if (response.status == 200) {
        this.stop();
        if (response.data != "success_with_no_result") {
          this.setState({
            columns: response.data["columns"],
            sql_results: response.data["display_results"],
            logs: false,
          });
        } else {
          this.setState({
            logs_variant: "success",
            logs: "SQL Statement ran successfully.",
            sqlChatLogs: ""
          });
        }

        if (this.chatBtnRef.current) {
          this.chatBtnRef.current.showError(null);
        }
      }
    } else {
      // Handle error case - this is where we need to send the error to the chatbot
      if (response && response.data && response.data.error) {
        const errorData = {
          sql_code: SQL.sql,
          error_message: response.data.error
        };
        
        console.log('SQL error detected:', errorData);
        
        if (this.chatBtnRef.current && !gptGenerated) {
          this.chatBtnRef.current.showError(errorData);
        }
        
        this.setState({
          logs_variant: "danger",
          logs: response.data.error,
          sqlChatLogs: SQL.sql
        });
      }
      
      // OVERWRITE ERROR MESSAGE IF QUERY WAS USER CANCELLED
      if (response && response.data && response.data["error"] && response.data["error"].includes("cancelled on user")) {
        const logs = "Query successfully cancelled.";
        this.setState({
          logs_variant: "danger",
          logs,
        });
      } else if (response && response.data && response.data.is_backend_error) {
        // Handle backend communication errors
        this.setState({
          logs_variant: "danger",
          logs: "There was an error communicating with the backend.",
        });
        
        // Explicitly clear any error in the chat component
        if (this.chatBtnRef.current) {
          this.chatBtnRef.current.showError(null);
        }
      } else {
        // For other errors
        this.setState({
          logs_variant: "danger",
          logs: response?.data?.error || "There was an error running your SQL statement.",
        });
      }
    }

    //Once query finished, remove from running query list
    this.stop();
    const duration = (performance.now() - start) / 1000;
    this.setState({
      results_tab: this.props.activeTab,
      loading: false,
      started_query: start_time,
      query_time: duration.toFixed(2),
    });
    delete running_queries[file];
    this.setState({
      running_queries: running_queries,
    });
  };

  cancelSQL = async () => {
    this.setState({ cancelling: true });

    await cancelSQLService(this.state.dbt_url, this.state.running_queries, this.state.sql_results);
  };

  getFileHistory = async (filename) => {
    this.setState({ loading_file_history: true });

    const response = await getFileHistoryService(filename, this.state.activeBranch);

    if(response) {
      this.props.updateFilesHistory({ [filename]: response.data });
      this.props.updateSelectedShas({
        [filename]: this.props.files_history[filename][0]["sha"],
      });
    }
    this.setState({ loading_file_history: false });
  };

  generate_dbt_docs = async () => {
    this.setState({ generating_docs: true });
    
    // Wait for dbt_url to be defined (with a timeout)
    let attempts = 0;
    const maxAttempts = 10;
    
    while (!this.state.dbt_url && attempts < maxAttempts) {
      console.log('Waiting for dbt_url...');
      await new Promise(resolve => setTimeout(resolve, 500)); // Wait 500ms
      attempts++;
    }
    
    if (!this.state.dbt_url) {
      toast.error("DBT URL could not be loaded. Please try again later.");
      this.setState({ generating_docs: false });
      return;
    }
    console.log('Generating docs : DBT url: ', this.state.dbt_url);
    await generateDBTDocsService(this.state.dbt_url, this.state.activeBranch);
    this.setState({ generating_docs: false });
  };

  createFolder = async (foldername) => {
    let message = "";

    searchForFolder(
      this.state.git_files["repos"],
      foldername.split("/").slice(0, -1).join("/"),
      (parent) => {
        if (
          parent &&
          parent.children.find((child) => child.id === foldername)
        ) {
          message = `There is already a folder called ${foldername} in your branch ${this.state.activeBranch} please choose another name.`;
        }
      }
    );

    if (!message) {
      if (
        !["models", "macros", "tests", "analyses", "seeds", "snapshots"].find(
          (folder) => foldername.startsWith(folder)
        )
      ) {
        message = "You are not allowed to create a folder at root level.";
      } else if (foldername.endsWith("/")) {
        message = "Folder cannot finish by /, please remove it.";
      } else if (/[ `!@#$£%^&*()+\-=\[\]{};':"\\|,.<>\?~]/.test(foldername)) {
        message = "Folder cannot have special characters except '_'.";
      } else if (foldername.split("").filter((c) => c === "/").length < 1) {
        message = "Please create your folder within a subfolder in models.";
      } else {
        this.setState({ creating_folder: true, logs: false });
        const isSuccess = await createFolderService(this.state.activeBranch, foldername);
        this.setState({ creating_folder: false });
        return isSuccess;
      }
    }

    if (message) {
      toast.info(message, { autoClose: 5000 });
      return false;
    }
  };

  findKey = (obj, value) => {
    for (var key in obj) {
      if (obj[key] === value) {
        return key;
      } else if (typeof obj[key] === "object") {
        var result = findKey(obj[key], value);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  };

  createNewFile = async (closing_file, filename) => {
    let message = "";

    if (filename == false) {
      message = "Please provide a filename.";
    } else if (
      !["models", "macros", "tests", "analyses", "seeds", "snapshots"].find(
        (folder) => filename.startsWith(folder)
      )
    ) {
      message = "You are not allowed to create a folder at root level.";
    } else if (!/[a-zA-Z]/.test(filename.split("/").slice(-1)[0][0])) {
      message = "Filename has to start with a letter";
    } else if (!filename.endsWith(".sql") && !filename.endsWith(".yml")) {
      message = "You can only create .sql or .yml files.";
    } else if (/[ `!@#$£%^&*()+\-=\[\]{};':"\\|,<>\?~]/.test(filename)) {
      message = "Filename cannot have special characters except '_'.";
    } else if (
      this.state.git_files.filenames?.includes(filename.split("/").slice(-1)[0])
    ) {
      message = `There is already a file called ${filename} in your branch ${this.state.activeBranch} please choose another name.`;
    } else {
      this.setState({ creating: true, logs: false });

      let content = "/* Write your query here */";
      if (closing_file) {
        content = this.props.files[closing_file];
      }

      const isSuccess = await createFileService(filename, this.state.activeBranch, content);

      if(isSuccess) {
        this.setState({ filename: false, modal_save_file: false });
        const newGitFiles = { ...this.state.git_files };
        if (!newGitFiles.changes) {
          newGitFiles.changes = [];
        }
        if (!newGitFiles.changes.some(f => f.path === filename)) {
          newGitFiles.changes.push({ path: filename, status: 'added' });
          this.setState({ git_files: newGitFiles });
        }
  
        this.props.updateActiveTab(filename);
        this.props.updateFiles({ [filename]: content });
        this.closeFile(closing_file);
        this.props.updateFilesHistory({
          [filename]: [{ date: "Few seconds ago", author: "Saved!" }],
        });
        setTimeout(() => {this.props.removeUnsavedFiles(filename);}, 200);
        if (closing_file) {
          this.fetchGitRepo();
        }
        this.scrollSelectedFile(this.tabsRef, `dags/dbt/${filename}`);
        this.getFileHistory(filename);
      }

      if (closing_file) {
        this.toggleNewFileModal();
      }
      this.setState({ creating: false, opening: false });

      return isSuccess;
    }

    if (message) {
      if (closing_file) {
        this.setState({ create_file_log: message });
      } else {
        toast.info(message, { autoClose: 5000 });
      }

      return false;
    }
  };

  openFile = async (branch, file, sha = "unsaved") => {
    this.resetTestSelection();
    if(sha !== "latest" && sha !== "unsaved") {
      this.setState({ isUpdatingBranch: true });
    }

    if (
      Object.keys(this.props.files).includes(file) &&
      sha === "latest"
    ) {
      this.setFileName(file, false);
      this.setState({
        opening: false,
      });
    } else {
      this.setState({
        activefolder: file + "/",
        opening: true,
      });
      if (file.includes(".")) {
        this.setState({ progress: 40 });
        let toastId;
        if (sha === "latest") {
          toastId = toast.loading(
            "Opening " + file.split("/").pop() + " from " + branch + "..."
          );
        } else if (sha !== "unsaved") {
          toastId = toast.loading(
            "Opening historical version of " +
              file.split("/").pop() +
              " from " +
              branch +
              "..."
          );
        }
        this.setState({ progress: 60 });
        if (sha === "unsaved") {
          this.props.updateSelectedShas({ [file]: "unsaved" });
          this.props.updateFiles({ [file]: this.props.unsaved_files[file] });
          this.setState({ opening: false });
        } else {
          this.setState({ selected_text: false });
          const GitDetails = {
            branch: this.state.activeBranch,
            filename: "dags/dbt/" + file,
            sha: sha,
          };
          this.setState({ progress: 70 });

          const response = await openFileService(GitDetails);

          if(response) {
            // Update files first to ensure content is set before updating active tab
            this.props.updateFiles({ [file]: response.data });

            // Add a short delay to ensure file content is set before updating the active tab
            setTimeout(() => {
              this.props.updateActiveTab(file);
            }, 100);

            this.props.updateSelectedShas({ [file]: sha });
            this.scrollSelectedFile(this.tabsRef, file);
            if (sha === "latest") {
              this.getFileHistory(file);
            }
          }

          toast.dismiss(toastId);
          this.setState({ progress: 100, opening: false });
          if (this.state.activeResultTab === "lineage") {
            setTimeout(() => {
              this.get_lineage();
            }, 100);
          }
        }
      }
    }
  };

  deleteFile = async ({modelsName: file, fullName}, branch) => {
    if (
      file == "Prod/dbt_project.yml" ||
      file == "Dev/dbt_project.yml" ||
      file == "Prod/profiles.yml" ||
      file == "Dev/profiles.yml" ||
      file == "Prod/models" ||
      file == "Dev/models" ||
      file == "Dev" ||
      file == "Prod"
    ) {
      toast.info("This is a core file - deleting is not permitted.");
    } else {
      this.setState({ deleting: true });
      const isSuccess = await deleteFileService(file, branch);

      if(isSuccess) {
        this.closeFile(branch + "/" + file);
        this.setState({ modal_delete_file: false });

        // Add to changes list after successful deletion
        const newGitFiles = { ...this.state.git_files };
        if (!newGitFiles.changes) {
          newGitFiles.changes = [];
        }
        if (!newGitFiles.changes.some(f => f.path === file)) {
          newGitFiles.changes.push({ path: file, status: 'removed' });
        }
        
        const reposFilesCopy = _.cloneDeep(this.state.git_files["repos"]);
        searchForFolder(reposFilesCopy, fullName, (_, parent, index) => {
          parent.splice(index, 1);

          this.setState({
            git_files: {
              ...this.state.git_files,
              repos: reposFilesCopy,
            },
          });
        });
      }
      this.setState({ deleting: false });
    }
  };

  deleteFolder = async (foldername) => {
    if (
      foldername == "models" ||
      foldername == "macros" ||
      foldername == "tests" ||
      foldername == "analyses" ||
      foldername == "seeds" ||
      foldername == "snapshots"
    ) {
      toast.info("Deleting a folder at root level is not allowed.", {
        autoClose: 5000,
      });
    } else if (foldername.endsWith("/")) {
      toast.info("Folder name cannot end with a '/'.", {
        autoClose: 5000,
      });
    } else if (/[ `!@#$£%^&*()+\-=[\]{};':"\\|,.<>?~]/.test(foldername)) {
      toast.info("Folder name cannot have special characters except '_'.", {
        autoClose: 5000,
      });
    } else {
      this.setState({ deleting: true });
      
      const isSuccess = await deleteFolderService(foldername, this.state.activeBranch);

      if(isSuccess) {
        this.setState({ modal_delete_file: false });

        const reposFilesCopy = _.cloneDeep(this.state.git_files["repos"]);
        searchForFolder(reposFilesCopy, foldername, (_, parent, index) => {
          parent.splice(index, 1);
          this.setState({
            git_files: { ...this.state.git_files, repos: reposFilesCopy },
          });
        });
      }

      this.setState({ deleting: false });
    }
  };

  setActiveBranch = (branch) => {
    console.log("Setting active branch to " + branch);
    this.setChangedFiles([]); // Clear changed files
    const unsavedFilesList = Object.keys(this.props.unsaved_files);

    if (unsavedFilesList.length > 0) {
      this.checkForUnsavedFilesBeforeBranchSwitch(branch);
    } else {
      this.setState({
        newBranch: branch,
        showBranchSwitchWarningModal: true,
      });
    }
  };

  saveCheck = (event, file) => {
    this.setState({ closing_file: file });
    event.stopPropagation();
    if (Object.keys(this.props.unsaved_files).includes(file)) {
      this.toggleSaveFileModal();
    } else {
      this.closeFile(file);
    }
  };

  handleSaveAndOpenTests = () => {
    this.saveSQL(0, this.props.activeTab, 0);
    this.setState({ showSaveBeforeTestsModal: false });
    this.setState({ showDataQualityTestsSidebar: true });
  };

  handleSaveAndHammer = (dbt_command) => {
    this.saveSQL(0, this.props.activeTab, 0,null, this.state.activeBranch, true);  
  };

  handleCancelOpenTests = () => {
    this.setState({ showSaveBeforeTestsModal: false });
  };

  closeFile = async (file) => {
    await new Promise(resolve => setTimeout(resolve, 300));
    const openedfiles = Object.keys(this.props.files);
    const index = openedfiles.indexOf(file);
    this.props.removeUnsavedFiles(file);
    this.props.removeFilesHistory(file);
    this.props.removeSelectedShas(file);
    this.removeSuggestedCode(file);

    const isCurrentTab = file === this.props.activeTab;
    if (openedfiles.length > 1) {
      const newfileName = openedfiles[index === 0 ? 1 : index - 1];
      if (isCurrentTab) {
        console.log("Current active.Setting active tab to " + newfileName);
        this.props.updateActiveTab(newfileName);
        this.scrollSelectedFile(this.tabsRef, newfileName);
      }
    } else {
      this.props.updateActiveTab("+");
    }

    this.props.removeFiles(file);

    //close save modal if open
    if (this.state.modal_save_file == true) {
      this.toggleSaveFileModal();
    }
  };

  setFileName = (file, toggle_new_file) => {
    if (file.includes(".yml")) {
      this.setState({showDataQualityTestsSidebar: false});
    }
    this.resetTestSelection();
    let prevActiveFile = this.props.activeTab;
    this.setState({ closing_file: file });

    if (file === "+" && toggle_new_file) {
      this.toggleNewFileModal();
    } else {
      this.setState(
        {
          add_table_to: 0,
          selected_text: false,
          sql: this.props.files[file],
        },
        () => {
          this.props.updateActiveTab(file);
          this.scrollSelectedFile(this.tabsRef, file);
          if (this.state.showDataQualityTestsSidebar) {
            this.setState(prevState => ({
              dataQualityTestsReloadTrigger: prevState.dataQualityTestsReloadTrigger + 1
            }));
          }
          if (
            file !== "+" &&
            this.state.activeResultTab === "lineage" &&
            prevActiveFile !== this.props.activeTab
          ) {
            setTimeout(() => {
              this.get_lineage();
            }, 100);
          }
        }
      );
    }
  };

  
  // UPDATE STATE WHEN  USER ENTERS A FILENAME IN MODAL
  newFilename = (newValue) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ filename: newValue.target.value });
    }, 50);
  };

  newFoldername = (newValue) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ foldername: newValue.target.value });
    }, 50);
  };

  toggleNewFileModal = () => {
    this.setState({
      filename: false,
      create_file_log: false,
      models_subfolder: false,
      modal_new_file: !this.state.modal_new_file,
    });
    document.body.style.overflow = "";
  };

  toggleOverwriteInProdModal = () => {
    this.setState({ modal_overwrite_file: !this.state.modal_overwrite_file });
  };

  toggleScheduleModal = () => {
    if (this.state.isScheduleModalShown) {
      this.setState({ isScheduleModalShown: false });
    } else {
      const isGeneralModalActive = document.getElementById(
        "general-schedule-crud-modal"
      );
      if (isGeneralModalActive) {
        toast.info(
          "please finish up the progress with current modal before opening another one"
        );
      } else {
        this.setState({ isScheduleModalShown: true });
      }
    }
  };

  toggleSaveFileModal = () => {
    this.setState({ modal_save_file: !this.state.modal_save_file });
  };

  toggleDeleteModal = (name, branch, isFile) => {
    if (
      (name != "" && name.split("/").length <= 1) ||
      name == "" ||
      branch == ""
    ) {
      toast.info("Deleting at top level of DBT repo is not allowed", {
        autoClose: 5000,
      });
    } else {
      this.setState({ modal_delete_file: !this.state.modal_delete_file });
      if (!this.state.modal_delete_file) {
        this.setState({
          delete_file: {
            fullName: name,
            modelsName: name.substring(name.indexOf("/models/") + 1),
            isFile,
          },
          delete_branch: branch,
        });
      }
    }
  };

  checkForUnsavedFilesBeforeBranchSwitch = (newBranch) => {
    const unsavedFilesList = Object.keys(this.props.unsaved_files);
    if (unsavedFilesList.length > 0) {
      this.setState({
        showUnsavedFilesModal: true,
        newBranch,
      });
    } else {
      this.switchBranch(newBranch);
    }
  };

  saveAllUnsavedFiles = async () => {
    const unsavedFilesList = Object.keys(this.props.unsaved_files);
    const { files, unsaved_files } = this.props;
    const currentBranch = this.state.activeBranch; // Store the current branch

    const filesToSave = unsavedFilesList.map((file) => ({
      filename: file,
      content: unsaved_files[file] || files[file],
    }));

    const isSuccess = await saveAllUnsavedFilesService(filesToSave, currentBranch);

    if(isSuccess) {
      this.setState({ showUnsavedFilesModal: false }, () => {
        this.switchBranch(this.state.newBranch);
      });
      // Dispose of the existing model to avoid conflicts
      unsavedFilesList.forEach((file) => {
        const model = monaco.editor.getModel(
          monaco.Uri.parse(`file://${file}`)
        );
        if (model) {
          model.dispose();
        }
      });
    }
  };

  switchBranch = (newBranch) => {
    this.setState({
      pull_request_dbt_logs: false,
      pull_request_dbt_test_status: false,
      showDataQualityTestsSidebar: false, // Close the sidebar
      existingTests: [],
      currentModelName: '',
    });
    this.resetTestSelection(); // Reset test selection
    if (this.state.showPullRequestModal) {
      this.setState({ showPullRequestModal: false });
    }
    if (this.state.isScheduleModalShown) {
      this.setState({ isScheduleModalShown: false });
    }
    const openedFiles = Object.keys(this.props.files);
    openedFiles.forEach((file) => {
      const model = monaco.editor.getModel(monaco.Uri.parse(`file://${file}`));
      if (model) {
        model.dispose();
      }
    });
    this.props.reset();
    this.setState({ activeBranch: newBranch });
    localStorage.setItem("selected_branch", newBranch);
    this.props.updateActiveTab("+");
  };

  updateFileContentWithoutUnsaved = (filePath, content) => {
    this.props.updateFiles({ [filePath]: content });
    // Remove the file from unsaved files if it was there
    if (this.props.unsaved_files[filePath]) {
      this.props.removeUnsavedFiles(filePath);
    }
  }

  discardAllUnsavedFiles = () => {
    Object.keys(this.props.unsaved_files).forEach((file) => {
      this.closeFile(file);
    });

    // Close all open files
    Object.keys(this.props.files).forEach((file) => {
      this.closeFile(file);
    });

    // Close all open files
    Object.keys(this.props.files).forEach((file) => {
      const model = monaco.editor.getModel(monaco.Uri.parse(`file://${file}`));
      if (model) {
        model.dispose();
      }
      this.closeFile(file);
    });

    // Ensure that no files are active
    this.props.updateActiveTab("+");

    this.setState({ showUnsavedFilesModal: false }, () => {
      this.switchBranch(this.state.newBranch);
    });
  };

  toggleDBTConfigModal = () => {
    this.setState({
      create_file_log: false,
      materialisation: "table",
      full_refresh: false,
      model_part: false,
      modal_dbt_config: !this.state.modal_dbt_config,
    });
  };

  toggleDuplicateFile = (node) => {
    const file_to_duplicate = node.id;
    const isOpen = this.state.modal_duplicate_file;
    this.setState(
      {
        file_to_duplicate: file_to_duplicate,
        create_file_log: false,
        models_subfolder: false,
        modal_duplicate_file: !isOpen,
      },
      () => {
        if (!isOpen) {
          document.body.style.overflow = "";
        }
      }
    );
  };

  DuplicateFile = async () => {
    if (this.state.filename == false) {
      this.setState({ create_file_log: "Please provide a filename." });
    } else if (
      this.state.filename.slice(0, 6) != "models" &&
      this.state.filename.slice(0, 6) != "macros" &&
      this.state.filename.slice(0, 5) != "tests" &&
      this.state.filename.slice(0, 8) != "analyses" &&
      this.state.filename.slice(0, 5) != "seeds" &&
      this.state.filename.slice(0, 6) != "models" &&
      this.state.filename.slice(0, 9) != "snapshots"
    ) {
      this.setState({
        create_file_log:
          "You are not allowed to create a folder at root level.",
      });
    } else if (
      !/[a-zA-Z]/.test(
        this.state.filename.substring(
          this.state.filename.lastIndexOf("/") + 1
        )[0]
      )
    ) {
      this.setState({
        create_file_log: "Filename has to start with a letter.",
      });
    } else if (
      this.state.filename.substring(this.state.filename.length - 4) != ".sql" &&
      this.state.filename.substring(this.state.filename.length - 4) != ".yml"
    ) {
      this.setState({
        create_file_log: "You can only create .sql or .yml files.",
      });
    } else if (
      /[ `!@#$£%^&*()+\-=\[\]{};':"\\|,<>\?~]/.test(this.state.filename)
    ) {
      this.setState({
        create_file_log: "Filename cannot have special characters except '_'.",
      });
    } else if (
      this.state.git_files.filenames.includes(
        this.state.filename.substring(this.state.filename.lastIndexOf("/") + 1)
      )
    ) {
      this.setState({
        create_file_log:
          "There is already a file called " +
          this.state.filename.substring(
            this.state.filename.lastIndexOf("/") + 1
          ) +
          " in your branch " +
          this.state.filename.substring(0, this.state.filename.indexOf("/")) +
          " please choose another name.",
      });
    } else {
      this.setState({ creating: true });
      
      const data = {
        file_to_duplicate: "dags/dbt/" + this.state.file_to_duplicate,
        branch: this.state.activeBranch,
        new_path: "dags/dbt/" + this.state.filename,
      };
      const isSuccess = await duplicateFileService(this.state.file_to_duplicate, data, this.state.filename);

      if(isSuccess) {
        this.setState({ filename: false, modal_duplicate_file: false });
        this.fetchGitRepo();
      }
      this.setState({ creating: false, opening: false, creating: false });
    }
  };

  handleKeyPress = (e) => {
    if (e.keyCode == 13) {
      this.submitDBTCommand();
    }
  };

  handleDoubleClick = (table) => {
    let sql = this.props.files[this.props.activeTab];
    sql =
      sql.substr(0, this.state.add_table_to) +
      table +
      sql.substr(this.state.add_table_to);
    this.addToFileHistory(sql);
    this.props.updateFiles({ [this.props.activeTab]: sql });
  };

  test_dbt_model = (schedule_dbt_command) => {
    if (!/--select|--s/.test(schedule_dbt_command) && schedule_dbt_command != this.state.testdbtCommand) {
      toast.error(
        "Your command doesn't seem to be selecting any node. Make sure you have a --select or --s to select relevant nodes."
      );
      return;
    }
    if (schedule_dbt_command != this.state.testdbtCommand) {
      this.setState({
        creating_transformation: true,
        running_lineage: false,
        lineage_status: false,
        logs: false,
        running_dbt_test: true,
        dbt_test_status: false,
        dbt_logs_test: "[API] Testing your model...",
        results_tab: this.props.activeTab,
        tested_command_lineage: false,
      });
    }
    else {
      this.setState({
        pull_request_dbt_logs: "[API] Testing your code...",
        pull_request_dbt_test_status: false,
      });
    }
      let time_stamp = Math.floor(Date.now() / 1000);
      const DBT_Command = {
        dbt_branch: schedule_dbt_command === this.state.testdbtCommand ? this.state.activeBranch : "main",
        dbt_cli_env: "Dev",
        dbt_command: schedule_dbt_command,
        get_lineage: schedule_dbt_command === this.state.testdbtCommand ? false : true,
        time_stamp: time_stamp,
      };
      try {
        if (
          Object.keys(this.props.unsaved_files).includes(this.props.activeTab) &&
          !this.props.activeTab.startsWith("untitled-")
        ) {
          this.saveSQL(0, this.props.activeTab, 0);
        }
        axios.post(
          this.state.dbt_url + "/api/sql/dbt_custom_command",
          DBT_Command,
          {
            onDownloadProgress: (progressEvent) => {
              const api_logs = progressEvent.event.currentTarget.response;
              if (
                api_logs.slice(0, 5) == "[API]" &&
                !api_logs.includes("DBT run finished") &&
                !api_logs.includes("[ERROR]")
              ) 
              {
                if (schedule_dbt_command != this.state.testdbtCommand) {
                  this.setState({
                    dbt_logs_test: api_logs,
                  });
                }
                else {
                  this.setState({
                    pull_request_dbt_logs: api_logs,
                  });
                }
              } else {
                if (schedule_dbt_command != this.state.testdbtCommand) {
                  this.setState({
                    running_dbt_test: false,
                    dbt_logs_test: api_logs,
                  });
                }
                else {
                  this.setState({
                    pull_request_dbt_logs: api_logs,
                  });
                }
                  if (api_logs.includes("DBT run finished successfully.")) {

                  if (schedule_dbt_command != this.state.testdbtCommand) {
                    toast.success("Successfully tested your DBT model. 🎉");
                  }
                  else {
                    toast.success("Your code was tested successfully. 🎉");
                  }

                  if (schedule_dbt_command != this.state.testdbtCommand) {
                      this.setState({
                      dbt_test_status: "success",
                      running_dbt_test: false,
                    });  
                  }
                  else {
                    this.setState({
                      pull_request_dbt_test_status: "success",
                      running_dbt_test: false,
                    });
                  }
                    if (schedule_dbt_command != this.state.testdbtCommand) {
                    this.setState({ running_lineage: true });
                    axios
                      .post(this.state.dbt_url + "/api/sql/tested_dag_lineage", {
                        time_stamp: time_stamp,
                        dbt_command: schedule_dbt_command,
                      })
                      .then((response) => {
                        this.setState({
                          tested_command_lineage: response.data["lineage"],
                          lineage_status: "success",
                          skips: response.data["skips"],
                        });
                      })
                      .catch((err) => {
                        toast.error("There was an issue retrieving lineage.", {
                          autoClose: 5000,
                        });
                        this.setState({ lineage_status: "failed" });
                      })
                      .finally(() => {
                        this.setState({ creating: false, running_lineage: false });
                      });
                }} else {
                  if (schedule_dbt_command != this.state.testdbtCommand) {
                    this.setState({
                      dbt_test_status: "failed",
                      running_dbt_test: false,
                    });
                  } else {
                    this.setState({
                      pull_request_dbt_test_status: "failed",
                    });
                  }
                    if (api_logs.includes("does not match any enabled nodes")) {
                    toast.info("The selection criterion doesn't match any enabled nodes.");
                  }
                  else {
                      toast.error("There is an issue with your DBT code.");
                  }
                }
              }
            },
          }
        );
      } catch (error) {
        toast.error("There was an issue running your model. Check logs.", {
          autoClose: 5000,
        });
        this.setState({
          creating_transformation: false,
          running_dbt_test: false,
          dbt_test_status: "failed",
        });
      }
  };

  formatResult = (item) => {
    return (
      <>
        <span
          style={{
            cursor: "pointer",
            position: "relative",
            display: "block",
            textAlign: "left",
          }}
        >
          {item.name}
        </span>
      </>
    );
  };

  renderEditorOptions = () =>
    Object.keys({ ...this.props.files }).map((fileName) => ({
      name: fileName === "+" ? "Create File" : fileName,
      clickHandler: () => this.setFileName(fileName, true),
      nameFormatter: () =>
        fileName === "+" ? (
          <span>
            {" "}
            <Icon.Plus
              size={14}
              style={{
                marginRight: 5,
                position: "relative",
                top: -1,
              }}
            />
            Create file
          </span>
        ) : (
          <span>{fileName.split("/").pop()}</span>
        ),
      hasFollowingDivider: fileName === "+",
    }));

  togglePullRequestModal = () => {
    this.setState(
      (prevState) => ({ showPullRequestModal: !prevState.showPullRequestModal }),
    );
  };

  onSortColumn = (column) => {
    const results = this.state.sql_results.map((result) => ({ ...result }));
    let isAsc = true;
    let columnName = column.toLocaleLowerCase();

    if (this.state.sortedColumn.column === columnName) {
      isAsc = !this.state.sortedColumn.isAsc;
    }

    this.setState({
      sql_results: results.sort((a, b) => {
        if (isAsc) {
          return a[column]?.toLowerCase() < b[column]?.toLowerCase() ? 1 : -1;
        } else {
          return b[column]?.toLowerCase() < a[column]?.toLowerCase() ? 1 : -1;
        }
      }),
    });
    this.setState({ sortedColumn: { column, isAsc } });
  };

  showDiffInEditor = (suggestedContent) => {
    if(this.props.files[this.props.activeTab].trim() === suggestedContent.trim()) return;

    this.addToFileHistory(`${this.props.files[this.props.activeTab]} `);
    this.props.updateFiles({ [this.props.activeTab]: `${this.props.files[this.props.activeTab]} ` });
    this.updateSuggestedCode(suggestedContent);
  }

  updateSuggestedCode = (content) => {
    this.setState({ editorSuggestedCode: { ...this.state.editorSuggestedCode, [this.props.activeTab]: content } })
  }

  removeSuggestedCode = (activeTab = this.props.activeTab) => {
    this.setState({
      editorSuggestedCode: Object.fromEntries(
        Object.entries(this.state.editorSuggestedCode).filter(([key]) => key !== activeTab)
      )
    });
  }

  render() {
    return (
      <div>
        <LoadingBar color="#0245a0" progress={this.state.progress} />
        <div className="container-fluid py-3">
          <Helmet title="Datajolt | SQL Editor" />
          <div className="card-body-sql" style={{height: "calc(100vh - 120px)"}}>
            <Stack mt={1} height={"calc(100% - 20px)"} flexDirection={"row"}>
              <Box
                minHeight={this.state.editor_height + 6}
                position="relative"
                display={!this.state.maxEditorSize ? "block" : "none"}
              >
                <ResizableBox
                  id="editor-helpers-wrapper"
                  width={this.state.sidebarConstrains.leftBoxes}
                  minConstraints={[this.state.sidebarConstrains.min, 100]}
                  maxConstraints={[this.state.sidebarConstrains.max, 100]}
                  resizeHandles={["e"]}
                  style={{ position: "relative" }}
                  onResize={(_, { size }) => {
                    if (window.innerWidth >= 768) {
                      this.setState({
                        sidebarConstrains: {
                          ...this.state.sidebarConstrains,
                          leftBoxes: size.width,
                        },
                      });
                    }
                  }}
                  onResizeStop={(_, { size }) =>
                    localStorage.setItem("editor-width", size.width)
                  }
                >
                  <LeftBoxes>
                    <SchemaBox
                      setAutocomplete={this.setAutocomplete}
                      setSchemasData={this.setSchemasData}
                      activeTenant={this.props.activeTenant}
                    />

                    <QueriesBox
                      loading_git={this.state.loading_git}
                      git_files={this.state.git_files}
                      fetchGitRepo={this.fetchGitRepo}
                      openFile={this.openFile}
                      toggleDuplicateFile={this.toggleDuplicateFile}
                      toggleDeleteModal={this.toggleDeleteModal}
                      activeBranch={this.state.activeBranch}
                      setActiveBranch={this.setActiveBranch}
                      activeTenant={this.props.activeTenant?.name}
                      generate_docs={this.generate_dbt_docs}
                      createNewFile={this.createNewFile}
                      createFolder={this.createFolder}
                      updateGitFiles={(repos) =>
                        this.setState({
                          git_files: { ...this.state.git_files, repos },
                        })
                      }
                      files={this.props.files}  // Add this line to pass files prop
                      toggleEditingNotAllowedModal={this.toggleEditingNotAllowedModal}
                      togglePullRequestModal={this.togglePullRequestModal}
                      showPullRequestModal={this.state.showPullRequestModal}
                      test_dbt_model={this.test_dbt_model}
                      dbt_test_status={this.state.pull_request_dbt_test_status}
                      dbt_logs={this.state.pull_request_dbt_logs}
                      dbtCommand={this.state.testdbtCommand}
                      dbt_url={this.state.dbt_url}
                      changedFiles={this.state.changedFiles}
                      setChangedFiles={this.setChangedFiles}
                    />

                    <EditorHistoryBox
                      getFileHistory={this.getFileHistory}
                      openFile={this.openFile}
                      loading_file_history={this.state.loading_file_history}
                      activeBranch={this.state.activeBranch}
                      saveCode={this.saveSQL}
                    />
                  </LeftBoxes>
                </ResizableBox>
              </Box>

              <Stack overflow="hidden" flexDirection={"column"} flex={1}>
                <div
                  className="editior-tab-container-wrapper"
                  style={{
                    width: "100%",
                  }}
                >
                  <span
                    className="create-file-tab-button"
                    role="button"
                    onClick={() => this.createNewUntitledFile()}
                  >
                    <Icon.Plus size={14} />
                  </span>
                  <div className="editior-tab-container-wrapper-inner">
                    <Tab.Container
                      id="files-tabs"
                      activeKey="test.sql"
                      className="d-flex flex-nowrap flex-row"
                    >
                      <ButtonGroup
                        ref={this.tabsRef}
                        className="editor-tab-container"
                      >
                        <div className="new-file-container">
                          <Button
                            className="d-flex align-items-center action-buttons-editor"
                            variant="tab"
                            style={{
                              fontSize: "11px",
                              cursor: "default",
                            }}
                          >
                            <IconButton
                              onClick={() =>
                                this.setState({
                                  maxEditorSize: !this.state.maxEditorSize,
                                  sidebarConstrains: {
                                    ...this.state.sidebarConstrains,
                                    leftBoxes: this.state.sidebarConstrains.leftBoxes + 1
                                  }
                                })
                              }
                              sx={{ padding: 0 }}
                            >
                              {this.state.maxEditorSize ? (
                                <Icon.Minimize size={16} />
                              ) : (
                                <Icon.Maximize size={16} />
                              )}
                            </IconButton>

                            <DropdownMenu
                              IconType="MoreVertical"
                              options={this.renderEditorOptions()}
                            />
                          </Button>
                        </div>

                        <EditorTabs
                          files={Object.keys(this.props.files).filter(
                            (file) => file !== "+"
                          )}
                          setFileName={this.setFileName}
                          running_queries={this.state.running_queries}
                          saveCheck={this.saveCheck}
                        />
                      </ButtonGroup>
                    </Tab.Container>
                  </div>
                </div>

                <div className="borders-editor" style={{ flex: 1, position: "relative" }}>
                  <MonacoEditor
                    editor_height={this.state.editor_height}
                    handleEditorChange={this.handleEditorChange}
                    handleEditorDidMount={this.handleEditorDidMount}
                    editorSuggestedCode={this.state.editorSuggestedCode}
                    updateSuggestedCode={this.updateSuggestedCode}
                    removeSuggestedCode={this.removeSuggestedCode}
                  />
                  
                  {!this.state.sidebarConstrains.chatBox && (
                    <ChatBtnIcon 
                      ref={this.chatBtnRef} 
                      top={this.state.editor_height - 125}
                      openSidebar={() => {
                        const width = window.innerWidth >= 768 ? window.innerWidth / 6 - 20 : window.innerWidth - 300;

                        localStorage.setItem("chat-width", width);
                        this.setState({sidebarConstrains: {...this.state.sidebarConstrains, chatBox: width }})
                      }}
                      setError={(lastError) => {
                        if (this.chatSidebarRef.current) {
                          this.chatSidebarRef.current.startFix(lastError, true);
                        }
                      }}
                    />
                  )}
                </div>

                <Card
                  style={{
                    position: "relative",
                    boxShadow: "none",
                    marginBottom: 0
                  }}
                >
                  <ResizableBox
                    className="dragged-box absolutely-positioned bottom-aligned left-aligned react-resizable"
                    height={
                      this.state.queryResultBoxMargin +
                      50
                    }
                    width={"100%"}
                    resizeHandles={["n"]}
                    onResizeStop={() => {
                      const documentHeight = document.documentElement.clientHeight - 150;
                      const newHeight = this.queryResultBoxRef?.current
                        ?.state?.height
                        ? Math.floor(
                            this.queryResultBoxRef.current.state.height
                          ) - 50
                        : 0;

                      this.setState({
                        queryResultBoxMargin: newHeight,
                        editor_height: documentHeight - newHeight
                      })
                    }
                    }
                    ref={this.queryResultBoxRef}
                    style={{
                      minHeight: 52,
                      maxHeight: document.documentElement.clientHeight - 170,
                      display: "flex",
                      flexDirection: "column",
                      overflow: "visible"
                    }}
                    maxConstraints={[Infinity, document.documentElement.clientHeight - 170]}
                    minConstraints={[Infinity, 52]}
                  >
                    <>
                      <section className="editor-actions-box">
                        <div className="actions-helper-tabs-editor">
                        <Button
                            onClick={() =>
                              this.setState({ activeResultTab: "query" })
                            }
                            className={
                              this.state.activeResultTab === "query"
                                ? "active-result-tab"
                                : ""
                            }
                            variant="link"
                          >
                            <div style={{ position: 'relative' }}>
                              Query Results
                              {this.state.running && (
                                <div style={{
                                  height: '2px',
                                  background: 'linear-gradient(90deg,rgba(0, 123, 255, 0.54) 0%, transparent 50%,rgb(0, 78, 161) 100%)',
                                  backgroundSize: '200% 100%',
                                  animation: 'loading 1.2s infinite linear',
                                  position: 'absolute',
                                  bottom: '-4px',
                                  left: 0,
                                  width: '100%'
                                }}></div>
                              )}
                            </div>
                          </Button>
                          <Button
                            onClick={() =>
                              this.setState({ activeResultTab: "dbt" })
                            }
                            className={
                              this.state.activeResultTab === "dbt"
                                ? "active-result-tab"
                                : ""
                            }
                            variant="link"
                          >
                            <div style={{ position: 'relative' }}>
                              DBT CLI
                              {this.state.running_dbt_command && (
                                <div style={{
                                  height: '2px',
                                  background: 'linear-gradient(90deg,rgba(0, 123, 255, 0.54) 0%, transparent 50%,rgb(0, 78, 161) 100%)',
                                  backgroundSize: '200% 100%',
                                  animation: 'loading 1.2s infinite linear',
                                  position: 'absolute',
                                  bottom: '-4px',
                                  left: 0,
                                  width: '100%'
                                }}></div>
                              )}
                            </div>
                          </Button>
                          <Button
                            onClick={() => {
                              this.get_lineage();
                              this.setState({
                                activeResultTab: "lineage",
                              });
                            }}
                            className={
                              this.state.activeResultTab === "lineage"
                                ? "active-result-tab"
                                : ""
                            }
                            variant="link"
                          >
                            Lineage
                          </Button>
                          <Button
                              disabled={this.state.generating_docs}
                              onClick={() => this.setState({ showDbtDocsModal: true })}
                              className={
                                this.state.activeResultTab === "dbt_docs"
                                  ? "active-result-tab"
                                  : ""
                              }
                              variant="link"
                              style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <div style={{ position: 'relative' }}>
                              DBT Docs
                              {this.state.generating_docs && (
                                <div style={{
                                  height: '2px',
                                  background: 'linear-gradient(90deg,rgba(0, 123, 255, 0.54) 0%, transparent 50%,rgb(0, 78, 161) 100%)',
                                  backgroundSize: '200% 100%',
                                  animation: 'loading 1.2s infinite linear',
                                  position: 'absolute',
                                  bottom: '-4px',
                                  left: 0,
                                  width: '100%'
                                }}></div>
                              )}
                            </div>
                          </Button>
                        </div>

                        <small className="text-navy editor-tab-actions">
                          {this.props.activeTab && (
                                <>
                              <OverlayTrigger
                                trigger={["hover", "hover"]}
                                rootClose
                                placement="bottom"
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    Save File (Ctrl + S)
                                  </Tooltip>
                                }
                              >

                                <Button
                                  variant="primary"
                                  size="sm-pad"
                                  disabled={this.state.saving}
                                  onClick={() =>
                                    this.checkFileStatus(this.props.activeTab, 0)
                                  }
                                  className="btn float-right"
                                >
                                  <Icon.Save size={14} />
                                </Button>
                              </OverlayTrigger>
                              &nbsp;
                              {this.props.activeTab.includes(".sql") && this.state.activeBranch !== "main" ?
                              <><OverlayTrigger
                              trigger={["hover", "hover"]}
                              rootClose={true}
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-bottom`}>
                                  Data Quality Tests
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="secondary"
                                size="sm-pad"
                                disabled={!this.props.activeTab.includes(".sql")}
                                onClick={(e) => {
                                  this.toggleDataQualityTestsSidebar();
                                }}
                                className="btn float-right"
                              >
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                  <Icon.Database size={16} />
                                  <Icon.Check size={12} style={{ position: 'absolute', bottom: -4, right: -4 }} />
                                </div>
                              </Button>
                            </OverlayTrigger> &nbsp;
                            </>:""}
                            {this.props.activeTab.includes(".sql") && this.state.activeBranch !== "main" ?<>
                                <DropdownButton
                                size="sm-pad"
                                title={<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M571.31 193.94l-22.63-22.63c-6.25-6.25-16.38-6.25-22.63 0l-11.31 11.31-28.9-28.9c5.63-21.31.36-44.9-16.35-61.61l-45.25-45.25c-62.48-62.48-163.79-62.48-226.28 0l90.51 45.25v18.75c0 16.97 6.74 33.25 18.75 45.25l49.14 49.14c16.71 16.71 40.3 21.98 61.61 16.35l28.9 28.9-11.31 11.31c-6.25 6.25-6.25 16.38 0 22.63l22.63 22.63c6.25 6.25 16.38 6.25 22.63 0l90.51-90.51c6.23-6.24 6.23-16.37-.02-22.62zm-286.72-15.2c-3.7-3.7-6.84-7.79-9.85-11.95L19.64 404.96c-25.57 23.88-26.26 64.19-1.53 88.93s65.05 24.05 88.93-1.53l238.13-255.07c-3.96-2.91-7.9-5.87-11.44-9.41l-49.14-49.14z"></path>
                                </svg>}
                                className='hammer-btn'
                                menuProps={{
                                  style: { zIndex: 99999 }
                                }}
                              >
                                <Dropdown.Item
                                  onClick={(e) => {
                                    const filename = this.props.activeTab.split('/').pop().replace('.sql', '');
                                    const command = `dbt build --select ${filename}`;
                                    this.triggerHammer(command);
                                  }}
                                >
                                  dbt build --select {this.props.activeTab.split('/').pop().replace('.sql', '')}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={(e) => {
                                    const filename = this.props.activeTab.split('/').pop().replace('.sql', '');
                                    const command = `dbt build --select +${filename}`;
                                    this.triggerHammer(command);
                                  }}
                                >
                                  dbt build --select +{this.props.activeTab.split('/').pop().replace('.sql', '')}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={(e) => {
                                    const filename = this.props.activeTab.split('/').pop().replace('.sql', '');
                                    const command = `dbt build --select +${filename}+`;
                                    this.triggerHammer(command);
                                  }}
                                >
                                  dbt build --select +{this.props.activeTab.split('/').pop().replace('.sql', '')}+
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={(e) => {
                                    const filename = this.props.activeTab.split('/').pop().replace('.sql', '');
                                    const command = `dbt build --select ${filename}+`;
                                    this.triggerHammer(command);
                                  }}
                                >
                                  dbt build --select {this.props.activeTab.split('/').pop().replace('.sql', '')}+
                                </Dropdown.Item>
                              </DropdownButton>&nbsp;</>:""}                     
                              {this.props.activeTab.slice(-4) === ".sql" ||
                              this.props.activeTab === "+" ||
                              this.props.activeTab.startsWith("untitled-") ? (
                                <>
                                  <OverlayTrigger
                                    trigger={["hover", "hover"]}
                                    rootClose={true}
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id={`tooltip-bottom`}>
                                        Run your SQL (Ctrl + R)
                                      </Tooltip>
                                    }
                                  >
                                      <Button
                                        variant="success"
                                        size="sm-pad"
                                        disabled={
                                          this.props.files[
                                            this.props.activeTab
                                          ] == "" || this.state.loading
                                        }
                                        onClick={() => this.runSQL()}
                                        className="btn float-right"
                                      >
                                        <Icon.PlayCircle size={16} />
                                      </Button>
                                  </OverlayTrigger>{" "}
                                </>
                              ) : (
                                ""
                              )}
                              &nbsp;
                              {this.state.activeBranch == "main" ?  (
                                <OverlayTrigger
                                  trigger={["hover", "hover"]}
                                  rootClose={true}
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      Create DAG
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="primary"
                                    size="sm-pad"
                                    disabled={false}
                                    onClick={this.toggleScheduleModal}
                                    className="btn float-right"
                                  >
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="14" width="14" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M505.12019,19.09375c-1.18945-5.53125-6.65819-11-12.207-12.1875C460.716,0,435.507,0,410.40747,0,307.17523,0,245.26909,55.20312,199.05238,128H94.83772c-16.34763.01562-35.55658,11.875-42.88664,26.48438L2.51562,253.29688A28.4,28.4,0,0,0,0,264a24.00867,24.00867,0,0,0,24.00582,24H127.81618l-22.47457,22.46875c-11.36521,11.36133-12.99607,32.25781,0,45.25L156.24582,406.625c11.15623,11.1875,32.15619,13.15625,45.27726,0l22.47457-22.46875V488a24.00867,24.00867,0,0,0,24.00581,24,28.55934,28.55934,0,0,0,10.707-2.51562l98.72834-49.39063c14.62888-7.29687,26.50776-26.5,26.50776-42.85937V312.79688c72.59753-46.3125,128.03493-108.40626,128.03493-211.09376C512.07526,76.5,512.07526,51.29688,505.12019,19.09375ZM384.04033,168A40,40,0,1,1,424.05,128,40.02322,40.02322,0,0,1,384.04033,168Z"></path>
                                    </svg>
                                  </Button>
                                </OverlayTrigger>
                              ) : ""
                              }
                            </>
                          )}
                        </small>
                      </section>

                      {this.state.showDbtDocsModal && (
                        <DbtDocsModal
                          show={this.state.showDbtDocsModal}
                          handleClose={() =>
                            this.setState({ showDbtDocsModal: false })
                          }
                          dbtUrl={this.state.dbt_url}
                          activeBranch={this.state.activeBranch}
                        />
                      )}

                      {this.state.activeResultTab === "dbt" && (
                        <Stack
                          className="section-wrapper dbt-results"
                          id="dbt-results"
                          sx={{ flex: 1, maxHeight: "calc(100% - 50px)" }}
                          component={"section"}
                        >
                          <InputGroup className="cli-wrapper">
                            <AutocompleteInput
                              val={this.state.dbt_command}
                              sx={{
                                padding: 0,
                                position: "relative",
                                top: 2.7,
                                right: -2,
                                flexGrow: 1,
                              }}
                              options={this.props.savedCommands}
                              placeholder={this.state.dbt_command ? this.state.dbt_command : "DBT CLI"}
                              onChange={(command) => {
                                this.setState({ dbt_command: command });
                                if (!command) {
                                  this.setState({
                                    isShowingCLI: false,
                                  });
                                }
                              }}
                              onKeyDown={(e) => this.handleKeyPress(e)}
                            />

                            <DropdownButton
                              variant={
                                this.state.dbt_cli_env == "Dev"
                                  ? "secondary"
                                  : "danger"
                              }
                              size="sm-pad"
                              title={this.state.dbt_cli_env}
                              id="input-query-folder-dropdown"
                            >
                              <Dropdown.Item
                                onClick={(e) =>
                                  this.setState({ dbt_cli_env: "Dev" })
                                }
                              >
                                Dev
                              </Dropdown.Item>
                              <Dropdown.Item
                                style={{ color: "red" }}
                                onClick={(e) =>
                                  this.setState({ dbt_cli_env: "Prod" })
                                }
                              >
                                Prod
                              </Dropdown.Item>
                            </DropdownButton>

                            <OverlayTrigger
                              rootClose
                              trigger={["hover", "hover"]}
                              placement="bottom"
                              overlay={
                                <Tooltip
                                  id={`tooltip-bottom`}
                                  style={{
                                    display: this.state.running_dbt_command
                                      ? "none"
                                      : "block",
                                  }}
                                >
                                  Run DBT Command
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="primary"
                                className="trigger-cli"
                                disabled={this.state.running_dbt_command}
                                size="sm-pad"
                                onClick={this.submitDBTCommand}
                              >
                                {this.state.running_dbt_command ? (
                                  <CircularProgress size={12} color="#fff" />
                                ) : (
                                  <>
                                    <Icon.Play size={14} />
                                  </>
                                )}
                              </Button>
                            </OverlayTrigger>
                          </InputGroup>
                          {this.state.cli_runs.length > 0 ?
                            <div className="container" style={{ overflow: "auto", flex: 1 }}>
                              <div className="left-panel">
                                {this.state.cli_runs.map((run, index) => (
                                  <div
                                    key={index}
                                    className={`run-item ${
                                      this.state.selectedRun === run
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() => this.selectRun(run)}
                                  >

                                    <span className="run-status-icon">{this.getStatusIcon(run.status)}</span>  
                                    <span className="run-command-text" title={run.dbt_command}>{run.dbt_command}</span>                                </div>
                                ))}
                              </div>
                              <div className="right-panel">
                                {this.state.loadingLogs ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                    }}
                                  >
                                    <br />
                                    <DatajoltSpinner />
                                  </div>
                                ) : (
                                  this.state.dbt_command_logs &&
                                  this.state.activeResultTab === "dbt" && (
                                    <div
                                      className="log-display"
                                      style={{
                                        wordBreak: "break-all"
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: this.state.dbt_command_logs
                                          ? this.state.dbt_command_logs.replace(
                                              /^.*\[API\].*\[debug\].*$/gm,
                                              ""
                                            )
                                          : this.state.dbt_command_logs,
                                      }}
                                    ></div>
                                  )
                                )}
                              </div>
                            </div>
                            : 
                            <p style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 14
                            }}><b>
                              DBT Command logs will appear here. Give it a try!</b>
                            </p>
                          }
                        </Stack>
                      )}

                      {this.state.started_query &&
                        "query" === this.state.activeResultTab && (
                        <Stack
                          className="section-wrapper query-results"
                          id="query-results"
                          component={"section"}
                          sx={{ height: "calc(100% - 50px)"}}
                        >
                          <Card.Header
                            style={{ margin: "10px 12px 10px", padding: "0.5rem 0 0" }}
                          >
                            <b>
                              {this.state.results_tab.split("/").pop()}
                              &nbsp;&nbsp;
                            </b>
                            Started at : {this.state.started_query} |{" "}
                            {!this.state.query_time ? this.state.text : (
                              <>
                                {" "}
                                {this.state.query_time}s |{" "}
                                {this.state.sql_results.length} row(s)
                                displayed{" "}
                                <small className="float-end text-navy">
                                  &nbsp;&nbsp;
                                </small>
                              </>
                            )}
                          </Card.Header>
                          <Card.Body style={{ overflowX: "auto",flex: "unset", maxHeight: "calc(100% - 45px)" }}>
                            <Card.Text className="h-100" as="div">
                              {this.state.logs && (
                                <Alert
                                  variant={this.state.logs_variant}
                                  className="alert-outline-coloured"
                                  key="1"
                                  onClose={() =>
                                    this.setState({ logs: false })
                                  }
                                  dismissible
                                  style={{ overflowY: "auto" }}
                                >
                                  <div className="alert-icon"></div>
                                  <div className="alert-message" style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingInlineEnd: "32px",
                                    wordBreak: "break-word",
                                    gap: "8px",
                                  }}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: this.state.logs,
                                      }}
                                      style={{
                                        flex: 1
                                      }}
                                    />
                                  {!this.state.logs.includes("There was an error communicating with the backend") && (
                                    <Button
                                      variant="danger"
                                      style={{paddingBlock: 0, fontSize: "11px", marginBottom: "auto"}}
                                      onClick={() => {
                                        if(this.chatBtnRef.current?.openSidebar) {
                                          this.chatBtnRef.current.openSidebar();
                                          if (localStorage.getItem('conversation_id')) {
                                            this.chatSidebarRef.current.startFix({
                                              sql_code: this.state.sqlChatLogs,
                                              error_message: this.state.logs,
                                            });
                                          }
                                        } else if(this.chatSidebarRef.current) {
                                          this.chatSidebarRef.current.startFix({
                                            sql_code: this.state.sqlChatLogs,
                                            error_message: this.state.logs,
                                          });
                                        }
                                          else {
                                            this.chatSidebarRef.current.startFix({
                                              sql_code: this.state.sqlChatLogs,
                                              error_message: this.state.logs,
                                            });
                                        }
                                      }}
                                    >
                                      Fix now
                                    </Button>
                                  )}
                                  </div>
                                </Alert>
                              )}
                              <TableContainer 
                                sx={{ 
                                  maxHeight: "100%",
                                  [`& .${tableCellClasses.head}`]: {
                                    backgroundColor: "#fff",
                                    maxWidth: "unset",
                                  },
                                  [`& .${tableCellClasses.body}`]: {
                                    maxWidth: "70px",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  },
                                  [`& .${tableCellClasses.root}`]: {
                                    minWidth: 0,
                                    overflow: "hidden",
                                  },
                                  [`& .${tableClasses.root}`]: {
                                    maxWidth: "unset",
                                    width: "max-content"
                                  },
                                  [`& .${tableRowClasses.root}`]: {
                                    height: "40px !important"
                                  },
                                  [`& .${tableHeadClasses.root}`]: {
                                    display: this.state.columns && this.state.columns.length ? "table-header-group" : "none"
                                  }
                                }}>
                                <CustomTable 
                                  headings={this.state.columns.map((column) => ({
                                    // width: !column ? "50px" : undefined,
                                    columnName: column,
                                    isSortable: !!column,
                                    Children: ({ children }) => <span className="d-flex align-items-center h-100">{column}{children}</span>
                                  }))}
                                  onSortColumn={(columnName) => this.onSortColumn(columnName)}
                                  sortedColumn={this.state.sortedColumn}
                                  rows={this.state.sql_results.map((row, i) => (
                                    {
                                      key: i,
                                      columns: [
                                        {
                                          type: "text",
                                          text: i + 1
                                        },
                                        ...this.state.columns.slice(1).map((column) => ({
                                          type: "text",
                                          text: row[column]
                                        }))
                                      ]
                                    }
                                  ))}
                                  isLoading={this.state.running}
                                  loadingOptions={{
                                    columns: 6
                                  }}
                                />
                              </TableContainer>
                            </Card.Text>
                          </Card.Body>
                        </Stack>
                      )}
                      {this.state.showDataQualityTestsSidebar && (
                        <DataQualityTestsCRUD
                        onClose={() => this.setState({ showDataQualityTestsSidebar: false })}
                        currentModelName={this.props.activeTab}
                        dbt_url={this.state.dbt_url}
                        branch={this.state.activeBranch}
                        editorContent={this.props.files[this.props.activeTab]}
                        updateFiles={this.props.updateFiles}
                        files={this.props.files}
                        updateFileContentWithoutUnsaved={this.updateFileContentWithoutUnsaved}
                        triggerReload={this.state.dataQualityTestsReloadTrigger}
                        />
                    )}

                      <section className="section-wrapper lineage" id="lineage" style={{ flex: 1 }}>
                        {this.state.activeResultTab === "lineage" && (
                          <>
                            {this.state.getting_file_lineage ? (
                              <div
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <DatajoltSpinner />
                              </div>
                            ) : (
                              <LineageVisulaizer
                                editWorkflow={{
                                  initial_workflow_chain: this.state.lineage,
                                  workflow_name: this.props.activeTab,
                                }}
                              />
                            )}
                          </>
                        )}
                      </section>
                    </>
                  </ResizableBox>
                </Card>
              </Stack>

              <ResizableBox
                id="ai-chat-sidebar"
                width={this.state.sidebarConstrains.chatBox}
                minConstraints={[this.state.sidebarConstrains.min, 100]}
                maxConstraints={[this.state.sidebarConstrains.max, 100]}
                resizeHandles={["w"]}
                onResize={(_, { size }) => {
                  if (window.innerWidth >= 768) {
                    this.setState({
                      sidebarConstrains: {
                        ...this.state.sidebarConstrains,
                        chatBox: size.width,
                      },
                    });
                  }
                }}
                onResizeStop={(_, { size }) =>
                  localStorage.setItem("chat-width", size.width)
                }
                style={{ display: this.state.sidebarConstrains.chatBox ? "block" : "none" }}
              >
                <ChatBot
                  ref={this.chatSidebarRef}
                  onHideSidebar={() => {
                    localStorage.setItem("chat-width", "0")
                    this.setState({ sidebarConstrains: {...this.state.sidebarConstrains, chatBox: 0 }})
                  }}
                  runSQL={() => this.runSQL(true)}
                  updateSelectedText={(selected_text, cb) => this.setState({selected_text}, cb)} 
                  showDiffInEditor={this.showDiffInEditor}
                  files={this.props.files}  // Add this line to pass files prop
                  activeTab={this.props.activeTab}
                  branch={this.state.activeBranch}
                  isDiffViewActive={!!this.state.editorSuggestedCode[this.props.activeTab]}
                  selectedText={this.state.selected_text}
                  dwh_structure={this.state.autocomplete?.dwh_structure}
                />
              </ResizableBox>
            </Stack>
          </div>
        </div>

        <CreateFileModal
          isShown={this.state.modal_new_file}
          onHide={() => this.toggleNewFileModal()}
          onAction={() => this.createNewFile(this.state.closing_file, this.state.filename)}
          activeBranch={this.state.activeBranch}
          createFileLog={this.state.create_file_log}
          creating={this.state.creating}
          filename={this.state.filename}
          newFilename={this.newFilename}
        />

        <TestWithoutSavingModal
          isShown={this.state.showSaveBeforeTestsModal}
          onHide={this.handleCancelOpenTests}
          onAction={this.handleSaveAndOpenTests}
          saving={this.state.saving}
        />

        <HammerWithoutSavingModal
          isShown={this.state.showSaveBeforeHammer}
          onHide={() => this.setState({ showSaveBeforeHammer: false })}
          onAction={this.handleSaveAndHammer}
          saving={this.state.saving}
        />

        <DuplicateFileModal
          isShown={this.state.modal_duplicate_file}
          onHide={() => this.setState({
            modal_duplicate_file: false,
            create_file_log: false,
            models_subfolder: false,
          })}
          onAction={() => this.DuplicateFile()}
          duplicatedFile={this.state.file_to_duplicate}
          newFilename={this.newFilename}
          createFileLog={this.state.create_file_log}
          fileName={this.state.filename}
          creating={this.state.creating}
        />

        <OverwriteFileModal
          isShown={this.state.modal_overwrite_file}
          onHide={() => this.toggleOverwriteInProdModal()}
        />

        <DeleteFileModal
          isShown={this.state.modal_delete_file}
          onHide={() => this.setState({ modal_delete_file: false })}
          onAction={() => {
            if (this.state.delete_file.isFile) {
              this.deleteFile(this.state.delete_file, this.state.activeBranch);
            } else {
              this.deleteFolder(this.state.delete_file.fullName);
            }
          }}
          deleting={this.state.deleting}
          activeBranch={this.state.activeBranch}
          filename={this.state.delete_file?.modelsName}
        />

        <CloseWithoutSavingModal
          isShown={this.state.modal_save_file}
          onHide={() => this.toggleSaveFileModal()}
          closeFile={() => this.closeFile(this.state.closing_file)}
          saveFile={() => this.checkFileStatus(this.state.closing_file, 1)}
          closingFile={this.state.closing_file}
          activeBranch={this.state.activeBranch}
        />

        <SaveForLineageModal
          isShown={this.state.saveForLineageModal}
          onHide={() => this.toggleSaveForLineageModal()}
          getLineage={() => {
            this.toggleSaveForLineageModal();
            this.get_lineage();
          }}
          saveFile={() => this.OpenLineageAfterSavingSQL(1, this.props.activeTab, 0)}
          activeTab={this.props.activeTab}
          saving={this.state.saving}
        />

        <UnsavedFilesModal
          isShown={this.state.showUnsavedFilesModal}
          onHide={() => this.setState({ showUnsavedFilesModal: false })}
          discardFiles={this.discardAllUnsavedFiles}
          saveFiles={this.saveAllUnsavedFiles}
          activeBranch={this.state.activeBranch}
          unsavedFiles={this.props.unsaved_files}
        />

        <EditingNotAllowedModal
          isShown={this.state.showEditingNotAllowedModal}
          onHide={this.toggleEditingNotAllowedModal}
          onAction={this.toggleEditingNotAllowedModal}
        />

        <RunDBTProdModal
          isShown={this.state.showProdConfirmationModal}
          onHide={() => this.setState({ showProdConfirmationModal: false })}
          onAction={() => {
            this.setState({ showProdConfirmationModal: false });
            this.executeDBTCommand();
          }}
        />

        <SwitchBranchModal
          isShown={this.state.showBranchSwitchWarningModal}
          onHide={this.toggleBranchSwitchWarningModal}
          onAction={() => {
            this.toggleBranchSwitchWarningModal();
            this.switchBranch(this.state.newBranch);
          }}
          activeBranch={this.state.activeBranch}
        />
        <CSSTransition
          in={this.state.isScheduleModalShown}
          classNames="right-slide"
          timeout={300}
          unmountOnExit
        >
          <ScheduleModal
            testModel={this.test_dbt_model}
            isShown={this.state.isScheduleModalShown}
            setIsShown={(shownState) =>
              this.setState({ isScheduleModalShown: shownState })
            }
            lineageData={{
              initial_workflow_chain: this.state.tested_command_lineage,
              workflow_name: this.props.activeTab,
            }}
            dbtUrl={this.state.dbt_url}
            testData={{
              running_dbt: this.state.running_dbt_test,
              creating_transformation: this.state.creating_transformation,
              dbt_logs: this.state.dbt_logs_test,
              dbt_test_status: this.state.dbt_test_status,
              lineage_status: this.state.lineage_status,
              running_lineage: this.state.running_lineage,
            }}
            skips_={this.state.skips}
          />
        </CSSTransition>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TenantContextComponent);