import React from "react";
import { CircularProgress, DialogContentText, Stack, Typography } from "@mui/material";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";
import ModalLayoutInput from "./Layout/Input";
import { Check, LogIn } from "react-feather";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  changeTapAlias: (e: React.ChangeEvent) => void;
 *  savedAlias: boolean;
 *  tapAlias: string;
 *  getTapDetails: (val: boolean) => void;
 *  newSchema: string;
 *  fivetranName: string;
 *  setNewSchema: () => void;
 *  schemaSaved: boolean;
 *  schema: string;
 *  embeddedUrl: string;
 *  schemaOverwritten: string;
 * }} data
 */
const FivetranAuthModal = ({
  isShown,
  onHide,
  changeTapAlias,
  savedAlias,
  tapAlias,
  getTapDetails,
  newSchema,
  fivetranName,
  setNewSchema,
  schemaSaved,
  schema,
  embeddedUrl,
  schemaOverwritten,
}) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>
        Authenticate with Fivetran
        <img
          src="https://assets-global.website-files.com/6130fa1501794ed4d11867ba/613e9ef6d25b70060cb305ca_fivetran-logo.fb5c1b9c.svg"
          width="12%"
          style={{
            position: "absolute",
            right: "48px",
          }}
          alt=""
        />
      </ModalLayoutHeader>
      <ModalLayoutContent>
        <DialogContentText
          component={"h5"}
          textAlign={"center"}
          fontSize={11}
          fontFamily={"Poppins"}
          color="#6c757d"
        >
          We partner with Fivetran for our data ingestion layer.
        </DialogContentText>
        <DialogContentText
          textAlign={"center"}
          fontSize={11}
          fontFamily={"Poppins"}
          color="#6c757d"
        >
          Once your data tap is created, upon clicking the Authenticate button
          below, you will be redirected to Fivetran to authenticate, then back
          to Datajolt and your data tap will be ready for use.
          <Typography
            display={"block"}
            mt={3}
            fontWeight={"bold"}
            fontSize={11}
            fontFamily={"Poppins"}
          >
            Name your tap to identify it easily in Datajolt
          </Typography>
        </DialogContentText>

        <Stack direction={"row"} mt={2}>
          <ModalLayoutInput
            type="text"
            onChange={changeTapAlias}
            placeholder="Your tap alias"
            disabled={savedAlias}
            aria-describedby="basic-addon2"
            style={{
              margin: 0,
            }}
            inputGroup
          />
          <ModalLayoutBtn
            color="primary"
            disabled={!tapAlias || savedAlias}
            onClick={() => getTapDetails(false)}
            style={{
              margin: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            Save{savedAlias ? "d" : ""}
            {savedAlias && <Check size={16} />}
          </ModalLayoutBtn>
        </Stack>

        {newSchema && (
          <>
            <Typography
              display={"block"}
              mt={3}
              fontWeight={"bold"}
              fontSize={11}
              fontFamily={"Poppins"}
            >
              An integration is already replicating data into the default schema
              name (datajolt_{fivetranName})
            </Typography>
            <Stack mb={0.5} direction={"row"}>
              <ModalLayoutInput
                type="text"
                onChange={setNewSchema}
                placeholder="Provide a different schema name and click Save"
                disabled={schemaSaved}
                aria-describedby="basic-addon2"
              />

              <ModalLayoutBtn
                color="primary"
                disabled={!schema || schemaSaved}
                onClick={() => getTapDetails(true)}
              >
                Save{schemaSaved ? "d" : ""}
                {schemaSaved && <Check size={16} />}
              </ModalLayoutBtn>
            </Stack>
          </>
        )}
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn
          href={embeddedUrl}
          target="_blank"
          rel="noopener noreferrer"
          color="success"
          disabled={!embeddedUrl}
        >
          {schemaOverwritten ? (
            <CircularProgress size={16} color="#fff" />
          ) : (
            <>
              <LogIn size={16} /> Authenticate
            </>
          )}
        </ModalLayoutBtn>
        <Typography fontSize={11} fontFamily={"Poppins"} color="#6c757d">
          This will open in a new window
        </Typography>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default FivetranAuthModal;
