import React, { useState } from "react";
import {
  MenuItem,
  Button,
  Tooltip,
  circularProgressClasses,
  MenuList,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
} from "@mui/material";
import { MoreVertical } from "react-feather";

/**
 *
 * @param {{items: {disabled: boolean; onClick: () => void; className?: string; variant: string; tooltipTitle: string; children: JSX.Element}[]}} items
 * @param {JSX.Element} children
 */
const HorizontalDropdownMenu = ({ items, children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="horizontal-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertical color="#4E4F61" />
      </Button>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{
          zIndex: 1,
        }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "left top",
            }}
          >
            <Paper
              sx={{
                "--Paper-shadow":
                  "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12) !important",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="horizontal-menu"
                  sx={{
                    padding: "17px 12px",
                    gap: "10px",
                    display: "flex",
                    "& .MuiPaper-root": { overflow: "visible" },
                  }}
                >
                  {children}
                  {items.map((item) => (
                    <MenuItem sx={{ padding: 0 }} key={item.tooltipTitle}>
                      <Tooltip title={item.tooltipTitle} placement="bottom">
                        <Button
                          size="sm"
                          variant={item.variant}
                          disabled={item.disabled}
                          onClick={(e) => {
                            item.onClick(e);
                            setAnchorEl(null);
                          }}
                          className={item.className || ""}
                          sx={{
                            minWidth: 0,
                            padding: 0,
                            borderRadius: "50%",
                            width: "26px",
                            height: "26px",
                            backgroundColor: "#f3f3f3",
                            transition: "all ease",
                            border: item.tooltipTitle === "Unpause" && "1px solid #6c757d",

                            "&:disabled": {
                              cursor: "not-allowed !important",
                              opacity: 0.2,
                            },

                            "&:hover": {
                              backgroundColor: "#2c49fc",
                              color: "#f3f3f3",

                              "& svg, & svg *": {
                                fill: "#f3f3f3",
                              },
                            },

                            [`& svg:not(.${circularProgressClasses.svg})`]: {
                              width: "12px",
                              height: "12px",
                              fill: "#2c49fc",
                              transition: "all ease",

                              "&.bigger-svg": {
                                width: "17px",
                                height: "17px",
                              },
                            },

                            "& .spinner-border-sm": {
                              width: "22px",
                              height: "13px",
                              borderWidth: "0.2em",
                            },
                          }}
                        >
                          {item.children}
                        </Button>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default HorizontalDropdownMenu;
