import React, { Component } from "react";
import { Button, Col, Card, Row } from "react-bootstrap";
import { RefreshCw, Database, Clock, UploadCloud, CheckCircle } from "react-feather";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import CreateDataWarehouses from "../../dwh/CreateDWH";
import { CSSTransition } from "react-transition-group";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getDailyStatsService, getMetricsService, getRowJoltedDataService } from "../../../services/dashboard";
import { CircularProgress } from "@mui/material"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

Sentry.init({
  dsn: "https://53a82b56123b43348a6579efda968a77@o4504469493645312.ingest.sentry.io/4504469494497280",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.2,
});

export default class Statistics extends Component {
  constructor(props) {
    super(props);
    // Initialize the state with the last 7 days
    const today = new Date();
    const labels = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      labels.push(date.toISOString().split('T')[0]);
    }

    this.state = {
      metrics: false,
      isDWHCreatorShown: false,
      dailyStats: {
        labels: labels, // Last 7 days labels
        datasets: [
          {
            label: "DAG Success Runs",
            data: Array(7).fill(0), // 0s for success runs
            backgroundColor: "rgba(75, 192, 192, 0.6)",
          },
          {
            label: "DAG Failed Runs",
            data: Array(7).fill(0), // 0s for failed runs
            backgroundColor: "rgba(255, 99, 132, 0.6)",
          },
        ],
      },
      emptyChartData: {
        labels: labels, // Last 7 days labels
        datasets: [
          {
            label: "Rows Replicated",
            data: Array(7).fill(0), // No data
            backgroundColor: "rgba(169, 169, 169, 0.6)", // Dark grey color
          },
        ],
      },
    };
  }
  
  componentDidMount() {
    this.setState({ progress: 70 });
    this.getMetrics();
    this.getDailyStats(7);
    this.getRowJoltedData(7);
  }

  toggleDWHCreator = () =>
    this.setState({ isDWHCreatorShown: !this.state.isDWHCreatorShown });

  getMetrics = async () => {
    const response = await getMetricsService();

    if(response) {
      this.setState({ metrics: response.data });
    }
  };

  getDailyStats = async (timeframe) => {
    const response = await getDailyStatsService(timeframe);

    if(response) {
      const stats = response.data;
      const labels = stats.map((stat) => stat.date);
      const successData = stats.map((stat) => stat.success);
      const failData = stats.map((stat) => stat.failed);

      this.setState({
        dailyStats: {
          labels: labels,
          datasets: [
            {
              label: "DAG Success Runs",
              data: successData,
              backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
            {
              label: "DAG Failed Runs",
              data: failData,
              backgroundColor: "rgba(255, 99, 132, 0.6)",
            },
          ],
        },
      });
    }
  };

  getRowJoltedData = async (timeframe) => {
    const response = await getRowJoltedDataService(timeframe);

    if(response) {
      const data = response.data;
      const labels = data.map((item) => item.date);
      const rowJoltedData = data.map((item) => item.count);

      this.setState({
        lineChartData: {
          labels: labels,
          datasets: [
            {
              label: "Row Jolted",
              data: rowJoltedData,
              borderColor: "rgba(75, 192, 192, 1)",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
            },
          ],
        },
      });
    }
  };

  render() {
    const { dailyStats, emptyChartData } = this.state;

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };

    return (
      <>
        <Row style={{ marginLeft: "5px" }}>
          <Col md="6" xl className="d-flex">
            <Card className="flex-fill">
              <Card.Body className="py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h3 className="mb-2">
                      {this.state.metrics ? (
                        this.state.metrics.dwh
                      ) : (
                        <CircularProgress size={16} color="#fff" />
                      )}
                    </h3>
                    <p className="mb-2">Connected DWH(s)</p>
                  </div>
                  <div className="d-inline-block ms-3">
                    <div className="stat">
                      <Database className="align-middle text-success" />
                    </div>
                  </div>
                </div>
                <hr />
                <Button
                  variant="generaltabs"
                  disabled={true}
                  size="sm-pad"
                  className="active"
                  onClick={this.toggleDWHCreator}
                >
                  DWH  set up correctly <CheckCircle size={16} className="align-middle text-success ms-2" />
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl className="d-flex">
            <Card className="flex-fill">
              <Card.Body className="py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h3 className="mb-2">
                      {this.state.metrics ? (
                        this.state.metrics["taps"]
                      ) : (
                        <CircularProgress size={16} color="#fff" />
                      )}
                    </h3>
                    <p className="mb-2">Data Taps</p>
                  </div>
                  <div className="d-inline-block ms-3">
                    <div className="stat">
                      <RefreshCw className="align-middle text-success" />
                    </div>
                  </div>
                </div>
                <hr />
                <Link to={"/taps/mytaps"}>
                  <Button
                    variant="generaltabs"
                    size="sm-pad"
                    className="active"
                  >
                    Create a Data Tap
                  </Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl className="d-flex">
            <Card className="flex-fill">
              <Card.Body className="py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h3 className="mb-2">
                      {this.state.metrics ? (
                        this.state.metrics["dags"]
                      ) : (
                        <CircularProgress size={16} color="#fff" />
                      )}
                    </h3>
                    <p className="mb-2">DAGs Scheduled</p>
                  </div>
                  <div className="d-inline-block ms-3">
                    <div className="stat">
                      <Clock className="align-middle text-success" />
                    </div>
                  </div>
                </div>
                <hr />
                <Link to={"/sql/editor"}>
                  <Button
                    variant="generaltabs"
                    size="sm-pad"
                    className="active"
                  >
                    Create a DAG
                  </Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl className="d-flex">
            <Card className="flex-fill">
              <Card.Body className="py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h3 className="mb-2">
                      {this.state.metrics ? (
                        this.state.metrics["pushes"]
                      ) : (
                        <CircularProgress size={16} color="#fff" />
                      )}
                    </h3>
                    <p className="mb-2">Data Pushes</p>
                  </div>
                  <div className="d-inline-block ms-3">
                    <div className="stat">
                      <UploadCloud className="align-middle text-success" />
                    </div>
                  </div>
                </div>
                <hr />
                <Link to={"/jobs/push"}>
                  <Button
                    variant="generaltabs"
                    size="sm-pad"
                    className="active"
                  >
                    Create a Push
                  </Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row style={{ marginLeft: "5px" }}>
          <Col md="6" xl className="d-flex">
            <Card className="flex-fill">
              <Card.Body className="py-4" style={{ height: '400px' }}>
                <Bar data={dailyStats} options={options} style={{ width: '100%', height: '100%' }} />
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl className="d-flex">
            <Card className="flex-fill">
              <Card.Body className="py-4" style={{ height: '400px', position: 'relative' }}>
                <div style={{ width: '100%', height: '100%' }}>
                  <Bar data={emptyChartData} options={options} />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '2em',
                      color: '#888',
                      fontWeight: 'bold',
                      zIndex: 10,
                    }}
                  >
                    Coming Soon...
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <CSSTransition
          in={this.state.isDWHCreatorShown}
          classNames="form-tab"
          timeout={300}
          unmountOnExit
        >
          <CreateDataWarehouses toggleDWHCreator={this.toggleDWHCreator} />
        </CSSTransition>
      </>
    );
  }
}
