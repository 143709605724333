import React from "react";
import { useRoutes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/index.css";
import routes from "./routes";
import { AuthProvider } from "./contexts/JWTContext";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./pages/error";

const App = () => {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s" defaultTitle="Datajolt" />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
            <ToastContainer
              position="top-right"
              closeOnClick
              pauseOnHover
              pauseOnFocusLoss
              style={{ height: "50px", minHeight: "50px" }}
            />
            <ErrorBoundary fallback={<ErrorFallback />}>
              {content}
            </ErrorBoundary>
          </AuthProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
