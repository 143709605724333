import React from "react";
import { DialogContent } from "@mui/material";

/**
 * @param {{children: JSX.Element}} data
 */
const ModalLayoutContent = ({ children }) => {
  return (
    <DialogContent sx={{ margin: 1, padding: 2, textAlign: "center" }}>
      {children}
    </DialogContent>
  );
};

export default ModalLayoutContent;
