import { toast } from "react-toastify";
import axios from "../utils/axios";
import { config } from "../constants";

export const fetchLastRuns = (
  datajolt_task_id,
  page_limit,
  updateState,
  isComponentMounted,
  task_name
) => {
  // Add validation to ensure we have a valid datajolt_task_id
  if (!datajolt_task_id) {
    console.warn("No datajolt_task_id provided to fetchLastRuns");
    updateState((prevState) => ({
      historical_runs_available: false,
      fetching_last_runs: false,
      progress: 100,
    }));
    return;
  }

  // Only include task_name in payload if it's explicitly provided
  const payload = {
    page_limit: page_limit,
    datajolt_task_id: datajolt_task_id,
    ...(task_name && { task_name }) // Only add task_name if it exists and is not null
  };
  
  axios
    .post(config.url.METADATA + "/api/get_historical_runs", payload)
    .then((response) => {
      if (response.data["runs"].length) {
        if (page_limit > 1) {
          updateState((prevState) => ({
            historical_runs_available: true,
            historical_dag_runs: {
              ...prevState.historical_dag_runs,
              [datajolt_task_id]: response.data["runs"],
            },
          }));
        } else {
          if (
            response?.data?.["runs"]?.[0]?.state === "running" ||
            response?.data?.["runs"]?.[0]?.state === "queued"
          ) {
            console.log(
              datajolt_task_id + " is still running, pinging again..."
            );
            setTimeout(() => {
              if (isComponentMounted()) {
                fetchLastRuns(
                  datajolt_task_id,
                  1,
                  updateState,
                  isComponentMounted,
                  task_name
                );
              }
            }, 5000);
          } else {
            console.log(datajolt_task_id + " is finished. Updating its status");

            // Update the state
            updateState((prevState) => {
              const updatedMytasks = prevState.mytasks.map((dag) => {
                if (dag.datajolt_task_id === datajolt_task_id) {
                  return {
                    ...dag,
                    status: response?.data?.["runs"]?.[0]?.state,
                    last_started: response?.data?.["runs"]?.[0]?.last_started,
                    last_ended: response?.data?.["runs"]?.[0]?.last_ended,
                    rows: response?.data?.["runs"]?.[0]?.rows,
                  };
                }
                return dag;
              });
              return { mytasks: updatedMytasks };
            });
          }
        }
      }
    })
    .catch((err) => {
      toast.error("Error while fetching job history.");
      updateState((prevState) => ({
        historical_runs_available: false,
      }));
    })
    .finally(() => {
      updateState((prevState) => ({
        fetching_last_runs: false,
        progress: 100,
      }));
    });
};
