import React from "react";
import { CircularProgress, DialogContentText, Typography } from "@mui/material";
import { FilePlus } from "react-feather";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";
import ModalLayoutInput from "./Layout/Input";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  onAction: () => void;
 *  activeBranch: string;
 *  createFileLog: string;
 *  filename: string;
 *  newFilename: () => void;
 *  creating: boolean;
 * }} data
 */
const CreateFileModal = ({
  isShown,
  onHide,
  onAction,
  activeBranch,
  createFileLog,
  filename,
  newFilename,
  creating,
}) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>
        Create a new file in: &nbsp;
        <Typography
          component={"span"}
          position={"relative"}
          fontSize={9}
          paddingX={1}
          paddingY={"1px"}
          fontWeight={400}
          lineHeight={1.5}
          color="#fff"
          bgcolor={"grey"}
          borderRadius={"50px"}
        >
          {activeBranch}
        </Typography>
      </ModalLayoutHeader>
      <ModalLayoutContent>
        <img src="/images/newfile.jpg" width="15%" alt="" />
        <ModalLayoutInput
          type="text"
          aria-label="Text input with dropdown button"
          placeholder="Your new file"
          onChange={newFilename}
        />
        <DialogContentText
          color="red"
          fontWeight={700}
          fontFamily={"Poppins"}
          textAlign={"center"}
          fontSize={11}
        >
          {createFileLog}
        </DialogContentText>
        <br />
        {filename === "models/"
          ? "Your SQL files have to be stored in a subfolder inside the models folder."
          : ""}
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn color="primary" disabled={creating} onClick={onAction}>
          <FilePlus size={16} />
          &nbsp;
          {creating ? (
            <>
              Creating File...&nbsp;&nbsp;
              <CircularProgress size={16} color="#fff" />
            </>
          ) : (
            "Create File"
          )}
        </ModalLayoutBtn>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default CreateFileModal;
