import React from "react";
import { Button, buttonClasses } from "@mui/material";

/**
 *
 * @param {{
 *  children: JSX.Element;
 *  disabled?: boolean;
 *  onClick: () => void;
 *  color?: "inherit" | "error" | "warning" | "info" | "success" | "secondary" | "primary"
 * }}
 */
const ModalLayoutBtn = ({
  children,
  onClick,
  color,
  disabled = false,
  ...props
}) => {
  return (
    <Button
      variant="contained"
      color={color}
      disabled={disabled}
      onClick={onClick}
      sx={{
        gap: 0.5,
        marginY: 0.5,
        paddingY: 0.5,
        paddingX: 1.5,
        fontSize: 14,
        fontFamily: "Poppins",
        textTransform: "none",
        fontWeight: 400,
        boxShadow: "none",
        bgcolor:
          color === "secondary"
            ? "#495057"
            : color === "success"
            ? "#4BBF73"
            : undefined,

        [`&.${buttonClasses.disabled}`]: {
          color: "var(--variant-containedColor)",
          backgroundColor: "var(--variant-containedBg)",
          opacity: 0.65
        }
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ModalLayoutBtn;
