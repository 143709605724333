import React from "react";
import { Typography } from "@mui/material";
import { Save, X } from "react-feather";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  discardFiles: () => void;
 *  saveFiles: () => void;
 *  activeBranch: string;
 *  unsavedFiles: Record<string, any>
 * }} data
 */
const UnsavedFilesModal = ({
  isShown,
  onHide,
  discardFiles,
  saveFiles,
  activeBranch,
  unsavedFiles,
}) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>
        <Typography
          component={"span"}
          fontSize={11}
          fontFamily={"Poppins"}
          fontWeight={700}
        >
          Unsaved Files in branch {activeBranch}
        </Typography>
      </ModalLayoutHeader>
      <ModalLayoutContent>
        {Object.keys(unsavedFiles).some((file) =>
          file.startsWith("untitled-")
        ) ? (
          <>
            There are untitled files with unsaved changes:
            <ul>
              {Object.keys(unsavedFiles)
                .filter((file) => file.startsWith("untitled-"))
                .map((file) => (
                  <React.Fragment key={file}>
                    - <b>{file}</b>
                    <br />
                  </React.Fragment>
                ))}
            </ul>
          </>
        ) : (
          <>
            You have unsaved files. <br />
            Do you want to save them before switching branches?
            <ul>
              {Object.keys(unsavedFiles).map(
                (file) =>
                  file !== "+" && (
                    <React.Fragment key={file}>
                      - <b>{file}</b>
                      <br />
                    </React.Fragment>
                  )
              )}
            </ul>
          </>
        )}
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn color="error" onClick={discardFiles}>
          <X size={16} /> No, discard all
        </ModalLayoutBtn>

        {Object.keys(unsavedFiles).some((file) =>
          file.startsWith("untitled-")
        ) ? (
          <ModalLayoutBtn color="primary" onClick={onHide}>
            <Save size={16} /> Go back to save them
          </ModalLayoutBtn>
        ) : (
          <ModalLayoutBtn color="success" onClick={saveFiles}>
            <Save size={16} /> Yes, save them
          </ModalLayoutBtn>
        )}
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default UnsavedFilesModal;
