import React from "react";
import {
  DialogTitle,
  DialogContentText,
  Typography,
  Stack,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Trash2, X } from "react-feather";
import ModalLayout from "./Layout";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";

/**
 * @param {{
 *  isShown: boolean,
 *  onHide: () => void,
 *  onAction: () => void,
 *  deleteData: {
 *    logo: string;
 *    deleting: boolean;
 *  },
 *  text: {
 *    bold: string;
 *    regular: string;
 *    regularFirst?: boolean;
 *  },
 *  deleteBtnText?: string;
 * }}} data
 */
const DeleteConnectorModal = ({
  isShown,
  onHide,
  onAction,
  deleteData,
  text,
  deleteBtnText,
}) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        position={"relative"}
        padding={2}
        borderBottom={"1px solid #dee6ed"}
      >
        <DialogTitle
          padding={"0 !important"}
          color="#495057"
          fontSize={11}
          fontWeight={500}
          fontFamily={"Poppins"}
        >
          Warning!
        </DialogTitle>
        {deleteData.logo && (
          <img
            src={deleteData.logo}
            alt=""
            width="8%"
            style={{
              position: "absolute",
              right: "48px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
        )}
        <IconButton
          aria-label="close"
          onClick={onHide}
          sx={{
            position: "absolute",
            right: 16,
            top: 10,
            color: "#495057",
          }}
        >
          <X size={16} />
        </IconButton>
      </Stack>
      <ModalLayoutContent>
        <DialogContentText
          textAlign={"center"}
          fontSize={11}
          fontFamily={"Poppins"}
          color="#6c757d"
        >
          You are about to delete your {text.regularFirst && text.regular}{" "}
          <Typography component={"span"} fontSize={11} fontWeight={700}>
            {text.bold}
          </Typography>{" "}
          {!text.regularFirst && text.regular}
          <Typography
            component={"span"}
            fontWeight={700}
            display={"block"}
            fontSize={11}
            mt={2}
          >
            Are you sure ?
          </Typography>
        </DialogContentText>
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn color="secondary" onClick={onHide}>
          <X size={16} /> No
        </ModalLayoutBtn>{" "}
        <ModalLayoutBtn
          color="error"
          disabled={deleteData.deleting}
          onClick={onAction}
        >
          <Trash2 size={16} />
          {deleteData.deleting ? (
            <>
              Deleting... <CircularProgress size={16} color="#fff" />
            </>
          ) : (
            deleteBtnText || "Delete"
          )}
        </ModalLayoutBtn>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default DeleteConnectorModal;
