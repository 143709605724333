import React from "react";
import { DialogContentText, Typography } from "@mui/material";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  onAction: () => void;
 * }} data
 */
const EditingNotAllowedModal = ({ isShown, onHide, onAction }) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>
        <Typography
          component={"span"}
          fontSize={11}
          fontFamily={"Poppins"}
          fontWeight={700}
        >
          Warning!
        </Typography>
      </ModalLayoutHeader>
      <ModalLayoutContent>
        <DialogContentText
          textAlign={"center"}
          fontSize={11}
          fontFamily={"Poppins"}
          color="#6c757d"
        >
          Editing a file / folder in the main branch is not allowed. Please
          switch to a different branch to make changes.
        </DialogContentText>
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn color="primary" onClick={onAction}>
          OK
        </ModalLayoutBtn>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default EditingNotAllowedModal;
