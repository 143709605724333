import React from "react";
import { DialogContentText, Typography } from "@mui/material";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";
import { Trash2, X } from "react-feather";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  onAction: () => void;
 *  isDeleting: boolean;
 *  sourceAlias: string;
 *  taps: string;
 *  isPush?: boolean;
 * }} data
 */
const DeleteSourceModal = ({
  isShown,
  onHide,
  onAction,
  isDeleting,
  sourceAlias,
  taps,
  isPush = false,
}) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>
        Confirm {isPush ? "destination" : "source"} deletion
      </ModalLayoutHeader>
      <ModalLayoutContent>
        <DialogContentText
          textAlign={"center"}
          fontSize={11}
          fontFamily={"Poppins"}
          color="#6c757d"
        >
          The following {isPush ? "pushes" : "taps"} will be impacted:
          <br />
          {taps?.split(",").map((tap, index) => (
            <Typography
              component={"span"}
              fontSize={11}
              fontFamily={"Poppins"}
              fontWeight={700}
              display={"block"}
              key={index}
            >
              -{tap}
            </Typography>
          ))}
          <br />
          Are you sure you want to delete {isPush ? "destination" : "source"}{" "}
          <Typography
            component={"span"}
            fontSize={11}
            fontFamily={"Poppins"}
            fontWeight={700}
          >
            {sourceAlias}
          </Typography>
          ?
          <br />
          <br />
        </DialogContentText>
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn color="secondary" onClick={onHide}>
          <X size={14} /> Cancel
        </ModalLayoutBtn>
        <ModalLayoutBtn color="error" disabled={isDeleting} onClick={onAction}>
          <Trash2 size={14} className="me-2" />
          Delete {isPush ? "destination" : "source"}
        </ModalLayoutBtn>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default DeleteSourceModal;
