import { config } from "../constants";
import axios from "../utils/axios";
import { toast } from "react-toastify";

export const createFivetranTapService = async (
  connector_name,
  tap_alias,
  connector_id,
  schema
) => {
  const toastID = toast.loading(
    `Importing your new ${connector_name} tap from Fivetran...`
  );
  let isSuccess = false;

  try {
    await axios.post(`${config.url.TAPS_URL}/api/fivetran/create_tap`, {
      tap_alias: tap_alias,
      connector_id: connector_id,
      connector_name: connector_name,
      schema: schema,
    });
    toast.success(`Your ${connector_name} tap is now live! 🎉`, {
      autoClose: 5000,
    });
    isSuccess = true;
  } catch (_) {
    toast.error("There was an internal issue creating your data tap.", {
      autoClose: 5000,
    });
  }
  toast.dismiss(toastID);
  return isSuccess;
};

export const getGACustomReportsService = async (sourceID, dag_name) => {
  const toastID = toast.loading("Retrieving custom reports...");
  let response;

  try {
    response = await axios.post(
      `${config.url.TAPS_URL}/api/airbyte/get_existing_specs`,
      {
        sourceID,
        dag_name,
      }
    );

    const specs = response.data?.specs;
    const window_in_days = specs?.connectionConfiguration?.window_in_days;
    const custom_reports = specs?.connectionConfiguration?.custom_reports;

    return {
      specs: response.data?.specs,
      window_in_days:
        window_in_days && JSON.parse(window_in_days) ? window_in_days : 1,
      custom_reports:
        custom_reports && JSON.parse(custom_reports) ? custom_reports : [],
    };
  } catch (_) {
    toast.error("Unable to retrieve custom reports.", { autoClose: 5000 });
  }

  toast.dismiss(toastID);
  return response;
};

export const getMyTapsService = async () => {
  try {
    const response = await axios.post(
      `${config.url.AIRFLOW}/api/airflow/list_tasks_from_pg`,
      {
        dag_type: "Taps",
      }
    );

    return response;
  } catch (err) {
    toast.error("There was an issue listing your data taps.", {
      autoClose: 5000,
    });
  }
};

export const triggerAirbyteTapService = async (
  dag_id,
  connection_id,
  datajolt_task_id
) => {
  const toastID = toast.loading(`Triggering tap ${dag_id}...`);
  let isSuccess = false;

  try {
    await axios.post(`${config.url.TAPS_URL}/api/airbyte/trigger_job`, {
      run_type: "Triggered",
      connection_id,
      datajolt_task_id,
    });
    toast.success("Job triggered successfully.", { autoClose: 5000 });
    isSuccess = true;
  } catch (_) {
    toast.error("Error triggering your job.", { autoClose: 5000 });
  }
  toast.dismiss(toastID);
  return isSuccess;
};

export const deleteConnectorService = async (datajolt_task_id) => {
  const toastID = toast.loading("Deleting your tap...");
  let isSuccess = false;

  try {
    await axios.post(`${config.url.TAPS_URL}/api/fivetran/delete_connector`, {
      datajolt_task_id,
    });

    toast.success("It will take a few seconds to be reflected in Datajolt.", {
      autoClose: 5000,
    });
    toast.success("Tap successfully deleted", { autoClose: 5000 });
    isSuccess = true;
  } catch (_) {
    toast.error("There was an issue deleting your tap.");
  }
  toast.dismiss(toastID);
  return isSuccess;
};

export const deleteDagService = async (alias, datajolt_task_id) => {
  const toastId = toast.loading(`Deleting ${alias}...`);
  let isSuccess = false;

  try {
    await axios.post(`${config.url.TAPS_URL}/api/taps/delete_tap`, {
      tap_id: datajolt_task_id,
      tap_alias: alias,
    });
    toast.success(`${alias} deleted successfully.`, { autoClose: 5000 });
    isSuccess = true;
  } catch (_) {
    toast.error(`Could not delete your tap ${alias}.`, {
      autoClose: 5000,
    });
  }
  toast.dismiss(toastId);
  return isSuccess;
};

export const triggerFivetranService = async (alias, datajolt_task_id) => {
  const toastID = toast.loading(`Triggering ${alias}...`);
  let isSuccess = false;

  try {
    await axios.post(`${config.url.TAPS_URL}/api/fivetran/trigger_connector`, {
      datajolt_task_id: datajolt_task_id,
    });
    toast.success("Your data tap has been triggered !", {
      autoClose: 5000,
    });
    isSuccess = true;
  } catch (_) {
    toast.error("There was an issue triggering your Fivetran tap.", {
      autoClose: 5000,
    });
  }
  toast.dismiss(toastID);
  return isSuccess;
};

export const discoverSchemaService = async (
  selectedTabObj,
  selectedTabText
) => {
  const toastID = toast.loading(
    `Retrieving data structure from your ${selectedTabText} tap...`
  );
  let response = undefined;

  try {
    response = await axios.post(
      `${config.url.TAPS_URL}/api/airbyte/discover_schema`,
      selectedTabObj
    );
  } catch (_) {
    toast.error("There was an issue getting metadata from your data source...");
  }
  toast.dismiss(toastID);
  return response;
};

export const saveCustomReportsService = async (specs, dag_name) => {
  const toastID = toast.loading("Updating custom reports..");
  let isSuccess = false;

  try {
    await axios.post(
      `${config.url.TAPS_URL}/api/airbyte/update_custom_report`,
      {
        specs,
        dag_name,
      }
    );
    isSuccess = true;
  } catch (_) {
    toast.error("Unable to update custom reports.", { autoClose: 5000 });
  }
  toast.dismiss(toastID);
  return isSuccess;
};

export const stopTaskService = async (display_task_id, datajolt_task_id) => {
  const toastId = toast.loading(
    `Stopping ${display_task_id.substring(display_task_id.indexOf("_") + 1)}...`
  );
  let isSuccess = false;

  try {
    await axios.post(`${config.url.WEBAPP}/api/datajolt/stop_task`, {
      datajolt_task_id: datajolt_task_id,
    });
    toast.success(`${display_task_id} stopped successfully.`, {
      autoClose: 5000,
    });
    isSuccess = true;
  } catch (_) {
    toast.error(`Could not stop your task ${display_task_id}.`, {
      autoClose: 5000,
    });
  }
  toast.dismiss(toastId);
  return isSuccess;
};

export const handleDeleteService = async (source_id, source_alias) => {
  try {
    await axios.post(`${config.url.TAPS_URL}/api/taps/delete_source`, {
      source_id,
    });
    toast.success(`Your source ${source_alias} was deleted successfully.`, {
      autoClose: 5000,
    });
    return true;
  } catch (error) {}
};

export const testSourceService = async (data) => {
  const toastID = toast.loading(
    `Testing connection to  ${data.source_alias} (${data.selected_tap}) data source...`
  );
  let response;

  try {
    response = await axios.post(
      `${config.url.TAPS_URL}/api/airbyte/create_source`,
      data
    );

    toast.success(
      `Your ${data.selected_tap} data source was created successfully. 🎉`,
      { autoClose: 5000 }
    );
  } catch (err) {
    if (err?.error) {
      toast.error(err.error, { autoClose: 15000 });
    } else {
      toast.error(`Unable to connect to ${data.selected_tap}.`, {
        autoClose: 5000,
      });
    }
  }
  toast.dismiss(toastID);
  return response;
};

export const getTapDetailsService = async (SelectedTap, tap) => {
  const toastID = toast.loading("Retrieving data source requirements...");
  let response;

  try {
    response = await axios.post(
      `${config.url.TAPS_URL}/api/airbyte/get_specs`,
      SelectedTap
    );
  } catch (_) {
    toast.error(`There was an issue loading ${tap} requirements.`, {
      autoClose: 5000,
    });
  }
  toast.dismiss(toastID);
  return response;
};

export const getTapDetails2Service = async (data, tap) => {
  var toastID = toast.loading("Creating your data tap...");
  let response;

  try {
    response = await axios.post(
      `${config.url.TAPS_URL}/api/fivetran/create_connector`,
      data
    );

    if (response.data["message"] === "provide schema") {
      toast.info("Please provide a different schema name.");
    }
  } catch (_) {
    toast.error(
      `There was an issue loading ${tap} requirements from Fivetran.`,
      { autoClose: 5000 }
    );
  }
  toast.dismiss(toastID);
  return response;
};

export const scheduleTapService = async (SelectedTap) => {
  const toastID = toast.loading(
    `Saving your ${SelectedTap.tap_alias} data tap...`
  );
  let isSuccess = false;

  try {
    await axios.post(
      `${config.url.TAPS_URL}/api/airbyte/create_job`,
      SelectedTap
    );
    toast.success("Your tap is now ready to go ! 🎉", {
      autoClose: 5000,
    });
    isSuccess = true;
  } catch (_) {
    toast.error(
      "There was an internal issue scheduling your data tap. Please contact datajolt on slack.",
      { autoClose: 5000 }
    );
  }
  toast.dismiss(toastID);
  return isSuccess;
};

export const updateCatalogService = async (SelectedTap) => {
  const toastID = toast.loading("Updating your data selection...");
  let isSuccess = false;

  try {
    await axios.post(
      `${config.url.TAPS_URL}/api/airbyte/update_catalog`,
      SelectedTap
    );

    toast.success("Your data selection has been updated.", {
      autoClose: 5000,
    });
    isSuccess = true;
  } catch (_) {
    toast.error(
      "There was an internal issue updating your data selection. Please contact datajolt on slack.",
      { autoClose: 5000 }
    );
  }
  toast.dismiss(toastID);
  return isSuccess;
};

export const getCatalogService = async (tap, SelectedTap) => {
  const toastID = toast.loading(
    `Retrieving data structure from your ${tap} tap...`
  );
  let response;

  try {
    response = await axios.post(
      `${config.url.TAPS_URL}/api/airbyte/get_catalog`,
      SelectedTap
    );
  } catch (_) {
    toast.error("There was an issue listing your source metadata.");
  }
  toast.dismiss(toastID);
  return response;
};

export const resetDataService = async (connectionId) => {
  const toastID = toast.loading("Queuing up a full reset of your tap...");
  let isSuccess = false;

  try {
    await axios.post(`${config.url.TAPS_URL}/api/airbyte/reset`, {
      connectionId,
    });
    isSuccess = true;
  } catch (_) {
    toast.error("There was an issue triggering your tap reset.");
  }
  toast.dismiss(toastID);
  return isSuccess;
};

export const listConnectorsService = async (type) => {
  try {
    const response = await axios.post(
      `${config.url.TAPS_URL}/api/taps/list_available_connectors`,
      {
        type,
      }
    );
    return response;
  } catch (_) {
    toast.error("There was an internal issue listing taps.");
  }
};
