import React from "react";
import { DialogContentText, Typography } from "@mui/material";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";
import { X, Save } from "react-feather";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  closeFile: () => void;
 *  saveFile: () => void;
 *  closingFile: string;
 *  activeBranch: string;
 * }} data
 */
const CloseWithoutSavingModal = ({
  isShown,
  onHide,
  closeFile,
  saveFile,
  closingFile,
  activeBranch,
}) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>
        <Typography
          component={"span"}
          fontSize={11}
          fontFamily={"Poppins"}
          fontWeight={700}
        >
          Warning!
        </Typography>
      </ModalLayoutHeader>
      <ModalLayoutContent>
        <DialogContentText
          textAlign={"center"}
          fontSize={11}
          fontFamily={"Poppins"}
          color="#6c757d"
        >
          You may lose work on{" "}
          <Typography
            component={"span"}
            fontSize={11}
            fontFamily={"Poppins"}
            fontWeight={700}
          >
            {closingFile && closingFile.replace("models/", "")}
          </Typography>
          .<br />
          {activeBranch === "main" && (
            <Typography
              component={"span"}
              fontSize={11}
              fontFamily={"Poppins"}
              fontWeight={700}
              color="red"
              display={"block"}
            >
              This model is in Production.{" "}
            </Typography>
          )}
          Do you want to save changes?
        </DialogContentText>
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn color="error" onClick={closeFile}>
          <X size={16} /> No
        </ModalLayoutBtn>
        <ModalLayoutBtn color="success" onClick={saveFile}>
          <Save size={16} /> Yes
        </ModalLayoutBtn>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default CloseWithoutSavingModal;
