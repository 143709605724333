import { config } from "../constants";
import axios from "../utils/axios";
import { toast } from "react-toastify";

export const getMetricsService = async () => {
  try {
    const response = await axios.get(
      `${config.url.WEBAPP}/api/datajolt/get_metrics`
    );
    return response;
  } catch (_) {
    toast.error("Issue getting metrics.");
  }
};

export const getDailyStatsService = async (timeframe) => {
  try {
    const response = await axios.post(
      `${config.url.WEBAPP}/api/datajolt/get_graph_metrics`,
      { timeframe }
    );

    return response;
  } catch (_) {
    toast.error("Issue getting daily stats.");
  }
};

export const getRowJoltedDataService = async (timeframe) => {
  try {
    const response = await axios.post(
      `${config.url.WEBAPP}/api/datajolt/get_row_jolted`,
      { timeframe }
    );

    return response;
  } catch (_) {
    toast.error("Issue getting row jolted data.");
  }
};

export const testConnectionService = async (
  ConnectionDetails,
  selected_dwh,
  setState
) => {
  var toastId = toast.loading(`Testing your ${selected_dwh} connection...`);

  try {
    await axios.post(`${config.url.API_URL}/api/dwh/test_connection`, ConnectionDetails, {
      onDownloadProgress: (progressEvent) => {
        const api_logs = progressEvent.currentTarget.response;
        if (
          api_logs.slice(0, 5) === "[API]" &&
          api_logs.slice(-4) !== "Done" &&
          !api_logs.includes("[ERROR]")
        ) {
          setState({
            logs: api_logs,
          });
        } else if (api_logs.slice(-4) === "Done") {
          setState({
            progress: 100,
            loading: false,
            color: "success",
          });
          toast.dismiss(toastId);
          toast.success(
            `Congratulations! Your ${selected_dwh} DWH is accessible.\n\nClick Save to add it to Datajolt!`,
            { autoClose: 5000 }
          );
        } else {
          toast.dismiss(toastId);
          toast.error(
            "Unable to connect to your DWH.\n\nPlease check your credentials and try again."
          );
          setState({
            loading: false,
            progress: 100,
          });
        }
      },
    });
  } catch {
    toast.dismiss(toastId);
    toast.error("Issue testing your connection.");
    setState({
      progress: 100,
      loading: false,
      status_message: "error",
      color: "danger",
    });
  }
};

export const saveDWHService = async (ConnectionDetails) => {
  const toastId = toast.loading(
    `Saving your ${ConnectionDetails.dwhtype} connection...`
  );
  let isSuccess = false;

  try {
    axios.post(`${config.url.API_URL}/api/dwh/save_dwh`, ConnectionDetails);
    toast.success("Successfully connected your DWH to Datajolt!", {
      autoClose: 5000,
    });
    isSuccess = true;
  } catch (_) {
    toast.error("There was an internal issue saving your DWH.");
  }
  toast.dismiss(toastId);
  return isSuccess;
};

export const listDWHtapsService = async () => {
  try {
    const response = await axios.post(
      `${config.url.API_URL}/api/taps/list_available_connectors`,
      { type: "dwh" }
    );
    toast.success("Select your DWH type.", { autoClose: 5000 });
    return response;
  } catch (_) {
    toast.error("There was an internal issue listing DWH connectors.");
  }
};
