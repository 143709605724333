import { Stack } from "@mui/material";
import React from "react";

const Main = ({ className, children }) => (
  <Stack
    minHeight={"100vh"}
    width={"calc(100vw - 60px)"}
    className={className || ""}
    sx={{
      transition:
        "margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out, right 0.35s ease-in-out",
    }}
  >
    {children}
  </Stack>
);

export default Main;
