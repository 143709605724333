import React, { useRef, useState, useEffect, useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  accordionSummaryClasses
} from "@mui/material";
import * as Icon from "react-feather";
import { SimpleTreeView, TreeItem, treeItemClasses } from "@mui/x-tree-view";
import { config } from "../../constants";
import { debounce } from "lodash";
import { HeightContext } from "./LeftBoxes";
import { fetchSchemasService } from "../../services/editor";

const box_titles_style = {
  borderColor: "#dee6ed",
  color: "#448593",
  fontSize: "12px",
  fontWeight: "500",
  minHeight: "5px",
  marginTop: "3px",
};

function SchemaBox({ setAutocomplete, setSchemasData, activeTenant }) {
  const editor_height = document.documentElement.clientHeight - 136;
  const schemaREF = useRef(null);
  const [loading_schemas, setLoading_schemas] = useState(true);
  const [isSearchingSchema, setIsSearchingSchema] = useState(null);
  const [schemaTreeExpandedList, setSchemaTreeExpandedList] = useState([]);
  const [schemasSearchKeywords, setSchemasSearchKeywords] = useState("");
  const SchemaSearchRef = useRef(null);
  const [dbtUrl, setDbtUrl] = useState("");
  const [dwh_structure, setDwhStructure] = useState({});
  const {
    height: { schema: currentHeight },
    onResizeBox,
    collapsed: { schema: isSchemaCollapsed },
  } = useContext(HeightContext);
  const hasLoaded = useRef(false);
  const isLoadingRef = useRef(false);

  useEffect(() => {
    if (activeTenant) {
      const getDBTUrl = () => {
        const localUrl = "http://127.0.0.1:5000";
        const sqlEditorUrl = config.url.SQL_EDITOR_URL;
        const dbtUrl =
          sqlEditorUrl === localUrl
            ? localUrl
            : `${sqlEditorUrl}/${activeTenant?.name}-dbt`;
        return dbtUrl;
      };
      const url = getDBTUrl();
      setDbtUrl(url);
    }
  }, [activeTenant]);

  const fetchSchemas = async (forceRefresh = false) => {
    if (isLoadingRef.current) {
      return;
    }
    
    if (hasLoaded.current && !forceRefresh) {
      return;
    }
    
    isLoadingRef.current = true;
    setLoading_schemas(true);
    
    try {
      const response = await fetchSchemasService(dbtUrl);
      console.log("Server response received:", !!response);

      if(response) {
        const dwhStructure = response.data.autocomplete.dwh_structure;
        const autocomplete = response.data["autocomplete"];
        
        setDwhStructure(dwhStructure);
        setSchemasData(Object.keys(dwhStructure));
        setAutocomplete(autocomplete);
        
        try {
          localStorage.setItem('dwh_schemas', JSON.stringify({
            dwhStructure,
            autocomplete,
            timestamp: new Date().getTime()
          }));
        } catch (error) {
          console.error("Error caching schemas in localStorage:", error);
        }
        
        hasLoaded.current = true;
      } else {
        console.log("Empty response from server");
        setDwhStructure({});
        setSchemasData([]);
        setAutocomplete([]);
      }
    } catch (error) {
      console.error("Error fetching schemas:", error);
      setDwhStructure({});
      setSchemasData([]);
      setAutocomplete([]);
    } finally {
      setLoading_schemas(false);
      isLoadingRef.current = false;
    }
  };

  useEffect(() => {    
    if (hasLoaded.current || isLoadingRef.current) {
      return;
    }

    const cachedSchemas = localStorage.getItem('dwh_schemas');
    if (cachedSchemas) {
      try {
        const parsedData = JSON.parse(cachedSchemas);
        setDwhStructure(parsedData.dwhStructure);
        setSchemasData(Object.keys(parsedData.dwhStructure));
        setAutocomplete(parsedData.autocomplete);
        hasLoaded.current = true;
        setLoading_schemas(false);
        return;
      } catch (error) {
        setLoading_schemas(false);
        console.error("Error parsing cached schemas:", error);
      }
    }

    if (dbtUrl) {
      fetchSchemas();
    } else {
      console.log("dbtUrl not available yet, waiting...");
    }
  }, [dbtUrl]);

  useEffect(() => {
    if (loading_schemas && isSearchingSchema) {
      setTimeout(() => {
        setLoading_schemas(false);
      }, 500);
    }
  }, [loading_schemas, isSearchingSchema]);

  useEffect(() => {
    if (isSearchingSchema === false) {
      clearSchemaSearch();
    }

    if (isSearchingSchema && SchemaSearchRef.current) {
      SchemaSearchRef.current.focus();
    }
  }, [isSearchingSchema]);

  const highlightWord = (word, searchTerm) => {
    const regex = new RegExp(`(${searchTerm})`, "gi");
    const parts = word.split(regex);

    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {regex.test(part) ? <mark key={index}>{part}</mark> : part}
      </React.Fragment>
    ));
  };

  const filterSchemas = (schemas, searchTerm) => {
    const filteredObj = {};

    for (let schema in schemas) {
      if (schema.toLowerCase().includes(searchTerm.toLowerCase())) {
        filteredObj[schema] = schemas[schema];
      } else {
        const filteredTables = {};
        for (let table in schemas[schema]) {
          if (table.toLowerCase().includes(searchTerm.toLowerCase())) {
            filteredTables[table] = schemas[schema][table];
          } else {
            const filteredColumns = schemas[schema][table].filter((column) =>
              column.toLowerCase().includes(searchTerm.toLowerCase())
            );
            if (filteredColumns.length > 0) {
              filteredTables[table] = filteredColumns;
            }
          }
        }
        if (Object.keys(filteredTables).length > 0) {
          filteredObj[schema] = filteredTables;
        }
      }
    }
    return filteredObj;
  };

  const updateSchemasList = (e) => {
    setLoading_schemas(true);
    setSchemasSearchKeywords(e.target.value);
    setSchemaTreeExpandedList(
      Object.keys(filterSchemas(dwh_structure, e.target.value)).filter(
        (treeNode) => !treeNode.includes(e.target.value)
      )
    );
  };

  const debouncedHandleChange = debounce(updateSchemasList, 400);

  const clearSchemaSearch = () => {
    setSchemasSearchKeywords("");
    setSchemaTreeExpandedList([]);

    if (SchemaSearchRef.current) {
      SchemaSearchRef.current.value = "";
    }
  };

  const handleRefreshClick = (event) => {
    event.stopPropagation();
    
    if (dbtUrl) {
      fetchSchemas(true);
    }
  };

  return (
    <Accordion
      id="schema-editor-helper-box"
      expanded={!isSchemaCollapsed}
      onChange={() => {
        if (!isSchemaCollapsed) {
          const schemaWrapper = document.getElementById(
            "schema-editor-helper-box"
          );
          if (schemaREF?.current && schemaREF?.current?.style) {
            schemaREF.current.style.height = 0;
            schemaWrapper.scrollTop = 0;
          }
          onResizeBox("schema", -2);
        } else {
          if (schemaREF?.current && schemaREF?.current?.style) {
            schemaREF.current.style.height = "unset";
          }
          onResizeBox("schema", -1);
        }
      }}
      className="schema-list helper-list"
      sx={{
        boxShadow: "none",
        height: !isSchemaCollapsed ? currentHeight : 48,
        ...(isSchemaCollapsed && { overflow: "hidden" }),

        "& .MuiAccordionSummary-root": {
          minHeight: "46px !important",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          display: "flex",
          color: "#448593",
          alignItems: "baseline",
          "&>div": { flexWrap: "wrap" },

          [`& .${accordionSummaryClasses.content}`]: {
            flexWrap: "wrap"
          }
        }}
      >
        <Icon.ChevronRight
          size={18}
          style={{
            marginTop: "3px",
            transform: !isSchemaCollapsed ? "rotate(90deg)" : "unset",
            transition: "all 0.2s ease",
            marginRight: "5px",
          }}
        />
        <span style={box_titles_style}>Schemas</span>

        {!isSchemaCollapsed && (
          <div
            style={{
              marginLeft: "auto",
              color: "rgb(68, 133, 147)",
              display: "flex",
              gap: "5px",
              marginTop: "4px",
            }}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-bottom-helper-box`}>
                  Refresh Schema List
                </Tooltip>
              }
            >
              <Icon.RefreshCw
                size={16}
                role="button"
                onClick={handleRefreshClick}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-bottom-helper-box`}>
                  Search Schema List
                </Tooltip>
              }
            >
              <Icon.Search
                size={16}
                role="button"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!loading_schemas) {
                    setIsSearchingSchema((isSearching) => !isSearching);
                  }
                }}
              />
            </OverlayTrigger>
          </div>
        )}
        {isSearchingSchema && !isSchemaCollapsed && (
          <TextField
            id="search-schemas-names"
            sx={{
              width: "100%",
              margin: "10px 5px 5px 5px",
              bgcolor: "white",
              "& > div": {
                padding: 0,
              },
              "& input": {
                padding: 0.3,
                pl: 0.8,
                color: "#6c757d",
              },
              "& fieldset": { top: 0 },
              "& fieldset legend": { display: "none" },
              "& .MuiInputBase-sizeSmall": {
                padding: "0 !important",
              },
            }}
            inputRef={SchemaSearchRef}
            label={null}
            placeholder="search"
            variant="outlined"
            onChange={debouncedHandleChange}
            onClick={(e) => e.stopPropagation()}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer" }}
                    id="connectors-search"
                  >
                    <Icon.Search size={14} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    onClick={clearSchemaSearch}
                    position="end"
                    sx={{
                      cursor: "pointer",
                      mr: "8px",
                    }}
                    id="clear-connectors-search"
                  >
                    <Icon.X
                      color="#6c757d"
                      size={14}
                      style={{
                        visibility: schemasSearchKeywords
                          ? "visible"
                          : "hidden",
                      }}
                    />
                  </InputAdornment>
                ),
              },
            }}
          />
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{ height: editor_height / 3 - 50, margin: 0, padding: 0 }}
      >
        <div>
          {loading_schemas ? (
            <center>
              <CircularProgress size={16} color="#fff" />
            </center>
          ) : (
            <SimpleTreeView
              ref={schemaREF}
              aria-label="file system navigator"
              expandedItems={schemaTreeExpandedList}
              slots={{
                expandIcon: Icon.Layout,
                collapseIcon: Icon.ArrowDownCircle,
              }}
              sx={{
                [`& .${treeItemClasses.label}`]: {
                  fontSize: 13,
                  fontWeight: 500,
                  color: "#495057",
                },
                [`& .${treeItemClasses.iconContainer}`]: {
                  color: "#495057"
                },
                [`& .${treeItemClasses.content}`]: {
                  padding: "0 8px"
                },
                flexGrow: 1,
                width: "max-content",
                maxWidth: 600,
                overflowY: "auto",
              }}
              onExpandedItemsChange={(_, ids) => setSchemaTreeExpandedList(ids)}
            >
              {dwh_structure &&
                Object.keys(
                  schemasSearchKeywords
                    ? filterSchemas(dwh_structure, schemasSearchKeywords)
                    : dwh_structure
                ).map((schema) => (
                  <TreeItem
                    itemId={schema}
                    label={
                      schemasSearchKeywords
                        ? highlightWord(schema, schemasSearchKeywords)
                        : schema
                    }
                    key={schema}
                  >
                    {Object.keys(
                      schemasSearchKeywords
                        ? filterSchemas(dwh_structure, schemasSearchKeywords)[
                            schema
                          ]
                        : dwh_structure[schema]
                    ).map((table) => (
                      <TreeItem
                        key={table}
                        itemId={schema + "_" + table}
                        label={
                          schemasSearchKeywords
                            ? highlightWord(table, schemasSearchKeywords)
                            : table
                        }
                      >
                        {dwh_structure[schema][table].map((column) => {
                          return (
                            <TreeItem
                              key={column}
                              itemId={schema + "_" + table + "_" + column}
                              label={column}
                            />
                          );
                        })}
                      </TreeItem>
                    ))}
                  </TreeItem>
                ))}
              {dwh_structure &&
                schemasSearchKeywords &&
                Object.keys(filterSchemas(dwh_structure, schemasSearchKeywords))
                  .length === 0 && (
                  <p style={{ margin: "3px 11px" }}>No match for your search</p>
                )}
            </SimpleTreeView>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default React.memo(SchemaBox);
