import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { TableContainer, tableHeadClasses } from "@mui/material";
import { CSSTransition } from "react-transition-group";
import * as Icon from "react-feather";
import moment from "moment-timezone";
import _ from "lodash";
import "./daghistory.css";
import "../../../styles/table.css";
import DatajoltSpinner from "../../../components/global/DatajoltSpinner.js";
import { fetchLastRuns } from "../../functions.js";
import Table from "../../../components/global/Table";
import LogsTable from "../LogsTable.js";
import { 
  getTargetDAGDataService, 
  getTasksService, 
  getTasksAcrossDagsService 
} from "../../../services/tables.js";

var customer_timezone;
(function retryFetch(retryDelay = 4000, maxAttempts = 2, currentAttempt = 1) {
  var localTimezone = localStorage.getItem("timezone");

  if (!localTimezone && currentAttempt <= maxAttempts) {
    console.log(
      `Attempt ${currentAttempt}: Timezone not found, retrying in ${retryDelay}ms...`
    );
    setTimeout(
      () => retryFetch(retryDelay, maxAttempts, currentAttempt + 1),
      retryDelay
    );
  } else if (localTimezone) {
    customer_timezone = localTimezone; // Update the broader scoped variable
    // Proceed with your logic here
  } else {
    console.log("Max attempts reached. Failed to get timezone.");
    // Handle failure case here
  }
})();

class DagHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historyTargetId: this.props.historyModalId || new URLSearchParams(window.location.search).get('history_modal'),
      loading: false,
      historical_runs_available: true,
      DAGs: [],
      running_dags: [],
      loading_metadata: true,
      dag_runs: [],
      last_succesful_runs: {},
      dag_pause_statuses: {},
      historical_dag_runs: [],
      progress: 40,
      fetching_last_runs: false,
      selected_job: "",
      schedule_frequency: false,
      schedule_details: false,
      scheduling: false,
      modal_logs: null,
      schedule_day: false,
      dag_logs: [],
      selected_job_logo: "",
      selected_job_type: "",
      isShowingDagHistoryTable: false,
    };
  }

  componentDidMount() {
    this.setState({ 
      fetching_last_runs: true,
      isShowingDagHistoryTable: true 
    });
    
    window.scrollTo({
      top: 0,
    });
    
    this.getTargetDAGData();
    
    setTimeout(() => {
      document.body.style.overflow = "hidden";
    }, 500);
    
    this.fetchDAGLastRunsTimerMounted = true;
    this.fetchDAGLastRunsTimeout = this.fetchDAGLastRunsTimer();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.historyModalId !== this.props.historyModalId) {
      this.setState({ 
        historyTargetId: this.props.historyModalId 
      }, () => {
        this.getTargetDAGData();
      });
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = "";
    this.fetchDAGLastRunsTimerMounted = false;
    clearTimeout(this.fetchDAGLastRunsTimeout);
  }

  getTargetDAGData = async () => {
    const history_modal = this.state.historyTargetId;

    if (this.props.dagList) {
      const targetDAG = this.props.dagList.find(
        (DAG) => DAG.id === history_modal || DAG.workflowname === history_modal || DAG.dag_id === history_modal || DAG.alias === history_modal
      );

      if (!targetDAG && !this.props.isWorkflowView) {
        console.warn("No matching DAG found for ID:", history_modal);
        this.setState({ 
          historical_runs_available: false,
          fetching_last_runs: false 
        });
        return;
      }

      // For workflow view, use the history_modal directly as the task name
      if (this.props.isWorkflowView) {
        this.setState({
          historyTargetId: history_modal,
          selected_job_type: targetDAG?.type || ''
        });
        this.openDAGHistory(history_modal, targetDAG?.logo);
        return;
      }

      // For taps and dags views
      const displayName = targetDAG?.id || targetDAG?.alias || targetDAG?.workflowname || history_modal;
      const taskId = targetDAG?.datajolt_task_id || targetDAG?.dag_id || history_modal;
      
      this.setState({
        historyTargetId: displayName,
        selected_job_type: targetDAG?.type || '',
        selected_logo: targetDAG?.logo
      });

      this.openDAGHistory(taskId, targetDAG?.logo);
      return;
    }

    this.setState({ 
      DAGs: [], 
      loading: true, 
      fetching_last_runs: true,
    });
    this.setState({ progress: 80 });

    const response = await getTargetDAGDataService();

    if(response) {
      this.setState({
        dag_pause_statuses: response.data["pauses_status"],
        DAGs: response.data["dags_list"],
      });

      const targetDAG = response.data["dags_list"].find(
        (DAG) => DAG.workflowname === history_modal || DAG.dag_id === history_modal || DAG.alias === history_modal
      );

      // Update display name here as well
      const displayName = targetDAG?.alias || targetDAG?.workflowname || history_modal;
      const taskId = this.props.isWorkflowView ? history_modal : targetDAG?.dag_id || history_modal;

      this.setState({
        historyTargetId: displayName
      });

      this.openDAGHistory(taskId, targetDAG?.logo);
    } else {
      this.setState({ historical_runs_available: false });
    }
    
    this.setState({
      progress: 100,
      loading: false,
      fetching_last_runs: false,
    });
  };

  fetchDAGLastRunsTimer = (datajolt_task_id, no, bool, time) => {
    if (datajolt_task_id)
      setTimeout(() => {
        this.fetchDAGLastRunsTimerMounted &&
          fetchLastRuns(datajolt_task_id, bool, this.setState.bind(this));
      }, time);
  };

  openDAGHistory = (datajolt_task_id, logo) => {
    if (!datajolt_task_id) {
      console.warn("No task ID provided to openDAGHistory");
      this.setState({ 
        historical_runs_available: false,
        fetching_last_runs: false,
        progress: 100
      });
      return;
    }

    this.setState({
      selected_job: datajolt_task_id,
      modal_history: true,
      selected_logo: logo,
    });
    
    // Only pass task_name if isWorkflowView prop is true
    const task_name = this.props.isWorkflowView ? this.state.historyTargetId : null;
        
    fetchLastRuns(
      datajolt_task_id, 
      50, 
      this.setState.bind(this), 
      () => this.fetchDAGLastRunsTimerMounted,
      task_name
    );
  };

  gettasks = async (dag_state, dag_id, dag_run_id, started_at) => {
    if (this.state.modal_logs === dag_run_id) {
      this.setState({
        modal_logs: false,
        dag_logs: [],
      });
      return;
    }
    this.setState({
      fetching_logs: true,
      progress: 40,
      dag_started_at: started_at,
      selected_dag_state: dag_state,
      loading_log_id: dag_run_id,
    });

    // Choose the appropriate service based on whether we're in workflow view
    const serviceFunction = this.props.isWorkflowView ? 
      getTasksAcrossDagsService : 
      getTasksService;

    const response = await serviceFunction({
      dag_state,
      dag_id,
      dag_run_id,
      dag_started_at: started_at,
      ...(this.props.isWorkflowView && { task_name: this.state.historyTargetId })
    });

    if(response) {
      this.setState({
        modal_logs: dag_run_id,
        dag_logs: response.data.logs,
      });
    }

    this.setState({
      fetching_logs: false,
      progress: 100,
      loading_log_id: false,
    });
  };

  render() {
    return (
      <div id="history-table-container">
        <div
          style={{ display: "flex", justifyContent: "flex-start" }}
          className="history-table-content-container"
        >
          <CSSTransition
            in={this.state.isShowingDagHistoryTable}
            classNames="right-slide"
            timeout={300}
            unmountOnExit
          >
            <div className="history-sidebar">
              <header className="history-heading">
                {this.state.selected_logo ? (
                  <img
                    alt="dag logo"
                    src={this.state.selected_logo}
                    style={{
                      width: "50px",
                      objectFit: "cover",
                    }}
                  ></img>
                ) : null}
                <b className="history-heading-name">
                  Historical Runs:&nbsp;
                  {this.state.historyTargetId}
                  &nbsp;
                </b>
                <button
                  className="close-history-sidebar-button"
                  onClick={() => this.props.onClose()}
                >
                  <Icon.X size={18} className="close-history-sidebar-icon" />
                  Close
                </button>
              </header>

              <TableContainer sx={{
                [`& .${tableHeadClasses.root}`]: {
                  position: "relative"
                }
              }}>
                <Table
                  headings={[
                    {
                      isSortable: false,
                      columnName: "date",
                      Children: () => "Date",
                      width: "15%",
                    },
                    {
                      isSortable: false,
                      columnName: "started",
                      Children: () => "Started",
                      width: "20%",
                    },
                    {
                      isSortable: false,
                      columnName: "ended",
                      Children: () => "Ended",
                      width: "15%",
                    },
                    {
                      isSortable: false,
                      columnName: "duration",
                      Children: () => "Duration",
                      width: "15%",
                    },
                    {
                      isSortable: false,
                      columnName: "status",
                      Children: () => "Status",
                      width: "15%",
                    },
                    {
                      isSortable: false,
                      columnName: "run",
                      Children: () => this.props.isWorkflowView ? "Triggered by" : "Run Type",
                      width: "25%",
                    },
                    {
                      isSortable: false,
                      columnName: "logs",
                      Children: () => "Logs",
                      width: "10%",
                    },
                  ]}
                  rows={this.state.historical_dag_runs[
                    this.state.selected_job
                  ]?.map((DAG) => ({
                    key: DAG.dag_run_id,
                    columns: [
                      {
                        type: "text",
                        text:
                          DAG.start_date && DAG.start_date !== "Seconds ago"
                            ? moment
                                .utc(DAG.start_date)
                                .tz(customer_timezone)
                                ?.format("YYYY-MM-DD")
                            : "Today",
                      },
                      {
                        type: "text",
                        text:
                          DAG.start_date && DAG.start_date !== "Seconds ago"
                            ? moment
                                .utc(DAG.start_date)
                                .tz(customer_timezone)
                                ?.format("YYYY-MM-DD HH:mm")
                            : "Seconds ago",
                      },
                      {
                        type: "text",
                        text: DAG.end_date
                          ? moment
                              .utc(DAG.end_date)
                              .tz(customer_timezone)
                              ?.format("YYYY-MM-DD HH:mm")
                          : "",
                      },
                      { type: "text", text: DAG.duration },
                      { type: "status", key: DAG.state },
                      { type: "text", text: DAG.run_type },
                      {
                        type: "component",
                        component: (
                          <Button
                            disabled={!this.props.show_logs}
                            variant="primary"
                            className="me-1 mb-1 logs-trigger"
                            size="sm"
                            onClick={() =>
                              this.gettasks(
                                DAG.state,
                                this.state.dag_id || DAG.dag_id,
                                DAG.dag_run_id,
                                DAG.start_date
                              )
                            }
                          >
                            {this.state.fetching_logs &&
                            DAG.dag_run_id === this.state.loading_log_id ? (
                              <center className="rotate-icon">
                                <Icon.Loader size={16} color="white" />
                              </center>
                            ) : DAG.dag_run_id === this.state.modal_logs ? (
                              <Icon.ArrowDown size={16} color="white" />
                            ) : (
                              <Icon.Code size={16} color="white" />
                            )}
                          </Button>
                        ),
                      },
                    ],
                    logsTable: DAG.dag_run_id === this.state.modal_logs && (
                      <LogsTable
                        show={this.state.modal_logs}
                        onHide={() => this.setState({ modal_logs: false })}
                        selected_job={this.state.selected_job}
                        dag_started_at={this.state.dag_started_at}
                        selected_dag_state={this.state.selected_dag_state}
                        dag_logs={this.state.dag_logs}
                      />
                    )
                  }))}
                />
              </TableContainer>

              {!this.state.historical_runs_available && (
                <center
                  className="no-history-text"
                  onClick={() => this.props.onClose()}
                >
                  <b>No historical runs of this job yet.</b>{" "}
                  <Icon.ChevronsRight width={14} />
                </center>
              )}
              {this.state.fetching_last_runs && (
                <>
                  <center>
                    <DatajoltSpinner />
                  </center>
                </>
              )}
            </div>
          </CSSTransition>
        </div>
      </div>
    );
  }
}

export default DagHistory;
