import React from "react";
import { TextField } from "@mui/material";

/**
 * @param {{
 *  type: React.HTMLInputTypeAttribute;
 *  placeholder: string;
 *  label?: string;
 *  onChange: (e: React.ChangeEvent) => void;
 *  value?: string;
 *  defaultValue?: string;
 *  inputGroup?: boolean;
 * }}
 */
const ModalLayoutInput = ({
  type,
  placeholder,
  label,
  onChange,
  value,
  defaultValue,
  inputGroup,
  ...props
}) => {
  return (
    <TextField
      variant="outlined"
      type={type}
      placeholder={placeholder}
      label={label}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      sx={{
        width: "100%",
        marginY: 2,
        "& legend span": {
          width: 0,
          padding: 0,
        },
        "& fieldset": {
          borderTopRightRadius: inputGroup ? 0 : "inherit",
          borderBottomRightRadius: inputGroup ? 0 : "inherit",
        },
      }}
      slotProps={{
        htmlInput: {
          sx: {
            color: "#495057",
            fontSize: "13px",
            lineHeight: 1.625,
            fontWeight: 400,
            fontFamily: "Poppins",
            paddingX: "11px",
            paddingY: "6px",
            borderRadius: "6px",
          },
        },
        inputLabel: {
          sx: {
            transform: "none",
            top: -16,
            fontSize: 11,
            fontFamily: "Poppins",
          },
        },
      }}
      {...props}
    />
  );
};

export default ModalLayoutInput;
