import React from "react";
import { DialogContentText, Typography } from "@mui/material";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";
import { Save, X } from "react-feather";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  getLineage: () => void;
 *  saveFile: () => void;
 *  activeTab: string;
 *  saving: boolean;
 * }} data
 */
const SaveForLineageModal = ({
  isShown,
  onHide,
  saveFile,
  getLineage,
  activeTab,
  saving,
}) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>
        <Typography
          component={"span"}
          fontSize={11}
          fontFamily={"Poppins"}
          fontWeight={700}
        >
          Warning!
        </Typography>
      </ModalLayoutHeader>
      <ModalLayoutContent>
        <DialogContentText
          textAlign={"center"}
          fontSize={11}
          fontFamily={"Poppins"}
          color="#6c757d"
        >
          Your {activeTab} model is unsaved. Unsaved changes will not be
          reflected in your lineage view.
          <Typography
            component={"span"}
            fontSize={11}
            fontFamily={"Poppins"}
            fontWeight={700}
            display={"block"}
          >
            Do you want to save changes?
          </Typography>
        </DialogContentText>
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn color="error" onClick={getLineage}>
          <X size={16} /> No
        </ModalLayoutBtn>
        <ModalLayoutBtn color="success" disabled={saving} onClick={saveFile}>
          <Save size={16} /> Yes
        </ModalLayoutBtn>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default SaveForLineageModal;
