import React, { useState } from "react";
import { CircularProgress, DialogContentText, Typography } from "@mui/material";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";
import { X, Save } from "react-feather";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  closeFile: () => void;
 *  saveFile: () => Promise<void>;
 * }} data
 */
const ChangeHistoryWithoutSavingModal = ({
  isShown,
  onHide,
  closeFile,
  saveFile,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    await saveFile();
    setIsSaving(false);
  };

  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>
        <Typography
          component={"span"}
          fontSize={11}
          fontFamily={"Poppins"}
          fontWeight={700}
        >
          Warning!
        </Typography>
      </ModalLayoutHeader>
      <ModalLayoutContent>
        <DialogContentText
          textAlign={"center"}
          fontSize={11}
          fontFamily={"Poppins"}
          color="#6c757d"
        >
          You have unsaved changes. Do you want to save changes before you open
          your file's historical version?
        </DialogContentText>
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn color="error" onClick={closeFile} disabled={isSaving}>
          <X size={16} /> No, discard changes
        </ModalLayoutBtn>
        <ModalLayoutBtn
          color="success"
          onClick={handleSave}
          disabled={isSaving}
          style={{
            height: "32px"
          }}
        >
          {isSaving ? (
            <>
              Saving...{" "}
              <CircularProgress size={16} sx={{ color: 'rgba(40, 199, 111, 0.5)' }} />
            </>
          ) : (
            <>
              <Save size={16} /> Yes, save changes first
            </>
          )}
        </ModalLayoutBtn>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default ChangeHistoryWithoutSavingModal;
