import React from "react";
import { ChevronsDown, ChevronsUp } from "react-feather";

/**
 * @param {{onClick: () => void, isSorted: "asc" | "desc" | "none"}} data
 */
const SortBtn = ({ onClick, isSorted }) => {
  return (
    <button
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: "column",
        background: "transparent",
        border: 0,
        padding: 0,
        marginLeft: "auto",
        marginRight: "10px",
        height: "28px",
        alignSelf: "flex-end",
        justifyContent: "space-between",
      }}
    >
      <ChevronsUp size={12} color={isSorted === "asc" ? "blue" : "#879fb5"} />
      <ChevronsDown
        size={12}
        color={isSorted === "desc" ? "blue" : "#879fb5"}
      />
    </button>
  );
};

export default SortBtn;
