import React from "react";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";
import { DialogContentText } from "@mui/material";
import { Trash2 } from "react-feather";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  onAction: () => void;
 *  isDeletingConversation: boolean;
 * }} data
 */
const DeleteChatModal = ({ isShown, onHide, onAction, isDeletingConversation }) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>Warning!</ModalLayoutHeader>
      <ModalLayoutContent>
        <DialogContentText
          textAlign={"center"}
          fontSize={11}
          fontFamily={"Poppins"}
          color="#6c757d"
        >
          Are you sure you want to delete this conversation? This action cannot
          be undone.
        </DialogContentText>
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn onClick={onHide} color="secondary">
          Cancel
        </ModalLayoutBtn>
        <ModalLayoutBtn color="error" onClick={onAction} disabled={isDeletingConversation}>
          {isDeletingConversation ? (
            <>
              <span 
                className="spinner-border spinner-border-sm me-2 align-middle" 
                role="status" 
                aria-hidden="true"
              />
              Deleting...
            </>
          ) : (
            <>
              <Trash2 size={14} /> Delete
            </>
          )}
        </ModalLayoutBtn>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default DeleteChatModal;
