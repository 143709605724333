import React, { useState } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
import { Trash2, Settings, MoreHorizontal, X } from "react-feather"; // Import icons
import { handleDeleteService } from "../../services/taps";
import DeleteSourceModal from "../Modals/DeleteSource";
import {CircularProgress} from "@mui/material";

function ConnectorCard({ datasource, onDeleteSuccess }) {
  const sourceAlias =
    datasource && datasource.source_alias ? datasource.source_alias : "";
  const [isLoading, setIsLoading] = useState(false); // Add state to manage loading
  const [isDeleting, setIsDeleting] = useState(false); // Add state to manage loading
  const [showDelete, setShowDelete] = useState(false); // State to manage modal visibility
  const [showDropdown, setShowDropdown] = useState(false); // State to manage dropdown visibility
  const truncatedAlias =
    sourceAlias.length > 10
      ? `${sourceAlias.substring(0, 10)}...`
      : sourceAlias;

  // Function to handle delete action
  const handleDelete = async (e) => {
    setIsDeleting(true);
    e.stopPropagation(); 
    setIsLoading(true);
    const isSuccess = await handleDeleteService(datasource.id, datasource.source_alias);

    if(isSuccess) {
      setShowDelete(false); // Close modal after deletion
      onDeleteSuccess();
    }
    
    setIsDeleting(false);
    setIsLoading(false);
  };

  const handleCloseModal = (e) => {
    if (e) {
      e.stopPropagation(); // Prevent triggering the card click event
    }
    setShowDelete(false); // Close the modal
  };

  // Function to handle dropdown item click
  const handleDropdownItemClick = (e, action) => {
    e.stopPropagation(); // Prevent triggering the card click event
    setShowDropdown(false); // Hide the dropdown
    if (action === "delete") {
      setShowDelete(true); // Show delete modal
    }
    // Add other actions here if needed
  };

  return (
    <>
      <div
        className="card-taps"
        role="button"
        tabIndex={0}
        aria-pressed="false"
        style={{ cursor: "pointer", position: "relative" }}
      >
        <div
          className="card-taps-body connector-card-taps-body"
          title={
            datasource && datasource.label ? datasource.label : datasource.alias
          }
        >
          <center>
            <div className="connector-image">
              {datasource && datasource.logo ? (
                <img
                  src={datasource.logo}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  loading="lazy"
                  alt=""
                  className="image-contain"
                />
              ) : null}
            </div>
          </center>
          <div className="card-taps-info" style={{ flexGrow: 3 }}>
            <center>
              <div
                className="card-taps-title text-center"
                style={{
                  wordWrap: "break-word",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "10px",
                  lineHeight: "15px",
                  maxWidth: "100px",
                }}
                title={
                  datasource && datasource.label
                    ? datasource.label
                    : datasource.alias
                }
              >
                {datasource && datasource.label
                  ? datasource.label
                  : datasource.alias}
              </div>
            </center>
            <div className="card-taps-metadata">
              {datasource && datasource.tap_group}
            </div>
          </div>
          <div className="card-taps-metadata mt-1">
            {datasource && !datasource.status ? (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-${sourceAlias}`}>
                      {sourceAlias}
                    </Tooltip>
                  }
                >
                  <Button
                    variant="live-success"
                    className="me-1 mb-1"
                    size="sm"
                  >
                    {truncatedAlias}
                  </Button>
                </OverlayTrigger>
                <div
                  className="icons-wrapper"
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                >
                  <Dropdown
                    show={showDropdown}
                    onToggle={(isOpen) => setShowDropdown(isOpen)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Dropdown.Toggle
                      as={MoreHorizontal}
                      size={14}
                      style={{ cursor: "pointer" }}
                    />
                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#/edit-source"
                        disabled={true}
                        onClick={(e) => handleDropdownItemClick(e, "edit")}
                      >
                        <Settings size={14} className="me-2" /> Edit connection
                        details
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => handleDropdownItemClick(e, "delete")}
                      >
                        <Trash2 size={14} className="me-2" /> Delete source
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            ) : (
              <Button
                disabled={isLoading} // Disable button when loading
                className={
                  datasource && datasource.status === "Live"
                    ? "btn btn-success rounded-pill"
                    : "btn btn-primary rounded-pill"
                }
                style={{ fontSize: "8px" }}
              >
                {isLoading ? (
                  <CircularProgress size={16} color="#fff" />
                ) : datasource && datasource.status ? (
                  datasource.status.toUpperCase()
                ) : (
                  ""
                )}
              </Button>
            )}
          </div>
        </div>
      </div>

      {/* Modal for delete confirmation */}
      <DeleteSourceModal
        isShown={showDelete}
        onHide={handleCloseModal}
        onAction={handleDelete}
        isDeleting={isDeleting}
        sourceAlias={sourceAlias}
        taps={datasource?.taps}
      />
    </>
  );
}

export default ConnectorCard;
