import React from "react";
import { CircularProgress, DialogContentText, Typography } from "@mui/material";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";
import { X, Trash2 } from "react-feather";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  onAction: () => void;
 *  deleting: boolean;
 *  activeBranch: string
 *  filename: string;
 * }} data
 */
const DeleteFileModal = ({
  isShown,
  onHide,
  onAction,
  deleting,
  activeBranch,
  filename,
}) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>
        <Typography
          component={"span"}
          fontSize={11}
          fontFamily={"Poppins"}
          fontWeight={700}
        >
          Warning!
        </Typography>
        <img
          src="/images/dbt.png"
          width="8%"
          style={{
            position: "absolute",
            right: "48px",
          }}
          alt=""
        />
      </ModalLayoutHeader>
      <ModalLayoutContent>
        <DialogContentText
          textAlign={"center"}
          fontSize={11}
          fontFamily={"Poppins"}
          color="#6c757d"
        >
          You are about to delete{" "}
          <Typography
            component={"span"}
            fontSize={11}
            fontFamily={"Poppins"}
            fontWeight={700}
          >
            {filename || ""}
          </Typography>{" "}
          from{" "}
          <Typography
            component={"span"}
            fontSize={11}
            fontFamily={"Poppins"}
            fontWeight={700}
          >
            {activeBranch}
          </Typography>
          .<br />
          <Typography
            component={"span"}
            fontSize={11}
            fontFamily={"Poppins"}
            fontWeight={700}
          >
            Are you sure ?
          </Typography>
        </DialogContentText>
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn color="secondary" onClick={onHide}>
          <X size={16} /> No
        </ModalLayoutBtn>{" "}
        <ModalLayoutBtn color="error" disabled={deleting} onClick={onAction}>
          <Trash2 size={16} />{" "}
          {deleting ? (
            <>
              Deleting...&nbsp;&nbsp;
              <CircularProgress size={16} color="#fff" />
            </>
          ) : (
            "Delete"
          )}
        </ModalLayoutBtn>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default DeleteFileModal;
