import React from "react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
} from "@mui/material";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";
import { Save, X } from "react-feather";
import ModalLayoutInput from "./Layout/Input";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  onAction: () => void;
 *  logo: string;
 *  windowInDays: number;
 *  setWindwInDays: (e: React.ChangeEvent) => void;
 *  customReports: { name: string; metrics: string; dimensions: string}[];
 *  addCustomReport: () => void;
 *  selectedTap: string;
 *  savingNewSpecs: boolean;
 *  updateCustomReport: (i: number, type: string, e: React.ChangeEvent) => void;
 *  deleteReport: () => void;
 * }} data
 */
const CustomReportsModal = ({
  isShown,
  onHide,
  onAction,
  logo,
  windowInDays,
  setWindwInDays,
  customReports,
  addCustomReport,
  selectedTap,
  savingNewSpecs,
  updateCustomReport,
  deleteReport,
}) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>
        <img
          src={logo}
          width="5%"
          style={{ marginTop: "-3px", marginLeft: "5px" }}
          alt=""
        />{" "}
        <Typography
          component={"span"}
          fontSize={11}
          fontFamily={"Poppins"}
          fontWeight={700}
        >
          Customize Google Analytics
        </Typography>
      </ModalLayoutHeader>

      <ModalLayoutContent>
        <ModalLayoutInput
          type="number"
          placeholder="30,60,90,120,200,364"
          label="Window in days"
          onChange={setWindwInDays}
          defaultValue={windowInDays}
        />

        <Typography
          component={"span"}
          fontFamily={"Poppins"}
          fontSize={11}
          color="rgba(0, 0, 0, 0.6)"
        >
          Custom Reports ({customReports.length})
        </Typography>
        <Button
          onClick={addCustomReport}
          color="primary"
          sx={{
            bgcolor: "#3651FE",
            py: "2px",
            px: 1,
            ml: 1,
            fontSize: 11,
            fontFamily: "Poppins",
            textTransform: "none",
            fontWeight: 400,
            boxShadow: "none",
            color: "#fff",
            minWidth: 0,
          }}
        >
          +
        </Button>

        {customReports.map((custom_report, index) => (
          <Accordion key={index} sx={{ my: 2 }} defaultExpanded>
            <AccordionSummary>{custom_report.name}</AccordionSummary>
            <AccordionDetails>
              <ModalLayoutInput
                type="text"
                placeholder="LTV Report"
                label="Report Name"
                onChange={(e) => updateCustomReport(index, "name", e)}
                defaultValue={custom_report.name}
              />
              <ModalLayoutInput
                type="text"
                placeholder={
                  selectedTap === "Google Analytics"
                    ? "ga:date, ga:country"
                    : "date, country"
                }
                label="Dimensions"
                onChange={(e) => updateCustomReport(index, "dimensions", e)}
                defaultValue={custom_report.dimensions}
              />
              <ModalLayoutInput
                type="text"
                placeholder={
                  selectedTap === "Google Analytics"
                    ? "ga:activeUsers"
                    : "activeUsers"
                }
                label="Metrics"
                onChange={(e) => updateCustomReport(index, "metrics", e)}
                defaultValue={custom_report.metrics}
              />

              <Button
                onClick={deleteReport}
                color="error"
                sx={{
                  display: "block",
                  bgcolor: "#ae423f",
                  py: "2px",
                  px: 2,
                  fontSize: 11,
                  fontFamily: "Poppins",
                  textTransform: "none",
                  fontWeight: 400,
                  boxShadow: "none",
                  color: "#fff",
                }}
              >
                Delete Report
              </Button>
            </AccordionDetails>
          </Accordion>
        ))}
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn color="secondary" disabled={false} onClick={onHide}>
          <X size={16} /> Close
        </ModalLayoutBtn>
        <ModalLayoutBtn
          color="primary"
          disabled={savingNewSpecs || !customReports.length}
          onClick={onAction}
        >
          <Save size={16} /> {savingNewSpecs ? "Saving..." : "Save & Refresh"}
          {savingNewSpecs && <CircularProgress size={16} color="#fff" />}
        </ModalLayoutBtn>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default CustomReportsModal;
