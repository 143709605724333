import { Handle, Position, useStore } from "react-flow-renderer";
import { ReactComponent as DeleteIcon } from "../../../assets/img/icons/deleteIcon.svg";
import { ReactComponent as TimeIcon } from "../../../assets/img/icons/timeIcon.svg";
import { ReactComponent as ModelIcon } from "../../../assets/img/icons/model.svg";
import * as Icon from "react-feather";
import moment from "moment-timezone";
import { Tooltip, CircularProgress } from "@mui/material";
import { redirectToEditorService } from "../../../services/overview";
import React, { useState } from "react";

var customer_timezone = localStorage.getItem("timezone");

const connectionNodeIdSelector = (state) => state.connectionNodeId;

function WorkflowDAG({ data, isConnectable }) {
  const [isLoading, setIsLoading] = useState(false);
  const editorURL = data.editorURL;
  const [isOverviewPage, setIsOverviewPage] = useState(window.location.href.includes("/overview"));


  const redirectToEditor = async (model_name) => {
    setIsLoading(true);
    
    try {
      const response = await redirectToEditorService(editorURL, model_name);
      
      if(response && response.success) {
        const baseUrl = window.location.origin;
        window.location.href = `${baseUrl}/sql/editor?file=${response.path}`;
        }
    } catch (error) {
      console.error("Error redirecting to editor:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;

  const openHistorySidebar = (name) => {
    // Use the node's display name (label) for the history view
    const nodeName = data?.label;
    console.log("Opening history for node:", nodeName); // Debug log
    data._setIsShowingDagHistoryTable(nodeName);
  };

  const renderStatusColors = () => {
    const state = data?.nodeData?.state;
    const type = data?.nodeData?.type || data?.label?.split(".")?.[0];

    return {
      nodeTypeColor:
        type === "source" || type === "Source"
          ? "#330066"
          : type === "Model" || type === "model"
          ? "#448593"
          : type === "exposure" || type === "exposure"
          ? "#76141f"
          : "black",

      mainColor:
        state === "failed"
          ? "#F30A0A"
          : state === "running" || state === "waiting"
          ? "#54CB75"
          : state === "success"
          ? "green"
          : state === "skipped"
          ? "blue"
          : "black",
      bgColor:
        state === "failed"
          ? "#f8e8e8"
          : state === "running" || state === "waiting"
          ? "#ecfbf0"
          : state === "success"
          ? "#a9f8be"
          : state === "skipped"
          ? "#CCE5FF"
          : "white",
    };
  };

  const renderDAGtypeIcon = () => {
    const type = data?.label?.split(".")?.[0];
    if (type === "Source" || type === "source") {
      return <Icon.RefreshCcw color="white" size={14} />;
    }
    if (type === "Model" || type === "model") {
      return <ModelIcon color="white" width={12} height={14} />;
    }
    if (type === "exposure" || type === "exposure") {
      return <Icon.UploadCloud color="white" size={14} />;
    }
    return type;
  };
  return (
    <div
      className={`dag-wrapper ${
        data?.nodeData?.state === "running" || data?.nodeData?.state === "waiting" ? "animated-running" : ""
      } `}
      style={{
        background: renderStatusColors().bgColor,
        border: `2px solid ${
          data?.isActiveFile ? "green" : renderStatusColors().mainColor
        }`,
      }}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        style={{ width: 13, height: 13 }}
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
        style={{ width: 13, height: 13 }}
      />
      <div className="status-delete-wrapper">
        <div className="d-flex">
          <p
            className="status-tag text-capitalize"
            style={{ background: renderStatusColors().nodeTypeColor }}
          >
            {renderDAGtypeIcon()}
          </p>
          {data?.nodeData?.state && (
            <p
              className="status-tag"
              style={{ background: renderStatusColors().mainColor }}
            >
              {data?.nodeData?.state == "skipped" ? "User skipped" : data?.nodeData?.state}
            </p>
          )}
        </div>

        <div className="d-flex">
          {isOverviewPage && (data?.nodeData?.type === "Model" || data?.nodeData?.type === "model" || 
            (data?.label?.split(".")?.[0] === "Model" || data?.label?.split(".")?.[0] === "model")) && (
            <Tooltip 
              title="Open Model in Editor" 
              placement="top"
              arrow
              enterDelay={300}
            >
              <span
                className="history-dag-button lighter-hover"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  redirectToEditor(data.label);
                }}
              >
                {isLoading ? <CircularProgress size={16} /> : <Icon.Link width={16} />}
              </span>
            </Tooltip>
          )}

        {isOverviewPage && (
          <Tooltip 
            title="Show history" 
            placement="top"
            arrow
            enterDelay={300}
          >
            <span
              className="history-dag-button lighter-hover"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                openHistorySidebar(data.label);
              }}
            >
              <Icon.Clock width={16} />
            </span>
          </Tooltip>
          )}
        </div>

        {data._handleDelete && (
          <span className="delete-button">
            <DeleteIcon
              onClick={() => data._handleDelete(data?.nodeData?.id)}
            />
          </span>
        )}
      </div>

      <div className="dag-details">
        <span className="dag-icon">
          {data?.logo ? (
            <img src={data?.logo} alt="dag-icon" />
          ) : (
            renderDAGtypeIcon()
          )}
        </span>
        <div className="dag-data">
          <p
            className="dag-label"
            style={{ color: data?.isActiveFile ? "green" : "inherit" }}
          >
            {data.label}
          </p>
          <span className="last-run-wrapper">
            {data?.nodeData?.start_date && customer_timezone ? (
              <>
                <TimeIcon width={14} height={14} />{" "}
                <p>
                  Started at{" "}
                  {moment
                    .utc(data.nodeData.start_date)
                    .tz(customer_timezone)
                    ?.format("HH:mm")}
                  {data?.nodeData?.end_date ? (
                    <>
                      {" "}
                      | Ended at{" "}
                      {moment
                        .utc(data.nodeData.end_date)
                        .tz(customer_timezone)
                        ?.format("HH:mm")}
                    </>
                  ) : (
                    ""
                  )}
                </p>
              </>
            ) : (
              ""
            )}
          </span>
        </div>
      </div>
      {isConnecting && (
        <span
          className="dag-connection-net"
          id={data?.nodeData?.id || data?.label}
        />
      )}
    </div>
  );
}

export default WorkflowDAG;
