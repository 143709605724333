import { Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";

const LoadingTableBody = ({ colNum, rowCount = 10 }) =>
  Array.from({ length: rowCount }).map((_, i) => (
    <TableRow
      key={i}
      sx={{
        backgroundColor: "#fff",
        outline: "1px solid #ebebeb",
        outlineOffset: "-1px",
        height: "65px",
        "&:hover": {
          backgroundColor: "rgb(250, 250, 250) !important",
        },
      }}
    >
      {Array.from({ length: colNum }).map((_, j) => (
        <TableCell key={`${i}-${j}`}>
          <Skeleton />
        </TableCell>
      ))}
    </TableRow>
  ));

export default LoadingTableBody;
