import React from "react";
import { DialogContentText, Typography } from "@mui/material";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 * }} data
 */
const OverwriteFileModal = ({ isShown, onHide }) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>
        <Typography
          component={"span"}
          fontSize={11}
          fontFamily={"Poppins"}
          fontWeight={700}
        >
          Warning! This file is in Production.
        </Typography>
        <img
          src="/images/dbt.png"
          width="8%"
          style={{
            position: "absolute",
            right: "48px",
          }}
          alt=""
        />
      </ModalLayoutHeader>
      <ModalLayoutContent>
        <DialogContentText
          textAlign={"center"}
          fontSize={11}
          fontFamily={"Poppins"}
          color="#6c757d"
        >
          Editing directly in main branch is not recommended.
          <br />
          Branch off main to make changes and merge them back.
        </DialogContentText>
      </ModalLayoutContent>
    </ModalLayout>
  );
};

export default OverwriteFileModal;
