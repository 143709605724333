import React, { useState, useEffect } from "react";
import { ButtonGroup, Button, OverlayTrigger, Popover } from "react-bootstrap";
import { Line, Doughnut } from 'react-chartjs-2';
import { config } from "../../constants";
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { createTheme } from '@mui/material/styles';
import DatajoltSpinner from "../../components/global/DatajoltSpinner";
import moment from 'moment-timezone';
import TablePagination from "../../components/global/Table/Pagination";
import { ReactComponent as DetailsIcon } from "../../assets/img/icons/details.svg";
import LoadingBar from "react-top-loading-bar";
import { Helmet } from "react-helmet-async";
import { Info, Filter } from 'react-feather';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import CustomTable from "../../components/global/Table";
import { TableContainer, tableHeadClasses } from "@mui/material";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

// Fake data for the time series charts
const timeSeriesData = Array.from({ length: 30 }, (_, i) => ({
  date: `2024-02-${i + 1}`,
  value: Math.floor(Math.random() * 100)
}));

// Data for the donut charts
const testResultsData = [
  { name: 'Passed', value: 100, color: '#4CAF50' },
  { name: 'Failed', value: 1, color: '#f44336' }
];

const tablesHealthData = [
  { name: 'Healthy', value: 58, color: '#4CAF50' },
  { name: 'Failed', value: 1, color: '#f44336' }
];

const monitoredTablesData = [
  { name: 'Monitored', value: 59, color: '#4CAF50' },
  { name: 'Unmonitored', value: 181, color: '#E0E0E0' }
];

const DonutChart = ({ data, centerText, subText, disabled = false, onSegmentClick }) => {
  const [showHint, setShowHint] = useState(true);

  const chartData = {
    labels: data.map(d => d.name),
    datasets: [{
      data: data.map(d => d.value),
      backgroundColor: disabled ? data.map(() => '#E0E0E0') : data.map(d => d.color),
      borderWidth: 0,
    }]
  };

  const options = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false
      }
    },
    maintainAspectRatio: false,
    onClick: (event, elements, chart) => {
      if (!disabled) {
        if (elements.length > 0) {
          const index = elements[0].index;
          onSegmentClick(data[index].name);
        }
      }
    }
  };

  return (
    <div 
      style={{ 
        position: 'relative', 
        width: 200, 
        height: 200,
        opacity: disabled ? 0.5 : 1,
        margin: '0 auto'
      }}
      onMouseEnter={() => setShowHint(false)}
    >
      <Doughnut data={chartData} options={options} />
      <div 
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          cursor: disabled ? 'default' : 'pointer'
        }}
        onClick={() => {
          if (!disabled) {
            onSegmentClick('all');
          }
        }}
      >
        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
          {disabled ? '-' : centerText}
        </div>
        <div style={{ fontSize: '14px', color: '#666' }}>{subText}</div>
      </div>
      
      {showHint && !disabled && (
        <div 
          style={{
            position: 'absolute',
            bottom: -30,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#f8f9fa',
            padding: '4px 8px',
            borderRadius: '4px',
            fontSize: '12px',
            color: '#666',
            whiteSpace: 'nowrap',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            animation: 'fadeIn 0.3s ease-out',
            cursor: 'default'
          }}
        >
          <span role="img" aria-label="click">👆</span> Click a segment to see records or center for all
        </div>
      )}
    </div>
  );
};

const LineChartComponent = ({ data = [], color, disabled = false, options = {} }) => {
  // Add data validation
  if (!Array.isArray(data) || data.length === 0) {
    return (
      <div style={{ 
        height: '100%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        color: '#666'
      }}>
        No data available
      </div>
    );
  }

  const chartData = {
    labels: data.map(d => moment(d.date)?.format('MMM DD')),
    datasets: [{
      data: data.map(d => d.value),
      borderColor: disabled ? '#E0E0E0' : color,
      tension: 0.1,
      fill: false
    }]
  };

  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: function(context) {
            return moment(data[context[0].dataIndex].date)?.format('MMM DD');
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          callback: function(value) {
            return Math.round(value);
          }
        }
      }
    }
  };

  return (
    <div style={{ 
      opacity: disabled ? 0.5 : 1,
      height: '140px'
    }}>
      <Line data={chartData} options={{ ...defaultOptions, ...options }} />
    </div>
  );
};

const ChartContainer = ({ title, children }) => (
  <div className="card h-100">
    <div className="card-body text-center">
      <h6 style={{ 
        marginBottom: '0', 
        fontSize: '0.875rem',
        color: '#6c757d',
        fontWeight: 'normal',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        {title}
      </h6>
      <hr style={{ 
        margin: '8px 0',
        opacity: 0.1
      }} />
      <div style={{ 
        height: 'calc(100% - 40px)',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 20px'
      }}>
        {children}
      </div>
    </div>
  </div>
);

// Update the LoadingSpinner component
const LoadingSpinner = () => (
  <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
    <div 
      className="spinner-border text-primary" 
      role="status" 
      style={{ 
        width: '2rem', 
        height: '2rem',
        borderWidth: '0.2em'
      }}
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

const TestDetailsTable = ({ data, loading }) => (
  <div className="card mb-3" style={{
    animation: 'slideDown 0.7s ease-out',
    overflow: 'hidden'
  }}>
    <div className="card-body">
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th width="20%">Test Name</th>
              <th>Table</th>
              <th>Column</th>
              <th>Status</th>
              <th>Last Run</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="6" className="text-center">
                  <LoadingSpinner />
                </td>
              </tr>
            ) : (
              data.map((test, index) => (
                <tr key={index} style={{
                  animation: `fadeIn 0.3s ease-out ${index * 0.05}s`
                }}>
                  <td>{test.test_name}</td>
                  <td>{test.table}</td>
                  <td>{test.column || '-'}</td>
                  <td>
                    <span className={`badge bg-${test.status === 'passed' ? 'success' : 'danger'}`}>
                      {test.status}
                    </span>
                  </td>
                  <td className="text-nowrap truncate">
                    {test.last_run ? 
                      moment(test.last_run)
                        .tz(customer_timezone)
                        ?.format('YYYY-MM-DD HH:mm:ss z') 
                      : '-'}
                  </td>
                  <td>{test.message || '-'}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

// Update the keyframes to only handle appearing animations
const styles = `
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .text-nowrap {
    white-space: nowrap;
  }

  .truncate {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .resizables {
    position: relative;
  }

  .badge {
    padding: 4px 8px;
    font-size: 0.75rem;
    font-weight: 500;
    border-radius: 4px;
  }

  .bg-success {
    background-color: #4CAF50 !important;
    color: white;
  }

  .bg-warning {
    background-color: #FFA726 !important;
    color: white;
  }

  .bg-danger {
    background-color: #f44336 !important;
    color: white;
  }

  .bg-yellow {
    background-color: #FFD700 !important;
    color: white;
  }
`;

// Add the styles to the document
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

// Add this theme configuration
const tableTheme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
          fontSize: '0.875rem',
          fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif",
          color: '#344054',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          whiteSpace: 'nowrap'
        },
        head: {
          fontWeight: 500,
          backgroundColor: '#f8f9fa',
          color: '#344054',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }
      }
    }
  }
});

// Add this line outside the component (like in taps.js)
var customer_timezone = localStorage.getItem("timezone");

const Observability = () => {
  // State management
  const [dashboardSummary, setDashboardSummary] = useState({
    test_results: { passed: 0, failed: 0, total: 0 },
    table_health: { healthy: 0, unhealthy: 0 },
    monitored_tables: { monitored: 0, total: 0 },
    table_freshness: { fresh: 0, over_24h: 0, over_1w: 0, failed: 0, never: 0 }
  });
  const [timeseriesData, setTimeseriesData] = useState({
    executions: [],
    failures: [],
    warnings: [],
    monitored_tables: [],
    failed_tables: []
  });
  const [selectedTimeRange, setSelectedTimeRange] = useState(30);
  const [errors, setErrors] = useState({
    dashboard: null,
    timeseries: null,
    table: null
  });
  const [selectedTestStatus, setSelectedTestStatus] = useState(null); // 'passed', 'failed', or null
  const [testDetails, setTestDetails] = useState([]);

  
  // Add state for table data and view type
  const [tableData, setTableData] = useState([]);
  const [activeView, setActiveView] = useState(null); // null, 'executions', 'configured', 'coverage'

  // Add state for configured tests
  const [configuredTests, setConfiguredTests] = useState([]);

  // Add state for sorting
  const [sortedColumn, setSortedColumn] = useState({ column: '', isAsc: true });

  // Add state for filters
  const [jobNameFilters, setJobNameFilters] = useState('');
  
  // Add filter handler
  const updateJobNameFilters = (value) => {
    setJobNameFilters(value);
  };

  // Add filter function
  const filterJobNames = (test) => {
    if (!jobNameFilters) return true;
    return test.table_name?.toLowerCase().includes(jobNameFilters.toLowerCase()) ||
           test.test_name?.toLowerCase().includes(jobNameFilters.toLowerCase());
  };

  // Add the time range change handler
  const handleTimeRangeChange = (days) => {
    setSelectedTimeRange(days);
    // Clear active table view when changing time range
    setActiveView(null);
    setTableData([]);
  };

  // Add separate loading states
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [timeseriesLoading, setTimeseriesLoading] = useState(true);

  // Update useEffect to handle dashboard data independently
  useEffect(() => {
    const fetchDashboardData = async () => {
      setDashboardLoading(true);
      setTimeseriesLoading(true);
      setProgress(40);

      try {
        const [summaryRes, timeseriesRes] = await Promise.all([
          axios.get(`${config.url.OBSERVABILITY}/api/observability/dashboard_summary?days=${selectedTimeRange}`),
          axios.get(`${config.url.OBSERVABILITY}/api/observability/timeseries?days=${selectedTimeRange}`)
        ]);

        setDashboardSummary(prev => ({
          ...prev,
          ...summaryRes.data
        }));
        
        setTimeseriesData(prev => ({
          ...prev,
          ...timeseriesRes.data
        }));

        setErrors(prev => ({
          ...prev,
          dashboard: null,
          timeseries: null
        }));
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setErrors(prev => ({
          ...prev,
          dashboard: 'Failed to load dashboard data',
          timeseries: 'Failed to load timeseries data'
        }));
      } finally {
        setDashboardLoading(false);
        setTimeseriesLoading(false);
        setProgress(100);
      }
    };

    fetchDashboardData();
  }, [selectedTimeRange]);

  // Update handleDonutClick function to handle 'all' case
  const handleDonutClick = async (view, status) => {
    console.log('handleDonutClick called with:', { view, status });
    
    if (view === activeView && status === selectedStatus) {
      setActiveView(null);
      setSelectedStatus(null);
      setColumnFilters({});
      return;
    }

    setCurrentPage(1);
    setPerPage(10);
    setTableLoading(true);
    setActiveView(view);
    setSelectedStatus(status);
    setProgress(prev => Math.min(prev + 20, 90));

    try {
      let endpoint = `${config.url.OBSERVABILITY}/api/observability/`;
      
      switch(view) {
        case 'configured':
          endpoint += `configured_tests?days=${selectedTimeRange}`;
          if (status !== 'all') {
            endpoint += `&status=${status}`;
          }
          break;
        case 'executions':
          endpoint += `test_executions?days=${selectedTimeRange}`;
          break;
        case 'coverage':
          endpoint += `table_coverage?days=${selectedTimeRange}`;
          break;
        case 'freshness':
          endpoint += `table_freshness?days=${selectedTimeRange}`;
          break;
        default:
          return;
      }

      console.log('DEBUG - Endpoint:', endpoint);
      console.log('DEBUG - View:', view);
      console.log('DEBUG - Status:', status);

      const response = await axios.get(endpoint);
      console.log('DEBUG - Response data:', response.data.slice(0, 2));

      if (response.data && Array.isArray(response.data)) {
        if (view === 'configured') {
          setConfiguredTests(response.data);
          setTableData(response.data);
          console.log('DEBUG - Set configured tests data:', response.data.length, 'rows');
        } else {
          setTableData(response.data);
        }

        // Clear all filters if status is 'all'
        if (status === 'all') {
          setColumnFilters({});
        } else {
          let statusValue; // Declare statusValue here

          // Set status filter based on view type
          if (view === 'freshness') {
            // Table Freshness mapping
            const freshnessMap = {
              'Fresh': 'Fresh',
              'Over 24h': 'Over 24h',
              'Over 1w': 'Over 1w',
              'Failed': 'Failed',
              'Never': 'Never'
            };
            statusValue = freshnessMap[status];
            setColumnFilters({
              'materialized_status': {
                values: [statusValue],
                searchTerm: ''
              }
            });
          } else if (view === 'configured') {
            // Tables Health mapping
            const tableHealthMap = {
              'Healthy': 'pass',
              'Failed': 'fail',
              'Warning': 'warn'
            };
            statusValue = tableHealthMap[status];
            console.log('Tables Health - Original Status:', status);
            console.log('Tables Health - Mapped Status:', statusValue);
            console.log('Tables Health - Data Sample:', response.data.slice(0, 3));

            // Set the data first
            setTableData(response.data);

            // Then set the filter
            if (status === 'all') {
              setColumnFilters({});
            } else {
              console.log('Setting status filter to:', statusValue);
              if (statusValue) {  // Only set filter if we have a valid mapped status
                setColumnFilters({
                  'status': {
                    values: [statusValue],
                    searchTerm: ''
                  }
                });
              } else {
                console.error('Invalid status mapping for:', status);
                setColumnFilters({});  // Clear filters if mapping failed
              }
            }

            // Debug log the filtered data
            const filteredData = response.data.filter(row => 
              status === 'all' || row.status === statusValue
            );
            console.log('Tables Health - Filtered Data Count:', filteredData.length);
            console.log('Tables Health - Filter Applied:', { status: statusValue });
            console.log('Tables Health - Sample filtered row:', filteredData[0]);
          } else if (view === 'executions') {
            // Test Results mapping
            const testResultsMap = {
              'Passed': 'Pass',
              'Failed': 'Fail',
              'warn': 'Warning'
            };
            statusValue = testResultsMap[status];
            setColumnFilters({
              'status': {
                values: [statusValue],
                searchTerm: ''
              }
            });
          } else if (view === 'coverage') {
            // Coverage mapping
            statusValue = status.toLowerCase();
            setColumnFilters({
              'monitoring_status': {
                values: [statusValue],
                searchTerm: ''
              }
            });
          }
        }
      }

      setErrors(prev => ({
        ...prev,
        table: null
      }));
    } catch (error) {
      console.error('Error fetching table data:', error);
      setErrors(prev => ({
        ...prev,
        table: 'Failed to load table data'
      }));
    } finally {
      setTableLoading(false);
      setProgress(100);
    }
  };

  // Update the onSortColumn function
  const onSortColumn = (column) => {
    // Convert column header to data field name
    const fieldMap = {
      'Test Name': 'test_name',
      'Table': 'table_name',
      'Schema': 'schema_name',
      'Status': 'status',
      'Execution Time': 'execution_time',
      'Test Type': 'test_type',
      'Last Status': 'status',
      'Last Run': 'last_run',
      'Last Materialized': 'last_updated',
      'Test Count': 'test_count'
    };
    
    const fieldName = fieldMap[column];
    if (!fieldName) return;

    let isAsc = true;
    if (sortedColumn.column === fieldName) {
      isAsc = !sortedColumn.isAsc;
    }

    setSortedColumn({ column: fieldName, isAsc });
    
    const dataToSort = activeView === 'configured' ? [...configuredTests] : [...tableData];
    
    const sortedData = dataToSort.sort((a, b) => {
      let valueA = a[fieldName];
      let valueB = b[fieldName];
      
      // Handle dates
      if (fieldName === 'execution_time' || fieldName === 'last_run' || fieldName === 'last_updated') {
        valueA = valueA ? new Date(valueA) : new Date(0);
        valueB = valueB ? new Date(valueB) : new Date(0);
      }
      // Handle numbers
      else if (fieldName === 'test_count') {
        valueA = Number(valueA) || 0;
        valueB = Number(valueB) || 0;
      }
      // Handle strings
      else {
        valueA = String(valueA || '').toLowerCase();
        valueB = String(valueB || '').toLowerCase();
      }
      
      if (isAsc) {
        return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      } else {
        return valueB > valueA ? 1 : valueB < valueA ? -1 : 0;
      }
    });
    
    if (activeView === 'configured') {
      setConfiguredTests(sortedData);
    } else {
      setTableData(sortedData);
    }
  };

  // Add pagination state if not already present
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(25);

  // Add pagination calculation function
  const calculateTotalPages = (data) => {
    const totalLength = data.filter(filterJobNames).length;
    return totalLength ? Math.ceil(totalLength / perPage) : 1;
  };

  // Update the paginateArray function
  const paginateArray = (array) => {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    return array.slice(startIndex, endIndex);
  };

  // Update the tableConfigs object
  const tableConfigs = {
    executions: {
      columns: ['Test Name', 'Schema', 'Table', 'Column', 'Test Type', 'Test Category', 'Status', 'Execution Time'],
      fields: ['test_name', 'schema_name', 'table_name', 'column_name', 'test_category', 'test_subcategory', 'status', 'execution_time']
    },
    configured: {
      columns: ['Schema', 'Table', 'Tests Passed', 'Warning Tests', 'Failed Tests', 'Status', 'Last Run'],
      fields: ['schema_name', 'table_name', 'passed_tests', 'warning_tests', 'failed_tests_detail', 'status', 'last_run']
    },
    coverage: {
      columns: ['Schema', 'Table', 'Live Tests', 'Status', 'Last Run'],
      fields: ['schema_name', 'table_name', 'live_tests', 'monitoring_status', 'last_updated']
    },
    freshness: {
      columns: ['Schema', 'Table', 'Last Run Status', 'Execute Started At', 'Hours Ago', 'Materialized Status'],
      fields: ['schema_name', 'table_name', 'last_run_status', 'execute_started_at', 'hours_ago', 'materialized_status']
    }
  };

  // Add the filter change handler
  const onFilterChange = (value) => {
    setJobNameFilters(value);
  };

  // Revert filterData to its original working version
  const filterData = (data) => {
    return data.filter(row => {
      return Object.entries(columnFilters).every(([column, filter]) => {
        if (!filter?.values?.length) return true;
        
        const cellValue = row[column];
        if (cellValue === null || cellValue === undefined) return false;
        
        return filter.values.includes(cellValue);
      });
    });
  };

  // Add this new function near other helper functions
  const getConfiguredTestStatusClass = (status) => {
    const statusMap = {
      'Healthy': 'bg-success',
      'Warning': 'bg-warning',
      'Failed': 'bg-danger',
      // Add fallback
      'default': 'bg-secondary'
    };
    return statusMap[status] || statusMap.default;
  };

  // Update the renderTable function
  const renderTable = () => {
    console.log('renderTable called with:', {
      activeView,
      tableData: tableData.length,
      configuredTests: configuredTests.length
    });

    if (!activeView) return null;

    const config = tableConfigs[activeView];
    if (!config) return null;

    // Get the right data source
    const data = tableData;
    console.log('Data to render:', {
      activeView,
      dataLength: data.length,
      sampleRow: data[0]
    });
    
    // Apply filters first
    const filteredData = filterData(data);
    console.log('Filtered data:', {
      beforeLength: data.length,
      afterLength: filteredData.length,
      filters: columnFilters
    });
    
    // Calculate start and end indices for pagination
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    
    // Get the current page's data
    const paginatedData = filteredData.slice(startIndex, endIndex);
    console.log('Paginated data:', {
      startIndex,
      endIndex,
      length: paginatedData.length,
      sampleRow: paginatedData[0]
    });

    return (
      <div className="card mb-3">
        <div className="card-body">
          <TableContainer 
            sx={
              {[`& .${tableHeadClasses.root}`]: {
                backgroundColor: "#f8f9fa",
                height: "52px"
              }}
            }>
            <CustomTable
              isLoading={tableLoading}
              headings={config.columns.map((col, index) => ({
                Children: ({ children }) => (
                  <div className="d-flex justify-content-start gap-1 align-items-center h-100">
                    <span>{col}</span>
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      rootClose
                      overlay={
                        <Popover id={`filter-${col}`} style={{ minWidth: '200px', maxWidth: '300px' }}>
                          <Popover.Body>
                            <input
                              type="text"
                              className="form-control form-control-sm mb-2"
                              placeholder={`Search ${col.toLowerCase()}...`}
                              value={columnFilters[config.fields[index]]?.searchTerm || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setColumnFilters(prev => ({
                                  ...prev,
                                  [config.fields[index]]: {
                                    ...prev[config.fields[index]],
                                    searchTerm: value,
                                    values: prev[config.fields[index]]?.values || []
                                  }
                                }));
                              }}
                            />
                            <div 
                              style={{ 
                                maxHeight: '200px', 
                                overflowY: 'auto',
                                fontSize: '12px',
                                border: '1px solid #dee2e6',
                                borderRadius: '4px'
                              }}
                            >
                              {getUniqueValues(data, config.fields[index])
                                .filter(value => 
                                  !columnFilters[config.fields[index]]?.searchTerm || 
                                  value.toLowerCase().includes((columnFilters[config.fields[index]]?.searchTerm || '').toLowerCase())
                                )
                                .map((value, i) => (
                                  <div
                                    key={i}
                                    className="suggestion-item d-flex align-items-center"
                                    style={{
                                      padding: '6px 8px',
                                      cursor: 'pointer',
                                      borderBottom: '1px solid #dee2e6',
                                      backgroundColor: columnFilters[config.fields[index]]?.values?.includes(value) ? '#e9ecef' : 'white',
                                      ':hover': {
                                        backgroundColor: '#f8f9fa'
                                      }
                                    }}
                                    onClick={() => {
                                      setColumnFilters(prev => {
                                        const currentValues = prev[config.fields[index]]?.values || [];
                                        const newValues = currentValues.includes(value)
                                          ? currentValues.filter(v => v !== value)
                                          : [...currentValues, value];
                                        
                                        return {
                                          ...prev,
                                          [config.fields[index]]: {
                                            ...prev[config.fields[index]],
                                            values: newValues
                                          }
                                        };
                                      });
                                      setCurrentPage(1);
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={columnFilters[config.fields[index]]?.values?.includes(value) || false}
                                      onChange={() => {}}
                                      className="me-2"
                                    />
                                    {config.fields[index] === 'materialized_status' || config.fields[index] === 'status' || config.fields[index] === 'monitoring_status' || config.fields[index] === 'last_run_status' ? (
                                      <span className={`badge ${
                                        value.toLowerCase() === 'pass' || value.toLowerCase() === 'fresh' || value.toLowerCase() === 'healthy' || value.toLowerCase() === 'monitored' || value.toLowerCase() === 'success' ? 'bg-success' :
                                        value.toLowerCase() === 'fail' || value.toLowerCase() === 'failed' ? 'bg-danger' :
                                        value.toLowerCase() === 'warn' || value.toLowerCase() === 'warning' || value.toLowerCase() === 'over 1w' ? 'bg-warning' :
                                        value.toLowerCase() === 'over 24h' ? 'bg-yellow' :
                                        'bg-secondary'
                                      }`}>
                                        {value}
                                      </span>
                                    ) : (
                                      value
                                    )}
                                  </div>
                                ))}
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div style={{ cursor: 'pointer', marginLeft: '4px' }}>
                        <Filter 
                          size={14} 
                          color={columnFilters[config.fields[index]] ? '#0245a0' : '#666'} 
                        />
                      </div>
                    </OverlayTrigger>
                    {children}
                  </div>
                ),
                isSortable: true,
                columnName: col,
              }))}
              sortedColumn={sortedColumn}
              onSortColumn={(columnName) => onSortColumn(columnName)}
              rows={paginatedData.map((row, key) => ({
                key,
                columns: config.fields.map((field) => (
                  {
                    type: "component",
                    component: field === 'table_name' ? (
                      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        {/* For configured tests table */}
                        {activeView === 'configured' && row.table_path && (
                          <Link 
                            to={`/sql/editor?file=models/${row.table_path}`}
                            style={{ 
                              color: '#0245a0',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Icon.ExternalLink size={12} />
                          </Link>
                        )}
                        {/* For monitored tables table */}
                        {activeView === 'coverage' && row.table_path && (
                          <Link 
                            to={`/sql/editor?file=models/${row.table_path}`}
                            style={{ 
                              color: '#0245a0',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Icon.ExternalLink size={12} />
                          </Link>
                        )}
                        {/* For test executions table */}
                        {activeView === 'executions' && row.table_path && (
                          <Link 
                            to={`/sql/editor?file=models/${row.table_path}`}
                            style={{ 
                              color: '#0245a0',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Icon.ExternalLink size={12} />
                          </Link>
                        )}
                        {row[field]}
                      </div>
                    ) : field === 'status' && activeView === 'configured' ? (
                      <span 
                        className={`badge ${getConfiguredTestStatusClass(row[field])}`}
                        style={{
                          padding: "4px 8px",
                          fontSize: "11px",
                          fontWeight: 500,
                          textTransform: "capitalize",
                          letterSpacing: "0.3px"
                        }}
                      >
                        {row[field]}
                      </span>
                    ) : field === 'status' || field === 'last_status' || field === 'last_run_status' || field === 'monitoring_status' ? (
                      <span 
                        className={`badge ${
                          row[field]?.toLowerCase() === 'pass' || row[field]?.toLowerCase() === 'success' ? 'bg-success' : 
                          row[field]?.toLowerCase() === 'warn' || row[field]?.toLowerCase() === 'warning' ? 'bg-warning' :
                          row[field]?.toLowerCase() === 'fail' || row[field]?.toLowerCase() === 'failed' ? 'bg-danger' :
                          row[field]?.toLowerCase() === 'monitored' ? 'bg-success' :
                          'bg-secondary'
                        }`}
                        style={{
                          padding: "4px 8px",
                          fontSize: "11px",
                          fontWeight: 500,
                          textTransform: "capitalize",
                          letterSpacing: "0.3px",
                          backgroundColor: row[field]?.toLowerCase() === 'warn' ? '#FFA726' : undefined
                        }}
                      >
                        {row[field]?.toLowerCase() || 'unknown'}
                      </span>
                    ) : field === 'execution_time' || field === 'last_run' || field === 'last_updated' ? (
                      row[field] ? 
                        moment(row[field])
                          .tz(customer_timezone)
                          ?.format('YYYY-MM-DD HH:mm:ss z') 
                        : '-'
                    ) : field === 'execute_started_at' ? (
                      row[field] ? 
                        moment(row[field])
                          .tz(customer_timezone)
                          ?.format('YYYY-MM-DD HH:mm:ss z') 
                        : '-'
                    ) : field === 'materialized_status' ? (
                      <span 
                        className={`badge ${
                          row[field] === 'Fresh' ? 'bg-success' :
                          row[field] === 'Failed' ? 'bg-danger' :
                          row[field] === 'Never' ? 'bg-secondary' :
                          row[field] === 'Over 24h' ? 'bg-yellow' :
                          row[field] === 'Over 1w' ? 'bg-warning' :
                          'bg-secondary'
                        }`}
                        style={{
                          backgroundColor: row[field] === 'Over 1w' ? '#FF7043' : undefined  // Custom orange color
                        }}
                      >
                        {row[field]}
                      </span>
                    )  : field === 'hours_ago' ? (
                      row[field] !== null ? `${row[field]}` : ''  // Return empty string if null
                    )
                    : (
                      row[field] || '-'
                    )
                  }
                )),
                style: {animation: `fadeIn 0.3s ease-out ${key * 0.05}s`}
              }))}
              loadingOptions={{
                rows: activeView === 'executions' && selectedStatus === 'failed' ? dashboardSummary.test_results.failed :
                activeView === 'executions' && selectedStatus === 'passed' ? dashboardSummary.test_results.passed :
                activeView === 'configured' && selectedStatus === 'healthy' ? dashboardSummary.table_health.healthy :
                activeView === 'configured' && selectedStatus === 'failed' ? dashboardSummary.table_health.unhealthy :
                activeView === 'coverage' && selectedStatus === 'monitored' ? dashboardSummary.monitored_tables.monitored :
                activeView === 'coverage' && selectedStatus === 'unmonitored' ? 
                  (dashboardSummary.monitored_tables.total - dashboardSummary.monitored_tables.monitored) :
                10  // Default fallback
              }}
            />
          </TableContainer>

          <TablePagination
            currentPage={currentPage}
            totalPages={Math.ceil(filteredData.length / perPage)}
            updatePageNum={(num) => setCurrentPage(num)}
            perPage={perPage}
            updatePerPage={(num) => {
              setPerPage(num);
              setCurrentPage(1);
            }}
            totalItems={filteredData.length}
          />
        </div>
      </div>
    );
  };

  // Add these styles
  const styles = `
    .table-lists {
      width: 100%;
      margin-bottom: 0;
      border-spacing: 0;
    }

    .table-daged {
      --bs-table-striped-bg: rgba(0, 0, 0, 0.02);
    }

    .table-hover tbody tr:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    .table-bordered {
      border: 1px solid #dee2e6;
    }

    .table-triped tbody tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.02);
    }

    .my-8px {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    th.resizables {
      background-color: #f8f9fa;
      font-weight: 500;
      color: #344054;
      padding: 8px 16px;
      border-bottom: 1px solid #dee2e6;
    }

    td {
      padding: 8px 16px;
      border-bottom: 1px solid #dee2e6;
      color: #344054;
      font-size: 0.875rem;
    }

    .badge {
      padding: 4px 8px;
      font-size: 0.75rem;
      font-weight: 500;
      border-radius: 4px;
    }

    .bg-success {
      background-color: #4CAF50 !important;
      color: white;
    }

    .bg-warning {
      background-color: #FFA726 !important;
      color: white;
    }

    .bg-danger {
      background-color: #f44336 !important;
      color: white;
    }

    .truncate {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `;

  // Add progress state
  const [progress, setProgress] = useState(40);

  // Add this with other state declarations at the top of the component
  const [selectedStatus, setSelectedStatus] = useState(null);

  // Add state for column filters
  const [columnFilters, setColumnFilters] = useState({});
  const [uniqueValues, setUniqueValues] = useState({});

  // Add function to get unique values for a column
  const getUniqueValues = (data, field) => {
    const values = new Set();
    data.forEach(row => {
      if (row[field] !== null && row[field] !== undefined) {
        if (field === 'execution_time' || field === 'last_run' || field === 'last_updated') {
          // Format dates consistently
          const dateStr = moment(row[field])?.format('YYYY-MM-DD');
          values.add(dateStr);
        } else if (typeof row[field] === 'number') {
          values.add(row[field].toString());
        } else {
          values.add(String(row[field]));
        }
      }
    });
    return Array.from(values).sort();
  };

  return (
    <div>
      <LoadingBar color="#0245a0" progress={progress} />
      <div className="container-fluid py-3">
        <Helmet title="Datajolt | Data Observability" />
        
        {/* Title section */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "12px 8px",
            height: "45px",
          }}
        >
          <h4 style={{ marginBottom: 0 }}>
            <b style={{ color: "#090E47", marginRight: "10px" }}>
              Data Observability
            </b>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={
                <Popover id={`popover-positioned-bottom`}>
                  <Popover.Header as="h3">{"What is Data Observability?"}</Popover.Header>
                  <Popover.Body>
                    Data Observability helps you monitor and understand your data quality and reliability.
                    <br />
                    <br />
                    This page provides insights into your data health, test results, and coverage metrics.
                  </Popover.Body>
                </Popover>
              }
            >
              <DetailsIcon />
            </OverlayTrigger>{" "}
          </h4>
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
              alignItems: "center",
              gap: 10,
            }}
          >
            <ButtonGroup>
              <Button 
                variant={selectedTimeRange === 1 ? "primary" : "outline-primary"}
                onClick={() => handleTimeRangeChange(1)}
              >
                Last 24 hours
              </Button>
              <Button 
                variant={selectedTimeRange === 7 ? "primary" : "outline-primary"}
                onClick={() => handleTimeRangeChange(7)}
              >
                Last 7 days
              </Button>
              <Button 
                variant={selectedTimeRange === 30 ? "primary" : "outline-primary"}
                onClick={() => handleTimeRangeChange(30)}
              >
                Last 30 days
              </Button>
              <Button 
                variant={selectedTimeRange === 90 ? "primary" : "outline-primary"}
                onClick={() => handleTimeRangeChange(90)}
              >
                Last 90 days
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <hr style={{ margin: "0 8px" }} /><br/>

        {/* Donut Charts Row */}
        <div className="row g-3 mb-4" style={{ marginBottom: '0px' }}>
          <div className="col-md-3">
            <ChartContainer title={
              <div className="d-flex align-items-center">
                Test Results
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover id="test-results-info">
                      <Popover.Body>
                        Latest unique test results by status
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Info size={14} className="ms-2" style={{ cursor: 'pointer' }} />
                </OverlayTrigger>
              </div>
            }>
              {dashboardLoading ? (
                <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <DatajoltSpinner size="small" />
                </div>
              ) : (
                <div style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                  <DonutChart 
                    data={[
                      { name: 'Passed', value: dashboardSummary.test_results.passed, color: '#4CAF50' },
                      { name: 'Failed', value: dashboardSummary.test_results.failed, color: '#f44336' },
                      { name: 'Warning', value: dashboardSummary.test_results.warning, color: '#FFA726' }
                    ]} 
                    centerText={dashboardSummary.test_results.total.toString()} 
                    subText="Tests" 
                    disabled={errors.dashboard}
                    onSegmentClick={(status) => {
                      // Map the status correctly for warnings
                      let mappedStatus = status;
                      if (status.toLowerCase() === 'warning') {
                        mappedStatus = 'warn';
                      }
                      handleDonutClick('executions', mappedStatus);
                    }}
                  />
                </div>
              )}
            </ChartContainer>
          </div>
          <div className="col-md-3">
            <ChartContainer title={
              <div className="d-flex align-items-center">
                Tables Health
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover id="tables-health-info">
                      <Popover.Body>
                        Health of tables based on the status of their test results
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Info size={14} className="ms-2" style={{ cursor: 'pointer' }} />
                </OverlayTrigger>
              </div>
            }>
              {dashboardLoading ? (
                <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <DatajoltSpinner size="small" />
                </div>
              ) : (
                <div style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                  <DonutChart 
                    data={[
                      { name: 'Healthy', value: dashboardSummary.table_health.healthy, color: '#4CAF50' },
                      { name: 'Failed', value: dashboardSummary.table_health.unhealthy, color: '#f44336' },
                      { name: 'Warning', value: dashboardSummary.table_health.warning, color: '#FFA726' }
                    ]} 
                    centerText={(
                      dashboardSummary.table_health.healthy + 
                      dashboardSummary.table_health.unhealthy + 
                      (dashboardSummary.table_health.warning || 0)
                    ).toString()} 
                    subText="Tables" 
                    disabled={errors.dashboard}
                    onSegmentClick={async (status) => {
                      // Special handling just for Tables Health donut
                      if (status === 'all') {
                        setColumnFilters({});
                        setActiveView('configured');
                        setTableLoading(true);  // Add loading state
                        const response = await axios.get(`${config.url.OBSERVABILITY}/api/observability/configured_tests?days=${selectedTimeRange}`);
                        setTableData(response.data);
                        setTableLoading(false);  // Remove loading state
                        return;
                      }

                      // Direct mapping for Tables Health only
                      const statusMap = {
                        'Healthy': 'Healthy',
                        'Failed': 'Failed',
                        'Warning': 'Warning'
                      };

                      const filterValue = statusMap[status];

                      if (filterValue) {
                        setActiveView('configured');
                        setTableLoading(true);  // Add loading state
                        const response = await axios.get(`${config.url.OBSERVABILITY}/api/observability/configured_tests?days=${selectedTimeRange}`);
                        setTableData(response.data);
                        setColumnFilters({
                          'status': {
                            values: [filterValue]
                          }
                        });
                        setTableLoading(false);
                      }
                    }}
                  />
                </div>
              )}
            </ChartContainer>
          </div>
          <div className="col-md-3">
            <ChartContainer title={
              <div className="d-flex align-items-center">
                Monitored Tables
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover id="table-coverage-info">
                      <Popover.Body>
                        Tables with associated tests versus those without
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Info size={14} className="ms-2" style={{ cursor: 'pointer' }} />
                </OverlayTrigger>
              </div>
            }>
              {dashboardLoading ? (
                <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <DatajoltSpinner size="small" />
                </div>
              ) : (
                <div style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                  <DonutChart 
                    data={[
                      { name: 'Monitored', value: dashboardSummary.monitored_tables.monitored, color: '#4CAF50' },
                      { name: 'Unmonitored', value: dashboardSummary.monitored_tables.total - dashboardSummary.monitored_tables.monitored, color: '#E0E0E0' }
                    ]} 
                    centerText={dashboardSummary.monitored_tables.total.toString()} 
                    subText="Tables" 
                    disabled={errors.dashboard}
                    onSegmentClick={(status) => handleDonutClick('coverage', status)}
                  />
                </div>
              )}
            </ChartContainer>
          </div>
          <div className="col-md-3">
            <ChartContainer title={
              <div className="d-flex align-items-center">
                Table Freshness
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover id="freshness-info">
                      <Popover.Body>
                        Status of table materializations and their freshness
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Info size={14} className="ms-2" style={{ cursor: 'pointer' }} />
                </OverlayTrigger>
              </div>
            }>
              {dashboardLoading ? (
                <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <DatajoltSpinner size="small" />
                </div>
              ) : (
                <div style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                  <DonutChart 
                    data={[
                      { name: 'Fresh', value: dashboardSummary.table_freshness.fresh || 0, color: '#4CAF50' },
                      { name: 'Over 24h', value: dashboardSummary.table_freshness.over_24h || 0, color: '#FFD700' },
                      { name: 'Over 1w', value: dashboardSummary.table_freshness.over_1w || 0, color: '#FF7043' },
                      { name: 'Failed', value: dashboardSummary.table_freshness.failed || 0, color: '#f44336' },
                      { name: 'Never', value: dashboardSummary.table_freshness.never || 0, color: '#E0E0E0' }
                    ]} 
                    centerText={(
                      (dashboardSummary.table_freshness.fresh || 0) + 
                      (dashboardSummary.table_freshness.over_24h || 0) +
                      (dashboardSummary.table_freshness.over_1w || 0) +
                      (dashboardSummary.table_freshness.failed || 0) +
                      (dashboardSummary.table_freshness.never || 0)
                    ).toString()} 
                    subText="Tables" 
                    disabled={errors.dashboard}
                    onSegmentClick={(status) => {
                      if (status === 'all') {
                        handleDonutClick('freshness', 'all');
                      } else {
                        const statusMap = {
                          'Fresh': 'Fresh',
                          'Over 24h': 'Over 24h',
                          'Over 1w': 'Over 1w',
                          'Failed': 'Failed',
                          'Never': 'Never'
                        };
                        handleDonutClick('freshness', statusMap[status]);
                      }
                    }}
                  />
                </div>
              )}
            </ChartContainer>
          </div>
        </div>

        {/* Table View */}
          <div className="col">
            {errors.table ? (
              <div className="alert alert-danger">
                {errors.table}
              </div>
            ) : (
              renderTable()
            )}
          </div>

        {/* Line Charts Row */}
        <div className="row g-3 mb-4" style={{ height: '200px' }}>
          <div className="col-md-4 h-100">
            <ChartContainer title={
              <div className="d-flex align-items-center">
                Unique tests executions
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover id="executions-info">
                      <Popover.Body>
                        Daily number of unique test executions overtime
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Info size={14} className="ms-2" style={{ cursor: 'pointer' }} />
                </OverlayTrigger>
              </div>
            }>
              {timeseriesLoading ? (
                <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-40px' }}>
                  <DatajoltSpinner size="small" />
                </div>
              ) : (
                <div style={{ height: '100%', width: '100%' }}>
                  <LineChartComponent 
                    data={timeseriesData.executions || []} 
                    color="#4CAF50" 
                    disabled={errors.timeseries}
                  />
                </div>
              )}
            </ChartContainer>
          </div>
          <div className="col-md-4 h-100">
            <ChartContainer title={
              <div className="d-flex align-items-center">
                Test failures
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover id="failures-info">
                      <Popover.Body>
                        Daily number of unique test failures over time
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Info size={14} className="ms-2" style={{ cursor: 'pointer' }} />
                </OverlayTrigger>
              </div>
            }>
              {timeseriesLoading ? (
                <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-40px' }}>
                  <DatajoltSpinner size="small" />
                </div>
              ) : (
                <div style={{ height: '100%', width: '100%' }}>
                  <LineChartComponent 
                    data={timeseriesData.failures} 
                    color="#f44336" 
                    disabled={errors.timeseries}
                  />
                </div>
              )}
            </ChartContainer>
          </div>
          <div className="col-md-4 h-100">
            <ChartContainer title={
              <div className="d-flex align-items-center">
                Test warnings
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover id="warnings-info">
                      <Popover.Body>
                        Daily number of unique test warnings over time
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Info size={14} className="ms-2" style={{ cursor: 'pointer' }} />
                </OverlayTrigger>
              </div>
            }>
              {timeseriesLoading ? (
                <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-40px' }}>
                  <DatajoltSpinner size="small" />
                </div>
              ) : (
                <div style={{ height: '100%', width: '100%' }}>
                  <LineChartComponent 
                    data={timeseriesData.warnings} 
                    color="#FFA726" 
                    disabled={errors.timeseries}
                  />
                </div>
              )}
            </ChartContainer>
          </div>
        </div>

      <br/>
        <div className="row g-3 mb-4" style={{ height: '200px' }}>
          <div className="col-md-6 h-100">
            <ChartContainer title={
              <div className="d-flex align-items-center">
                Monitored tables (%)
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover id="monitored-info">
                      <Popover.Body>
                        Percentage of tables covered by at least one test over time
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Info size={14} className="ms-2" style={{ cursor: 'pointer' }} />
                </OverlayTrigger>
              </div>
            }>
              {timeseriesLoading ? (
                <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-40px' }}>
                  <DatajoltSpinner size="small" />
                </div>
              ) : (
                <div style={{ height: '100%', width: '100%' }}>
                  <LineChartComponent 
                    data={timeseriesData.monitored_tables} 
                    color="#4CAF50" 
                    disabled={errors.timeseries}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                          max: 100,
                          ticks: {
                            callback: function(value) {
                              return value + '%';
                            }
                          }
                        }
                      },
                      plugins: {
                        legend: {
                          display: false  // Hide the legend
                        },
                        tooltip: {
                          callbacks: {
                            label: function(context) {
                              return context.parsed.y + '%';
                            }
                          }
                        }
                      }
                    }}
                  />
                </div>
              )}
            </ChartContainer>
          </div>
          <div className="col-md-6 h-100">
            <ChartContainer title={
              <div className="d-flex align-items-center">
                Tables with failed tests
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover id="failed-tables-info">
                      <Popover.Body>
                        The number of tables with at least one failed test over time
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Info size={14} className="ms-2" style={{ cursor: 'pointer' }} />
                </OverlayTrigger>
              </div>
            }>
              {timeseriesLoading ? (
                <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-40px' }}>
                  <DatajoltSpinner size="small" />
                </div>
              ) : (
                <div style={{ height: '100%', width: '100%' }}>
                  <LineChartComponent 
                    data={timeseriesData.failed_tables} 
                    color="#f44336" 
                    disabled={errors.timeseries}
                  />
                </div>
              )}
            </ChartContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Observability; 