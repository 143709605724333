import React from "react";
import { DialogTitle, IconButton } from "@mui/material";
import { X } from "react-feather";

/**
 * @param {{children: JSX.Element; onHide: () => void;}} data
 */
const ModalLayoutHeader = ({ children, onHide }) => {
  return (
    <>
      <DialogTitle
        padding={2}
        borderBottom={"1px solid #dee6ed"}
        color="#495057"
        fontSize={11}
        fontWeight={500}
        fontFamily={"Poppins"}
      >
        {children}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onHide}
        sx={{
          position: "absolute",
          right: 16,
          top: 10,
          color: "#495057",
        }}
      >
        <X size={16} />
      </IconButton>
    </>
  );
};

export default ModalLayoutHeader;
