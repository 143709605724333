import {
  Terminal,
  Home,
  RefreshCw,
  Clock,
  UploadCloud,
  BarChart2,
} from "react-feather";


const sidebarLinks = [
  {
    href: "/",
    icon: Home,
    title: "Home",
  },
  {
    href: "/taps/mytaps",
    icon: RefreshCw,
    title: "Manage your data taps",
  },
  {
    href: "/sql/editor",
    icon: Terminal,
    title: "SQL Editor",
  },
  {
    href: "/jobs/overview",
    icon: Clock,
    title: "Scheduled Jobs",
  },
  {
    href: "/jobs/push",
    icon: UploadCloud,
    title: "Data Push",
  },
  {
    href: "/observability",
    icon: BarChart2,
    title: "Data Observability",
  },
];

export default sidebarLinks;
