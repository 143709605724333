import React from "react";
import { Spinner, Button, Modal } from "react-bootstrap";
import * as Icon from "react-feather";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onDeleteBranchService } from "../../services/editor";
import DeleteConnectorModal from "../../components/Modals/DeleteConnector";

const DeleteBranchModal = ({ deleteBranch, setDeleteBranch,fetchBranches }) => {
  const onDeleteBranch = async () => {
    setDeleteBranch({ ...deleteBranch, isDeleting: true });

    const isSuccess = await onDeleteBranchService(deleteBranch.branch);

    if(isSuccess) {
      fetchBranches();
      setDeleteBranch({ isVisible: false, isDeleting: false, branch: "" });
    } else {
      setDeleteBranch({ ...deleteBranch, isDeleting: false });
    }
  };

  return (
    <>
      <ToastContainer />
      <DeleteConnectorModal
        isShown={deleteBranch.isVisible}
        onHide={() =>
          setDeleteBranch({ isVisible: false, branch: "", isDeleting: false })
        }
        onAction={onDeleteBranch}
        deleteData={{
          logo: "/images/dbt.png",
          deleting: deleteBranch.isDeleting
        }}
        text={{
          bold: deleteBranch.branch,
          regular: " branch called ",
          regularFirst: true
        }}
        deleteBtnText="Yes, delete branch"
      />
    </>
  );
};

export default DeleteBranchModal;
