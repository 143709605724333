import React, { Component } from "react";
import {
  Row,
  OverlayTrigger,
  Tab,
  Col,
  Button,
  Form,
  Popover,
} from "react-bootstrap";
import * as Icon from "react-feather";
import StepIndicator from "../../components/DagCreators/StepIndicator";
import { CSSTransition } from "react-transition-group";
import "./dwh.css";
import { ReactComponent as DetailsIcon } from "../../assets/img/icons/details.svg";
import {
  listDWHtapsService,
  saveDWHService,
  testConnectionService,
} from "../../services/dashboard";
import CloseDrawerModal from "../../components/Modals/CloseDrawer";
import { CircularProgress } from "@mui/material"

export default class CreateDataWarehouses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      loading_dwhs: false,
      color: "primary",
      activeTab: "",
      EditOpen: false,
      progress: 100,
      dwh_types: [],
      loading_connectors: false,
    };
  }

  componentDidMount() {
    this.listDWHtaps();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value.trim(),
    });
  };

  handleSelectChange = (e) => {
    this.setState({
      DWHType: e.value.trim(),
    });
  };

  testConnection = async () => {
    this.setState({
      progress: 40,
      loading: true,
      status_message: "",
      logs: "",
    });
    let ConnectionDetails;

    if (this.state.selected_dwh === "Redshift") {
      ConnectionDetails = {
        dwhtype: this.state.selected_dwh,
        endpoint: this.state.DWHEndpoint,
        username: this.state.DWHUsername,
        alias: this.state.Alias,
        password: this.state.DWHPassword,
        dbname: this.state.DBname,
        port: "5439",
      };
    } else if (this.state.selected_dwh === "Snowflake") {
      ConnectionDetails = {
        alias: this.state.Alias,
        dwhtype: this.state.selected_dwh,
        accountid: this.state.AccountID,
        username: this.state.DWHUsername,
        password: this.state.DWHPassword,
        dbname: this.state.DBname,
      };
    }
    await testConnectionService(
      ConnectionDetails,
      this.state.selected_dwh,
      this.setState
    );
  };

  saveDWH = async () => {
    this.setState({ progress: 40, saving: true, status_message: "", logs: "" });
    let ConnectionDetails;

    if (this.state.selected_dwh === "Redshift") {
      ConnectionDetails = {
        dwhtype: this.state.selected_dwh,
        endpoint: this.state.DWHEndpoint,
        username: this.state.DWHUsername,
        alias: this.state.Alias,
        password: this.state.DWHPassword,
        dbname: this.state.DBname,
        port: "5439",
      };
    } else if (this.state.selected_dwh === "Snowflake") {
      ConnectionDetails = {
        alias: this.state.Alias,
        dwhtype: this.state.selected_dwh,
        accountid: this.state.AccountID,
        username: this.state.DWHUsername,
        password: this.state.DWHPassword,
        dbname: this.state.DBname,
      };
    }

    const isSuccess = await saveDWHService(ConnectionDetails);

    if (isSuccess) {
      this.setState({
        activeTab: "dwh",
        progress: 100,
      });
    }

    this.props.toggleDWHCreator();
    this.setState({ progress: 100, saving: false });
  };

  listDWHtaps = async () => {
    this.setState({
      loading_connectors: true,
      color: "primary",
      progress: 40,
      activeTab: "choseDWH",
    });

    const response = await listDWHtapsService();

    if (response) {
      this.setState({ progress: 100, dwh_types: response.data });
    }

    this.setState({ loading_connectors: false, progress: 100 });
  };
  setupDWH = (selected_dwh, logo) => {
    this.setState({
      progress: 40,
      logo: logo,
      selected_dwh: selected_dwh,
      activeTab: "setup",
    });
    this.setState({ progress: 100 });
  };

  handleCloseDWHForm = () => {
    if (this.state.activeTab === "setup") {
      this.setState({ loseProgressModal: !this.state.loseProgressModal });
    } else {
      this.props.toggleDWHCreator();
    }
  };

  render() {
    return (
      <div>
        {this.state.logo && this.state.selected_dwh && (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginLeft: "auto",
              position: "fixed",
              right: "20px",
              top: "65px",
            }}
          >
            <img
              alt="chosen-dwh-logo"
              style={{
                width: "50px",
                height: "50px",
                objectFit: "contain",
              }}
              src={this.state?.logo}
            />
            <b
              style={{
                marginBottom: "0",
                fontWeight: "bold",
                color: "#090E47",
                fontSize: "18px",
              }}
            >
              {this.state?.selected_dwh}
            </b>
          </span>
        )}

        <div className="container-fluid py-3">
          <div style={{ display: "flex" }} className="DWH-form">
            <StepIndicator
              steps={["Select DWH", "Credentials"]}
              activeStep={
                this.state.activeTab === "choseDWH"
                  ? 1
                  : this.state.activeTab === "setup" && 2
              }
            />
            <section
              style={{
                width: "80%",
                position: "relative",
              }}
            >
              <Tab.Container id="taps-tabs" activeKey={this.state.activeTab}>
                <Tab.Content>
                  <CSSTransition
                    in={this.state.activeTab === "choseDWH"}
                    classNames="dwh-form-slide"
                    timeout={300}
                    unmountOnExit
                  >
                    <Tab.Pane eventKey="choseDWH">
                      <div className="card-body">
                        <div
                          className="card"
                          style={{ minHeight: "500px", height: "100%" }}
                        >
                          <h4
                            style={{
                              marginLeft: "20px",
                              marginTop: "30px",
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <b>Data Warehouse Connectors</b>{" "}
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={
                                <Popover id={`popover-positioned-right`}>
                                  <Popover.Header as="h3">
                                    {"What are DWH Connectors?"}
                                  </Popover.Header>
                                  <Popover.Body>
                                    Connectors are the data warehouses currently
                                    supported by Datajolt.
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <DetailsIcon />
                            </OverlayTrigger>
                            <button
                              className="close-dwh"
                              onClick={this.handleCloseDWHForm}
                            >
                              <Icon.X size={18} className="close-dwh-icon" />
                              Close
                            </button>
                          </h4>

                          <Row style={{ marginLeft: "20px" }}>
                            {!this.state.loading_connectors ? (
                              this.state.dwh_types.length > 0 ? (
                                this.state.dwh_types.map((dwh_type) => (
                                  <Col lg="2">
                                    <div
                                      onClick={() =>
                                        this.setupDWH(
                                          dwh_type.value,
                                          dwh_type.logo,
                                          "Data Warehouse"
                                        )
                                      }
                                      className="w-dyn-item"
                                      style={{
                                        display: "inline-block",
                                        marginleft: "5px",
                                      }}
                                    >
                                      <div className="w-embed">
                                        <input
                                          type="hidden"
                                          className="jetboost-list-item"
                                        />
                                      </div>
                                      <div className="card-taps">
                                        <div className="card-taps-body connector-card-taps-body">
                                          <div className="connector-image">
                                            <img
                                              src={dwh_type.logo}
                                              loading="lazy"
                                              alt=""
                                              className="image-contain"
                                            />
                                          </div>
                                          <div className="card-taps-title text-center">
                                            {dwh_type.label}
                                          </div>
                                          <a className="mb-5 w-inline-block">
                                            <div className="card-taps-metadata">
                                              {dwh_type.tap_group}
                                            </div>
                                          </a>
                                          <div className="icons-wrapper"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                ))
                              ) : (
                                ""
                              )
                            ) : (
                              <>
                                <br></br>
                                <center>
                                <CircularProgress size={16} color="#fff" />
                                  <br />
                                  <br />
                                </center>
                              </>
                            )}
                          </Row>
                        </div>
                      </div>
                    </Tab.Pane>
                  </CSSTransition>

                  <CSSTransition
                    in={this.state.activeTab === "setup"}
                    classNames="dwh-form-slide"
                    timeout={300}
                    unmountOnExit
                  >
                    <Tab.Pane eventKey="setup">
                      <div className="card-body">
                        <div
                          className="card"
                          style={{ minHeight: "500px", height: "100%" }}
                        >
                          <h4
                            style={{
                              marginLeft: "20px",
                              marginTop: "30px",
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <b>Data Warehouse Details</b>{" "}
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={
                                <Popover id={`popover-positioned-right`}>
                                  <Popover.Header as="h3">
                                    {"What are DWH Details?"}
                                  </Popover.Header>
                                  <Popover.Body></Popover.Body>
                                </Popover>
                              }
                            >
                              <DetailsIcon />
                            </OverlayTrigger>
                            <button
                              className="close-dwh"
                              onClick={this.handleCloseDWHForm}
                            >
                              <Icon.X size={18} className="close-dwh-icon" />
                              Close
                            </button>
                          </h4>
                          <Col lg="12">
                            <Row>
                              <Col lg="10">
                                <Form>
                                  <Form.Group
                                    className="mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Form.Label>
                                      <b>DWH Alias</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="Alias"
                                      placeholder="Alias"
                                      onChange={this.handleChange}
                                    />
                                  </Form.Group>
                                  {this.state.selected_dwh == "Redshift" ? (
                                    <>
                                      <Form.Group
                                        className="mb-3"
                                        style={{ marginLeft: "20px" }}
                                      >
                                        <Form.Label>
                                          <b>DWH Endpoint</b>
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="DWHEndpoint"
                                          placeholder="Endpoint"
                                          onChange={this.handleChange}
                                        />
                                      </Form.Group>
                                    </>
                                  ) : (
                                    <Form.Group
                                      className="mb-3"
                                      style={{ marginLeft: "20px" }}
                                    >
                                      <Form.Label>
                                        <b>Account ID</b>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="AccountID"
                                        placeholder="Account ID"
                                        onChange={this.handleChange}
                                      />
                                    </Form.Group>
                                  )}
                                  <Form.Group
                                    className="mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Form.Label>
                                      <b>Database Name</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="DBname"
                                      placeholder="Database Name"
                                      onChange={this.handleChange}
                                    />
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Form.Label>
                                      <b>Username</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="DWHUsername"
                                      placeholder="Username"
                                      onChange={this.handleChange}
                                    />
                                  </Form.Group>

                                  <Form.Group
                                    className="mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Form.Label>
                                      <b>Password</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="password"
                                      name="DWHPassword"
                                      placeholder="Password"
                                      onChange={this.handleChange}
                                    />
                                  </Form.Group>
                                </Form>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <Button
                                  style={{
                                    marginLeft: "20px",
                                    marginBottom: "20px",
                                  }}
                                  type="submit"
                                  variant={this.state.color}
                                  size="lg"
                                  disabled={
                                    this.state.loading
                                      ? true
                                      : this.state.color == "success"
                                      ? true
                                      : false
                                  }
                                  onClick={this.testConnection}
                                >
                                  {this.state.color == "primary" && (
                                    <Icon.Play
                                      color="white"
                                      size={15}
                                      style={{
                                        position: "relative",
                                        top: "-2px",
                                        marginRight: "5px",
                                      }}
                                    />
                                  )}
                                  {this.state.color == "primary"
                                    ? this.state.loading
                                      ? "Testing..."
                                      : "Test Connection"
                                    : this.state.color == "success"
                                    ? "Success"
                                    : ""}
                                  &nbsp;
                                  {this.state.color == "success" ? (
                                    <Icon.Check />
                                  ) : (
                                    ""
                                  )}
                                  {this.state.loading ? (
                                    <CircularProgress size={16} color="#fff" />
                                  ) : (
                                    ""
                                  )}
                                </Button>
                                {this.state.color == "success" ? (
                                  <Button
                                    style={{
                                      marginLeft: "20px",
                                      marginBottom: "20px",
                                    }}
                                    type="submit"
                                    variant="primary"
                                    size="lg"
                                    disabled={this.state.saving}
                                    onClick={() => this.saveDWH()}
                                  >
                                    {this.state.saving
                                      ? "Saving..."
                                      : "Save DWH"}
                                    {this.state.saving ? (
                                      <CircularProgress size={16} color="#fff" />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </div>
                      </div>
                    </Tab.Pane>
                  </CSSTransition>
                </Tab.Content>
              </Tab.Container>
            </section>
          </div>
        </div>

        <CloseDrawerModal
          isShown={this.state.loseProgressModal}
          onHide={() => this.setState({ loseProgressModal: false })}
          onAction={() => {
            this.setState({ loseProgressModal: false });
            this.props.toggleDWHCreator();
          }}
          closeableDarwer="DWH creator"
        />
      </div>
    );
  }
}
