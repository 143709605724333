import { useState, useEffect, useRef } from "react";
import { TextField, Autocomplete, Popper } from "@mui/material";
import { Clock } from "react-feather";

const CustomPopper = function (props) {
  return (
    <Popper
      {...props}
      sx={{ "& .MuiPaper-elevation": { margin: "8px 0" } }}
      placement="top-start"
    />
  );
};

export default function AutocompleteInput({
  options,
  onChange,
  sx,
  placeholder,
  onKeyDown,
}) {
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [initialRender, setInitialRender] = useState(true);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!initialRender) {
      onChange(inputRef.current.value);
    } else {
      setInitialRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, value]);

  return (
    <Autocomplete
      slots={{
        popper: CustomPopper,
      }}
      onKeyDown={onKeyDown}
      options={options}
      freeSolo
      value={value}
      inputValue={inputValue}
      onChange={(_, newValue) => {
        setValue(newValue);
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      sx={{
        ...sx,
        mt: 1,
        "& .MuiAutocomplete-listbox > li": `Menlo, Monaco, "Courier New", monospace !important`,
        "& .MuiSvgIcon-root": { mt: "-2px" },
        "& .MuiOutlinedInput-root": {
          height: 18.5,
          "& fieldset": { borderColor: "#dfe0eb" },
          "& input": {
            fontFamily: `Menlo, Monaco, "Courier New", monospace !important`,
            height: 13,
            fontSize: 12,
          },
        },
      }}
      renderOption={(props, option) => (
        <li
          {...props}
          style={{
            color: "#6c757d",
            fontFamily: "Menlo",
            fontSize: 14,
          }}
        >
          <Clock color="#d3d3d3" width="15" style={{ marginRight: "8px" }} />{" "}
          {/* Render the time icon */}
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          inputRef={inputRef}
          sx={{
            mb: 1,
            "& fieldset legend": { display: "none" },
            "& .MuiInputBase-sizeSmall": {
              padding: "0 !important",
            },
            "& .MuiAutocomplete-input": { paddingTop: "0 !important" },
          }}
          {...params}
          label=""
          placeholder={placeholder}
        />
      )}
      size="small"
    />
  );
}
