import React from "react";
import {
  Button,
  Select,
  MenuItem,
  Typography,
  FormControl,
  Stack,
} from "@mui/material";
import { useEffect } from "react";

const perPagesOptions = [10, 20, 30];

const TablePagination = ({
  currentPage,
  totalPages,
  updatePageNum,
  updatePerPage,
  activeTable,
}) => {
  /**
   * @param {"previous" | "next"} direction
   */
  const handlePageChange = (direction) => {
    let newPage = currentPage;

    if (direction === "next" && currentPage < totalPages) {
      newPage = currentPage + 1;
    } else if (direction === "previous" && currentPage > 1) {
      newPage = currentPage - 1;
    }

    updatePageNum(newPage);
  };

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  const handleDropdownChange = (event) => {
    updatePerPage(perPagesOptions[event.target.value]);
    updatePageNum(1);
  };

  useEffect(() => {
    if (activeTable) {
      updatePageNum(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTable]);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      my={2.5}
      mx={1}
    >
      <FormControl variant="outlined">
        <Select
          size="small"
          height="20px"
          onChange={handleDropdownChange}
          defaultValue={0}
          label={null}
          sx={{
            height: "40px",
            backgroundColor: "white",
            borderColor: "#EBEBEB",
            fontFamily: "Poppins",

            "& fieldset": {
              height: "40px",
              top: "1px",
              borderColor: "#EBEBEB",
            },

            "& legend": {
              display: "none",
            },

            "& .MuiSelect-select": {
              color: "#448593",
              fontSize: "12px",
            },
          }}
        >
          {perPagesOptions.map((item, index) => (
            <MenuItem
              key={index}
              value={index}
              sx={{ fontFamily: "Poppins", color: "#448593", fontSize: "12px" }}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography variant="body1" sx={{ color: "#448593", fontSize: 12 }}>
        Page {currentPage} of {totalPages}
      </Typography>

      <Stack gap={1.5} direction={"row"}>
        <Button
          variant="outlined"
          style={{
            height: "40px",
            backgroundColor: "white",
            color: "#448593",
            borderColor: "#EBEBEB",
            textTransform: "capitalize",
            fontSize: 12,
          }}
          onClick={() => handlePageChange("previous")}
          disabled={currentPage <= 1}
        >
          Previous
        </Button>
        <Button
          variant="outlined"
          style={{
            height: "40px",
            backgroundColor: "white",
            color: "#448593",
            borderColor: "#EBEBEB",
            textTransform: "capitalize",
            fontSize: 12,
          }}
          onClick={() => handlePageChange("next")}
          disabled={currentPage >= totalPages}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  );
};

export default TablePagination;
