import React from "react";
import { DialogContentText } from "@mui/material";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";
import { GitBranch } from "react-feather";
/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  onAction: () => void;
 * }} data
 */
const ByPassTestsModal = ({
  isShown,
  onHide,
  onAction,
}) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide} styles={{ zIndex: 2002 }}>
      <ModalLayoutHeader onHide={onHide}>
        Bypass Tests Confirmation
      </ModalLayoutHeader>
      <ModalLayoutContent>
        <DialogContentText
          textAlign={"center"}
          fontSize={11}
          fontFamily={"Poppins"}
          color="#6c757d"
        >
          Are you sure you want to bypass tests? This may impact your production
          DAGs.
        </DialogContentText>
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn color="secondary" onClick={onHide}>
          Cancel
        </ModalLayoutBtn>
        <ModalLayoutBtn color="error" onClick={onAction}>
          <GitBranch size={12} />
          Yes, bypass
        </ModalLayoutBtn>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default ByPassTestsModal;
