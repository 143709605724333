import React, { useState, useEffect, useRef } from "react";
import "./Sidebar.css";
import { ResizableBox } from "react-resizable";
import * as Icon from "react-feather";
import { Form, Button, Collapse } from "react-bootstrap";
import { toast } from "react-toastify";
import { isValidCron } from 'cron-validator';
import { RefreshCw as RefreshIcon } from "react-feather";
import { handleRefreshSerivce, updateDagService } from "../../../services/overview";
import { CircularProgress } from "@mui/material"

const frequenciesOptions = [
  { value: "Hourly", label: "Hourly" },
  { value: "Daily", label: "Daily" },
  { value: "Custom", label: "Custom Cron" },
  { value: "Relative", label: "After another DAG" },
];

const AlertsOptions = [
  { value: "all", label: "When DAG starts and finishes (Success or Failure)" },
  { value: "only_finishes", label: "Only when DAG finishes (Success or Failure)" },
  { value: "only_failures", label: "Only when DAG fails" },
];

const DagEdit = ({ editing_dag_alias,advanced_settings,onClose,fetchDAGs, workflows,editorURL }) => {
  const [skips, setskips] = useState({});
  const [frequencies, setFrequencies] = useState("");
  const [cronExpression, setCronExpression] = useState(""); // New state for cron expression
  const [retries, setRetries] = useState(3);
  const [dagTimeout, setDagTimeout] = useState(60);
  const [alerts, setAlerts] = useState("");
  const [isFrequenciesMenuOpen, setFrequenciesMenuOpen] = useState(false);
  const [isAlertsMenuOpen, setAlertsMenuOpen] = useState(false);
  const [creatingDag, setCreatingDag] = useState(false);
  const [dbtCommand, setDbtCommand] = useState("");
  const [initialFrequencies, setInitialFrequencies] = useState("");
  const [initialCronExpression, setInitialCronExpression] = useState("");
  const [initialRetries, setInitialRetries] = useState(3);
  const [initialDagTimeout, setInitialDagTimeout] = useState(60);
  const [initialAlerts, setInitialAlerts] = useState("");
  const [initialSkips, setInitialSkips] = useState({});
  const [isDAGsListOpen, setIsDAGsListOpen] = useState(false);
  const alertsButtonRef = useRef(null);
  const alertsMenuRef = useRef(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  

  useEffect(() => {
    if (advanced_settings) {
      console.log(advanced_settings);
      setRetries(advanced_settings.retries);
      setskips(advanced_settings.skips || {});
      setDagTimeout(advanced_settings.dag_timeout);
      setFrequencies(advanced_settings.schedule_frequency);
      setCronExpression(advanced_settings.schedule_details);
      setAlerts(advanced_settings.alerts);
      setDbtCommand(advanced_settings.dbt_command.join(" "));
  
      setInitialFrequencies(advanced_settings.schedule_frequency);
      setInitialCronExpression(advanced_settings.schedule_details);
      setInitialRetries(advanced_settings.retries);
      setInitialDagTimeout(advanced_settings.dag_timeout);
      setInitialAlerts(advanced_settings.alerts);
      setInitialSkips(advanced_settings.skips);
    }
  }, [advanced_settings]);

  const handleFrequenciesToggle = () => {
    setIsDAGsListOpen(false);
    setFrequenciesMenuOpen(!isFrequenciesMenuOpen);
  };

  const selectAlerts = (val) => {
    setAlerts(val);
    handleAlertsToggle();
  };

  const handleAlertsToggle = () => {
    setAlertsMenuOpen(!isAlertsMenuOpen);
  };

  const selectFrequencies = (val) => {
    setFrequencies(val);
    handleFrequenciesToggle();
    setCronExpression("");
  };

  const renderAlertsOptions = () =>
    AlertsOptions.map((alertsOption) => (
      <li
        key={alertsOption.value}
        onClick={() => selectAlerts(alertsOption.value)}
        className="schedule-menu-item"
      >
        {alertsOption.label}
      </li>
    ));

  const renderFrequenciesOptions = () =>
    frequenciesOptions.map((frequenciesOption) => (
      <li
        key={frequenciesOption.value}
        onClick={() => selectFrequencies(frequenciesOption.value)}
        className="schedule-menu-item"
      >
        {frequenciesOption.label}
      </li>
    ));

  const updateDAG = async () => {
    if (!frequencies) {
      toast.info("Please provide the schedule frequency");
      return;
    }
    else if (frequencies === "Custom" && !isValidCron(cronExpression, { alias: true, seconds: true })) {
      toast.info("Invalid cron expression");
      return;
    }

    else if (frequencies === "Hourly" && !cronExpression) {
      toast.info("Please input the minute you want your DAG to run at every hour");
      return;
    } else if (frequencies === "Hourly" && cronExpression) {
      const minute = parseInt(cronExpression, 10);
      if (isNaN(minute) || minute < 0 || minute > 59) {
        toast.error("Please input a valid minute (0-59) for hourly DAG run");
        return;
      }
    }
    
    else if (frequencies === "Daily" && !cronExpression) {
      toast.info("Please input the hour you want your DAG to run at every day");
      return;
    } else if (frequencies === "Daily" && cronExpression) {
      const hour = parseInt(cronExpression, 10);
      if (isNaN(hour) || hour < 0 || hour > 23) {
        toast.error("Please input a valid hour (0-23) for daily DAG run");
        return;
      }
    }

    else if (frequencies === "Relative" && !cronExpression) {
        toast.info("Please select a relative DAG");
        return;
      }

    if (
      frequencies === initialFrequencies &&
      cronExpression === initialCronExpression &&
      retries === initialRetries &&
      dagTimeout === initialDagTimeout &&
      alerts === initialAlerts &&
      skips === initialSkips
    ) {
      toast.info("No changes detected to your DAG.");
      return;
    }
    setCreatingDag(true);

    const isSuccess = await updateDagService({
      dag_alias: editing_dag_alias,
      schedule_frequency: frequencies,
      cron_expression: cronExpression,
      dag_timeout: dagTimeout,
      retries: retries,
      alerts: alerts,
      skips: skips
    });

    if(isSuccess) {
      fetchDAGs(true);
      onClose();
    }

    setCreatingDag(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isAlertsMenuOpen && alertsButtonRef.current && alertsMenuRef.current) {
        const buttonRect = alertsButtonRef.current.getBoundingClientRect();
        alertsMenuRef.current.style.top = `${buttonRect.bottom + window.scrollY}px`;
        alertsMenuRef.current.style.left = `${buttonRect.left + window.scrollX}px`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isAlertsMenuOpen]);

  const selectWorkflow = (DAG) => {
    setCronExpression(DAG);
    setIsDAGsListOpen(false);
  }
  const handleRefresh = async () => {
    setIsRefreshing(true);
    
    const isSuccess = await handleRefreshSerivce(editorURL, advanced_settings);

    if(isSuccess) {
      fetchDAGs(true, true);
    }

    setTimeout(() => {
      setIsRefreshing(false);
    }, 2000);
  };

  return (
    <ResizableBox
      className={`dragged-box react-resizable sidebar-modal-wrapper`}
      height={window.innerHeight - 50}
      width={window.innerWidth / 3}
      maxConstraints={[window.innerWidth - 65, window.innerHeight - 50]}
      minConstraints={[400, 400]}
      resizeHandles={["w"]}
      id="active-modal-sidebar"
    >
      <section className="schedule-sidebar-content">
        <h2 className="heading-schedule-model">
        <small
          style={{
            fontWeight: 500,
            fontSize: 12,
            border: "2px solid #D0D5DD",
            borderRadius: "7px",
            display: "flex",
            alignItems: "center",
            padding: "12px 15px",
            height: "48px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >            <Icon.ChevronsRight
        color="#438593"
        onClick={onClose}
        role="button"
      />{" "}
          Edit DAG
        </small>
        </h2>
        <Form>
          <Form.Group controlId="modelName" className="mb-3">
            <Form.Label>DAG alias</Form.Label>
            <Form.Control
              value={editing_dag_alias}
              type="text"
              placeholder="Enter DAG alias"
              disabled
            />
          </Form.Group>
        </Form>
        <Form.Group controlId="modelName" className="mb-3">
            <Form.Label>DBT Command</Form.Label>
            <Form.Control
              value={dbtCommand}
              type="text"
              disabled
            />
          </Form.Group>
            <Form.Group controlId="schedule_freq">
            <Form.Label>Schedule Frequency</Form.Label>
            <div style={{ position: 'relative', display: 'flex', gap: '10px' }}>
              <div style={{flex: 1, position: "relative"}}>
                <Button
                  onClick={handleFrequenciesToggle}
                  aria-controls="schedule-collapse-frequencies"
                  aria-expanded={isFrequenciesMenuOpen}
                  className={`collapse-schedule ${
                    frequencies && "collapse-schedule-chosen"
                  }`}
                  style={{ flex: 1 }}
                >
                  {frequencies
                    ? frequenciesOptions.find((opt) => opt.value === frequencies).label
                    : "Select schedule"}
                </Button>
                <Collapse in={isFrequenciesMenuOpen}>
                  <ul id="schedule-collapse-frequencies" className="schedule-menu">
                    {renderFrequenciesOptions()}
                  </ul>
                </Collapse>
              </div>
            {frequencies === "Relative" ? (
              <div style={{ flex: 1, position: "relative", maxWidth: "calc(50% - 5px)" }}>
                <Button
                  onClick={() => {
                    setFrequenciesMenuOpen(false);
                    setIsDAGsListOpen((value) => !value)
                  }}
                  aria-controls="schedule-collapse-dags"
                  aria-expanded={isDAGsListOpen}
                  className={`collapse-schedule ${
                    cronExpression && "collapse-schedule-chosen"
                  }`}
                  style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                >
                {cronExpression && workflows
                  ? workflows.find((workflow) => workflow.dag_id === cronExpression)?.workflowname ?? "Select DAG"
                  : "Select DAG"}
                </Button>
                <Collapse in={isDAGsListOpen}>
                  <ul id="schedule-collapse-dags" className="schedule-menu">
                    {workflows
                      .filter(workflow => workflow.workflowname !== editing_dag_alias)
                      .map(workflow => (
                        <li
                          key={workflow.dag_id}
                          onClick={() => selectWorkflow(workflow.dag_id)}
                          className="schedule-menu-item"
                        >
                          {workflow.workflowname}
                        </li>
                      )
                    )}
                  </ul>
                </Collapse>
              </div>
            ) : (
                <Form.Control
                  type="text"
                  value={cronExpression}
                  onChange={(e) => setCronExpression(e.target.value)}
                  placeholder= {frequencies === "Custom" ? "Enter cron expression" :  frequencies === "Hourly" ? "Minute to run at" : "Hour to run at"}
                  className={`collapse-schedule ${
                    frequencies && "collapse-schedule-chosen"
                  }`}  
                  style={{ flex: 1 }}
                />
            )}
            </div>
          </Form.Group>
              <Form.Group controlId="alerts">
                <Form.Label>Slack Alert</Form.Label>
                <div style={{ position: 'relative' }}>
                  <Button
                    ref={alertsButtonRef}
                    onClick={handleAlertsToggle}
                    aria-controls="schedule-collapse-alerts"
                    aria-expanded={isAlertsMenuOpen}
                    className={`collapse-schedule ${
                      alerts && "collapse-schedule-chosen"
                    }`}
                  >
                    {alerts
                      ? AlertsOptions.find((opt) => opt.value === alerts).label
                      : "Should we tell you when your DAG starts, succeeds or fails?"}
                  </Button>
                  <Collapse in={isAlertsMenuOpen}>
                    <ul
                      id="schedule-collapse-alerts"
                      className="schedule-menu"
                      ref={alertsMenuRef}
                    >
                      {renderAlertsOptions()}
                    </ul>
                  </Collapse>
                </div>
              </Form.Group>
              <Form.Group controlId="dag_timeout">
                <Form.Label>DAG Timeout (in minutes)</Form.Label>
                <Form.Control
                  type="number"
                  value={dagTimeout}
                  onChange={(e) => setDagTimeout(e.target.value)}
                  placeholder="Your DAG will automatically fail after this time (default: 60)"
                />
              </Form.Group>
              <Form.Group controlId="retries">
                <Form.Label>Retries</Form.Label>
                <Form.Control
                  type="number"
                  value={retries}
                  onChange={(e) => setRetries(e.target.value)}
                  placeholder="Retries before considering the DAG failed (default: 3)"
                />
              </Form.Group>
              <Form.Group controlId="sources">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <Form.Label className="mb-0">Skip Sources (in minutes) - 0 minutes default means never skip</Form.Label>
                  {isRefreshing ? (
                    <CircularProgress size={16} color="#fff" sx={{mt: "3px"}} />
                  ) : (
                    <Button 
                      variant="no-outline-secondary" 
                      size="sm"
                      onClick={handleRefresh}
                    >
                      <RefreshIcon size={14} />
                    </Button>
                  )}
                </div>
                {isRefreshing ? (
                  <div>Identifying sources, please wait...</div>
                ) : Object.keys(skips).length > 0 ? (
                  Object.keys(skips).map((sourceName) => (
                    <div key={sourceName} className="d-flex mb-2">
                      <Form.Control
                        type="text"
                        value={sourceName}
                        disabled
                        className="mr-2"
                        style={{ width: '60%' }}
                      />
                      <Form.Control
                        type="number"
                        value={skips[sourceName]}
                        onChange={(e) => {
                          const updatedSkips = { ...skips };
                          updatedSkips[sourceName] = e.target.value === '' ? '' : parseInt(e.target.value, 10);
                          setskips(updatedSkips);
                        }}
                        style={{ width: '40%' }}
                      />
                    </div>
                  ))
                ) : (
                  <div>No source identified</div>
                )}
              </Form.Group>

        <div className="d-flex justify-content-between gap-2 mt-auto schedule-model-btns-wrapper">
          <Button
            className="w-100 sc-btn"
            disabled={creatingDag}
            onClick={updateDAG}
          >
            Edit my DAG {" "}
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" className="button-icon">
              <path d="M505.12019,19.09375c-1.18945-5.53125-6.65819-11-12.207-12.1875C460.716,0,435.507,0,410.40747,0,307.17523,0,245.26909,55.20312,199.05238,128H94.83772c-16.34763.01562-35.55658,11.875-42.88664,26.48438L2.51562,253.29688A28.4,28.4,0,0,0,0,264a24.00867,24.00867,0,0,0,24.00582,24H127.81618l-22.47457,22.46875c-11.36521,11.36133-12.99607,32.25781,0,45.25L156.24582,406.625c11.15623,11.1875,32.15619,13.15625,45.27726,0l22.47457-22.46875V488a24.00867,24.00867,0,0,0,24.00581,24,28.55934,28.55934,0,0,0,10.707-2.51562l98.72834-49.39063c14.62888-7.29687,26.50776-26.5,26.50776-42.85937V312.79688c72.59753-46.3125,128.03493-108.40626,128.03493-211.09376C512.07526,76.5,512.07526,51.29688,505.12019,19.09375ZM384.04033,168A40,40,0,1,1,424.05,128,40.02322,40.02322,0,0,1,384.04033,168Z"></path>
            </svg>
          </Button>
        </div>
      </section>
    </ResizableBox>
  );
};

export default DagEdit;