import { create } from "zustand";

const initialState = {
  activeTab: "+",
  files_history: {
    "+": [{ date: "File Changes", author: "Just click to revert!" }],
  },
  files: { "+": "/* Write your SQL here! */" },
  selected_shas: {},
  unsaved_files: {},
  expanded_list: [],
};

export const useEditor = create((set) => ({
  activeTab: "+",
  files_history: {
    "+": [{ date: "File Changes", author: "Just click to revert!" }],
  },
  files: { "+": "/* Write your SQL here! */" },
  selected_shas: {},
  unsaved_files: {},
  expanded_list: [],
  reset: () => set(() => initialState),
  // Expanded list
  saveExpandedList: (expanded_list) => set(() => ({ expanded_list })),
  // Active Tab
  updateActiveTab: (activeTab) => set(() => ({ activeTab })),
  // Selected Shas
  updateSelectedShas: (updatedState) =>
    set(({ selected_shas }) => ({
      selected_shas: Object.assign(selected_shas, updatedState),
    })),
  removeSelectedShas: (removeState) =>
    set(({ selected_shas }) => ({
      selected_shas: Object.fromEntries(
        Object.entries(selected_shas).filter(([key]) => key !== removeState)
      ),
    })),
  // Unsaved files
  updateUnsavedFiles: (updatedState) =>
    set(({ unsaved_files }) => ({
      unsaved_files: Object.assign(unsaved_files, updatedState),
    })),
  removeUnsavedFiles: (removeState) =>
    set(({ unsaved_files }) => ({
      unsaved_files: Object.fromEntries(
        Object.entries(unsaved_files).filter(([key]) => key !== removeState)
      ),
    })),
  // Files history
  updateFilesHistory: (updatedState) =>
    set(({ files_history }) => ({
      files_history: Object.assign(files_history, updatedState),
    })),
  insertFilesHistory: (data) =>
    set(({ files_history }) => {
      return { files_history };
      // const stateValue = Object.values(data);
      // const findState = Object.keys(
      //   Object.fromEntries(
      //     Object.entries(files_history).filter(([key]) =>
      //       key.includes(Object.keys(data))
      //     )
      //   )
      // );
      // return {
      //   files_history: {
      //     ...files_history,
      //     [findState]: [stateValue[0], ...files_history[findState]],
      //   },
      // };
    }),
  // Files
  removeFilesHistory: (removeState) =>
    set(({ files_history }) => ({
      files_history: Object.fromEntries(
        Object.entries(files_history).filter(([key]) => key !== removeState)
      ),
    })),
  updateFiles: (newFiles) =>
    set(({ files }) => ({ files: { ...files, ...newFiles } })),
  removeFiles: (removeState) =>
    set(({ files }) => ({
      files: Object.fromEntries(
        Object.entries(files).filter(([key]) => key !== removeState)
      ),
    })),
  reorderFiles: (newFiles) =>
    set(({ files }) => ({
      files: newFiles.reduce(
        (prev, cur) => ({ ...prev, [cur]: files[cur] }),
        {}
      ),
    })),
}));
