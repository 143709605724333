import React from "react";
import { Outlet } from "react-router-dom";
import Main from "../components/Layout/Main";

const Auth = ({ children }) => (
  <React.Fragment>
    <div
      style={{ backgroundImage: "radial-gradient(#090F47, #090F47, #090F47)" }}
    >
      <Main className="d-flex w-100 justify-content-center">
        {children}
        <Outlet />
      </Main>
    </div>
  </React.Fragment>
);

export default Auth;
