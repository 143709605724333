import Editor, { DiffEditor } from "@monaco-editor/react";
import React, { useState } from "react";
import { Stack, CircularProgress } from "@mui/material";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { insertFilesHistory, updateFiles, updateSelectedShas, updateUnsavedFiles } from "../../redux/slices/editorSlice";
import { debounce } from "lodash";

let oldDecorations = {};

const MonacoEditor = ({
  editor_height,
  handleEditorDidMount,
  handleEditorChange,
  editorSuggestedCode,
  updateSuggestedCode,
  removeSuggestedCode,
}) => {
  const activeTab = useSelector(state => state.activeTab)
  const files = useSelector(state => state.files)
  const files_history = useSelector(state => state.files_history)
  const dispatch = useDispatch();
  const [changeCount, setChangeCount] = useState(0);

  const addToFileHistory = (value) => {
    if (
      files_history[activeTab] &&
      files_history[activeTab].length > 0 &&
      files_history[activeTab][0]["date"] !== "RIGHT NOW"
    ) {
      dispatch(updateUnsavedFiles({ [activeTab]: value.trim() }));
      dispatch(insertFilesHistory({
        [activeTab]: {
          date: "RIGHT NOW",
          author: "Unsaved Changes",
          sha: "unsaved",
        },
      }));
      dispatch(updateSelectedShas({ [activeTab]: "unsaved" }));
    } else {
      dispatch(updateUnsavedFiles({ [activeTab]: value.trim() }));
    }
  };

  const handleExistingCodeMarkers = (suggestedCode, originalCode) => {
    if (!suggestedCode || !originalCode) return suggestedCode;

    const suggestedLines = suggestedCode.split('\n');
    const originalLines = originalCode.split('\n');
    let resultLines = [];
    let currentOriginalLine = 0;
    let hasEndChanges = false;
    
    for (let i = 0; i < suggestedLines.length; i++) {
      const line = suggestedLines[i];
      
      if (line.trim() === '// ... existing code ...') {
        // Find the context (3 lines before and after the marker)
        const prevLines = suggestedLines.slice(Math.max(0, i - 3), i)
          .filter(l => l.trim() && l.trim() !== '// ... existing code ...')
          .map(l => l.trim());

        // If this is the last marker in the file, include all remaining original content
        const isLastMarker = i === suggestedLines.findLastIndex(l => l.trim() === '// ... existing code ...');
        
        // Find start position using previous context
        let startLine = currentOriginalLine;
        if (prevLines.length > 0) {
          // Use progressive context matching (1, 2, then 3 lines) until we find a unique match
          let foundMatch = false;
          
          for (let contextSize = 1; contextSize <= Math.min(3, prevLines.length); contextSize++) {
            const contextToCheck = prevLines.slice(prevLines.length - contextSize);
            const matches = [];
            
            for (let j = currentOriginalLine; j < originalLines.length - contextToCheck.length + 1; j++) {
              let allMatch = true;
              for (let k = 0; k < contextToCheck.length; k++) {
                if (originalLines[j + k].trim() !== contextToCheck[k]) {
                  allMatch = false;
                  break;
                }
              }
              
              if (allMatch) {
                matches.push(j + contextToCheck.length);
              }
            }
            
            if (matches.length === 1) {
              startLine = matches[0];
              foundMatch = true;
              break;
            } else if (matches.length > 0 && contextSize === Math.min(3, prevLines.length)) {
              startLine = matches[0];
              foundMatch = true;
              break;
            }
          }
          
          if (!foundMatch && prevLines.length > 0) {
            // Fallback: try to find just the last line anywhere in the file
            for (let j = currentOriginalLine; j < originalLines.length; j++) {
              if (originalLines[j].trim() === prevLines[prevLines.length - 1]) {
                startLine = j + 1;
                break;
              }
            }
          }
        }

        if (isLastMarker) {
          // For the last marker, use multiple context lines to find the correct position
          if (prevLines.length > 0) {
            // Start with just the last line for context, then try more if needed
            let foundMatch = false;
            let matchPosition = -1;
            
            // Try with increasing context (1, 2, then 3 lines) until we find a unique match
            for (let contextSize = 1; contextSize <= Math.min(3, prevLines.length); contextSize++) {
              const contextToCheck = prevLines.slice(prevLines.length - contextSize);
              
              // Find all positions where this context appears
              const matches = [];
              for (let j = 0; j < originalLines.length - contextToCheck.length + 1; j++) {
                // Check if all context lines match at this position
                let allMatch = true;
                for (let k = 0; k < contextToCheck.length; k++) {
                  if (originalLines[j + k].trim() !== contextToCheck[k]) {
                    allMatch = false;
                    break;
                  }
                }
                
                if (allMatch) {
                  matches.push(j + contextToCheck.length);
                }
              }
              
              // If we found exactly one match, use it
              if (matches.length === 1) {
                console.log("Found 1 match");
                matchPosition = matches[0];
                foundMatch = true;
                break;
              }
              // If we found multiple matches but used all available context, use the first one
              else if (matches.length > 0 && contextSize === Math.min(3, prevLines.length)) {
                console.log("Found multiple matches");
                matchPosition = matches[0];
                foundMatch = true;
                console.warn(`Found ${matches.length} matches with max context, using first match`);
                break;
              }
            }
            
            if (foundMatch) {
              startLine = matchPosition;
            } else {
              console.warn("Could not find context match for last marker");
              // Use the current position as fallback
              startLine = currentOriginalLine;
            }
          }
          
          // Include all remaining original content
          resultLines.push(...originalLines.slice(startLine));
          
          // We shouldn't have any content after the last marker
          if (i < suggestedLines.length - 1) {
            console.warn("Unexpected content after last 'existing code' marker");
          }
          
          break;
        } else {
          // Normal case - find next context match
          const nextLines = suggestedLines.slice(i + 1, i + 4)
            .filter(l => l.trim() && l.trim() !== '// ... existing code ...')
            .map(l => l.trim());

          // Use next lines to find the end position
          let endLine = originalLines.length;
          
          if (nextLines.length > 0) {
            // Use progressive context matching for next lines too
            let foundMatch = false;
            
            for (let contextSize = 1; contextSize <= Math.min(3, nextLines.length); contextSize++) {
              const contextToCheck = nextLines.slice(0, contextSize);
              const matches = [];
              
              for (let j = startLine; j < originalLines.length - contextToCheck.length + 1; j++) {
                let allMatch = true;
                for (let k = 0; k < contextToCheck.length; k++) {
                  if (originalLines[j + k].trim() !== contextToCheck[k]) {
                    allMatch = false;
                    break;
                  }
                }
                
                if (allMatch) {
                  matches.push(j);
                }
              }
              
              if (matches.length === 1) {
                endLine = matches[0];
                foundMatch = true;
                break;
              } else if (matches.length > 0 && contextSize === Math.min(3, nextLines.length)) {
                endLine = matches[0];
                foundMatch = true;
                break;
              }
            }
            
            if (!foundMatch && nextLines.length > 0) {
              // Fallback: try to find just the first next line
              for (let j = startLine; j < originalLines.length; j++) {
                if (originalLines[j].trim() === nextLines[0]) {
                  endLine = j;
                  break;
                }
              }
            }
          }

          // Copy all lines from original between start and end
          for (let j = startLine; j < endLine; j++) {
            resultLines.push(originalLines[j]);
          }
          currentOriginalLine = endLine;
        }
      } else {
        resultLines.push(line);
        if (line.trim()) currentOriginalLine++;
      }
    }

    // Add an empty line if there are changes at the end
    if (hasEndChanges) {
      resultLines.push('');
    }

    return resultLines.join('\n');
  };

  const mergeChanges = (processedCode, originalCode) => {
    if (!processedCode || !originalCode) return processedCode;

    const processedLines = processedCode.split('\n');
    const originalLines = originalCode.split('\n');
    let resultLines = [];

    const getIndentationLevel = (line) => {
      return (line.match(/^\s*/)[0] || '').length;
    };

    const findKeyContent = (lines, startIndex, baseIndent) => {
      const content = [];
      let i = startIndex;
      const keyIndent = getIndentationLevel(lines[startIndex]);
      
      while (i < lines.length) {
        const line = lines[i];
        const currentIndent = getIndentationLevel(line);
        
        // Stop if we hit a line with same or lower indentation that starts a new key
        if (i !== startIndex && 
            currentIndent <= keyIndent && 
            line.trim().startsWith('- name:')) {
          break;
        }
        content.push(line);
        i++;
      }
      return { content, endIndex: i - 1 };
    };

    const mergeKeyContent = (original, processed, baseIndent) => {
      // Find where the key's direct content ends (before any subkeys)
      const findDirectContent = (lines) => {
        const keyIndent = getIndentationLevel(lines[0]);
        let i = 1;
        while (i < lines.length) {
          const currentIndent = getIndentationLevel(lines[i]);
          if (currentIndent === keyIndent && lines[i].trim().startsWith('- name:')) {
            break;
          }
          i++;
        }
        return i;
      };

      // Get direct content from both versions
      const originalDirectEnd = findDirectContent(original);
      const processedDirectEnd = findDirectContent(processed);

      // Add the processed direct content
      const result = processed.slice(0, processedDirectEnd);

      // Add any remaining original subkeys
      if (originalDirectEnd < original.length) {
        result.push(...original.slice(originalDirectEnd));
      }

      return result;
    };

    let i = 0;
    while (i < processedLines.length) {
      const line = processedLines[i];
      const baseIndent = getIndentationLevel(line);

      if (line.trim().startsWith('- name:')) {
        const key = line.trim().split(':')[1].trim();
        const { content: processedContent, endIndex: processedEnd } = findKeyContent(processedLines, i, baseIndent);

        // Find matching key in original
        const originalIndex = originalLines.findIndex(l => 
          l.trim().startsWith('- name:') && 
          l.trim().split(':')[1].trim() === key &&
          getIndentationLevel(l) === baseIndent
        );

        if (originalIndex !== -1) {
          // Merge existing key content
          const { content: originalContent } = findKeyContent(originalLines, originalIndex, baseIndent);
          const mergedContent = mergeKeyContent(originalContent, processedContent, baseIndent);
          resultLines.push(...mergedContent);
        } else {
          // Add new key
          resultLines.push(...processedContent);
        }
        i = processedEnd + 1;
      } else {
        resultLines.push(line);
        i++;
      }
    }

    return resultLines.join('\n');
  };

  const processExistingCodeMarkers = (suggestedCode, originalCode) => {
    if (!suggestedCode || !originalCode) return suggestedCode;

    // Add a marker at the end if there isn't one already
    let processedSuggestedCode = suggestedCode;
    if (!suggestedCode.trim().endsWith('// ... existing code ...')) {
      processedSuggestedCode = suggestedCode + '\n// ... existing code ...';
    }

    // First handle the existing code markers
    const withoutMarkers = handleExistingCodeMarkers(processedSuggestedCode, originalCode);
    
    // Then merge the changes
    const mergedChanges = mergeChanges(withoutMarkers, originalCode);
    
    return mergedChanges;
  };


  const handleCodeChunk = (e, editor, classes, isApproved, monaco) => {
    const classesArr = classes.split(" ").slice(1);
    let modifiedLineStart = +classesArr[0].split("-")[1];
    let modifiedLineEnd = +classesArr[1].split("-")[1];
    let originalLineStart = +classesArr[2].split("-")[1];
    let originalLineEnd = +classesArr[3].split("-")[1];

    if(!originalLineEnd && !originalLineStart) {
      originalLineStart++;
      modifiedLineEnd++;
      modifiedLineStart++;
    }

    if(isApproved) {
      if(!originalLineEnd) {
        // Added line/s
        const modifiedText = editor.getModel().modified.getValueInRange({
          endColumn: 10000,
          endLineNumber: modifiedLineEnd,
          startColumn: 1,
          startLineNumber: modifiedLineStart - 1,
        });

        editor.getModel().original.applyEdits([{
          forceMoveMarkers: true,
          range: {
            endColumn: 10000,
            endLineNumber: originalLineStart,
            startColumn: 0,
            startLineNumber: originalLineStart,
          },
          text: modifiedText
        }]);
      } else {
        // Default
        const modifiedText = editor.getModel().modified.getValueInRange({
          endColumn: 10000,
          endLineNumber: modifiedLineEnd,
          startColumn: 1,
          startLineNumber: modifiedLineStart,
        });

        editor.getModel().original.applyEdits([{
          forceMoveMarkers: true, 
          range: {
            endColumn: 10000,
            endLineNumber: originalLineEnd,
            startColumn: 1,
            startLineNumber: originalLineStart,
          },
          text: modifiedText
        }]);
      }
    }
    else {
      if(!originalLineEnd) {
        editor.getModel().modified.applyEdits([{
          forceMoveMarkers: true, 
          range: {
            endColumn: 10000,
            endLineNumber: modifiedLineEnd,
            startColumn: 1,
            startLineNumber: modifiedLineStart - 1,
          },
          text: editor.getModel().original.getValueInRange({
            endColumn: 10000,
            endLineNumber: originalLineStart,
            startColumn: 1,
            startLineNumber: originalLineStart,
          })
        }]);
      } else {
        editor.getModel().modified.applyEdits([{
          forceMoveMarkers: true, 
          range: {
            endColumn: 10000,
            endLineNumber: modifiedLineEnd,
            startColumn: 1,
            startLineNumber: modifiedLineStart,
          },
          text: editor.getModel().original.getValueInRange({
            endColumn: 10000,
            endLineNumber: originalLineEnd,
            startColumn: 1,
            startLineNumber: originalLineStart,
          })
        }]);
      }
    }

    const originalValue = editor.getModel().original.getValue();
    const modifiedValue = editor.getModel().modified.getValue();

    addToFileHistory(originalValue);
    dispatch(updateFiles({ [activeTab]: originalValue }))
    updateSuggestedCode(modifiedValue);

    e.target.parentElement.parentElement.removeChild(e.target.parentElement);

    // After handling the chunk, update the count
    setChangeCount(prev => Math.max(0, prev - 1));

    getDiffChuncks(editor, monaco);
  }

  const createCodeChunkBtns = (editor, monaco) => {
    const elmsObj = {};

    let elements = Array.from(document.querySelectorAll("[class*=MS-]"));
    const decoraionBlocks = Array.from(document.querySelectorAll(".first-line-block"));

    elements.sort((elm1, elm2) => elm1.parentElement.parentElement.offsetTop - elm2.parentElement.parentElement.offsetTop);
    elements = elements.filter(elm => !elm.classList.contains("btn-chunk-wrapper"));

    elements
      .forEach(elm => {
        if (!elm || !elm.classList) return;
        
        const className = Array.from(elm.classList)
          .filter(className => 
            className.startsWith("MS-") || 
            className.startsWith("ME-") || 
            className.startsWith("OE-") || 
            className.startsWith("OS-")
          ).join(" ");

        if (!className || !elm.parentElement || !elm.parentElement.parentElement) return;

        const decoraionIndex = decoraionBlocks.findIndex(elm2 => 
          elm2 && 
          elm2.parentElement && 
          elm2.parentElement.offsetTop === elm.parentElement.parentElement.offsetTop
        );

        if(decoraionIndex !== -1) {
          elmsObj[className] = elm;
          decoraionBlocks.splice(decoraionIndex, 1);
        }
      });

    const elms = Object.values(elmsObj);

    // There are added lines
    if(decoraionBlocks.length) {
      decoraionBlocks.forEach(elm => {
        if (!elm?.parentElement) return;
        
        const top = elm.parentElement.offsetTop;
        let lineMatch = Array.from(document.querySelectorAll(".view-line")).find(elm => elm.offsetTop === top);
        let overlayMatch = Array.from(document.querySelectorAll(".view-overlays > div")).find(elm => elm.offsetTop === top);

        // Handle end of file changes
        if (!lineMatch || !overlayMatch) {
          // Get all lines and overlays
          const allLines = Array.from(document.querySelectorAll(".view-line"));
          const allOverlays = Array.from(document.querySelectorAll(".view-overlays > div"));
          
          // Get the last line that has content
          const lastContentLine = allLines
            .filter(line => line.textContent.trim().length > 0)
            .pop();
          
          if (lastContentLine) {
            lineMatch = lastContentLine;
            // Find corresponding overlay
            overlayMatch = allOverlays.find(o => o.offsetTop === lastContentLine.offsetTop);
            
            // Force the editor to show an extra line
            const editorInstance = editor.getModifiedEditor();
            if (editorInstance) {
              const model = editorInstance.getModel();
              const lastLineNumber = model.getLineCount();
              // Add a temporary empty line if we're at the last line
              if (lineMatch.offsetTop === lastContentLine.offsetTop) {
                model.pushEditOperations(
                  [],
                  [{ range: model.getFullModelRange(), text: model.getValue() + '\n' }],
                  () => null
                );
                // Restore cursor position
                editorInstance.setPosition({ lineNumber: lastLineNumber, column: 1 });
              }
            }
          }
        }

        // If we still don't have matches, skip this block
        if (!lineMatch || !overlayMatch) return;

        const mlsElement = overlayMatch.querySelector("[class*=MLS-]");
        if (!mlsElement?.classList) return;

        const classNames = Array.from(mlsElement.classList);
        if (classNames.length < 5) return;

        const [msClass, meClass, osClass, oeClass] = classNames.slice(1);
        const spanElement = lineMatch.querySelector("span span");
        
        if (spanElement && msClass && meClass && osClass && oeClass) {
          spanElement.classList.add(
            `-`,
            `MS-${msClass.split("-")[1]}`,
            `ME-${meClass.split("-")[1]}`,
            `OS-${osClass.split("-")[1]}`,
            `OE-${oeClass.split("-")[1]}`
          );
          elms.push(spanElement);

          // Create buttons for all visible changed lines
          const btnWrapper = document.createElement("div");
          btnWrapper.classList.add("btn-chunk-wrapper");
          btnWrapper.classList.add(...spanElement.classList);
          btnWrapper.style.position = "absolute";
          btnWrapper.style.top = "0";
          btnWrapper.style.right = "0";
          btnWrapper.style.display = "none";
          btnWrapper.style.zIndex = "1000"; // Ensure buttons are visible

          btnWrapper.addEventListener("mousemove", (e) => e.stopPropagation());

          const approveBtn = document.createElement("button");
          approveBtn.classList.add("btn", "btn-success");
          approveBtn.textContent = "Accept";
          approveBtn.style.paddingBlock = "0";
          approveBtn.style.fontSize = "9px";
          approveBtn.style.borderRadius = 0;
          approveBtn.style.borderBottomLeftRadius = "5px";
          approveBtn.addEventListener("click", (e) => handleCodeChunk(e, editor, spanElement.className, true, monaco));
          
          const rejectBtn = document.createElement("button");
          rejectBtn.classList.add("btn", "btn-danger");
          rejectBtn.textContent = "Reject";
          rejectBtn.style.paddingBlock = "0";
          rejectBtn.style.fontSize = "9px";
          rejectBtn.style.borderRadius = 0;
          rejectBtn.style.borderBottomRightRadius = "5px";
          rejectBtn.addEventListener("click", (e) => handleCodeChunk(e, editor, spanElement.className, false, monaco));
          
          btnWrapper.appendChild(approveBtn);
          btnWrapper.appendChild(rejectBtn);
          lineMatch.appendChild(btnWrapper);
        }
      });
    }

    elms.forEach(elm => {
      const line = elm.parentElement.parentElement;

      if(!line.querySelector(".btn-chunk-wrapper")) {
        const btnWrapper = document.createElement("div");
        btnWrapper.classList.add("btn-chunk-wrapper");
        btnWrapper.classList.add(...elm.classList);
        btnWrapper.style.position = "absolute";
        btnWrapper.style.top = "0";
        btnWrapper.style.right = "0";
        btnWrapper.style.display = "none";

        btnWrapper.addEventListener("mousemove", (e) => e.stopPropagation());
  
        const approveBtn = document.createElement("button");
        approveBtn.classList.add("btn", "btn-success");
        approveBtn.textContent = "Accept";
        approveBtn.style.paddingBlock = "0";
        approveBtn.style.fontSize = "9px";
        approveBtn.style.borderRadius = 0;
        approveBtn.style.borderBottomLeftRadius = "5px";
        approveBtn.addEventListener("click", (e) => handleCodeChunk(e, editor, elm.className, true, monaco));
        
        const rejectBtn = document.createElement("button");
        rejectBtn.classList.add("btn", "btn-danger");
        rejectBtn.textContent = "Reject";
        rejectBtn.style.paddingBlock = "0";
        rejectBtn.style.fontSize = "9px";
        rejectBtn.style.borderRadius = 0;
        rejectBtn.style.borderBottomRightRadius = "5px";
        rejectBtn.addEventListener("click", (e) => handleCodeChunk(e, editor, elm.className, false, monaco));
        
        btnWrapper.appendChild(approveBtn);
        btnWrapper.appendChild(rejectBtn);
        line.appendChild(btnWrapper);
      }
    });
  }

  const getDiffChuncks = (editor, monaco) => {
    const diff = editor._diffComputationResult;

    document
      .querySelectorAll(".btn-chunk-wrapper")
      .forEach(elm => elm.parentElement.removeChild(elm));
    if(diff) {
      // Filter out changes that are only whitespace/indentation differences
      const meaningfulChanges = diff.changes.filter(change => {
        // Add safety check for valid line numbers
        if (!change.originalStartLineNumber || !change.modifiedStartLineNumber ||
            change.originalStartLineNumber < 1 || change.modifiedStartLineNumber < 1) {
          return false;
        }
        
        try {
          const originalText = editor.getModel().original.getValueInRange({
            startLineNumber: change.originalStartLineNumber,
            endLineNumber: change.originalEndLineNumber || change.originalStartLineNumber,
            startColumn: 1,
            endColumn: 10000
          });
          
          const modifiedText = editor.getModel().modified.getValueInRange({
            startLineNumber: change.modifiedStartLineNumber,
            endLineNumber: change.modifiedEndLineNumber || change.modifiedStartLineNumber,
            startColumn: 1,
            endColumn: 10000
          });

          // Normalize both texts by removing all whitespace and empty lines
          const normalizeText = (text) => text
            .split('\n')
            .map(line => line.trim())
            .filter(line => line.length > 0)
            .join('\n');

          const normalizedOriginal = normalizeText(originalText);
          const normalizedModified = normalizeText(modifiedText);

          // Return true only if there's an actual content difference
          return normalizedOriginal !== normalizedModified;
        } catch (error) {
          console.warn("Error processing diff chunk:", error);
          return false;
        }
      });

      // Update the total count of meaningful changes
      setChangeCount(meaningfulChanges.length);

      if(!meaningfulChanges.length) {
        const value = editor.getModel().original.getValue();
        addToFileHistory(value);
        dispatch(updateFiles({ [activeTab]: value }));
        removeSuggestedCode();
        return;
      }

      diff.changes = meaningfulChanges.map((change) => {
        const modifiedCodeLineCount = editor.getModel().modified.getLineCount()
  
        if(!change.modifiedEndLineNumber) {
          if(modifiedCodeLineCount <= change.originalStartLineNumber) {
            return {
              ...change,
              modifiedEndLineNumber: change.modifiedStartLineNumber,
              modifiedStartLineNumber: change.modifiedStartLineNumber,
              originalEndLineNumber: change.originalEndLineNumber,
              originalStartLineNumber: change.originalStartLineNumber - 1
            }
          }
  
          return {
            ...change,
            modifiedEndLineNumber: change.modifiedStartLineNumber + 1,
            modifiedStartLineNumber: change.modifiedStartLineNumber + 1,
            originalEndLineNumber: change.originalEndLineNumber + 1,
            originalStartLineNumber: change.originalStartLineNumber
          }
        }
        return change
      })
      
      const newDecorations = diff.changes.map((change) => ({
        range: new monaco.Range(
          change.modifiedStartLineNumber,
          1,
          change.modifiedEndLineNumber,
          1
        ),
        options: {
          isWholeLine: true,
          inlineClassName: `MS-${change.modifiedStartLineNumber} ME-${change.modifiedEndLineNumber} OS-${change.originalStartLineNumber} OE-${change.originalEndLineNumber}`,
          firstLineDecorationClassName: "first-line-block",
          className: `MLS-${change.modifiedStartLineNumber} MLE-${change.modifiedEndLineNumber} OLS-${change.originalStartLineNumber} OLE-${change.originalEndLineNumber}`
        },
      }));
  
      // It is used to avoid dublicating class name
      oldDecorations[activeTab] = editor.getModifiedEditor().deltaDecorations(
        oldDecorations[activeTab] || [],
        newDecorations
      );

      debounce(() => createCodeChunkBtns(editor, monaco), 200)();
    }
  }

  const handleDiffCodeHover = (target) => {
    document.querySelectorAll(".btn-chunk-wrapper").forEach(btn => btn.style.display = "none");
    let spanElm;

    if(target?.element.className === "view-line") {
      spanElm = target.element.querySelector("span span");
    } else if(/(MS-\d).*(OS-\d)/.test(target.element.className)) {
      spanElm = target.element;
    }

    if(spanElm && /(MS-\d).*(OS-\d)/.test(spanElm.className)) {
      const [msClass, meClass, osClass, oeClass] = spanElm.className.split(" ").slice(1);
      const btnWrapper = document.querySelector(`.${msClass}.${meClass}.${osClass}.${oeClass}.btn-chunk-wrapper`);

      if(btnWrapper) {
        const linesContent = document.querySelector(".lines-content");
        const container = spanElm.parentElement.parentElement.parentElement;
        const smallContainer = document.querySelector(".modified-in-monaco-diff-editor");

        const right = container?.scrollWidth - smallContainer.clientWidth + linesContent.offsetLeft + 60;

        btnWrapper.style.display = "flex";
        btnWrapper.style.right = `${right}px`;
      }
    }
  }

  const highlightDiffChunks = (editor, monaco) => {
    editor.onDidUpdateDiff(() => {
      if(editorSuggestedCode[activeTab]?.trim() === files[activeTab]?.trim()) {
        removeSuggestedCode();
      }
  
      getDiffChuncks(editor, monaco);
    })

    getDiffChuncks(editor, monaco);

    editor.getModifiedEditor().onMouseWheel(debounce(() => createCodeChunkBtns(editor, monaco), 200))
    editor.getModifiedEditor().onMouseMove(({target}) => handleDiffCodeHover(target))
    editor.getModifiedEditor().onMouseLeave(() => {
      document.querySelectorAll(".btn-chunk-wrapper").forEach(btn => btn.style.display = "none");
    }) 
  }

  return (
    <>
      {editorSuggestedCode[activeTab] && (
        <DiffEditor
          height={editor_height - 70}
          width={"100%"}
          path={activeTab}
          language={
            activeTab &&
            activeTab.slice(activeTab.length - 4) ===
              ".yml"
              ? "yaml"
              : "sql"
          }
          options={{
            renderSideBySide: false,
            minimap: { enabled: false },
            renderOverviewRuler: false,
          }}
          modified={editorSuggestedCode[activeTab] || ""}
          original={files[activeTab] || ""}
          onMount={highlightDiffChunks}
          beforeMount={() => updateSuggestedCode(processExistingCodeMarkers(editorSuggestedCode[activeTab], files[activeTab]))}
          loading={<CircularProgress size={16} color="#fff" />}
          key={activeTab}
        />
      )}
      <Editor
        height={!editorSuggestedCode[activeTab] ? editor_height - 70 : 0}
        width={!editorSuggestedCode[activeTab] ? "100%" : 0}
        theme="vs-light"
        path={activeTab}
        language={
          activeTab &&
          activeTab.slice(activeTab.length - 4) === ".yml"
            ? "yaml"
            : "sql"
        }
        options={{
          minimap: { enabled: false },
        }}
        value={files[activeTab]}
        onChange={handleEditorChange}
        onMount={handleEditorDidMount}
        loading={<CircularProgress size={16} color="#fff" />}
      />

      {editorSuggestedCode[activeTab] && (
        <Stack
          direction={"row"}
          position={"absolute"}
          top={editor_height - 116}
          left={"50%"}
          gap={1}
          px={"6px"}
          py={"4px"}
          border={"1px solid #6c757d"}
          borderRadius={"6px"}
          sx={{
            transform: "translateX(-50%)",
            background: "white",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              addToFileHistory(editorSuggestedCode[activeTab]);
              dispatch(updateFiles({
                [activeTab]: editorSuggestedCode[activeTab],
              }))
              removeSuggestedCode();
            }}
            style={{
              paddingInline: "8px",
              paddingBlock: "2px",
            }}
          >
            Accept File
          </Button>
          <Button
            variant=""
            onClick={() => removeSuggestedCode()}
            style={{
              paddingInline: "8px",
              paddingBlock: "2px",
            }}
          >
            Reject File
          </Button>
          <span>{changeCount} change(s)</span>&nbsp;&nbsp;
        </Stack>
      )}
    </>
  );
};

export default MonacoEditor;