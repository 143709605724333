import React from "react";
import { DialogActions } from "@mui/material";

/**
 * @param {{children: JSX.Element}} data
 */
const ModalLayoutFooter = ({ children }) => {
  return (
    <DialogActions sx={{ borderTop: "1px solid #dee6ed", padding: "12px" }}>
      {children}
    </DialogActions>
  );
};

export default ModalLayoutFooter;
