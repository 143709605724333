import React from "react";
import AuthGuard from "../../components/guards/AuthGuard";
import Dashboard from "../../layouts/Dashboard";
import { Stack } from "@mui/material";

const ErrorFallback = () => {
  return (
    <AuthGuard>
      <Dashboard>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          height={"100%"}
          fontSize={24}
          fontWeight={500}
          color={'#063970'}
          textAlign={"center"}
          component={"p"}
        >
          Sorry, something went wrong.<br/>We are investigating.
        </Stack>
      </Dashboard>
    </AuthGuard>
  );
};

export default ErrorFallback;
