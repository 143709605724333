import React from "react";
import { CircularProgress, DialogContentText, Typography } from "@mui/material";
import { FilePlus } from "react-feather";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";
import ModalLayoutInput from "./Layout/Input";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  onAction: () => void;
 *  duplicatedFile: string;
 *  newFilename: () => void;
 *  createFileLog: string;
 *  filename: string;
 *  creating: boolean;
 * }} data
 */
const DuplicateFileModal = ({
  isShown,
  onHide,
  onAction,
  duplicatedFile,
  createFileLog,
  filename,
  newFilename,
  creating,
}) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>
        Duplicate &nbsp;
        <Typography
          component={"span"}
          fontSize={11}
          fontFamily={"Poppins"}
          fontWeight={700}
        >
          {duplicatedFile?.split("/").pop()}
        </Typography>
      </ModalLayoutHeader>
      <ModalLayoutContent>
        <img src="/images/newfile.jpg" width="15%" alt="" />
        <ModalLayoutInput
          type="text"
          aria-label="Text input with dropdown button"
          placeholder="Your new filepath"
          onChange={newFilename}
        />
        <DialogContentText
          color="red"
          fontWeight={700}
          fontFamily={"Poppins"}
          textAlign={"center"}
          fontSize={11}
        >
          {createFileLog}
        </DialogContentText>
        <br />
        {filename === "models/"
          ? "Your SQL files have to be stored in a subfolder inside the models folder."
          : ""}
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn color="primary" disabled={creating} onClick={onAction}>
          <FilePlus size={16} />
          &nbsp;
          {creating ? (
            <>
              Duplicating File...&nbsp;&nbsp;
              <CircularProgress size={16} color="#fff" />
            </>
          ) : (
            "Duplicate File"
          )}
        </ModalLayoutBtn>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default DuplicateFileModal;
