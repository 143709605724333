import React, { useState } from "react";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { MoreHorizontal, MoreVertical } from "react-feather";

const DropdownMenu = ({ options, IconType }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (event, clickHandler) => {
    event.stopPropagation();
    await clickHandler();
    handleClose(event);
  };

  return (
    <div>
      <IconButton sx={{ padding: 0 }} onClick={handleClick}>
        {IconType === "MoreHorizontal" ? (
          <MoreHorizontal />
        ) : (
          <MoreVertical size={18} />
        )}
      </IconButton>
      <Menu
        sx={{
          "& .MuiMenu-list": { padding: "1px" },
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {options.map(
          ({ clickHandler, name, hasFollowingDivider, nameFormatter }, i) => (
            <span key={i}>
              <MenuItem
                onClick={(event) => handleMenuItemClick(event, clickHandler)}
                sx={{ fontSize: "12px", paddingX: 1 }}
              >
                {nameFormatter ? nameFormatter() : name}
              </MenuItem>
              {hasFollowingDivider && (
                <hr style={{ margin: "2px 0", backgroundColor: "#bbb" }} />
              )}
            </span>
          )
        )}
      </Menu>
    </div>
  );
};

export default DropdownMenu;
