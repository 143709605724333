import { config } from "../constants";
import axios from "../utils/axios";
import { toast } from "react-toastify";

export const fetchAirflowDagsService = async () => {
  try {
    const response = await axios.post(
      `${config.url.AIRFLOW}/api/airflow/list_tasks_from_pg`,
      {
        dag_type: "push",
      }
    );
    return response;
  } catch (_) {
    toast.error("Unable to retrieve pushes.");
  }
};

export const stopDagService = async (dag_id, dag_run_id) => {
  const toastId = toast.loading(`Stopping ${dag_id} Run ID: ${dag_run_id}...`);

  try {
    await axios.post(`${config.url.API_URL}/api/airflow/stop_dag`, {
      dag_id,
      dag_run_id,
    });
    toast.success(`${dag_id} stopped successfully.`);
  } catch (_) {
    toast.error(`Could not stop your DAG ${dag_id}.`);
  }

  toast.dismiss(toastId);
};

export const triggerDagService = async (dag_id) => {
  try {
    axios.post(`${config.url.API_URL}/api/airflow/trigger_dag`, { dag_id });
    toast.success("Job triggered successfully.");
  } catch (_) {
    toast.error("Error triggering your push.");
  }
};

export const deletePushService = async (
  deleting_push_alias,
  push_id,
  push_alias
) => {
  const toastId = toast.loading(`Deleting your ${deleting_push_alias} push...`);
  let isSuccess = false;

  try {
    await axios.post(`${config.url.PUSH_URL}/api/push/delete_push`, {
      push_id,
      push_alias,
    });
    toast.success("Push deleted successfully.");
    isSuccess = true;
  } catch (_) {
    toast.error("Error deleting your push.");
  }
  toast.dismiss(toastId);
  return isSuccess;
};

export const fetchDagLastRunsService = async (dag_id, page_limit) => {
  try {
    const response = await axios.post(
      `${config.url.API_URL}/api/airflow/get_dag_runs`,
      {
        dag_id,
        page_limit,
      }
    );
    return response;
  } catch (error) {
    toast.error("Error while fetching job history.");
  }
};

export const handleDeleteService = async (destination_id, alias) => {
  const toastId = toast.loading("Deleting destination...");
  let isSuccess = false;

  try {
    await axios.post(`${config.url.PUSH_URL}/api/push/delete_destination`, {
      destination_id,
    });
    toast.success(`Your destination ${alias} was deleted successfully.`, {
      autoClose: 5000,
    });
    isSuccess = true;
  } catch (_) {}
  toast.dismiss(toastId);
  return isSuccess;
};

export const showInitialEditViewService = async () => {
  try {
    const response = await axios.post(
      `${config.url.AIRFLOW}/api/airflow/list_dags`,
      {
        dag_type: "Push",
      }
    );
    return response;
  } catch (_) {
    toast.error("Error while fetching your push data");
  }
};

export const getColumnsService = async (table, editorURL) => {
  const toastID = toast.loading(
    `Fetching available datapoints from ${table}...`
  );
  let response;

  try {
    response = await axios.post(`${editorURL}/api/sql/get_columns`, { table });
  } catch (_) {
    toast.error(
      `There was an issue loading available datapoints from ${table}.`
    );
  }
  toast.dismiss(toastID);
  return response;
};

export const getMyModelsService = async (notify_user, editorURL) => {
  let toastId, response;

  if (notify_user) {
    toastId = toast.loading("Retrieving models from your DWH...");
  }

  try {
    response = await axios.get(`${editorURL}/api/sql/get_tables`);
  } catch (_) {
    toast.error("There was an issue loading your SQL Models.");
  }
  if (notify_user) {
    toast.dismiss(toastId);
  }
  return response;
};

export const getTapDetailsService = async (tap, SelectedTap) => {
  const toastID = toast.loading(`Retrieving ${tap} requirements...`);
  let response;

  try {
    response = await axios.post(
      `${config.url.PUSH_URL}/api/push/get_destination_specs`,
      SelectedTap
    );
  } catch (_) {
    toast.error(`There was an issue loading ${tap} requirements.`);
  }
  toast.dismiss(toastID);
  return response;
};

export const listDagsService = async () => {
  try {
    const response = await axios.post(
      `${config.url.AIRFLOW}/api/airflow/list_dags`,
      {
        dag_type: "Push",
      }
    );
    return response;
  } catch (_) {
    toast.error("Error while retrieving scheduled jobs.");
  }
};

export const applyEditService = async (dag_id, mappings, toastId) => {
  let isSuccess = false;

  try {
    await axios.post(`${config.url.PUSH_URL}/api/push/edit_push`, {
      dag_id,
      mappings,
    });
    toast.success("Push successfully updated.");
    isSuccess = true;
  } catch (_) {
    toast.error("Error while editing your push.");
  }
  toast.dismiss(toastId);
  return isSuccess;
};

export const editPushService = async (dag_id) => {
  const toastId = toast.loading("Retrieving your push details...");
  let response;

  try {
    response = await axios.post(
      `${config.url.PUSH_URL}/api/push/get_push_details`,
      { dag_id }
    );
  } catch (_) {
    toast.error("Error while retrieving push details.");
  }
  toast.dismiss(toastId);
  return response;
};

export const saveDestinationService = async (savetab) => {
  try {
    const response = await axios.post(
      `${config.url.PUSH_URL}/api/push/save_destination`,
      savetab
    );

    toast.success("Credentials saved successfully.");
    return response;
  } catch (_) {
    toast.error("There was an internal issue saving credentials.");
  }
};

export const getPushTypeService = async (destination) => {
  try {
    const response = await axios.post(
      `${config.url.PUSH_URL}/api/push/get_push_types`,
      { destination }
    );
    return response;
  } catch (_) {}
};

export const getDestinationService = async (destination, destination_alias) => {
  const toastId = toast.loading(
    `Retrieving objects from ${destination} (this can take up to 30 seconds depending on your destination connection)...`
  );
  let response;

  try {
    response = await axios.post(
      `${config.url.PUSH_URL}/api/push/get_destination_objects`,
      {
        destination_alias,
        destination,
      }
    );
  } catch (_) {
    toast.error("There was an issue getting objects from your destination.");
  }
  toast.dismiss(toastId);
  return response;
};

export const getDestinationObjectsAirbyteService = async (destinationId) => {
  const toastId = toast.loading(
    "Retrieving objects from your source & destination, this can take up to 30s depending on their connection..."
  );
  let response;

  try {
    response = await axios.post(
      `${config.url.PUSH_URL}/api/push/get_destination_objects_airbyte`,
      {
        destinationId,
      }
    );
  } catch (_) {
    toast.error("There was an issue getting objects from your PG destination.");
  }
  toast.dismiss(toastId);
  return response;
};

export const refreshDestinationCatalogService = async (
  destination,
  destination_alias
) => {
  var toastId = toast.loading(
    `Retrieving objects from ${destination} (this can take up to 30 seconds depending on your destination connection)...`
  );
  let response;

  try {
    response = await axios.post(
      `${config.url.PUSH_URL}/api/push/refresh_destination_catalog`,
      {
        destination_alias,
        destination,
      }
    );
  } catch (_) {
    toast.error("There was an issue getting objects from your destination.");
  }
  toast.dismiss(toastId);
  return response;
};

export const testDestinationService = async (data) => {
  const toastId = toast.loading(
    `Testing your connection to ${data.selected_tap}...`
  );
  let response;

  try {
    response = await axios.post(
      `${config.url.PUSH_URL}/api/push/test_destination`,
      data
    );

    toast.success(`Connected to ${data.selected_tap} successfully.`, {
      autoClose: 5000,
    });
  } catch (err) {
    if (err.response && err.response.status === 501) {
      toast.error(err.response.data["error"], { autoClose: 15000 });
    } else {
      toast.error(`Unable to connect to ${data.selected_tap}.`, {
        autoClose: 5000,
      });
    }
  }
  toast.dismiss(toastId);
  return response;
};

export const createPushService = async (data) => {
  const toastId = toast.loading(
    "Creating your push, this could take up to a minute...",
    { autoClose: false }
  );
  let isSuccess = false;

  try {
    await axios.post(`${config.url.PUSH_URL}/api/push/create_push`, data);
    toast.success("Push created successfully 🎉");
    isSuccess = true;
  } catch (_) {
    toast.error("Error creating your push.");
  }
  toast.dismiss(toastId);
  return isSuccess;
};

export const testPushService = async (data, toastId) => {
  let response;

  try {
    response = await axios.post(
      `${config.url.PUSH_URL}/api/push/test_push`,
      data
    );
    toast.success(`Pushed to ${data.destination} successfully.`, {
      autoClose: 5000,
    });
  } catch (_) {
    toast.error(
      `There was an issue pushing to ${data.destination}. Try again.`
    );
  }
  toast.dismiss(toastId);
  return response;
};

export const getMyDestinationsService = async () => {
  try {
    const response = await axios.get(
      `${config.url.PUSH_URL}/api/push/mydestinations`
    );
    return response;
  } catch (_) {
    toast.error("There was an issue listing your destinations taps.");
  }
};

export const listConnectorsService = async () => {
  try {
    const response = await axios.post(
      `${config.url.TAPS_URL}/api/taps/list_available_connectors`,
      { type: "destinations" }
    );
    return response;
  } catch (_) {
    toast.error("There was an internal issue listing taps.");
  }
};

export const fetchDAGLastRunsService = async (dag_id, page_limit, toastId) => {
  try {
    const response = await axios.post(`${config.url.AIRFLOW}/api/airflow/get_dag_runs`, {
      dag_id,
      page_limit,
    });
    return response;
  } catch (_) {
    toast.error("Error while fetching job history.");
  }
  toast.dismiss(toastId);
};
