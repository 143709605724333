import React, { useState } from "react";
import mainlogo from "../../assets/img/customers/logo.png";
import { Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import sidebarLinks from "../../utils/sidebarLinks";

const Sidebar = () => {
  const location = useLocation();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  return (
    <Box
      component={"nav"}
      width={60}
      zIndex={2}
      bgcolor={"#090F47"}
      position={"sticky"}
      top={0}
      left={0}
      height={"100vh"}
    >
      <Link
        href="/home"
        style={{
          paddingBlock: "12px",
          paddingInline: "20px",
          display: "block",
        }}
      >
        <img src={mainlogo} width="85%" alt="Datajolt" />
      </Link>

      <Box position={"relative"} mt={1.5}>
        <Box
          component={"span"}
          aria-hidden
          position={"absolute"}
          display={"block"}
          height={30}
          bgcolor={"rgba(255,255,255,0.1)"}
          width={30}
          left={14}
          borderRadius={"5px"}
          top={`${38 * currentPageIndex + 5}px`}
          sx={{
            transition: "all 0.5s ease-in-out",
          }}
        />

        <Box component={"ul"} padding={0} style={{ listStyle: "none" }}>
          {sidebarLinks.map((item, i) => {
            if (item.href === location.pathname && currentPageIndex !== i)
              setCurrentPageIndex(i);

            return (
              <Box component={"li"} key={item.title} position={"relative"}>
                <Link
                  to={item.href}
                  style={{
                    color:
                      item.href === location.pathname
                        ? "#e9ecef"
                        : "rgba(233, 236, 239, 0.5)",
                    paddingBlock: "10px",
                    paddingInline: "20px",
                    display: "block",

                    "&:hover": {
                      color: "rgba(233, 236, 239, 0.75)",
                    },
                  }}
                  aria-label={item.title}
                >
                  <item.icon size={18} />
                </Link>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
