import React from "react";
import CustomTableHead from "./Head";
import LoadingTableBody from "./Loading";
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@mui/material";
import TableTooltip from "./Tooltip";
import moment from "moment-timezone";

/**
 *
 * @param {{
    headings: {
      Children: JSX.Element,
      isSortable: boolean;
      columnName: string;
      width?: number;
    }[], 
    sortedColumn: {column: string; isAsc: boolean}; 
    onSortColumn: (columnName: string) => void;
    rows: {
      key: string; 
      columns: JSX.Element[], 
      style?: Record<string, string>, 
      onClick?: () => void, 
      logsTable?: false | JSX.Element
    }[]
    isLoading?: boolean
    loadingOptions?: {
      columns?: number
      rows?: number
    }
  }} param0
 */
const CustomTable = ({
  headings,
  sortedColumn,
  onSortColumn,
  rows,
  isLoading,
  loadingOptions
}) => {
  const customer_timezone = localStorage.getItem("timezone");

  const renderTableCell = (column) => {
    switch (column.type) {
      case "text":
        return column.text;
      case "image":
        return column.logo ? (
          <img
            style={{
              transform: "scale(0.8)",
              width: "40px",
              height: "40px",
              backgroundColor: "#f1f1f1",
              borderRadius: "50%",
              objectFit: "contain",
              padding: "5px",
            }}
            src={column.logo}
            alt="DAG logo"
          />
        ) : (
          ""
        );
      case "date":
        return !column.date || column.date === "None"
          ? ""
          : moment
              .utc(column.date)
              .tz(customer_timezone)
              ?.format("YYYY-MM-DD HH:mm");
      case "status":
        return <TableTooltip status={column.key} />;
      default:
        return column.component;
    }
  };

  return (
    <Table
      sx={{
        marginInline: 1,
        maxWidth: "calc(100% - 16px)",
        minWidth: "calc(100% - 16px)",
        width: "unset",
        borderSpacing: "0 3px",
        borderCollapse: "separate",
        tableLayout: "fixed",
        cursor: "pointer"
      }}
    >
      {/* sticky-table-header */}
      <TableHead 
        sx={{ 
          height: "40px",
          position: "sticky",
          top: 0,
          backgroundColor: "#fff",
          zIndex: 100,
        }}
      >
        <TableRow>
          {headings.map((heading) => (
            <CustomTableHead
              key={heading.columnName}
              {...heading}
              sortedColumn={sortedColumn}
              onSortColumn={onSortColumn}
            />
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {isLoading ? (
          <LoadingTableBody colNum={headings.length || loadingOptions?.columns} rowCount={loadingOptions?.rows} />
        ) : (
          (rows || []).map((row) => (
            <React.Fragment key={row.key}>
              <TableRow
                hover
                sx={{
                  backgroundColor: "#fff",
                  outline: "1px solid #ebebeb",
                  outlineOffset: "-1px",
                  height: "65px",
                  ...row.style,
                  "&:hover": {
                    backgroundColor: "rgb(250, 250, 250) !important",
                  },
                }}
                >
                {row.columns?.map((column, i) => (
                  <TableCell
                    key={i}
                    sx={{
                      fontSize: "10px",
                      wordBreak: "break-all",
                      padding: "0rem 0.7rem",
                      borderWidth: 0,
                    }}
                    onClick={(e) => {
                      if(!e.currentTarget.querySelector("[aria-controls='horizontal-menu']")) {
                        row?.onClick?.(e);
                      }
                    }}
                  >
                    {renderTableCell(column)}
                  </TableCell>
                ))}
              </TableRow>
              {row.logsTable}
            </React.Fragment>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export default CustomTable;
