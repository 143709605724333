import React from "react";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";
import { GitBranch } from "react-feather";
import ModalLayoutInput from "./Layout/Input";
import Spinner from "react-bootstrap/Spinner";
/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  onAction: () => void;
 *  creating: boolean;
 *  newBranchName: string;
 *  setNewBranchName: (e: React.ChangeEvent) => void;
 * }} data
 */
const CreateBranchModal = ({
  isShown,
  onHide,
  onAction,
  creating,
  newBranchName,
  setNewBranchName,
}) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>Create New Branch</ModalLayoutHeader>
      <ModalLayoutContent>
        <form id="create-branch-form">
          <ModalLayoutInput
            type="text"
            placeholder="Enter branch name"
            value={newBranchName}
            onChange={setNewBranchName}
            label="Branch Name"
          />
        </form>
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn
          color="primary"
          disabled={creating}
          onClick={onAction}
          form="create-branch-form"
          type="submit"
        >
          {creating ? (
            <>
              Creating branch...&nbsp;
              <Spinner animation="border" variant="light" size="sm" />
            </>
          ) : (
            <>
              <GitBranch size={12} />{" "}
              Create Branch
            </>
          )}
        </ModalLayoutBtn>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default CreateBranchModal;
