import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  FolderMinus,
  File,
  ChevronRight,
  RefreshCw,
  Search,
  X,
  Plus,
  GitBranch,
  GitMerge,
  Trash2,
  Lock,
  ArrowDownCircle,
} from "react-feather";
import { SimpleTreeView, TreeItem, treeItemClasses } from "@mui/x-tree-view";
import {
  Box,
  InputAdornment,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  accordionSummaryClasses,
  Typography
} from "@mui/material";
import { ResizableBox } from "react-resizable";
import debounce from "lodash.debounce";
import _ from "lodash";
import { Dropdown } from "react-bootstrap";
import FileContextMenu from "./ContextMenu/File";
import FolderContextMenu from "./ContextMenu/Folder";
import { searchByName, searchForFolder } from "../../utils/searchForFolder";
import DeleteBranchModal from "./DeleteBranchModal";
import PullRequestModal from "../../components/PullRequestModal";
import { HeightContext } from "./LeftBoxes";
import { useEditor } from "../../store";
import {
  fetchBranchesService,
  handleCreateBranchService,
  renameFileService,
  renameFolderService,
} from "../../services/editor";
import CreateBranchModal from "../../components/Modals/CreateBranch";

const box_titles_style = {
  borderColor: "#dee6ed",
  color: "#448593",
  fontSize: "12px",
  fontWeight: "500",
  minHeight: "5px",
  marginTop: "3px",
};

export default function QueriesBox({
  loading_git,
  git_files,
  fetchGitRepo,
  openFile,
  toggleDuplicateFile,
  toggleDeleteModal,
  activeBranch,
  setActiveBranch,
  activeTenant,
  generate_docs,
  createNewFile,
  createFolder,
  updateGitFiles,
  files,
  toggleEditingNotAllowedModal,
  togglePullRequestModal,
  showPullRequestModal,
  test_dbt_model,
  dbt_test_status,
  dbt_logs,
  dbtCommand,
  dbt_url,
}) {
  const editor_height = document.documentElement.clientHeight - 136;
  const queriesREF = useRef(null);
  const accordionRef = useRef(null);

  const [isSearchingQueries, setIsSearchingQueries] = useState(false);
  const [queriesSearchKeywords, setQueriesSearchKeywords] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newBranchName, setNewBranchName] = useState("");
  const [Creating, setIsCreatingBranch] = useState(false);
  const [branches, setBranches] = useState([
    { branch_name: "main", updated_at: new Date().toISOString() },
  ]);
  const [dropdownAnchor, setDropdownAnchor] = useState({
    element: null,
    id: null,
    nodes: null,
    isFile: false,
  });
  const [reposFiles, setReposFiles] = useState(git_files["repos"]);
  const [fileOriginalName, setFileOriginalName] = useState("");
  const [deleteBranch, setDeleteBranch] = useState({
    isVisible: false,
    branch: "",
    isDeleting: false,
  });
  const queriesSearchRef = useRef(null);
  const expanded_list = useEditor((state) => state.expanded_list || []);
  const saveExpandedList = useEditor((state) => state.saveExpandedList);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const {
    height: { queries: currentHeight, history: historyHeight },
    onResizeBox,
    collapsed: { schema: isSchemaCollapsed, queries: isQueriesCollapsed },
  } = useContext(HeightContext);
  const [changedFiles, setChangedFiles] = useState([]);
  const [isChangesCollapsed, setIsChangesCollapsed] = useState(false);
  const prevBranch = useRef(activeBranch);
  const [changesBoxHeight, setChangesBoxHeight] = useState(120);
  const [isResizingChanges, setIsResizingChanges] = useState(false);

  const fetchBranches = useCallback(async () => {
    const response = await fetchBranchesService();

    if (response) {
      setBranches(response.data.branches);
    }
  }, []);

  const memoizedFetchGitRepo = useCallback(() => {
    fetchGitRepo(activeBranch);
  }, [fetchGitRepo, activeBranch]);

  useEffect(() => {
    fetchBranches();
    memoizedFetchGitRepo();
  }, [fetchBranches, memoizedFetchGitRepo]);

  useEffect(() => {
    setReposFiles(git_files["repos"]);
    setChangedFiles(git_files["changes"] || []);
  }, [git_files, activeBranch]);

  const updateExpandedList = (expandedList) => saveExpandedList(expandedList);

  useEffect(() => {
    if ((isSearching, isSearchingQueries)) {
      setTimeout(() => {
        setIsSearching(false);
      }, 500);
    }
  }, [isSearching, isSearchingQueries]);

  const highlightWord = (word, searchTerm) => {
    const regex = new RegExp(`(${searchTerm})`, "gi");
    const parts = word.split(regex);

    return (
      <>
        {parts.map((part, index) =>
          regex.test(part) ? (
            <mark key={index}>{part}</mark>
          ) : (
            <React.Fragment key={index}>{part}</React.Fragment>
          )
        )}
      </>
    );
  };

  const toggleIsSearchingQueries = () =>
    setIsSearchingQueries((searching) => !searching);

  const updateQueriesList = (e) => {
    setIsSearching(true);

    setQueriesSearchKeywords(e.target.value);
    updateExpandedList(
      flattenQueriesList(filterQueries(reposFiles, e.target.value)).filter(
        (item) =>
          !item.toLowerCase().includes("sql") &&
          !item.toLowerCase().includes("yml")
      )
    );
  };

  const clearQueriesSearch = () => {
    setQueriesSearchKeywords("");

    if (queriesSearchRef.current) {
      queriesSearchRef.current.value = "";
    }
    updateExpandedList([]);
  };

  const debouncedHandleChangeQueries = debounce(updateQueriesList, 400);

  const handleCreateBranch = async () => {
    const toastId = toast.loading("Creating branch...");
    let formattedBranchName = newBranchName.toLowerCase().replace(/\s+/g, "_");

    // GitHub-friendly branch name regex: must start with a letter or number, can contain letters, numbers, underscores, hyphens, and dots
    const githubFriendlyBranchNameRegex = /^[a-zA-Z0-9._-]+$/;

    // Check if formatted branch name is GitHub friendly
    if (!githubFriendlyBranchNameRegex.test(formattedBranchName)) {
      toast.dismiss(toastId);
      toast.info(
        "Branch name is not GitHub friendly. Please choose a different name."
      );
      return;
    }

    const branchExists = branches.some(
      (branch) => branch.branch_name === formattedBranchName
    );

    if (branchExists) {
      toast.dismiss(toastId);
      toast.info(
        formattedBranchName + " already exists. Please choose a different name."
      );
      return;
    }

    setIsCreatingBranch(true);

    const isSuccess = await handleCreateBranchService(
      formattedBranchName,
      toastId
    );

    if (isSuccess) {
      setNewBranchName("");
      setActiveBranch(formattedBranchName);
      generate_docs();
    }
    setShowModal(false);
    setIsCreatingBranch(false);
  };

  const renameFolder = async (oldFoldername, newFoldername) => {
    return await renameFolderService(
      oldFoldername,
      newFoldername,
      activeBranch
    );
  };

  const renameFile = async (oldFilepath, newFilename) => {
    return await renameFileService(oldFilepath, newFilename, activeBranch);
  };

  const handlePullRequest = () => {
    if (activeBranch !== "main") {
      togglePullRequestModal();
      setDropdownOpen(false); // Close the dropdown when opening the modal
    } else {
      // Handle the case when the active branch is main (if needed)
    }
  };

  const handleCreateNewBranch = () => {
    setShowModal(true);
    setDropdownOpen(false); // Close the dropdown when opening the create branch modal
  };

  const handleSelectBranch = (branch) => {
    if (branch === "pull_request") {
      handlePullRequest();
    } else {
      setActiveBranch(branch);
    }
  };

  const submitEditingFile = async (element, isFile, isAdded) => {
    if (isAdded) {
      let isSuccess = false;

      if (isFile) {
        const filename = dropdownAnchor.nodes.id + "/" + element.textContent;
        element.contentEditable = "false";

        isSuccess = await createNewFile(false, filename);
        
        // Only add to changes if successful and not a temporary file
        const isTemporaryFile = element.textContent.startsWith("+") || filename.includes("/+");
        if (isSuccess && !isTemporaryFile) {
          setChangedFiles(prev => {
            // Simply append the new file if it doesn't exist, don't modify existing entries
            if (!prev.some(file => file.path === filename)) {
              return [...prev, { path: filename }];
            }
            return prev;
          });
        }
      } else {
        // Add new folder
        const foldername = dropdownAnchor.nodes.id + "/" + element.textContent;
        element.contentEditable = "false";

        isSuccess = await createFolder(foldername);
      }

      if (isSuccess) {
        // Search for the folder to edit name and id of the file / folder in reposfile array
        setFileOriginalName("");

        const reposFilesCopy = _.cloneDeep(reposFiles);

        searchForFolder(reposFilesCopy, dropdownAnchor.nodes.id, (folder) => {
          const heirarchy = folder.children?.[0].id.split("/");

          heirarchy.splice(-1, 1, element.textContent);
          folder.children[0].id = heirarchy.join("/");
          folder.children[0].name = element.textContent;
          folder.children[0].contentEditable = false;

          if(!isFile) {
            folder.children[0].children = [
              {
                ...folder.children[0],
                isFolder: false,
                id: `${folder.children[0].id}/.gitadd`,
                name: ".gitadd",
              }
            ]
          }

          updateGitFiles(reposFilesCopy);
        });
      } else {
        // Return to editing file /folder name
        setTimeout(() => element.focus(), 0);
        element.contentEditable = "true";
      }
    } else {
      // Rename file / folder
      if (isFile) {
        let completeRenaming = true;
        const fileName = dropdownAnchor.nodes.id;
        const parent = fileName.split("/").slice(0, -1).join("/");
        const newFileName = `${parent}/${element.textContent}`;

        // Check for all files names => prevent duplicating
        const reposFilesCopy = _.cloneDeep(reposFiles);
        searchByName(reposFilesCopy, element.textContent, (folder) => {
          toast.info(
            `There is already a file called ${folder.name} in your branch ${activeBranch} please choose another name.`,
            { autoClose: 5000 }
          );
          completeRenaming = false;
        });

        if (completeRenaming) {
          const isSuccess = await renameFile(fileName, element.textContent);

          if (isSuccess) {
            // Update changes list with new filename and ensure it's tracked
            setChangedFiles(prev => {
              const withoutOldFile = prev.filter(file => file.path !== fileName);
              if (!withoutOldFile.some(file => file.path === newFileName)) {
                return [...withoutOldFile, { path: newFileName }];
              }
              return withoutOldFile;
            });

            searchForFolder(reposFilesCopy, dropdownAnchor.nodes.id, (file) => {
              file.id = newFileName;
              file.name = element.textContent;

              updateGitFiles(reposFilesCopy);
            });
          } else {
            element.textContent = dropdownAnchor.nodes.name;
          }

          element.contentEditable = "false";
          setFileOriginalName("");
        }
      } else {
        const foldername = dropdownAnchor.nodes.id;
        const parent = foldername.split("/").slice(0, -1).join("/");
        const newFolderName = `${parent}/${element.textContent}`;

        // Check for all folder names => prevent duplicating
        const reposFilesCopy = _.cloneDeep(reposFiles);
        searchForFolder(reposFilesCopy, parent, (parent) => {
          if (
            parent &&
            parent.children.find((child) => child.id === newFolderName)
          ) {
            toast.info(
              `There is already a folder called ${newFolderName} in your branch ${activeBranch} please choose another name.`,
              {
                autoClose: 5000,
              }
            );
          } else {
            // Check if folder in root level
            if (foldername.split("/").length <= 1) {
              toast.info(
                "You are not allowed to rename a folder at root level."
              );
              element.textContent = fileOriginalName;
            } else {
              renameFolder(foldername, element.textContent).then(
                (isSuccess) => {
                  const folderIndex = parent.children.findIndex(
                    (el) => el.id === foldername
                  );
                  let parentId = parent.children[folderIndex].id;

                  if (isSuccess) {
                    // Edit folder name & id
                    parent.children[folderIndex].id = newFolderName;
                    parent.children[folderIndex].name = element.textContent;

                    // Edit folder id in children of it
                    const updateChildren = (child) => {
                      for (let i = 0; i < child.children.length; i++) {
                        child.children[i].id = child.children[i].id.replace(
                          parentId,
                          newFolderName
                        );

                        if (child.children[i].children.length) {
                          updateChildren(child.children[i]);
                        }
                      }
                    };

                    updateChildren(parent.children[folderIndex]);
                    updateGitFiles(reposFilesCopy);
                  } else {
                    element.textContent = dropdownAnchor.nodes.name;
                  }
                }
              );
            }

            element.contentEditable = "false";
            setFileOriginalName("");
          }
        });
      }
    }
  };

  const cancelEditingFile = (element, isFile, isAdded, isEscape = false) => {
    if (element.contentEditable === "true") {
      if (!element.textContent || isEscape) {
        element.textContent = fileOriginalName || "";

        if (isAdded) {
          // Remove added file / folder from display, if clicked escape
          const reposFilesCopy = _.cloneDeep(reposFiles);
          searchForFolder(reposFilesCopy, dropdownAnchor.nodes.id, (folder) => {
            folder.children.shift();
            updateGitFiles(reposFilesCopy);
          });
        }

        element.contentEditable = "false";
        setFileOriginalName("");
      } else {
        submitEditingFile(element, isFile, isAdded);
      }
    }
  };

  const onKeyDown = (e, isFile, isAdded) => {
    e.stopPropagation();
    if (e.currentTarget.contentEditable !== "true") return;

    if (e.key === "Escape" || e.key === "Enter") {
      e.preventDefault();

      if (e.key === "Escape") {
        cancelEditingFile(e.currentTarget, isFile, isAdded, true);
      } else {
        if (e.currentTarget.textContent.trim() === "") {
          toast.info(`Please provide a ${isFile ? "file" : "folder"} name`);
          return;
        }

        submitEditingFile(e.currentTarget, isFile, isAdded);
      }
    }
  };

  const onRenameFileHandler = () => {
    const fileName = dropdownAnchor.nodes.id;

    if (Object.keys(files).includes(fileName)) {
      toast.info("File is open. Please close it before renaming");
      return;
    }
    dropdownAnchor.element.contentEditable = "true";
    setTimeout(() => dropdownAnchor.element.focus(), 0);

    setFileOriginalName(dropdownAnchor.element.textContent);
  };

  const renderTreeItem = (nodes) => {
    const isFile = !Boolean(nodes.children.length || nodes.isFolder);
    return (
      <TreeItem
        key={nodes.id}
        itemId={nodes.id}
        slots={{
          endIcon: isFile ? File : FolderMinus,
        }}
        label={
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              minHeight: "16px",
            }}
            onClick={(e) => {
              if (e.currentTarget.contentEditable !== "true")
                openFile(activeBranch, nodes.id, "latest");
              else
                e.stopPropagation();
            }}
            onKeyDown={(e) => onKeyDown(e, isFile, nodes.contentEditable)}
            onBlur={(e) =>
              cancelEditingFile(e.currentTarget, isFile, nodes.contentEditable)
            }
            contentEditable={!!nodes.contentEditable}
            suppressContentEditableWarning={true}
          >
            {queriesSearchKeywords
              ? highlightWord(nodes.name, queriesSearchKeywords)
              : nodes.name}
          </Box>
        }
        onContextMenu={(e) => {
          e.stopPropagation();
          e.preventDefault();

          if (isFile || activeBranch !== "main") {
            setDropdownAnchor({
              element: e.currentTarget.querySelector(".MuiTreeItem-label div"),
              id: nodes.id,
              nodes,
              isFile,
              position: {
                top: e.clientY - 6,
                left: e.clientX + 2,
              },
            });
          } else {
            toggleEditingNotAllowedModal();
          }

          if (
            isFile &&
            activeBranch === "main" &&
            !nodes.name.endsWith(".sql")
          ) {
            toggleEditingNotAllowedModal();
          }
        }}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTreeItem(node))
          : null}
      </TreeItem>
    );
  };

  const renderTree = (nodes) => {
    const isFile = !Boolean(nodes.children.length || nodes.isFolder);
    return (
      <TreeItem
        key={nodes.id}
        itemId={nodes.id}
        slots={{
          endIcon: isFile ? File : null,
        }}
        label={
          <Box
            onKeyDown={(e) => onKeyDown(e, isFile, nodes.contentEditable)}
            contentEditable={!!nodes.contentEditable}
          >
            {queriesSearchKeywords
              ? highlightWord(nodes.name, queriesSearchKeywords)
              : nodes.name}
          </Box>
        }
        onClick={() => openFile(activeBranch, nodes.id, "latest")}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (isFile || activeBranch !== "main") {
            setDropdownAnchor({
              element: e.currentTarget.querySelector(".MuiTreeItem-label div"),
              id: nodes.id,
              nodes,
              isFile,
              position: {
                top: e.clientY - 6,
                left: e.clientX + 2,
              },
            });
          } else {
            toggleEditingNotAllowedModal();
          }

          if (
            isFile &&
            activeBranch === "main" &&
            !nodes.name.endsWith(".sql")
          ) {
            toggleEditingNotAllowedModal();
          }
        }}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTreeItem(node))
          : null}
      </TreeItem>
    );
  };

  const flattenQueriesList = (data) => {
    return _.flatMapDeep(data, (obj) => {
      return [obj.id, flattenQueriesList(obj.children)];
    });
  };

  const filterQueries = (data, id) => {
    const filteredData = [];

    if(!data) return;

    for (let i = 0; i < data.length; i++) {
      const node = data[i];

      if (node.name.toLowerCase().includes(id?.toLowerCase())) {
        filteredData.push(node);
      } else if (node.children && node.children.length > 0) {
        const filteredChildren = filterQueries(node.children, id);
        if (filteredChildren.length > 0) {
          filteredData.push({
            ...node,
            children: filteredChildren,
          });
        }
      }
    }

    return filteredData;
  };

  const lockScrolling = () => {
    const schemaWrapper = document.getElementById("schema-editor-helper-box");
    const queriesWrapper = document.getElementById("queries-editor-helper-box");
    const historyWrapper = document.getElementById("history-editor-helper-box");

    schemaWrapper.style.overflow = "hidden";
    queriesWrapper.style.overflow = "hidden";
    historyWrapper.style.overflow = "hidden";
  };

  const unlockScrolling = () => {
    const schemaWrapper = document.getElementById("schema-editor-helper-box");
    const queriesWrapper = document.getElementById("queries-editor-helper-box");
    const historyWrapper = document.getElementById("history-editor-helper-box");

    schemaWrapper.style.overflow = "auto";
    queriesWrapper.style.overflow = "auto";
    historyWrapper.style.overflow = "auto";
  };

  const renderChangesSection = () => {
    if (!changedFiles.length || loading_git) return null;

    // Sort files alphabetically by path
    const sortedFiles = [...changedFiles].sort((a, b) => {
      const filenameA = a.path.split('/').pop().toLowerCase();
      const filenameB = b.path.split('/').pop().toLowerCase();
      return filenameA.localeCompare(filenameB);
    });

    return (
      <Box sx={{ 
        padding: isChangesCollapsed ? '8px 0 8px 10px' : '8px 0 0 10px',
        borderBottom: '1px solid #e2e8f0',
        marginBottom: '4px',
        position: 'relative'
      }}>
        <Box 
          onClick={() => setIsChangesCollapsed(!isChangesCollapsed)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            gap: '8px',
            width: '100%',
            height: '24px',
            paddingRight: '8px'
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            flex: 1,
            minWidth: 0
          }}>
            <Typography
              variant="subtitle2"
              sx={{
                color: '#448593',
                fontSize: '13px',
                fontWeight: '500',
                display: 'flex',
                alignItems: 'center',
                gap: '4px'
              }}
            >
              Changes ({changedFiles.length})
            </Typography>
          </Box>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            flexShrink: 0,
            height: '24px'
          }}>
            <ChevronRight
              size={16}
              style={{
                transform: !isChangesCollapsed ? "rotate(90deg)" : "unset",
                transition: "all 0.2s ease",
                color: '#64748b'
              }}
            />
          </Box>
        </Box>
        {!isChangesCollapsed && (
          <Box sx={{ 
            width: '100%',
            height: `${changesBoxHeight}px`,
            maxHeight: 'none',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#cbd5e1',
              borderRadius: '3px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f5f9',
            },
            boxSizing: 'border-box',
            position: 'relative'
          }}
            onScroll={(e) => {
              // Force all tooltips to hide on scroll
              const tooltips = document.querySelectorAll('.tooltip');
              tooltips.forEach(tooltip => tooltip.style.display = 'none');
            }}>
            {sortedFiles.map((file) => (
              <Box
                key={file.path}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  height: '24px',
                  cursor: 'pointer',
                  '&:hover': {
                    color: '#495057'
                  },
                  width: '95%'
                }}
                onClick={() => openFile(activeBranch, file.path, 'latest')}
              >
                <Box
                  sx={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: file.status === 'removed' ? '#ef4444' : 
                                   file.status === 'added' ? '#22c55e' : '#fbbf24',
                    flexShrink: 0
                  }}
                />
                <OverlayTrigger
                  rootClose
                  trigger={["hover", "hover"]}
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-bottom-helper-box`}>
                      {file.path} ({file.status})
                    </Tooltip>
                  }
                >
                  <Typography
                    sx={{
                      fontSize: '11px',
                      fontWeight: 400,
                      color: '#495057',
                      fontFamily: 'inherit',
                      lineHeight: '24px',
                      flex: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {file.path.split('/').pop()}
                  </Typography>
                </OverlayTrigger>
              </Box>
            ))}
              <Box 
                className="changes-resizer"
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '4px',
                  cursor: 'ns-resize',
                  '&:hover': {
                    backgroundColor: '#e2e8f0'
                  },
                  ...(isResizingChanges && {
                    backgroundColor: '#94a3b8'
                  }),
                }}
                onMouseDown={(e) => {
                  e.preventDefault();
                  setIsResizingChanges(true);
                  
                  const startY = e.clientY;
                  const startHeight = changesBoxHeight;
                  
                  const handleMouseMove = (moveEvent) => {
                    const deltaY = moveEvent.clientY - startY;
                    
                    // Get available space in the parent container
                    const parentHeight = accordionRef.current?.clientHeight || 0;
                    const maxAllowedHeight = Math.max(300, parentHeight * 0.7); // 70% of parent or at least 300px
                    
                    // Limit height between 60px and maxAllowedHeight
                    const newHeight = Math.max(10, Math.min(maxAllowedHeight, startHeight + deltaY));
                    setChangesBoxHeight(newHeight);
                  };
                  
                  const handleMouseUp = () => {
                    document.removeEventListener('mousemove', handleMouseMove);
                    document.removeEventListener('mouseup', handleMouseUp);
                    setIsResizingChanges(false);
                  };
                  
                  document.addEventListener('mousemove', handleMouseMove);
                  document.addEventListener('mouseup', handleMouseUp);
                }}
              />
          </Box>
        )}
      </Box>
    );
  };

  // Also ensure dropdownAnchor changes don't trigger unnecessary updates
  useEffect(() => {
    if (dropdownAnchor.id === null) {
      return;
    }
  }, [dropdownAnchor]);

  // Update the delete handler to track deleted files
  const handleDelete = (filePath) => {
    toggleDeleteModal(filePath, dropdownAnchor.nodes.branch, true);
  };

  return (
    <>
      <ResizableBox
        height={!isQueriesCollapsed ? currentHeight : 48}
        resizeHandles={["n"]}
        className={`dragged-box bottom-aligned left-aligned react-resizable helper-editor-resizable ${
          (isQueriesCollapsed || isSchemaCollapsed) && "no-resize"
        }`}
        style={{
          background: "transparent",
          border: "none",
        }}
        onResizeStart={lockScrolling}
        onResizeStop={unlockScrolling}
        onResize={(_, { size: { height } }) => onResizeBox("queries", height)}
        maxConstraints={[Infinity, editor_height - historyHeight - 48]}
        minConstraints={[10, 48]}
      >
        <Accordion
          id="queries-editor-helper-box"
          expanded={!isQueriesCollapsed}
          onChange={() => {
            if (!isQueriesCollapsed) {
              if (queriesREF?.current && queriesREF?.current?.style) {
                queriesREF.current.style.height = 0;
              }
              onResizeBox("queries", -2);
            } else {
              if (queriesREF?.current && queriesREF?.current?.style) {
                queriesREF.current.style.height = "unset";
              }
              onResizeBox("queries", -1);
            }
          }}
          className="schema-list helper-list"
          sx={{
            boxShadow: "none",
            height: !isQueriesCollapsed ? "100%" : 48,
            display: "flex",
            flexDirection: "column",
            ...(isQueriesCollapsed && { overflow: "hidden" }),

            "& .MuiAccordionSummary-root": {
              minHeight: "46px !important",
            },

            "& .MuiCollapse-root": {
              flex: 1,
            },

            "& .MuiCollapse-wrapper": {
              height: "100%",
            },
          }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              display: "flex",
              color: "#448593",
              alignItems: "baseline",
              "&>div": { flexWrap: "wrap" },

              [`& .${accordionSummaryClasses.content}`]: {
                flexWrap: "wrap"
              }
            }}
          >
            <ChevronRight
              size={18}
              style={{
                marginTop: "3px",
                transform: !isQueriesCollapsed ? "rotate(90deg)" : "unset",
                transition: "all 0.2s ease",
                marginRight: "5px",
              }}
            />

            <span style={box_titles_style}>File Explorer</span>

            {!isQueriesCollapsed && (
              <div
                style={{
                  marginLeft: "auto",
                  color: "rgb(68, 133, 147)",
                  display: "flex",
                  gap: "5px",
                  marginTop: "4px",
                }}
              >
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-bottom-helper-box`}>
                      Refresh SQL Files
                    </Tooltip>
                  }
                >
                  <RefreshCw
                    size={16}
                    role="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!loading_git) {
                        fetchGitRepo(activeBranch);
                      }
                    }}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  rootClose
                  trigger={["hover", "hover"]}
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-bottom-helper-box`}>
                      Search SQL Files
                    </Tooltip>
                  }
                >
                  <Search
                    size={16}
                    role="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (git_files) {
                        toggleIsSearchingQueries();
                      }
                    }}
                  />
                </OverlayTrigger>
              </div>
            )}

            {isSearchingQueries && !isQueriesCollapsed && (
              <TextField
                id="search-queries"
                inputRef={queriesSearchRef}
                label={null}
                placeholder="search"
                variant="outlined"
                onClick={(e) => e.stopPropagation()}
                onChange={debouncedHandleChangeQueries}
                sx={{
                  width: "100%", // Add this line
                  margin: "10px 5px 5px 5px",
                  bgcolor: "white",
                  "& > div": {
                    padding: 0,
                  },
                  "& input": {
                    padding: 0.3,
                    pl: 0.8,
                    color: "#6c757d",
                  },
                  "& fieldset": { top: 0 },
                  "& fieldset legend": { display: "none" },
                  "& .MuiInputBase-sizeSmall": {
                    padding: "0 !important",
                  },
                }}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ cursor: "pointer" }}
                        id="connectors-search"
                      >
                        <Search size={14} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        onClick={clearQueriesSearch}
                        position="end"
                        sx={{
                          cursor: "pointer",
                          mr: "8px",
                        }}
                        id="clear-connectors-search"
                      >
                        <X
                          color="#6c757d"
                          size={14}
                          style={{
                            visibility: queriesSearchKeywords
                              ? "visible"
                              : "hidden",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }
                }}
              />
            )}
          </AccordionSummary>
          <Dropdown
            show={dropdownOpen}
            onToggle={(isOpen) => setDropdownOpen(isOpen)}
            className="modern-branch-dropdown"
          >
            <Dropdown.Toggle
              className="branch-selector-button"
              style={{
                width: "100%",
                backgroundColor: "#ffffff",
                border: "1px solid #e2e8f0",
                color: "#334155",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "8px",
                transition: "all 0.2s ease",
                boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                fontSize: "13px",
                fontWeight: "450",
                cursor: "pointer",
                height: '36px',
                marginRight: '-4px'
              }}
              variant="light"
              id="dropdown-basic"
            >
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <GitBranch size={14} style={{ color: "#64748b" }} />
                <span style={{ color: "#448593" }}>{activeBranch}</span>
              </div>
              <ChevronRight
                size={16}
                style={{
                  transform: dropdownOpen ? "rotate(90deg)" : "rotate(0deg)",
                  transition: "transform 0.2s ease",
                  color: "#64748b",
                  display: "flex",
                  alignItems: "center",
                  marginRight: "-4px"
                }}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="custom-dropdown-menu w-100 overflow-auto"
              style={{
                maxHeight: `calc(${!isQueriesCollapsed ? currentHeight : 48}px - 120px)`,
                zIndex: 1050
              }}
              onScroll={() => {
                // Hide all tooltips when scrolling the dropdown
                const tooltips = document.querySelectorAll('.tooltip');
                tooltips.forEach(tooltip => tooltip.style.display = 'none');
              }}
            >
              <Dropdown.Item
                onClick={(e) => {
                  e.stopPropagation();
                  if (activeBranch === "main") {
                    handleCreateNewBranch();
                  } else {
                    handlePullRequest();
                  }
                }}
              >
                {activeBranch === "main" ? (
                  <>
                    <Plus size={12} />
                    <span style={{ fontSize: "12px" }}>
                      {" "}
                      Create a new branch
                    </span>
                  </>
                ) : (
                  <>
                    <GitMerge size={12} />
                    <span style={{ fontSize: "12px" }}>
                      {" "}
                      Pull request into main
                    </span>
                  </>
                )}
              </Dropdown.Item>
              <Dropdown.Divider />
              {branches.map((branch) =>
                branch.branch_name !== activeBranch ? (
                  <OverlayTrigger
                    rootClose
                    trigger={["hover", "hover"]}
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-bottom-helper-box`}>
                        {branch.branch_name} - {branch.updated_at}
                      </Tooltip>
                    }
                    key={branch.branch_name}
                  >
                    <Dropdown.Item
                      key={branch.branch_name}
                      onClick={() => handleSelectBranch(branch.branch_name)}
                      className="branch-dropdown"
                    >
                      <GitBranch size={12} />
                      <span className="branch-dropdown-name">
                        &nbsp;
                        {branch.branch_name}
                      </span>
                      {branch.branch_name !== "main" ? (
                        <button
                          className="delete-branch-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteBranch({
                              isDeleting: false,
                              isVisible: true,
                              branch: branch.branch_name,
                            });
                          }}
                        >
                          <Trash2 size={16} />
                        </button>
                      ) : (
                        <button className="locked-branch-btn" disabled={true}>
                          <Lock size={16} />
                        </button>
                      )}
                    </Dropdown.Item>
                  </OverlayTrigger>
                ) : null
              )}
            </Dropdown.Menu>
          </Dropdown>
          {renderChangesSection()}
          
          {(loading_git || isSearching || !changedFiles.length) && <br />}

          <AccordionDetails
            sx={{
              margin: 0,
              padding: 0,
              flex: 1,
              overflow: "auto",
              maxHeight: "100%",
            }}
            className="queries-box-details"
            ref={accordionRef}
            onScroll={() => {
              // Hide all tooltips when scrolling the main file tree
              const tooltips = document.querySelectorAll('.tooltip');
              tooltips.forEach(tooltip => tooltip.style.display = 'none');
            }}
          >
            <div style={{ width: "max-content", minWidth: "100%" }}>
              {loading_git || isSearching ? (
                <center>
                  <CircularProgress size={16} color="#fff" />
                </center>
              ) : (
                <>
                  {queriesSearchKeywords &&
                    filterQueries(reposFiles, queriesSearchKeywords).length ===
                      0 && (
                      <p
                        style={{
                          margin: "0 11px",
                          marginTop: "5px",
                        }}
                      >
                        No match for your search
                      </p>
                    )}
                  <SimpleTreeView
                    ref={queriesREF}
                    aria-label="file system navigator"
                    expandedItems={expanded_list}
                    onExpandedItemsChange={(e, expandableList) => {
                      if (
                        !e.currentTarget
                          .querySelector(".MuiTreeItem-label div")
                          .isEqualNode(dropdownAnchor.element) ||
                        dropdownAnchor.element.contentEditable !== "true"
                      )
                        updateExpandedList(expandableList);
                    }}
                    slots={{
                      expandIcon: FolderMinus,
                      collapseIcon: ArrowDownCircle,
                    }}
                    sx={{
                      [`& .${treeItemClasses.label}`]: {
                        fontSize: 13,
                        fontWeight: 500,
                      },
                      [`& .${treeItemClasses.iconContainer}`]: {
                        color: "#495057",
                      },
                      [`& .${treeItemClasses.content}`]: {
                        padding: "0 8px",
                      },
                      flexGrow: 1,
                      minWidth: 150,
                    }}
                  >
                    {git_files &&
                      (queriesSearchKeywords
                        ? filterQueries(reposFiles, queriesSearchKeywords).map(
                            (nodes) => renderTree(nodes)
                          )
                        : reposFiles.map((nodes) => renderTree(nodes)))}
                  </SimpleTreeView>
                  {dropdownAnchor.isFile ? (
                    <FileContextMenu
                      dropdownAnchor={dropdownAnchor}
                      onClose={() =>
                        setDropdownAnchor({
                          ...dropdownAnchor,
                          id: null,
                        })
                      }
                      onDelete={() => handleDelete(dropdownAnchor.id)}
                      onDuplicate={() => toggleDuplicateFile(dropdownAnchor.nodes)}
                      onRename={onRenameFileHandler}
                      isMainBranch={activeBranch === "main"}
                    />
                  ) : (
                    <FolderContextMenu
                      dropdownAnchor={dropdownAnchor}
                      onClose={() =>
                        setDropdownAnchor({
                          ...dropdownAnchor,
                          id: null,
                        })
                      }
                      onRename={onRenameFileHandler}
                      onDelete={() =>
                        toggleDeleteModal(
                          dropdownAnchor.id,
                          dropdownAnchor.nodes.branch,
                          false
                        )
                      }
                      setReposFiles={(files) => {
                        setReposFiles(files);
                        updateGitFiles(files);
                      }}
                      reposFiles={
                        reposFiles ? _.cloneDeep(git_files["repos"]) : []
                      }
                    />
                  )}
                </>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </ResizableBox>

      <CreateBranchModal
        isShown={showModal}
        onHide={() => setShowModal(false)}
        creating={Creating}
        onAction={handleCreateBranch}
        newBranchName={newBranchName}
        setNewBranchName={(e) => setNewBranchName(e.target.value)}
      />

      <DeleteBranchModal
        deleteBranch={deleteBranch}
        setDeleteBranch={setDeleteBranch}
        fetchBranches={fetchBranches}
      />

      {showPullRequestModal && (
        <PullRequestModal
          setIsShown={togglePullRequestModal}
          selectedBranch={activeBranch}
          dbtCommand={dbtCommand}
          activeTenant={activeTenant}
          test_dbt_model={test_dbt_model}
          dbt_test_status={dbt_test_status}
          dbt_logs={dbt_logs}
          dbt_url={dbt_url}
        />
      )}

      <style>
        {`
          .modern-branch-dropdown .dropdown-toggle::after {
            display: none;
          }
          
          .branch-selector-button:hover {
            border-color: #cbd5e1 !important;
            box-shadow: 0 2px 4px rgba(0,0,0,0.05) !important;
          }
          
          .branch-selector-button:active {
            background-color: #f8fafc !important;
            transform: translateY(1px);
          }

          .custom-dropdown-menu .dropdown-item {
            font-size: 13px !important;
            font-weight: 400 !important;
          }

          .locked-branch-btn {
            background: transparent;
            border: none;
            padding: 4px;
            border-radius: 4px;
            color: #94a3b8;
            margin-left: auto;
            display: flex;
            align-items: center;
            transition: all 0.2s ease;
          }

          .locked-branch-btn:hover {
            background-color: #f1f5f9;
            color: #64748b;
          }

          .locked-branch-btn svg {
            width: 14px;
            height: 14px;
            stroke-width: 2px;
          }
          
          .changes-resizer {
            transition: background-color 0.2s ease;
          }
          
          body.resizing-changes {
            cursor: ns-resize !important;
            user-select: none;
          }
        `}
      </style>
    </>
  );
}
