import { config } from "../constants";
import axios from "../utils/axios";
import { toast } from "react-toastify";

export const loginService = async (google_code) => {
  return await axios.post(`${config.url.API_URL}/api/taps/google_oauth`, {
    google_code,
  });
};

export const fetchCustomerParametersService = async () => {
  try {
    const response = await axios.get(
      `${config.url.WEBAPP}/api/datajolt/customer_parameters`
    );
    const { logo, timezone, customer_name } = response.data;

    if (logo) localStorage.setItem("logo", logo);
    if (timezone) localStorage.setItem("timezone", timezone);
    if (customer_name) localStorage.setItem("activeTenantId", customer_name);
    return customer_name;
  } catch (error) {}
};
