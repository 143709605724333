import { config } from "../constants";
import axios from "../utils/axios";
import { toast } from "react-toastify";

export const fetchDbtRunsService = async (dbt_url) => {
  try {
    const response = await axios.get(`${dbt_url}/api/sql/dbt_cli_runs`);
    return response;
  } catch (_) {
    toast.error("There was an issue fetching DBT CLI runs.");
  }
};

export const fetchRunLogsService = async (run_id, dbt_url) => {
  try {
    const response = axios.post(`${dbt_url}/api/sql/get_cli_logs`, {
      run_id,
    });

    return response;
  } catch (_) {
    toast.error("There was an issue fetching the CLI logs.");
  }
};

export const executeDBTCommandService = async (
  dbt_url,
  DBT_Command,
  setCmdLogs,
  handleRunUpdate
) => {
  const url = `${dbt_url}/api/sql/dbt_custom_command`;

  try {
    await axios.post(url, DBT_Command, {
      onDownloadProgress: (progressEvent) => {
        const api_logs = progressEvent.event.currentTarget.response;

        if (
          api_logs.slice(0, 5) === "[API]" &&
          !api_logs.includes("DBT run finished") &&
          !api_logs.includes("[ERROR]")
        ) {
          setCmdLogs(api_logs);
        } else {
          const status = api_logs.includes("DBT run finished successfully.")
            ? "success"
            : "failed";
          if (status === "success") {
            toast.success("DBT command ran successfully. 🎉");
          } else {
            if (api_logs.includes("does not match any enabled nodes")) {
              toast.info(
                "The selection criterion doesn't match any enabled nodes."
              );
            } else {
              toast.error("There was an issue with your DBT command.");
            }
          }

          handleRunUpdate(api_logs, status);
        }
      },
    });
    return true;
  } catch (_) {
    toast.error("There was an issue with your DBT command.");
    return false;
  }
};

export const getLineageService = async (dbt_url, DBT_Command) => {
  try {
    const response = await axios.post(`${dbt_url}/api/sql/dbt_lineage`, DBT_Command);
    return response;
  } catch (_) {
    toast.error("There was an issue getting lineage for your model.");
  }
};

export const fetchRepoFromPgService = async (branch) => {
  try {
    const response = await axios.post(`${config.url.GIT_URL}/api/git/get_repo_from_pg`, {
      branch,
    });
    return response;
  } catch (_) {
    toast.error("There was an issue loading your SQL Queries.");
  }
};

export const fetchGitRepoService = async (branch) => {
  try {
    const response = await axios.post(
      `${config.url.GIT_URL}/api/git/list_files`,
      {
        branch,
      }
    );
    return response;
  } catch (_) {
    toast.error("There was an issue loading your SQL Queries.");
  }
};

export const saveSQLService = async (file, branch, content) => {
  const toastId = toast.loading(`Saving ${file} in ${branch}...`);
  const data = {
    content,
    filename: `dags/dbt/${file}`,
    branch,
  };
  let isSuccess = false;

  try {
    await axios.post(`${config.url.GIT_URL}/api/git/save_file`, data);
    toast.success(`${file} saved successfully. 🎉`, { autoClose: 5000 });
    isSuccess = true;
  } catch (_) {
    toast.error("There was an issue saving your file. Please try again.");
  }
  toast.dismiss(toastId);
  return isSuccess;
};

export const openLineageAfterSavingSQLService = async (
  filename,
  branch,
  content
) => {
  const toastId = toast.loading(`Saving ${filename} in ${branch}...`);
  const data = {
    content,
    filename,
    branch,
  };
  let error;

  try {
    await axios.post(`${config.url.GIT_URL}/api/git/save_file`, data);
    toast.success(`${filename} saved successfully. 🎉`, { autoClose: 5000 });
  } catch (err) {
    error = err;
  }
  toast.dismiss(toastId);
  return error;
};

export const runSQLService = async (dbt_url, SQL, activeTab) => {
  const start = performance.now();
  const today = new Date();
  const start_time = `${String(today.getHours()).padStart(2, "0")}:${String(
    today.getMinutes()
  ).padStart(2, "0")}:${String(today.getSeconds()).padStart(2, "0")}`;
  let isSuccess = true; // Default to true
  let errorResponse = null;

  try {
    // Use streaming approach
    const response = await axios.post(`${dbt_url}/api/sql/submit_sql`, SQL, {
      timeout: 1800000,
      responseType: 'text',
      onDownloadProgress: (progressEvent) => {
        const responseText = progressEvent.event.currentTarget.response;
        const lines = responseText.split('\n').filter(line => line.trim());
        
        if (lines.length > 0) {
          try {
            // Process the last line as the most recent update
            const lastLine = lines[lines.length - 1];
            const data = JSON.parse(lastLine);
            
            // Check for error directly
            if (data.error) {              
              // Set isSuccess to false when there's an error
              isSuccess = false;
              
              // Store the error in the response
              response = {
                status: 400,  // Use a different status code to indicate error
                data: {
                  error: data.error,
                  sql_code: SQL.sql
                }
              };
            } else if (data.status === "processing" || data.status === "connected" || 
                        data.status === "executing_query" || data.status === "query_running") {
              // Update UI to show query is still running
              console.log(`Query status: ${data.status}`);
            } else if (data.display_results && data.columns) {
              // We have results
              const duration = (performance.now() - start) / 1000;
              localStorage.setItem(
                "last_successful_query",
                JSON.stringify({
                  results_tab: activeTab,
                  started_query: start_time,
                  columns: data.columns,
                  query_time: duration.toFixed(2),
                  sql_results: data.display_results,
                  logs: false,
                })
              );
            }
          } catch (e) {
            console.error("Error parsing streaming response:", e);
          }
        }
      }
    });

    // For backward compatibility, construct a response object
    // that matches what the UI expects
    const lastLine = response.data.split('\n')
      .filter(line => line.trim())
      .pop();
    
    if (lastLine) {
      try {
        const parsedData = JSON.parse(lastLine);
        
        // If we have results, return them in the expected format
        if (parsedData.display_results && parsedData.columns) {
          return { 
            isSuccess: true, 
            response: {
              status: 200,
              data: parsedData
            }
          };
        } else if (parsedData.status === "success_with_no_result") {
          return { 
            isSuccess: true, 
            response: {
              status: 200,
              data: "success_with_no_result"
            }
          };
        } else if (parsedData.error) {
          // Return error with status 201 to match original behavior
          return { 
            isSuccess: false, 
            response: {
              status: 201,
              data: {
                error: parsedData.error,  // Preserve the HTML formatting
                sql_code: SQL.sql  // Include the original SQL code
              }
            }
          };
        }
      } catch (e) {
        console.error("Error parsing final response:", e);
      }
    }
    
    // Default success response if we couldn't parse the data
    return { isSuccess: true, response: { status: 200 } };
  } catch (err) {
    if (!err.response)
      toast.error("There was an issue communicating with backend.");
    
    // Return a properly structured error response that won't trigger the Fix Now button
    return { 
      isSuccess: false, 
      response: {
        status: 500,
        data: {
          error: "Communication error with backend",
          is_backend_error: true
        }
      }
    };
  }
};

export const cancelSQLService = async (
  dbt_url,
  running_queries,
  sql_results
) => {
  const SQL = { sql: Object.values(running_queries)[0] };

  try {
    await axios.post(`${dbt_url}/api/sql/cancel_sql`, SQL);
  } catch (err) {
    // dont show error message if results are already displayed
    if (!sql_results.length) {
      toast.error("There was an issue cancelling your query.");
    }
  }
};

export const getFileHistoryService = async (filename, branch) => {
  try {
    const response = await axios.post(
      `${config.url.GIT_URL}/api/git/get_file_history`,
      {
        filename: `dags/dbt/${filename}`,
        branch,
      }
    );
    return response;
  } catch (_) {
    toast.error("There was an issue getting file history from GitHub.");
  }
};

export const generateDBTDocsService = async (dbt_url, branch) => {
  try {
    await axios.post(`${dbt_url}/generate_dbt_docs`, {
      branch,
    });
  } catch (_) {}
};

export const createFolderService = async (activeBranch, foldername) => {
  const displayName = foldername.split('/').pop();
  const toastId = toast.loading(`Creating folder ${displayName}...`);
  const data = {
    branch: activeBranch,
    content: "Folder created",
    filename: `dags/dbt/${foldername}`,
  };
  let isSuccess = false;

  try {
    await axios.post(`${config.url.GIT_URL}/api/git/save_file`, data);
    toast.success(`Folder ${displayName} created successfully. 🎉`, {
      autoClose: 5000,
    });
    isSuccess = true;
  } catch (_) {
    toast.error("There was an issue creating your folder.");
  }
  toast.dismiss(toastId);
  return isSuccess;
};

export const createFileService = async (filename, branch, content) => {
  const displayName = filename.split('/').pop();
  const toastId = toast.loading(`Creating file ${displayName}...`);
  const data = {
    branch,
    content,
    filename: `dags/dbt/${filename}`,
  };
  let isSuccess = false;

  try {
    await axios.post(`${config.url.GIT_URL}/api/git/save_file`, data);

    toast.success(`File ${displayName} created successfully. 🎉`, {
      autoClose: 5000,
    });
    isSuccess = true;
  } catch (_) {}
  toast.dismiss(toastId);
  return isSuccess;
};

export const openFileService = async (GitDetails) => {
  try {
    const response = await axios.post(
      `${config.url.GIT_URL}/api/git/open_file`,
      GitDetails
    );

    return response;
  } catch (err) {
    toast.error("There was an issue loading your query from Github.");
  }
};

export const deleteFileService = async (file, branch) => {
  const displayName = file.split('/').pop();
  const toastId = toast.loading(`Deleting ${displayName}...`);
  let isSuccess = false;

  try {
    await axios.post(`${config.url.GIT_URL}/api/git/delete_file`, {
      filename: `dags/dbt/${file}`,
      branch,
      repo: "sql",
    });
    toast.success(`${displayName} deleted successfully.`);
    isSuccess = true;
  } catch (_) {
    toast.error(`There was an issue deleting ${file}.`);
  }
  toast.dismiss(toastId);
  return isSuccess;
};

export const deleteFolderService = async (foldername, branch) => {
  const displayName = foldername.split('/').pop();
  const toastId = toast.loading(`Deleting folder ${displayName}...`);
  let isSuccess = false;

  try {
    const response = await axios.post(
      `${config.url.GIT_URL}/api/git/delete_folder`,
      {
        folderpath: `dags/dbt/${foldername}`,
        branch,
      }
    );

    if (response.status === 200) {
      toast.success(`Folder ${displayName} deleted successfully. 🎉`, { autoClose: 5000 });
      isSuccess = true;
    } else {
      toast.error("Failed to delete folder", { autoClose: 5000 });
    }
  } catch (error) {
    toast.error(
      `Error: ${error.response ? error.response.data.error : error.message}`,
      { autoClose: 5000 }
    );
  }
  toast.dismiss(toastId);

  return isSuccess;
};

export const saveAllUnsavedFilesService = async (files, branch) => {
  const toastId = toast.loading("Saving all unsaved files...");
  let isSuccess = false;

  try {
    await axios.post(`${config.url.GIT_URL}/api/git/save_all_files`, {
      files,
      branch,
    });

    toast.success("All files saved successfully. 🎉", { autoClose: 5000 });
    isSuccess = true;
  } catch (_) {}
  toast.dismiss(toastId);
  return isSuccess;
};

export const duplicateFileService = async (file_to_duplicate, data, filename) => {
  const displayName = file_to_duplicate.split('/').pop();
  const newDisplayName = filename.split('/').pop();
  const toastId = toast.loading(`Duplicating ${displayName}...`);
  let isSuccess = false;

  try {
    await axios.post(`${config.url.GIT_URL}/api/git/duplicate_file`, data);

    toast.success(
      `File ${displayName} duplicated successfully as ${newDisplayName}. 🎉`,
      { autoClose: 5000 }
    );
    isSuccess = true;
  } catch (_) {
    toast.error(`There was an issue duplicating ${displayName}.`, {
      autoClose: 5000,
    });
  }
  toast.dismiss(toastId);
  return isSuccess;
};

export const fetchDbtDocsService = async (dbtUrl, branch) => {
  try {
    const response = await axios.post(`${dbtUrl}/serve_dbt_docs`, {
      branch,
    });
    return response.data;
  } catch (error) {
    return '<p>Could not find DBT Docs.</p> <br/>Make sure you run <b>"dbt docs generate --static"</b> in the DBT CLI first.';
  }
};

export const onDeleteBranchService = async (branch) => {
  const toastId = toast.loading(`Deleting branch ${branch}...`, {
    autoClose: false,
  });
  let isSuccess = false;

  try {
    await axios.post(`${config.url.GIT_URL}/api/git/delete_branch`, {
      branch,
    });

    toast.success(`Branch ${branch} deleted successfully!`);
    isSuccess = true;
  } catch (error) {
    toast.error(
      `Error deleting branch ${branch}: ${error.response.data.error}`
    );
  }

  toast.dismiss(toastId);

  return isSuccess;
};

export const fetchSchemasService = async (dbtUrl) => {
  try {
    const response = await axios.get(`${dbtUrl}/api/sql/get_dwh_structure`);
    return response;
  } catch (_) {
    toast.error("There was an issue loading schemas from your DWH.");
  }
};

export const fetchBranchesService = async () => {
  try {
    const response = await axios.post(
      `${config.url.GIT_URL}/api/git/get_branches`
    );
    return response;
  } catch (_) {}
};

export const handleCreateBranchService = async (branch, toastId) => {
  let isSuccess = false;

  try {
    await axios.post(`${config.url.GIT_URL}/api/git/create_branch`, {
      branch,
    });
    toast.dismiss(toastId);
    toast.success(`Branch ${branch} created successfully 🎉`);
    localStorage.setItem("selected_branch", branch);
    isSuccess = true;
  } catch (_) {
    toast.error("Branch could not be created");
  }
  toast.dismiss(toastId);
  return isSuccess;
};

export const renameFolderService = async (
  old_foldername,
  new_foldername,
  branch
) => {
  const toastId = toast.loading(
    `Renaming folder ${old_foldername} to ${new_foldername}...`
  );
  let isSuccess = false;

  try {
    const response = await axios.post(
      `${config.url.GIT_URL}/api/git/rename_folder`,
      {
        old_foldername,
        new_foldername,
        branch,
      }
    );

    if (response.status === 200) {
      toast.success("Folder renamed successfully", { autoClose: 5000 });
      isSuccess = true;
    } else {
      toast.error("Failed to rename folder", { autoClose: 5000 });
    }
  } catch (_) {
    toast.error(`There was an issue renaming the folder`, {
      autoClose: 5000,
    });
  }
  toast.dismiss(toastId);
  return isSuccess;
};

export const renameFileService = async (old_filepath, new_filename, branch) => {
  const displayName = old_filepath.split('/').pop();
  const newDisplayName = new_filename.split('/').pop();
  const toastId = toast.loading(
    `Renaming file ${displayName} to ${newDisplayName}...`
  );
  let isSuccess = false;

  try {
    const response = await axios.post(
      `${config.url.GIT_URL}/api/git/rename_file`,
      {
        old_filepath,
        new_filename,
        branch,
      }
    );

    if (response.status === 200) {
      toast.success(`File ${newDisplayName} renamed successfully. 🎉`, { autoClose: 5000 });
      isSuccess = true;
    } else {
      toast.error("Failed to rename file", { autoClose: 5000 });
    }
  } catch (_) {
    toast.error(`There was an issue renaming the file`, {
      autoClose: 5000,
    });
  }
  toast.dismiss(toastId);
  return isSuccess;
};

export const getInitialNodesService = async (run_id, workflow_name) => {
  try {
    const response = await axios.post(
      `${config.url.API_URL}/api/workflows/metadata/dag_level`,
      {
        run_id,
        workflow_name,
      }
    );
    return response;
  } catch (_) {
    toast.error("Error fetching your tasks status");
  }
};

export const openGitHubPullRequestService = async (dbt_url) => {
  try {
    axios.post(`${dbt_url}/api/sql/kill_dbt_process`);
  } catch (_) {}
};

export const createDAGService = async (data) => {
  const toastId = toast.loading("Creating your DAG...");
  let isSuccess = false;

  try {
    await axios.post(`${config.url.AIRFLOW}/api/airflow/schedule_dag`, data);

    toast.success(`DAG ${data.dag_alias} successfully created 🎉`, {
      autoClose: 5000,
    });
    isSuccess = true;
  } catch (_) {
    toast.error("There was an issue creating your DAG");
  }
  toast.dismiss(toastId);
  return isSuccess;
};

export const fetchWorkflowsService = async () => {
  try {
    const response = await axios.get(
      `${config.url.AIRFLOW}/api/airflow/list_dags`
    );

    return response;
  } catch (_) {
    toast.error("Error while loading DAGs.");
  }
};

export const fetchTestsService = async (dbt_url, model_path, branch) => {
  try {
    const response = await axios.post(`${dbt_url}/api/sql/get_existing_tests`, {
      model_path,
      branch,
    });
    const existingTests = response.data;
    const columnTests = {};
    const tableTests = {};

    if (Array.isArray(existingTests)) {
      existingTests.forEach((test) => {
        if (test.column) {
          if (!columnTests[test.column]) {
            columnTests[test.column] = [];
          }
          columnTests[test.column].push(test);
        } else {
          tableTests[test.name] = test;
        }
      });
    }
    return { columnTests, tableTests };
  } catch (error) {
    if (error.error) {
      toast.error(error.error);
    } else {
      toast.error("Failed to fetch data quality tests...");
    }
  }
};

export const fetchAvaialbleTestsService = async (dbt_url) => {
  try {
    const response = await axios.get(`${dbt_url}/api/sql/get_available_tests`);
    return response;
  } catch (error) {
    toast.error("Failed to fetch available tests.");
    throw error;
  }
};

export const fetchColumnsFromEditorService = async (dbt_url, data) => {
  const ErrorMessage = () => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
      <span>Unable to get columns from your model:</span>
      <span>• run your SQL to make sure it is valid</span>
      <span>• run 'dbt parse' in the DBT CLI to ensure your project is setup properly</span>
    </div>
  );

  try {
    const response = await axios.post(`${dbt_url}/api/sql/get_columns_for_tests`, data);

    if (response.data && response.data.columns) {
      return response;
    } else {
      toast.error(ErrorMessage, { autoClose: 10000 });
    }
  } catch (_) {
    toast.error(ErrorMessage, { autoClose: 10000 });
  }
};

export const handleSaveTestsService = async (dbt_url, data) => {
  try {
    const response = axios.post(`${dbt_url}/api/sql/save_tests`, data);

    if (response.status === 200) {
      toast.success("Tests saved successfully! 🎉");
      return response;
    } else {
      toast.warning("Tests saved, but there might be an issue. Please check.");
    }
  } catch (_) {
    toast.error("Failed to save tests.");
  }
};