import React from "react";
import { Outlet } from "react-router-dom";
import { Stack } from "@mui/material";
import Main from "../components/Layout/Main";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Content from "../components/Layout/Content";
import Sidebar from "../components/Layout/Sidebar";

const Dashboard = ({ children }) => (
  <React.Fragment>
    <Stack direction={"row"} width={"100%"} alignItems={"stretch"}>
      <Sidebar />
      <Main>
        <Navbar />
        <Content>
          {children}
          <Outlet />
        </Content>
        <Footer />
      </Main>
    </Stack>
  </React.Fragment>
);

export default Dashboard;
