import React, { useState, useEffect } from "react";
import { Accordion, OverlayTrigger, Tooltip, Nav } from "react-bootstrap";
import moment from "moment-timezone";
import { fetchLogsService } from "../../services/tables";
import { CircularProgress } from "@mui/material"
import './LogsTable.css';

const LogsTable = (props) => {
  const { selected_job, dag_started_at, selected_dag_state, dag_logs } = props;
  var customer_timezone;
  (function retryFetch(
    retryDelay = 2000,
    maxAttempts = 10,
    currentAttempt = 1
  ) {
    var localTimezone = localStorage.getItem("timezone");

    if (!localTimezone && currentAttempt <= maxAttempts) {
      console.log(
        `Attempt ${currentAttempt}: und, retrying in ${retryDelay}ms...`
      );
      setTimeout(
        () => retryFetch(retryDelay, maxAttempts, currentAttempt + 1),
        retryDelay
      );
    } else if (localTimezone) {
      customer_timezone = localTimezone; // Update the broader scoped variable
      // Proceed with your logic here
    } else {
      console.log("Max attempts reached. Failed to get timezone.");
      // Handle failure case here
    }
  })();

  const logStates = [...new Set(dag_logs.map((log) => log.state))];
  const [filteredLogs, setFilteredLogs] = useState(dag_logs);
  const [activeTab, setActiveTab] = useState("all");
  const [loading, setLoading] = useState(false);
  const [logDetails, setLogDetails] = useState({});
  const [activeKey, setActiveKey] = useState("");

  useEffect(() => {
    if (activeTab === "all") {
      setFilteredLogs(dag_logs);
    } else {
      setFilteredLogs(dag_logs.filter((log) => log.state === activeTab));
    }
  }, [dag_logs, activeTab]);

  useEffect(() => {
    if (dag_logs.map((log) => log.state).includes("FAILED")) {
      setActiveTab("FAILED");
    } else {
      setActiveTab("all");
    }
    return () => {
      setActiveTab("all");
    };
  }, [dag_logs]);

  const styleActiveTab = (tabName) =>
    tabName === activeTab
      ? {
          color: "black",
          fontWeight: "bold",
        }
      : {
          color: "grey",
          fontWeight: "unset",
        };

        const fetchLogs =async (task_id, run_id, task_type) => {
          setLoading(true);
          setLogDetails('');      
          
          const logs = await fetchLogsService({
            dag_id: selected_job,
            dag_run_id: run_id,
            task_id: task_id,
            task_type: task_type,
          })

          if(logs) {
            setLogDetails(logs);
          }

          setLoading(false);
        };

        
  return (
    <tr style={{ background: "transparent", outline: "none", padding: 0 }}>
      <td
        colspan="100%"
        style={{ background: "transparent", outline: "none", padding: 0 }}
      >
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Nav
            variant="tabs"
            activeKey={activeTab}
            onSelect={(k) => {
              setActiveTab(k);
              setActiveKey("");
            }}
          >
            <Nav.Item>
              <Nav.Link eventKey="all" style={styleActiveTab("all")}>
                All ({dag_logs.length})
              </Nav.Link>
            </Nav.Item>
            {logStates.map((state, index) => (
              <Nav.Item key={index}>
                <Nav.Link eventKey={state} style={styleActiveTab(state)}>
                  {state} (
                  {dag_logs.filter((log) => log.state === state).length})
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          <section
            style={{
              background: "white",
              padding: "10px",
              border: "1px solid #dde6ec",
              borderRadius: "0 8px 8px 8px",
              boxShadow: "rgba(26, 25, 77, 0.12) 0px 2px 4px 0px",
              fontSize: "12px",
            }}
          >
            <header>
              <b>
                Logs for run started at{" "}
                {moment
                  .utc(dag_started_at)
                  .tz(customer_timezone)
                  ?.format("YYYY-MM-DD HH:mm")}                
              </b>
              <p
                style={{
                  color:
                    selected_dag_state === "success"
                      ? "#54CB75"
                      : selected_dag_state === "failed"
                      ? "red"
                      : "",
                }}
              >
                {selected_dag_state.charAt(0).toUpperCase() +
                  selected_dag_state.slice(1)}
              </p>
            </header>

            <Accordion style={{ width: "100%" }} className="log-accordion" activeKey={activeKey}
              onSelect={(eventKey) => {
                const log = JSON.parse(eventKey);

                if(log) {
                  setActiveKey(eventKey);
                  fetchLogs(log.task_id, log.run_id,log.task_type)
                } else {
                  setActiveKey("");
                }
              }}>
              {filteredLogs.map((log, index) => (
                <Accordion.Item
                  eventKey={JSON.stringify(log)}
                  className={`bg-white ${(selected_dag_state === 'running' || selected_dag_state === 'waiting') ? 'pulse-border' : ''}`}
                  key={index.toString()}
                  style={{
                    border: log.state === 'SUCCESS' ? '1px solid #54CB75' 
                           : log.state === 'FAILED' ? '1px solid red'
                           : '1px solid #dde6ec'
                  }}
                >
                  <Accordion.Header>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <OverlayTrigger
                        rootClose
                        trigger={["hover", "hover"]}
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>{log.state}</Tooltip>
                        }
                      >
                        <p>{log.task_id}</p>
                      </OverlayTrigger>
                      <p>
                        {log.state === 'SKIPPED' ? "Skipped" :
                         selected_dag_state == "running" ? "Running..." :
                         selected_dag_state == "waiting" ? "Waiting..." :
                         log.end_date === '' ? "Running..." :
                         moment.utc(log.end_date)
                           .tz(customer_timezone)
                           ?.format("YYYY-MM-DD HH:mm")
                        }
                      </p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body style={{ padding: "0px" }}>
                    {loading ? (
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px", backgroundColor: "black" }}>
                        <CircularProgress size={16} color="#fff" />
                      </div>
                    ) : (
                      <p
                        className="logs-container"
                        dangerouslySetInnerHTML={{ __html: logDetails }}
                      />
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </section>
        </div>
      </td>
    </tr>
  );
};

export default LogsTable;
